import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";
import { DeletedEventOutput } from "../../graphQL/outputModels/DeletedEvent.output";
import { DeleteEventInput } from "../../graphQL/inputModels/deleteEvent.input";
import { Event } from "../../models/Event";

interface DeleteEventDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  event: Event;
}

export default function DeleteEventDialog({ open, onClose, event }: DeleteEventDialogProps) {
  //GRAPHQL
  const [deleteEvent, { loading: loadingDeleteEvent, data: deletedEvent }] = useMutation<DeletedEventOutput, DeleteEventInput>(MUTATIONS.DELETE_EVENT);

  // HANDLERS
  const handleDelete = () => {
    deleteEvent({
      variables: {
        input: {
          eventId: event.eventId!,
        },
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedEvent?.deleteEvent.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(true);
      appNotification({
        severity: "success",
        message: `Successfully deleted '${deletedEvent.deleteEvent.eventName}'`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedEvent]);

  // RENDER
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth>
      <DialogTitle>
        Warning: Deleting '{event.eventName}'
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>You are about to PERMANENTLY delete this event and all of its contents. Are you sure you want to proceed? You cannot undo this action.</DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteEvent && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteEvent}>
          Delete Event
        </Button>
      </DialogActions>
    </Dialog>
  );
}
