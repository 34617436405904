import { Box, Typography } from "@mui/material";
import appConfig from "../../appConfig";

export default function EnvironmentIndicator() {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 10,
        right: 10,
        width: 100,
        backgroundColor: (theme) => theme.palette.cyan.lighter,
        borderRadius: "2px",
        boxShadow: "0 0 5px 2px rgb(0 0 0 / 20%)",
        color: "red",
        textAlign: "center",
      }}
    >
      <Typography fontFamily="AvenirRoman" fontWeight="bold" letterSpacing={1}>
        {appConfig.environment.toUpperCase()}
      </Typography>
    </Box>
  );
}
