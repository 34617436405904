import { Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { MemberSummaryScore } from "../../models/MemberSummaryScore";

interface PieChartProps {
  data: MemberSummaryScore;
}
export default function PieChart({ data }: PieChartProps) {
  // SETUP
  const theme = useTheme();

  // USEMEMOS
  const mappedData = useMemo(() => {
    return [
      {
        id: "Compliant",
        label: "Compliant",
        value: data.compliantCount ?? 0,
        color: theme.palette.success.main,
      },
      {
        id: "NonCompliant",
        label: "Non Compliant",
        value: data.noncompliantCount ?? 0,
        color: theme.palette.error.main,
      },
      {
        id: "N/A",
        label: "N/A",
        value: data.otherCount ?? 0,
        color: theme.palette.warning.main,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // HANDLERS
  const getColor = (d: any) => {
    var g = mappedData.find((m) => m.id === d.id);
    return g?.color ?? "#000";
  };

  // RENDER
  return (
    <>
      {data.compliantCount === 0 && data.noncompliantCount === 0 && data.otherCount === 0 ? (
        <Typography pt="120px">There is not enough data</Typography>
      ) : (
        <ResponsivePie
          data={mappedData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={10}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.5]],
          }}
          arcLinkLabel="label"
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          colors={getColor}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: -100,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
}
