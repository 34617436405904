import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";
import { PortalFile } from "../../models/PortalFile";
import { DeletedFileOutput } from "../../graphQL/outputModels/DeletedFile.output";
import { DeleteFileInput } from "../../graphQL/inputModels/deleteFile.input";

interface DeleteFileDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  file: PortalFile;
}

export default function DeleteFileDialog({ open, onClose, file }: DeleteFileDialogProps) {
  //GRAPHQL
  const [deleteFile, { loading: loadingDeleteFile, data: deletedFile }] = useMutation<DeletedFileOutput, DeleteFileInput>(MUTATIONS.DELETE_FILE);

  // HANDLERS
  const handleDelete = () => {
    deleteFile({
      variables: {
        input: {
          fileId: file.fileId!,
        },
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (!deletedFile) return;

    if (deletedFile?.deleteFile.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(true);
      appNotification({
        severity: "success",
        message: `Successfully deleted '${deletedFile.deleteFile.fileName}.${deletedFile.deleteFile.fileExtension}'`,
      });
    } else {
      console.error("deletion error! - " + deletedFile?.deleteFile.actionStatusMessage);
      onClose(false);
      if (deletedFile?.deleteFile) {
        appNotification({
          severity: "error",
          message: `Error deleting '${deletedFile.deleteFile.fileName}.${deletedFile.deleteFile.fileExtension}'`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedFile]);

  // RENDER
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth>
      <DialogTitle>
        {`Warning: Deleting '${file.fileName}.${file.fileExtension}'`}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>You are about to PERMANENTLY delete this file. Are you sure you want to proceed? You cannot undo this action.</DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteFile && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteFile}>
          Delete File
        </Button>
      </DialogActions>
    </Dialog>
  );
}
