import dayjs from "dayjs";
import { Assessment, AssessmentCustom } from "../../../models/Assessment";

export const assessmentsMapper = {
    mapToCustom
}


function mapToCustom(list: Assessment[]): AssessmentCustom[] {
    var ret: AssessmentCustom[] = [];
    list.forEach((v, i) => {
        var totalQuestions = v.assessmentQuestions?.length ?? 0;
        var totalAnswers = v.assessmentQuestions?.filter(q => q.selectedAssessmentQuestionResponseId).length ?? 0;
        var d = v.assessmentDate ? dayjs(v.assessmentDate) : dayjs();

        ret.push({
            ...v,
            totalQuestions,
            totalAnswers,
            assessmentDateDate: d
        })
    })

    return ret;
}