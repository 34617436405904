import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";
import { DownloadUtils } from "../utils/download.utils";
import dayjs from "dayjs";
import { appNotification } from "../apollo/PortalApolloProvider";

type vars = {
    startRange: string
    endRange: string
}
type ExportQueryRet = {
    execute: (params: vars) => void;
    loading: boolean;
}
export default function useLogExportQuery(): ExportQueryRet {
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ startRange, endRange }: vars) => {
        async function run({ startRange, endRange }: vars) {
                console.log("executing export")
                setLoading(true);
                try {
                    const response = await FetchUtils.EXPORT_LOG_DATA(
                        startRange, endRange
                    );
                    if (response) {
                        var currTime = dayjs().format("YYYYMMDD-HHmmss");
                        DownloadUtils.downloadBlob(response, "LogDataReport_" + currTime, "xlsx");
                    }
                }
                catch (e: any) {
                    console.error(e);
                    appNotification({
                        severity: "error",
                        message: "There was an issue downloading the log data.",
                    });
                }
                setLoading(false);
            }

        run({ startRange, endRange })
    }, []);



    return {
        execute,
        loading: loading,
    };
}