import { Box, Typography } from "@mui/material";

export default function PublicLandingPage() {
  return (
    <Box
      sx={{
        color: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Typography variant="h4">Welcome!</Typography>
      <Typography
        variant="h4"
        fontFamily="AvenirBlack"
        color=(theme) => theme.palette.cyan.light
        mt={3}
      >
        Caring Communities Portal
      </Typography> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          textAlign: "center",
          color: "text.primary",
        }}
      >
        <Typography
          sx={{
            // display: { xs: "none", md: "flex" },
            fontFamily: "AvenirRoman",
            fontSize: "2em",
            lineHeight: "1em",
            // color: "white",
            letterSpacing: 2,
            top: "-2em",
            // fontWeight: 700,
            // textDecoration: "none",
          }}
        >
          WELCOME TO
        </Typography>

        <Box
          component="img"
          sx={{
            height: "auto",
            maxHeight: 500,
            width: "auto",
            maxWidth: 500,
            mt: 8,
          }}
          alt=""
          src="/images/cc-logo-white.png"
        />

        <Typography
          sx={{
            // display: { xs: "none", md: "flex" },
            fontFamily: "AvenirRoman",
            fontSize: "2em",
            // color: "white",
            letterSpacing: 2,
            top: "3.5em",
            mt: 10,
            // fontWeight: 700,
            // textDecoration: "none",
          }}
        >
          MEMBER PORTAL
        </Typography>
      </Box>
    </Box>
  );
}
