import { Box, SxProps, Theme } from "@mui/material";

interface ActionLinkProps {
  href?: string;
  disabled?: boolean;
  children: string;
}

export function ActionLink({ href, disabled, children }: ActionLinkProps) {
  const enabledStyle: SxProps<Theme> = {
    backgroundColor: (theme) => theme.palette.orange.main,
    color: "white",
    textDecoration: "none",
    textTransform: "uppercase",
    fontFamily: "AvenirRoman",
    fontSize: "0.875em",
    mt: 1,
    p: 1.5,
    borderRadius: 1,
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    ":hover": {
      backgroundColor: (theme) => theme.palette.orange.dark,
    },
  };

  const disabledStyle: SxProps<Theme> = {
    backgroundColor: "rgba(145, 158, 171, 0.24)",
    color: "rgba(145, 158, 171, 0.8)",
    textDecoration: "none",
    textTransform: "uppercase",
    fontFamily: "AvenirRoman",
    fontSize: "0.875em",
    mt: 1,
    p: 1.5,
    borderRadius: 1,
    boxShadow: "none",
    cursor: "default",
  };

  return (
    <Box
      component="a"
      href={href?.includes("http") ? href : "//" + href}
      target="_blank"
      rel="noopener noreferrer"
      sx={disabled ? disabledStyle : enabledStyle}
      onClick={(event) => (disabled ? event.preventDefault() : undefined)}
    >
      {children}
    </Box>
  );
}
