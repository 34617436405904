import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ResponsiveBar } from "@nivo/bar";
import { useMemo } from "react";
import { YESSummaryPrescriptiveScore, YESSummaryPrescriptiveScoreCustom } from "../../models/MemberSummaryScore";
import dayjs from "dayjs";

interface BarGraphProps {
  data: YESSummaryPrescriptiveScore[];
}
export default function BarGraph({ data }: BarGraphProps) {
  // SETUP
  const theme = useTheme();

  // USEMEMOS
  const mappedData = useMemo(() => {
    return data.map((d): YESSummaryPrescriptiveScoreCustom => {
      return {
        ...d,
        assessmentDate: dayjs(d.assessmentDate),
      };
    });
  }, [data]);

  const sortedData = useMemo(() => {
    var ret: any[] = [];
    const sortedData = mappedData;
    sortedData.sort((a, b) => a.assessmentDate!.diff(b.assessmentDate!));
    for (var i = 0; i < sortedData.length; i++) {
      let d = sortedData[i];
      let existingIndex = ret.findIndex((r) => r.id === d.prescriptiveLabel!);
      let year = d.assessmentDate?.year();
      if (existingIndex >= 0) {
        ret[existingIndex] = {
          ...ret[existingIndex],
          [`Year ${year}`]: d.prescriptiveScorePercent,
        };
      } else {
        ret.push({
          id: d.prescriptiveLabel,
          [`Year ${year}`]: d.prescriptiveScorePercent,
        });
      }
    }
    return ret;
  }, [mappedData]);

  const barKeys = useMemo(() => {
    var ret: string[] = [];
    for (let i = 0; i < mappedData.length; i++) {
      let d = mappedData[i];
      let key = `Year ${d.assessmentDate?.year()}`;
      let existingIndex = ret.findIndex((r) => r === key);
      if (existingIndex < 0) {
        ret.push(key);
      }
    }
    return ret;
  }, [mappedData]);

  // HANDLERS
  const getColor = (d: any) => {
    var year = `${d.id}`;
    var yearIndex = barKeys.findIndex((k) => k === year);
    switch (yearIndex) {
      case 0:
        return theme.palette.orange.lighter!;
      case 1:
        return theme.palette.orange.light!;
      case 2:
        return theme.palette.cyan.lighter!;
      case 3:
        return theme.palette.cyan.light!;
      case 4:
        return theme.palette.cyan.main!;
      default:
        return "#FFF";
    }
  };

  return (
    <>
      {mappedData.length === 0 ? (
        <Typography pt='120px'>There is not enough data</Typography>
      ) : (
        <ResponsiveBar
          data={sortedData}
          keys={barKeys}
          indexBy='id'
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.2}
          groupMode='grouped'
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={getColor}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          // fill={[
          //   {
          //     match: {
          //       id: "fries",
          //     },
          //     id: "dots",
          //   },
          //   {
          //     match: {
          //       id: ""Year 2"",
          //     },
          //     id: "lines",
          //   },
          // ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Prescriptive",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Score %",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          // legendLabel={(e) => (e.data?."Year 1" ? "Year 1" : e.id.toString())}
          legends={[
            {
              dataFrom: "keys",
              // data: legendData.map((item, index) => {
              //   return {
              //     id: item.id,
              //     label: item.label,
              //     color: colorSchemes.nivo[index],
              //   };
              // }),
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role='application'
          ariaLabel='year compliance score trend'
          barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in prescriptive: " + e.indexValue}
        />
      )}
    </>
  );
}
