import { Log, LogCustom } from "../../models/Log";
import dayjs, { Dayjs } from "dayjs";

export const logMapper = {
    mapToCustom
}


function mapToCustom(value: Log): LogCustom {
    var ret: LogCustom = { ...value };

    ret.createdDateDate = ret.createdDate ? dayjs(ret.createdDate) : dayjs();
    ret.createdDateAsFormattedString = ret.createdDateDate?.format("M/DD/YYYY h:mm:ss A")

    return ret;
}