import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { CompanyOutput } from "../../../graphQL/outputModels/Company.output";
import { QUERIES } from "../../../graphQL/queries";
import MemberPicker from "../../MemberDialog/MemberPicker";

interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  allCompanies: boolean;
  selectedCompanyIds: number[];
  onAllCompaniesChanged: (e: any) => void;
  onCompanyChanged: (newId: number) => void;
}

export default function FilterDialog({ open, onClose, allCompanies, selectedCompanyIds, onAllCompaniesChanged, onCompanyChanged }: FilterDialogProps) {
  // GRAPHQL
  const [getCompanies, { loading, data: companies }] = useLazyQuery<CompanyOutput>(QUERIES.GET_COMPANIES);

  // USEMEMO
  const sortedCompanies = useMemo(() => {
    if (companies) {
      let g = [...companies.companies].sort((a, b) => a.companyName!.localeCompare(b.companyName!));
      return g;
    }
  }, [companies]);

  // USEEFFECTS
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {"Add Folder"}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <MemberPicker
              readonly={false}
              allCompanies={allCompanies}
              selectedCompanyIds={selectedCompanyIds}
              companies={sortedCompanies ?? []}
              onAllCompaniesChanged={onAllCompaniesChanged}
              onCompanyChanged={onCompanyChanged}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
