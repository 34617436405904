import { Box, Checkbox, FormControlLabel, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { DocReviewEnum } from "../../../models/AssessmentSiteVisitDocument";
import { AssessmentSiteVisitDocumentSelection } from "../../../models/AssessmentSiteVisitDocumentSelection";

interface Step2DocumentsProps {
  documents: AssessmentSiteVisitDocumentSelection[];
  loading: boolean;
  handleDocumentsChanged: (files: AssessmentSiteVisitDocumentSelection[]) => void;
}

export default function Step2Documents({ documents, loading, handleDocumentsChanged }: Step2DocumentsProps) {
  // HANDLERS
  const handleCheckBoxChange = (doc: AssessmentSiteVisitDocumentSelection, checked: DocReviewEnum) => {
    var newList = [...documents].map((d) => {
      if (d.assessmentDocumentId === doc.assessmentDocumentId) {
        var newVal = d.reviewSelection === checked ? DocReviewEnum.Unselected : checked;
        return {
          ...d,
          reviewSelection: newVal,
        };
      } else return d;
    });
    handleDocumentsChanged(newList);
  };

  // USEEFFECTS
  // useEffect(() => {
  //   if (siteVisitDocumentsData?.siteVisitDocuments) {
  //     var newList = siteVisitDocumentsData.siteVisitDocuments.map((d): AssessmentSiteVisitDocumentSelectionCustom => {
  //       return { assessmentDocumentId: d.documentId!, documentDescription: d.documentDescription!, reviewSelection: DocReviewEnum.Unselected, assessmentId: 0 };
  //     });
  //     setDocumentSelection([...newList]);
  //   }
  // }, [siteVisitDocumentsData]);

  return (
    <Box mr={-2}>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 420px)"
        margin="auto"
        mt={0}
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
        pr={1}
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: "100%" }}>
                Name
              </TableCell>
              <TableCell align="center" sx={{ minWidth: 140, width: 140 }}>
                Select
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              documents.map((doc, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "td:not(:first-of-type) ": { p: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {doc.documentDescription}
                  </TableCell>
                  <TableCell align="left">
                    <Stack direction="row" sx={{ ".MuiCheckbox-root": { p: 0, ml: "3px" } }}>
                      <FormControlLabel control={<Checkbox checked={doc.reviewSelection === DocReviewEnum.Yes} onChange={() => handleCheckBoxChange(doc, DocReviewEnum.Yes)} />} label="Yes" />
                      {/* </TableCell> */}
                      {/* <TableCell align="left"> */}
                      <FormControlLabel
                        control={
                          <Checkbox name={doc.assessmentDocumentId!.toString()} checked={doc.reviewSelection === DocReviewEnum.No} onChange={() => handleCheckBoxChange(doc, DocReviewEnum.No)} />
                        }
                        label="No"
                      />
                      {/* </TableCell>
                  <TableCell align="left"> */}
                      <FormControlLabel
                        control={
                          <Checkbox name={doc.assessmentDocumentId!.toString()} checked={doc.reviewSelection === DocReviewEnum.NA} onChange={() => handleCheckBoxChange(doc, DocReviewEnum.NA)} />
                        }
                        label="N/A"
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (documents.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no documents to choose from</Typography>}
    </Box>
  );
}
