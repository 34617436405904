import { CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import MemberPicker from "../../MemberDialog/MemberPicker";
import { useQuery } from "@apollo/client";
import { QUERIES } from "../../../graphQL/queries";
import { Company } from "../../../models/Company";
import { Dayjs } from "dayjs";
import { User } from "../../../models/User";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UsersOutput } from "../../../graphQL/outputModels/Users.output";

export const AssessmentTypes = [
  {
    assessmentTypeId: 1,
    assessmentTypeName: "Interim Visit",
  },
  {
    assessmentTypeId: 2,
    assessmentTypeName: "New Member 2 Visit",
  },
  {
    assessmentTypeId: 3,
    assessmentTypeName: "New Member 3 Visit",
  },
  {
    assessmentTypeId: 4,
    assessmentTypeName: "Triennial Visit",
  },

  {
    assessmentTypeId: 5,
    assessmentTypeName: "Year-End Scorecard",
  },
];

interface Step0Props {
  assessmentTypeId: number | null;
  companies: Company[];
  selectedCompany: Company | null;
  assessmentDate?: Dayjs;
  performedBy?: number | null;
  isEditing: boolean;

  onFormChanged: (e: any) => void;
  onPerformedByUserChanged?: (u: User) => void;
  loading: boolean;
}

export default function Step0({ assessmentTypeId, companies, selectedCompany, assessmentDate, performedBy, onFormChanged, onPerformedByUserChanged, isEditing, loading }: Step0Props) {
  // GRAPHQL
  const { data: usersData } = useQuery<UsersOutput>(QUERIES.GET_USERS);

  // HANDLERS
  const handleMemberChanged = (id: number) => {
    const member = companies.find((c) => c.companyId === id);
    if (member) {
      onFormChanged({ target: { name: "member", value: member } });
    }
  };

  const handleUserChanged = (e: any) => {
    const { value } = e.target;
    const selectedUser = sortedUsers.find((u) => u.userId === +value);
    if (onPerformedByUserChanged) onPerformedByUserChanged(selectedUser!);
  };

  // Disable going from scorecard -> visit and also visit -> scorecard
  const isAssessmentTypeDisabled = (currId: number) => {
    if (isEditing) {
      if (assessmentTypeId === 5) return currId < 5;
      return currId === 5;
    }
    return false;
  };

  // USEMEMOS
  const sortedCompanies = useMemo(() => {
    if (companies) {
      let _companies = [...companies].sort((a, b) => a.companyName!.localeCompare(b.companyName!));
      return _companies;
    }
    return [];
  }, [companies]);

  const sortedUsers = useMemo(() => {
    if (usersData) {
      let g = [...usersData.users].filter((u) => (u.fullName?.length ?? 0) > 1).sort((a, b) => a.fullName!.localeCompare(b.fullName!));
      console.log(g);
      return g;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData?.users]);

  return (
    <Stack alignItems="center">
      <Stack direction="row">
        <FormControl sx={{ minWidth: 240, width: 240, mb: 1 }}>
          <InputLabel id="assessmentType-label" required>
            Assessment Type
          </InputLabel>
          <Select
            labelId="assessmentType-label"
            name="assessmentTypeId"
            label="Assessment Type"
            value={assessmentTypeId ?? ""}
            onChange={onFormChanged}
            required
            // disabled={isEditing && assessmentTypeId === 5}
          >
            {AssessmentTypes?.map((a) => {
              return (
                <MenuItem key={a.assessmentTypeId!} value={a.assessmentTypeId!} disabled={isAssessmentTypeDisabled(a.assessmentTypeId!)}>
                  <Typography width={200} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {a.assessmentTypeName}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>required</FormHelperText>
        </FormControl>

        {performedBy !== undefined && (
          <FormControl disabled={loading} sx={{ width: 200, mb: 1, ml: 4 }}>
            <InputLabel id="performedBy-label" required>
              Performed By
            </InputLabel>
            <Select labelId="performedBy-label" name="performedByUser" label="Performed By" value={performedBy?.toString() ?? ""} onChange={handleUserChanged} required>
              {sortedUsers?.map((u) => {
                return (
                  <MenuItem key={u.userId!} value={u.userId!}>
                    <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                      {u.fullName}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>required</FormHelperText>
          </FormControl>
        )}

        {assessmentDate !== undefined && assessmentTypeId !== 5 && (
          <DatePicker
            label={"Assessment Date *"}
            sx={{ width: 200, ml: 4 }}
            slotProps={{
              textField: {
                helperText: "required",
              },
            }}
            value={assessmentDate}
            onChange={(newValue) => onFormChanged({ target: { name: "assessmentDate", value: newValue } })}
          />
        )}

        {assessmentDate !== undefined && assessmentTypeId === 5 && (
          <DatePicker
            label={"Assessment Year *"}
            views={["year"]}
            sx={{ width: 200, ml: 4 }}
            slotProps={{
              textField: {
                helperText: "required",
              },
            }}
            value={assessmentDate}
            onChange={(newValue) => onFormChanged({ target: { name: "assessmentDate", value: newValue } })}
          />
        )}
      </Stack>

      {loading ? (
        <Stack alignItems="center" mt={2}>
          <CircularProgress />
          <Typography>loading members...</Typography>
        </Stack>
      ) : (
        <MemberPicker readonly={false} selectedCompanyIds={[selectedCompany?.companyId ?? 0]} companies={sortedCompanies} onCompanyChanged={handleMemberChanged} />
      )}
    </Stack>
  );
}
