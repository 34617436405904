import { createTheme } from '@mui/material/styles';
import palette from './palette';


export const theme = createTheme({
    palette: { ...palette },
    typography: {
        fontFamily: "AvenirRoman",
    },
    components: {
        MuiIconButton: {
            defaultProps: {
                color: "orange"
            },
            // styleOverrides: {
            //     root: {
            //         ":hover": {
            //             backgroundColor: "rgb(50 185 175 / 20%)"
            //         }
            //     }
            // }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingRight: 40
                }
            }
        },
        MuiDialogContent: {
            defaultProps: {

            },
            styleOverrides: { root: { paddingTop: `${10}px !important` } },
        },
        MuiTextField: {
            defaultProps: {
                // variant: "standard"
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: `linear-gradient(white, ${palette.grey?.[300]});`
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "0 0 20px 5px rgb(0 0 0 / 20%)",
                    borderRadius: 6
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    background: `linear-gradient(white, ${palette.grey?.[400]});`,
                    borderBottom: `1px solid ${palette.grey?.[400]}`,
                    fontFamily: "AvenirBlack",
                },
                body: {
                    borderBottom: `1px solid ${palette.grey?.[300]}`,
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    // alternate table row colors
                    '&:nth-of-type(odd)': {
                        backgroundColor: "white",
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: palette.grey?.[200],
                    }
                }
            }
        },
        MuiAccordion: {
            defaultProps: {
                TransitionProps: {
                    unmountOnExit: true
                }
            },
            styleOverrides: {
                root: {
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    background: `linear-gradient(white, ${palette.grey?.[300]});`,
                    color: 'black',
                    "&.Mui-expanded": {
                        borderBottom: `1px solid ${palette.grey?.[400]}`,
                    }
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    // background: palette.grey?.[300],
                    // marginTop: 5
                    padding: "0 0 16px 0",
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "white"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: "white"
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    backgroundColor: "white"//"#eeeeff"
                })
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ":hover": {
                        backgroundColor: palette.cyan.lighter
                    },
                    "&.Mui-selected": {
                        backgroundColor: palette.grey?.[300]

                    }
                },
            }
        },
        MuiCircularProgress: {
            defaultProps: {
                color: "cyan"
            }
        },
        MuiSkeleton: {
            defaultProps: {
                animation: "wave"
            }
        },
        MuiTypography: {
            styleOverrides: {
                "h2": {
                    color: palette.text?.primary,
                    fontSize: "1.8em",
                    letterSpacing: 1,
                    fontFamily: "AvenirRoman",
                    fontWeight: "bolder",
                    // textTransform: "uppercase"
                }
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    transition: "700ms",
                    "&.Mui-active": {
                        transform: "scale(1.5)"
                    }
                }
            }
        }
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             transform: "unset"
        //         },
        //         shrink: false
        //     }
        // }
    }
});