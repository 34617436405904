import { Box, Checkbox, FormGroup, FormControlLabel, Typography } from "@mui/material";
import { Company } from "../../models/Company";
import { MemoizedRadioButton } from "./CustomRadioButton";

interface MemberPickerProps {
  readonly?: boolean;
  allCompanies?: boolean;
  selectedCompanyIds: number[];
  companies: Company[];
  onAllCompaniesChanged?: (e: any) => void;
  onCompanyChanged: (newId: number) => void;
  maxHeight?: string;
}

export default function MemberPicker({ readonly, allCompanies, selectedCompanyIds, companies, onAllCompaniesChanged, onCompanyChanged, maxHeight }: MemberPickerProps) {
  return (
    <Box width="100%">
      <Box display="flex" alignItems="center">
        <Typography id="addedit-folder-member" component="span" sx={{ fontWeight: "bold" }}>
          Member *
        </Typography>
        {onAllCompaniesChanged && (
          <FormGroup sx={{ ml: 3, ".MuiFormControlLabel-label": { fontStyle: "italic", color: "GrayText" } }}>
            <FormControlLabel control={<Checkbox name="allCompanies" checked={allCompanies} onChange={onAllCompaniesChanged} disabled={readonly} />} label="Select all" />
          </FormGroup>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" maxHeight={maxHeight ?? "42vh"} aria-labelledby="addedit-folder-company" overflow="auto">
        {companies?.map((c) => (
          <MemoizedRadioButton key={c.companyId} checked={selectedCompanyIds.includes(c.companyId!)} company={c} handleRadioChange={onCompanyChanged} disabled={readonly || allCompanies} />
        ))}
      </Box>
    </Box>
  );
}
