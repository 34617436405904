import { Box, Button, Paper, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EventCustom } from "../../models/Event";
import { DateUtils } from "../../utils/date.utils";
import PlaceIcon from "@mui/icons-material/Place";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

interface EventListComponentProps {
  event: EventCustom;
}

export default function EventListComponent({ event }: EventListComponentProps) {
  // SETUP
  const navigate = useNavigate();

  // HANDLERS
  const handleMoreInfoClicked = (eventId: number) => {
    navigate(eventId.toString());
  };

  return (
    <Paper elevation={5} sx={{ borderRadius: 1 }}>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          p: 1,
          mb: 4,
          height: 167,
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            height: "100%",
            minWidth: 250,
            width: 250,
            cursor: "pointer",
            p: event.pictureBlobUrl ? 0 : 2,
            boxSizing: "border-box",
          }}
          onClick={() => handleMoreInfoClicked(event.eventId!)}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: event.pictureBlobUrl ? "100%" : "auto",
              m: "auto",
            }}
            loading="lazy"
            alt=""
            src={event.pictureBlobUrl ?? "/images/cc-logo-cyan.png"}
            onLoad={() => {
              const placeholder = document.getElementById("event-placeholder-" + event.eventId);
              if (placeholder) placeholder.style.display = "none";
            }}
          />
          <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
            <Skeleton id={"event-placeholder-" + event.eventId} variant="rectangular" width={250} height={167} />
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            pl: 2,
            pr: 2,
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          <Typography sx={{ fontFamily: "AvenirBlack" }}>{event.eventName}</Typography>
          <Typography
            sx={{
              fontFamily: "AvenirRoman",
              fontSize: "0.9em",
              fontStyle: "italic",
              mb: 1,
            }}
          >
            {event.eventType?.eventTypeName}
          </Typography>
          <Box>
            <AccessTimeFilledIcon sx={{ fontSize: "1em", mr: 1 }} color="cyan" />
            <Typography component="span">{DateUtils.getFriendlyRange(event.startDateDate!, event.endDateDate!, event.allDay ?? false, event.timezone)}</Typography>
          </Box>
          <Box>
            <PlaceIcon sx={{ fontSize: "1.1em", mr: 1 }} color="cyan" />
            <Typography component="span">{event.location}</Typography>
          </Box>
          <Typography sx={{ overflow: "auto", mt: 1, mr: "115px" }}>{event.eventFullDescription}</Typography>
        </Box>
        <Button color="orange" variant="contained" sx={{ position: "absolute", right: 8, bottom: 8, width: 120 }} onClick={() => handleMoreInfoClicked(event.eventId!)}>
          More Info
        </Button>
      </Box>
    </Paper>
  );
}
