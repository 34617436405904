import { format, formatInTimeZone } from "date-fns-tz";
import { Timezone } from "../models/Timezone";

export const DateUtils = {
  // formatDateTime,
  // formatTime,
  // formatMonth,
  formatDateForTextfield,
  formatTimeForTextfield,
  formatCustom,
  // addDays,
  getFriendlyRange,
  getMonths,
  getCurrTimezoneOffset
};

// function formatDateTime(dateTimeUTC?: Date): string {
//   if (dateTimeUTC) return format(dateTimeUTC, "EEE, MMM d yyyy, h:mm aa");
//   return "";
// }

// function formatTime(dateTimeUTC?: Date): string {
//   if (dateTimeUTC) return format(dateTimeUTC, "h:mm aa");
//   return "";
// }

// function formatMonth(dateTimeUTC?: Date): string {
//   return dateTimeUTC?.toLocaleDateString("en-US", { month: "long" }) ?? "";
// }

function formatDateForTextfield(dateTimeUTC?: Date, offset?: string): string {
  if (!dateTimeUTC) return "";
  var friendlyOffSet = getFriendlyOffset(offset ?? format(new Date(), "XXXXX"));
  return formatInTimeZone(dateTimeUTC, friendlyOffSet, "yyyy-MM-dd");
}

function formatTimeForTextfield(dateTimeUTC?: Date, offset?: string): string {
  if (!dateTimeUTC) return "";
  var friendlyOffSet = getFriendlyOffset(offset ?? format(new Date(), "XXXXX"));
  return formatInTimeZone(dateTimeUTC, friendlyOffSet, "HH:mm");
}

function formatCustom(formatStr: string, dateTimeUTC?: Date, offset?: string): string {
  if (!dateTimeUTC) return "";
  var friendlyOffSet = getFriendlyOffset(offset ?? format(new Date(), "XXXXX"));
  return formatInTimeZone(dateTimeUTC, friendlyOffSet, formatStr);
}

// function addDays(date: Date, days: number) {
//   var result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result;
// }

function getFriendlyRange(startDate?: Date, endDate?: Date, allDay?: boolean, timeZone?: Timezone) {
  if (startDate && endDate) {
    var friendlyOffSet = getFriendlyOffset(timeZone?.standardOffset);
    var startDateStr = formatInTimeZone(startDate, friendlyOffSet, "yyyy-MM-dd")
    var endDateStr = formatInTimeZone(endDate, friendlyOffSet, "yyyy-MM-dd");

    if (allDay) {
      if (startDateStr === endDateStr)
        return `${formatInTimeZone(startDate, friendlyOffSet, "EEE, MMM d yyyy")}`
      else
        return `${formatInTimeZone(startDate, friendlyOffSet, "EEE, MMM d yyyy")} - ${formatInTimeZone(endDate, friendlyOffSet, "EEE, MMM d yyyy")}`
    } else {
      if (startDateStr === endDateStr)
        return `${formatInTimeZone(startDate, friendlyOffSet, "EEE, MMM d yyyy, h:mm aa")} ${timeZone?.abbrv} - ${formatInTimeZone(endDate, friendlyOffSet, "h:mm aa")} ${timeZone?.abbrv}`
      else
        return `${formatInTimeZone(startDate, friendlyOffSet, "EEE, MMM d yyyy, h:mm aa")} ${timeZone?.abbrv} - ${formatInTimeZone(endDate, friendlyOffSet, "MMM d yyyy, h:mm aa")} ${timeZone?.abbrv}`
    }
  }
  return "";
}

/**
 * 
 * @param offset expects string in form of -06:00:00
 */
function getFriendlyOffset(offset?: string) {
  if (offset && offset.length >= 6)
    return offset.substring(0, 6);
  return "Z";
}

function getCurrTimezoneOffset() {
  var d = new Date();
  return format(d, "XXXXX") + ":00";
}

function getMonths() {
  return [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}