import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Button, ButtonProps } from "@mui/material";

function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export const SignOutButton = ({ ...rest }: ButtonProps) => {
  const { instance } = useMsal();

  return (
    <Button {...rest} onClick={() => handleLogout(instance)} color="orange" variant="contained">
      Sign out
    </Button>
  );
};
