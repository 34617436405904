import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";

type vars = {
    eventId?: number
    thumbnail?: File
    attachments?: File[]
}

export default function useUploadBatchQuery<T>({ eventId, thumbnail, attachments }: vars = {}) {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ eventId, thumbnail, attachments }: vars) => {
        async function run(eventId: number, thumbnail?: File, attachments?: File[]) {
            console.log("executing upload to event")
            setLoading(true);
            const response = await FetchUtils.UPLOAD_FILES<T>(
                eventId, thumbnail, attachments
            );
            console.log("done uploading to event")
            setLoading(false);
            setData(response);
            return response;
        }

        if (eventId && (thumbnail || attachments)) {
            run(eventId, thumbnail, attachments)
        }
    }, []);



    return {
        callApi: execute,
        loading: loading,
        data: data
    };
}