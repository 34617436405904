import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import PortalApolloProvider from './apollo/PortalApolloProvider';
import { theme } from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// fonts
import './fonts/Avenir/AvenirLTStd-Black.otf';
import './fonts/Avenir/AvenirLTStd-Book.otf';
import './fonts/Avenir/AvenirLTStd-Roman.otf';
import './fonts/Matia/Pointed Gallery.otf';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserContextProvider } from './UserContextProvider';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    // Handle redirect response
  })
  .catch((error) => {
    // Handle redirect error
  });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <PortalApolloProvider>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <AppSSO> */}
                  <UserContextProvider>
                    <App />
                  </UserContextProvider>
                {/* </AppSSO> */}
              </LocalizationProvider>
            </ThemeProvider>
          </DndProvider>
        </BrowserRouter>
      </PortalApolloProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
