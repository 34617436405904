import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Step, StepLabel, Stepper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState, useMemo, useRef } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { Assessment } from "../../../models/Assessment";
import { CompanyOutput } from "../../../graphQL/outputModels/Company.output";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UserBasic } from "../../../models/User";
import Step1Visit from "./Step1Visit";
import Step0, { AssessmentTypes } from "./Step0";
import { StringUtils } from "../../../utils/string.utils";
import Step1Scorecard from "./Step1Scorecard";
import Step3 from "./Step3";
import Step2Documents from "./Step2Documents";
import { AddedAssessmentOutput } from "../../../graphQL/outputModels/AddedAssessment.output";
import { AddAssessmentInput } from "../../../graphQL/inputModels/addAssessment.input";
import { AddAssessmentParticipant, AssessmentParticipant } from "../../../models/AssessmentParticipant";
import { AddAssessmentSiteVisitDocumentSelection, AssessmentSiteVisitDocumentSelection } from "../../../models/AssessmentSiteVisitDocumentSelection";
import { DocReviewEnum } from "../../../models/AssessmentSiteVisitDocument";
import { SiteVisitDocumentsOutput } from "../../../graphQL/outputModels/SiteVisitDocuments.output";
import dayjs, { Dayjs } from "dayjs";
import { Company } from "../../../models/Company";
import { useNavigate } from "react-router-dom";
import { NotificationUtils } from "../../../utils/notification.utils";

interface AddEditAssessmentDialogPageProps {
  open: boolean;
  editingAssessment?: Assessment;
  onClose: (refetch: boolean) => void;
}

const defaultSteps = ["Choose a Type", "Enter details", "Create"];
const visitSteps = ["Choose a Type", "Enter details", "Documents", "Create"];

type pageState = {
  assessmentId: number;
  assessmentName: string;
  assessmentTypeId: number | null;
  member: Company | null;
  totalQuestions: number;
  totalAnswers: number;
  assessmentDate: Dayjs;
  performedByUser: UserBasic | null;
  participants: AssessmentParticipant[];
  documents: AssessmentSiteVisitDocumentSelection[];
};

export default function AddEditAssessmentDialogPage({ open, onClose }: AddEditAssessmentDialogPageProps) {
  // SETUP
  let defaultValues: pageState = {
    assessmentId: 0,
    assessmentName: "",
    assessmentTypeId: null,
    member: null,
    totalQuestions: 0,
    totalAnswers: 0,
    assessmentDate: dayjs(),
    performedByUser: null,
    participants: [{ participantName: "", participantTitle: "" }],
    documents: [],
  };
  // if (editingAssessment) {
  //   defaultValues = {
  //     assessmentId: editingAssessment?.assessmentId!,
  //     assessmentName: editingAssessment?.assessmentName!,
  //     assessmentTypeId: editingAssessment?.assessmentTypeId!,
  //     member: null,
  //     totalQuestions: editingAssessment?.assessmentQuestions?.length ?? 0,
  //     totalAnswers: editingAssessment?.assessmentQuestions?.filter((q) => q.selectedAssessmentQuestionResponseId).length ?? 0,
  //     assessmentDate: dayjs(editingAssessment.assessmentDate!),
  //     performedByUser: editingAssessment.performedByUser!,
  //     participants: [],
  //     documents: [],
  //   };
  // }
  const [_formValues, _setFormValues] = useState(defaultValues);
  const formValues = useRef(_formValues);
  function setFormValues(e: pageState) {
    formValues.current = e;
    _setFormValues(e);
  }
  const navigate = useNavigate();
  const [currStep, setCurrStep] = useState(0);
  const isScorecard = formValues.current.assessmentTypeId === 5;
  const steps = !formValues.current.assessmentTypeId || isScorecard ? defaultSteps : visitSteps;

  // GRAPHQL
  const { loading: loadingCompanies, data: companiesData } = useQuery<CompanyOutput>(QUERIES.GET_COMPANIES);
  const [addAssessment, { loading: loadingAssessments, data: addedAssessmentData }] = useMutation<AddedAssessmentOutput, AddAssessmentInput>(MUTATIONS.ADD_ASSESSMENT);
  const [getSiteVisitDocuments, { loading: loadingSiteVisitDocuments, data: siteVisitDocumentsData }] = useLazyQuery<SiteVisitDocumentsOutput>(QUERIES.GET_SITE_VISIT_DOCUMENTS, {
    fetchPolicy: "network-only",
  });
  const loadingUsers = false;
  const loading = loadingCompanies || loadingAssessments || loadingSiteVisitDocuments || loadingUsers;
  const successfullyAdded = addedAssessmentData?.addAssessment.actionStatus === ActionStatus.Success;

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setFormValues({
      ...formValues.current,
      [name]: value,
    });
  };

  const handleUserChanged = (u: UserBasic) => {
    console.log(u);
    setFormValues({
      ...formValues.current,
      performedByUser: { ...u },
    });
  };

  const handleDocumentsChanged = (documents: AssessmentSiteVisitDocumentSelection[]) => {
    setFormValues({
      ...formValues.current,
      documents: [...documents],
    });
  };

  const handleSubmit = (event: any) => {
    var assessmentType = AssessmentTypes.find((a) => a.assessmentTypeId === formValues.current.assessmentTypeId!)!.assessmentTypeName;
    const calcAssessmentName = `${assessmentType} ${formValues.current.member?.companyName} ${finalDateString}`;
    addAssessment({
      variables: {
        input: {
          assessmentName: calcAssessmentName,
          assessmentDate: formValues.current.assessmentDate.toISOString(),
          assessmentTypeId: formValues.current.assessmentTypeId!,
          memberId: formValues.current.member!.companyId!,
          memberName: formValues.current.member!.companyName!,
          memberPolicyNumber: formValues.current.member!.companyPolicyNo!,
          assessmentParticipants: finalParticipants.map((p): AddAssessmentParticipant => {
            return {
              assessmentId: 0,
              assessmentParticipantId: 0,
              participantName: p.participantName!,
              participantTitle: p.participantTitle!,
            };
          }),
          assessmentSiteVisitDocumentSelections: finalDocuments.map((d): AddAssessmentSiteVisitDocumentSelection => {
            return {
              assessmentId: 0,
              assessmentDocumentId: d.assessmentDocumentId,
              reviewSelection: d.reviewSelection,
            };
          }),
          performedBy: formValues.current.performedByUser!.userId!,
        },
      },
      // update: (cache, { data }) => {
      //   const existingAssessments = cache.readQuery<AssessmentsOutput>({ query: QUERIES.GET_ASSESSMENTS });
      //   if (existingAssessments) {
      //     const newAssessments = [...existingAssessments.assessments, { ...data!.addAssessment }];
      //     cache.writeQuery<AssessmentsOutput>({ query: QUERIES.GET_ASSESSMENTS, data: { assessments: newAssessments } });
      //   }
      // },
    });
  };

  const validateStep = () => {
    if (currStep === 0) {
      return (formValues.current.assessmentTypeId ?? 0) > 0 && formValues.current.member;
    } else if (currStep === 1) {
      return formValues.current.performedByUser && formValues.current.assessmentDate;
    }
    return true;
  };

  const handleStepChange = (stepsToAdd: number) => {
    if (currStep + stepsToAdd < 0 || currStep + stepsToAdd > steps.length - 1) {
      return;
    }

    if (!siteVisitDocumentsData && currStep === 1 && (formValues.current.assessmentTypeId ?? 5) !== 5) {
      getSiteVisitDocuments();
    }

    setCurrStep(currStep + stepsToAdd);
  };

  // USEMEMOS
  const finalParticipants = useMemo(() => {
    const participants = [...formValues.current.participants].filter((p) => !StringUtils.isEmpty(p.participantName) && !StringUtils.isEmpty(p.participantTitle));
    return participants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.current.participants]);

  const finalDocuments = useMemo(() => {
    const documents = [...formValues.current.documents].filter((p) => p.reviewSelection === DocReviewEnum.Yes || p.reviewSelection === DocReviewEnum.No || p.reviewSelection === DocReviewEnum.NA);
    return documents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.current.documents]);

  const finalDateString = useMemo(() => {
    const fmt = isScorecard ? "YYYY" : "MMM D, YYYY";
    const d = dayjs(formValues.current.assessmentDate).format(fmt);
    return d;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScorecard, formValues.current.assessmentDate]);

  // USEEFFECTS
  useEffect(() => {
    if (siteVisitDocumentsData?.siteVisitDocuments) {
      var docs = siteVisitDocumentsData.siteVisitDocuments.map((d): AssessmentSiteVisitDocumentSelection => {
        return {
          assessmentId: -1,
          documentDescription: d.documentDescription ?? "",
          assessmentDocumentId: d.documentId ?? -1,
          reviewSelection: DocReviewEnum.Unselected,
        };
      });
      setFormValues({
        ...formValues.current,
        documents: [...docs],
      });
    }
  }, [siteVisitDocumentsData]);

  useEffect(() => {
    NotificationUtils.notifyAdd(addedAssessmentData?.addAssessment.actionStatus!, addedAssessmentData?.addAssessment.actionStatusMessage!);
  }, [addedAssessmentData]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {/* {editingAssessment ? "Editing Assessment" : "Add Assessment"} */}
        Add Assessment
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={currStep} sx={{ mb: 3 }} alternativeLabel>
          {steps.map((s, i) => {
            return (
              <Step key={i}>
                <StepLabel>{s}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box overflow="auto" height={"calc(100vh - 420px)"} mr={-2} pr={2} pt={1} pb={1} mb={-1}>
          {currStep === 0 && (
            <Step0
              assessmentTypeId={formValues.current.assessmentTypeId}
              companies={companiesData?.companies ?? []}
              selectedCompany={formValues.current.member}
              loading={loadingCompanies}
              onFormChanged={handleInputChange}
              isEditing={false}
            />
          )}
          {currStep === 1 &&
            (isScorecard ? (
              <Step1Scorecard
                assessmentDate={formValues.current.assessmentDate}
                loading={loadingUsers}
                performedByUser={formValues.current.performedByUser}
                participants={formValues.current.participants}
                onFormChanged={handleInputChange}
                onPerformedByUserChanged={handleUserChanged}
              />
            ) : (
              <Step1Visit
                assessmentDate={formValues.current.assessmentDate}
                loading={loadingUsers}
                performedByUser={formValues.current.performedByUser}
                participants={formValues.current.participants}
                onFormChanged={handleInputChange}
                onPerformedByUserChanged={handleUserChanged}
              />
            ))}
          {((currStep === 2 && isScorecard) || (currStep === 3 && !isScorecard)) && (
            <Step3
              assessmentType={AssessmentTypes.find((a) => a.assessmentTypeId === formValues.current.assessmentTypeId!)!.assessmentTypeName}
              memberName={formValues.current.member?.companyName!}
              dateStr={finalDateString}
              performedByName={`${formValues.current.performedByUser?.fullName}`}
              participants={finalParticipants}
              documents={finalDocuments}
              isSuccessful={successfullyAdded}
            />
          )}
          {currStep === 2 && !isScorecard && <Step2Documents documents={formValues.current.documents} loading={loadingSiteVisitDocuments} handleDocumentsChanged={handleDocumentsChanged} />}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {currStep !== 0 && !successfullyAdded && (
          <Button variant="outlined" color="orange" disabled={loading} onClick={() => handleStepChange(-1)} sx={{ mr: "auto" }}>
            Back
          </Button>
        )}
        {currStep !== steps.length - 1 && !successfullyAdded ? (
          <Button variant="contained" color="orange" disabled={loading || !validateStep()} onClick={() => handleStepChange(1)}>
            Next
          </Button>
        ) : !successfullyAdded ? (
          <Box>
            {loading ? (
              <CircularProgress sx={{ mr: 4 }} size={30} />
            ) : (
              <Button variant="contained" color="orange" disabled={loading || !validateStep()} onClick={handleSubmit}>
                Create
              </Button>
            )}
          </Box>
        ) : (
          <Stack direction="row" justifyContent="center" width="100%" spacing={4}>
            <Button variant="outlined" color="info" onClick={() => onClose(true)}>
              Go back to List
            </Button>
            <Button variant="contained" color="info" onClick={() => navigate(`${addedAssessmentData?.addAssessment.assessmentId}`)}>
              Go to Assessment
            </Button>
            <CheckIcon color="success" fontSize="large" sx={{ position: "absolute", right: 20 }} />
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}
