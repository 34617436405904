import { alpha, PaletteOptions } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// Update the Palette options
declare module '@mui/material/styles' {
  interface Palette {
    cyan: SimplePaletteColorOptions;
    orange: SimplePaletteColorOptions;
    tertiary: SimplePaletteColorOptions;
    light: SimplePaletteColorOptions;
    gradients?: {
      primary?: PaletteColor
      secondary?: string
      info?: string
      success?: string
      warning?: string
      error?: string
    };
  }
  interface PaletteOptions {
    cyan: SimplePaletteColorOptions;
    orange: SimplePaletteColorOptions;
    tertiary: SimplePaletteColorOptions;
    light: SimplePaletteColorOptions;
    gradients?: {
      primary?: PaletteColor
      secondary?: PaletteColor
      info?: string
      success?: string
      warning?: string
      error?: string
    };
    // neutral: PaletteOptions['primary']
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cyan: true;
    orange: true;
    tertiary: true;
    light: true;
  }
}
// Update the IconButton's color prop options
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    cyan: true;
    orange: true;
    tertiary: true;
    light: true;
  }
}

// Update the CircularProgressBar's color prop options
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    cyan: true;
    orange: true;
    tertiary: true;
  }
}

// Update the IconButton's color prop options
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    cyan: true;
    orange: true;
    tertiary: true;
  }
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#231F20",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
  lighter: "#f9c7a9",
  light: "#f59e69",
  main: "#f07428",
  dark: "#a8511c",
  darker: "#602e10",
  contrastText: "#fff",
};

const ORANGE = {
  lighter: "#f9c7a9",
  light: "#f59e69",
  main: "#f07428",
  dark: "#a8511c",
  darker: "#602e10",
  contrastText: "#fff"
};

const SECONDARY = {
  lighter: "#ade3df",
  light: "#70cec7",
  main: "#32b9af",
  dark: "#23827a",
  darker: "#144a46",
  contrastText: GREY[900],
};

// const MAGENTA = {
//   lighter: "#f7b2dd",
//   light: "#de56ac",
//   main: "#D00E88",
//   dark: "#920a5f",
//   darker: "#530636",
//   contrastText: "#fff",
// };

const TERTIARY = {
  lighter: "#ec9fcf",
  light: "#de56ac",
  main: "#D00E88",
  dark: "#920a5f",
  darker: "#530636",
  contrastText: "#fff",
};

const CYAN = {
  lighter: "#ade3df",
  light: "#70cec7",
  main: "#32b9af",
  dark: "#23827a",
  darker: "#144a46",
  contrastText: GREY[900],
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: "#fff",
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: "#fff",
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const LIGHT = {
  lighter: "#D3F8F5",
  light: "#C6EAE7",
  main: "#BBDDDA",
  dark: "#ACCDCA",
  darker: "#97B9B6",
  contrastText: "#000",
};

const GRADIENTS: PaletteOptions['gradients'] = {
  primary: {
    light: createGradient(PRIMARY.lighter, PRIMARY.light),
    main: createGradient(PRIMARY.light, PRIMARY.main),
    dark: createGradient(PRIMARY.main, PRIMARY.dark),
    contrastText: "#fff"
  },
  secondary: {
    light: createGradient(SECONDARY.lighter, SECONDARY.light),
    main: createGradient(SECONDARY.light, SECONDARY.main),
    dark: createGradient(SECONDARY.main, SECONDARY.dark),
    contrastText: "#fff"
  },
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

// const CHART_COLORS = {
//   violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
//   blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
//   green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
//   yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
//   red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
// };

const palette: PaletteOptions = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  tertiary: { ...TERTIARY },
  orange: { ...ORANGE },
  cyan: { ...CYAN },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  light: { ...LIGHT },
  grey: GREY,
  gradients: GRADIENTS,
  //chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: "#fff", default: GREY[100] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  // neutral: {
  //   main: "#ff0000"
  // }
};

export default palette;
