import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AllRoutes } from "../../routing/Routes";

export default function Logo() {
  return (
    <Link
      to={AllRoutes.Home}
      style={{
        height: 46,
        width: 160,
        position: "relative",
        textAlign: "start",
      }}
    >
      <Box
        component="img"
        sx={{
          height: "auto",
          maxHeight: "100%",
          width: "auto",
        }}
        alt=""
        src="/images/cc-logo-cyan.png"
      />
    </Link>
  );
}
