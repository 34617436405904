import React, { useEffect, useState } from 'react';
import './App.scss';
import { useReactiveVar } from '@apollo/client';
import AppRouter from './routing/AppRouter';
import { appNotification } from './apollo/PortalApolloProvider';
import { Box, CircularProgress, IconButton, Snackbar, SnackbarCloseReason, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import appConfig from './appConfig';
import EnvironmentIndicator from './components/EnvironmentIndicator/EnvironmentIndicator';
import useExportQuery from './hooks/useExportQuery';


export default function App() {
  // SETUP
  const notification = useReactiveVar(appNotification);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { tasks, processing } = useExportQuery();

  // HOOKS

  // HANDLERS
  const closeNotification = (e: any, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    setNotificationOpen(false);
  };

  // USEEFFECTS
  useEffect(() => {
    if (notification?.message === 'Failed to fetch') {
      console.log('server is down');
    } else if (notification && notification.time !== 0) {
      console.log(notification);
      setNotificationOpen(true);
    }
  }, [notification]);

  useEffect(() => {
    // console.log(tasks);
    // console.log(processing);

    if (tasks.length > 0) {
      appNotification({
        severity: 'info',
        message: `${tasks.length} export${tasks.length === 1 ? '' : 's'} processing. This may take up to 1 minute.`,
        loading: true,
        time: null, // show indefinitely
      });
    } else {
      // close notification
      appNotification({
        severity: 'info',
        message: ``,
        loading: false,
        time: 0,
      });
    }
  }, [tasks, processing]);

  // ALERT COMPONENT
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });


  // RENDER
  if (notification?.message === 'Failed to fetch') {
    return (
      <Box
        id='App'
        sx={{
          // backgroundColor: (theme) => theme.palette.cyan.main,
          background: `radial-gradient(circle, #5dd1ca, #0a978c)`,
          color: 'white',
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography sx={{ fontSize: '2em', maxWidth: 600, textAlign: 'center' }}>
          The server is currently undergoing maintenance and will be available soon. We apologize for the inconvenience.
        </Typography>
        {appConfig.environment !== 'prod' && <EnvironmentIndicator />}
      </Box>
    );
  }

  return (
    <Box
      id='App'
      sx={{
        backgroundColor: (theme) => theme.palette.cyan.main,
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <AppRouter />

      {/* toast notification bottom of screen */}
      <Snackbar open={notificationOpen} onClose={closeNotification} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={notification?.time ?? 3000}>
        <Alert
          severity={notification?.severity}
          sx={{ width: '100%' }}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={(e) => closeNotification(e, 'escapeKeyDown')}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          <Box display='flex' alignItems='center'>
            {notification?.loading && <CircularProgress size='1em' sx={{ mr: 1, color: 'white' }} />}
            <Typography>{notification?.message}</Typography>
          </Box>
        </Alert>
      </Snackbar>

      {appConfig.environment !== 'prod' && <EnvironmentIndicator />}
    </Box>
  );
}
