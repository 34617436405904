import { useLazyQuery } from "@apollo/client";
import { Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Paper, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PortalEventsOutput } from "../../graphQL/outputModels/Events.output";
import { QUERIES } from "../../graphQL/queries";
import useUser from "../../hooks/useUser";
import EventListComponent from "./EventListComponent";
import { AllRoutes } from "../../routing/Routes";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { eventMapper } from "./eventMapper";
import { DateUtils } from "../../utils/date.utils";
import { EventType } from "../../models/EventType";

export default function EventListPage() {
  // SETUP
  const { portal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin, portals } = useUser();
  const [showPast, setShowPast] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [filterMonth, setFilterMonth] = useState<number | string>("");
  const [filterEventType, setFilterEventType] = useState<number | string>("");
  const currPortal = portals?.find((p) => p.portalName?.toLocaleLowerCase() === portal?.toLocaleLowerCase());
  const months = DateUtils.getMonths();

  // GRAPHQL
  const [getEvents, { loading, data: eventData }] = useLazyQuery<PortalEventsOutput>(QUERIES.GET_EVENTS, {
    fetchPolicy: "cache-and-network",
  });

  // HANDLERS
  const handleClearFilters = () => {
    setShowPast(false);
    setShowHidden(false);
    setFilterMonth("");
    setFilterEventType("");
  };

  const handleAddEvent = () => {
    navigate("add");
  };

  // USEEFFECTS
  useEffect(() => {
    if (portals?.length !== undefined && currPortal === undefined) {
      navigate(AllRoutes.NotFound);
    } else if (currPortal) getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals, currPortal, location]);

  // MEMO
  const sortedEventData = useMemo(() => {
    let sorted = eventData?.events
      ?.map((e) => {
        return eventMapper.mapToCustom(e);
      })
      .sort((a, b) => a.startDateDate!.getTime() - b.startDateDate!.getTime());
    return sorted;
  }, [eventData]);

  const filteredEventData = useMemo(() => {
    // filter by past
    let filtered = sortedEventData?.filter((e) => {
      if (showPast) return e.startDateDate!.getTime() <= new Date().getTime();

      if (e.allDay) {
        var startDay = e.startDateDate!.getDate();
        var startMonth = e.startDateDate!.getMonth();
        var startYear = e.startDateDate!.getFullYear();
        var startDate = new Date(startYear, startMonth, startDay, 23, 59, 59);
        return startDate.getTime() >= new Date().getTime();
      }
      return e.startDateDate!.getTime() >= new Date().getTime();
    });
    // filter by hidden
    filtered = filtered?.filter((e) => showHidden === !e.active);
    // filter by month
    filtered = filtered?.filter((e) => (filterMonth.toString() === "" || filterMonth === 0 ? true : e.startDateDate!.getMonth() + 1 === filterMonth));
    // filter by event type
    filtered = filtered?.filter((e) => (filterEventType.toString().length === 0 || filterEventType === 0 ? true : e.eventTypeId! === +filterEventType));
    return filtered;
  }, [sortedEventData, showPast, showHidden, filterMonth, filterEventType]);

  const memoEventTypes = useMemo(() => {
    let _eventTypes: EventType[] = [{ eventTypeId: 0, eventTypeName: "All" }];
    for (var et of eventData?.eventTypes ?? []) {
      _eventTypes.push(et);
    }
    return _eventTypes;
  }, [eventData?.eventTypes]);

  // RENDER
  return (
    <Box sx={{ p: 5 }}>
      <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column" }}>
        <Stack direction="row" sx={{ mb: 5 }}>
          <Typography variant="h2" textAlign="start">
            Meetings/Events
          </Typography>
          {isAdmin && (
            <Tooltip placement="top" title="Add Event">
              <Button
                aria-label="add-report"
                color="orange"
                variant="contained"
                sx={{
                  ml: "auto",
                  height: 40,
                  width: 40,
                  minWidth: 40,
                  borderRadius: 20,
                  p: 0,
                }}
                onClick={handleAddEvent}
              >
                <AddOutlinedIcon sx={{ fontSize: 30, color: "white" }} />
              </Button>
            </Tooltip>
          )}
        </Stack>

        {/* Filter container */}
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.cyan.light,
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
            }}
          >
            <FormControl disabled={loading} sx={{ width: 200 }}>
              <InputLabel id="addEvent-month-label">Month</InputLabel>
              <Select labelId="addEvent-month-label" name="month" label="month" value={filterMonth} onChange={(e) => setFilterMonth(+e.target.value)}>
                {months.map((m, i) => {
                  return (
                    <MenuItem key={i} value={i}>
                      {m}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl disabled={loading} sx={{ width: 200, ml: 1 }}>
              <InputLabel id="filter-eventType-label">Event Type</InputLabel>
              <Select labelId="filter-eventType-label" name="eventType" label="eventType" value={filterEventType} onChange={(e) => setFilterEventType(+e.target.value)}>
                {memoEventTypes.map((et, i) => {
                  return (
                    <MenuItem key={et.eventTypeId} value={et.eventTypeId!}>
                      {et.eventTypeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormGroup sx={{ ml: 3 }}>
              <FormControlLabel control={<Checkbox checked={showPast} onChange={(c) => setShowPast(c.target.checked)} />} label="Show past" />
            </FormGroup>
            {isAdmin && (
              <FormGroup sx={{ ml: 3 }}>
                <FormControlLabel control={<Checkbox checked={showHidden} onChange={(c) => setShowHidden(c.target.checked)} />} label="Show hidden" />
              </FormGroup>
            )}
            <Button
              variant="contained"
              color="cyan"
              sx={{
                ml: "auto",
                background: (theme) => theme.palette.cyan.lighter,
              }}
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Box>
        </Paper>

        {loading && (
          <Box mb={4}>
            <CircularProgress sx={{ color: "white" }} size="2em" />
          </Box>
        )}
        {!loading && filteredEventData && filteredEventData.length === 0 && <Typography>No events match this criteria</Typography>}
        <Box
          sx={{
            overflow: "auto",
            pr: 2,
          }}
        >
          {filteredEventData?.map((e) => (
            <EventListComponent event={e} key={e.eventId} />
          ))}
        </Box>
      </Container>
    </Box>
  );
}
