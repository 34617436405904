import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "../../routing/Routes";
import { SignOutButton } from "../Auth/SignOutButton";
import { showNavVar } from "../../UserContextProvider";

interface StatusPageProps {
  statusCode: 401 | 403 | 404;
}

export default function StatusPage({ statusCode }: StatusPageProps) {
  const navigate = useNavigate();

  // hide the nav bar when this page renders
  useEffect(() => {
    showNavVar(false);
    // show the nav bar when we leave
    return () => {
      showNavVar(true);
    };
  }, []);

  const statusMessage = statusCode === 404 ? "Not Found" : statusCode === 403 ? "You do not have access to the Portal" : "Unauthorized";

  const statusMessage2 =
    statusCode === 404
      ? "We're sorry, the page you requested could not be found"
      : statusCode === 403
      ? "Please contact portalhelp@caringcomm.org to assist setting up your account"
      : "You do not have access to this page or resource";

  return (
    <Box
      sx={{
        color: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography fontSize="2em" fontFamily="AvenirBlack">
        {statusMessage}
      </Typography>
      <Typography fontSize="1.3em">{statusMessage2}</Typography>
      {statusCode === 403 ? (
        <SignOutButton sx={{ mt: 5 }} />
      ) : (
        <Button variant="contained" color="orange" sx={{ color: "white", mt: 5 }} onClick={() => navigate(AllRoutes.Home)}>
          Go home
        </Button>
      )}
    </Box>
  );
}
