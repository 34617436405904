import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useRef } from "react";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { AssessmentReportBoilerplate } from "../../../models/AssessmentReportBoilerplate";
import { AddedAssessmentReportBoilerplateOutput } from "../../../graphQL/outputModels/AddedAssessmentReportBoilerplate.output";
import { AddAssessmentReportBoilerplateInput } from "../../../graphQL/inputModels/addAssessmentReportBoilerplateInput";
import { UpdatedAssessmentReportBoilerplateOutput } from "../../../graphQL/outputModels/UpdatedAssessmentReportBoilerplate.output";
import { UpdateAssessmentReportBoilerplateInput } from "../../../graphQL/inputModels/updateAssessmentReportBoilerplateInput";
import { AssessmentReportBoilerplatesOutput } from "../../../graphQL/outputModels/AssessmentReportBoilerplates.output";
import FormatEditor from "../../FormatEditor/FormatEditor";
import { NotificationUtils } from "../../../utils/notification.utils";
import { Editor as TinyMCEEditor } from "tinymce";
import { GetAssessmentReportBoilerplatesInput } from "../../../graphQL/inputModels/getAssessmentReportBoilerplates.input";
import { ActionStatus } from "../../../models/ActionStatus";

interface AddEditSiteAssessmentBoilerplateDialogProps {
  open: boolean;
  editingAssessmentReportBoilerplate?: AssessmentReportBoilerplate;
  onClose: (refetch: boolean) => void;
  assessReportId?: number;
}

export default function AddEditSiteAssessmentBoilerplateDialog({ open, onClose, editingAssessmentReportBoilerplate, assessReportId }: AddEditSiteAssessmentBoilerplateDialogProps) {
  // SETUP
  const editorRef = useRef<TinyMCEEditor>();

  let defaultValues = {
    boilerplateId: 0,
    assessmentReportSectionFK: 0,
    boilerplateName: "",
    boilerplateText: "",
    isHtmlText: true,
    boilerplateSort: 0,
    isUserEditable: false,
  };
  if (editingAssessmentReportBoilerplate) {
    defaultValues = {
      boilerplateId: editingAssessmentReportBoilerplate?.boilerplateId!,
      assessmentReportSectionFK: +editingAssessmentReportBoilerplate.assessmentReportSectionFK!,
      boilerplateName: editingAssessmentReportBoilerplate.boilerplateName!,
      boilerplateText: editingAssessmentReportBoilerplate.boilerplateText!,
      isHtmlText: editingAssessmentReportBoilerplate.isHtmlText!,
      boilerplateSort: editingAssessmentReportBoilerplate.boilerplateSort!,
      isUserEditable: editingAssessmentReportBoilerplate.isUserEditable!,
    };
  }
  const [formValues, setFormValues] = useState(defaultValues);
  const formValidated = true;

  // GRAPHQL
  const [addAssessmentReportBoilerplate, { loading: loadingAddAssessmentReportBoilerplate, data: addedAssessmentReportBoilerplate }] = useMutation<
    AddedAssessmentReportBoilerplateOutput,
    AddAssessmentReportBoilerplateInput
  >(MUTATIONS.ADD_ASSESSMENT_REPORT_BOILERPLATE);

  const [updateAssessmentReportBoilerplate, { loading: loadingUpdateAssessmentReportBoilerplate, data: updatedAssessmentReportBoilerplate }] = useMutation<
    UpdatedAssessmentReportBoilerplateOutput,
    UpdateAssessmentReportBoilerplateInput
  >(MUTATIONS.UPDATE_ASSESSMENT_REPORT_BOILERPLATE);

  // VARIABLES
  const loading = loadingAddAssessmentReportBoilerplate || loadingUpdateAssessmentReportBoilerplate;

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    if (!formValidated || !editorRef.current?.isDirty) return;
    console.log(formValues);
    if (editingAssessmentReportBoilerplate) {
      console.log("updating");
      updateAssessmentReportBoilerplate({
        variables: {
          input: {
            boilerplateId: +formValues.boilerplateId,
            boilerplateText: editorRef.current.getContent(),
          },
        },
        update: (cache, { data }) => {
          if (data?.updateAssessmentReportBoilerplate.actionStatus === ActionStatus.Success) {
            const existingAssessmentReportBoilerplate = cache.readQuery<AssessmentReportBoilerplatesOutput, GetAssessmentReportBoilerplatesInput>({
              query: QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
              variables: {
                assessReportId: assessReportId,
              },
            });
            if (existingAssessmentReportBoilerplate) {
              const newAssessmentReportBoilerplates = existingAssessmentReportBoilerplate.assessmentReportBoilerplates.map((p: AssessmentReportBoilerplate) => {
                if (p.boilerplateId === formValues.boilerplateId) {
                  return { ...p, ...data!.updateAssessmentReportBoilerplate };
                } else {
                  return p;
                }
              });
              cache.writeQuery<AssessmentReportBoilerplatesOutput>({
                query: QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
                variables: {
                  assessReportId: assessReportId,
                },
                data: { assessmentReportBoilerplates: newAssessmentReportBoilerplates },
              });
            }
          }
        },
      });
    } else {
      console.log("adding");
      addAssessmentReportBoilerplate({
        variables: {
          input: {
            assessmentReportSectionFK: formValues.assessmentReportSectionFK!,
            boilerplateName: formValues.boilerplateName!,
            boilerplateText: formValues.boilerplateText!,
            isHtmlText: formValues.isHtmlText!,
            boilerplateSort: +formValues.boilerplateSort!,
            isUserEditable: formValues.isUserEditable!,
          },
        },
        update: (cache, { data }) => {
          if (data?.addAssessmentReportBoilerplate.actionStatus === ActionStatus.Success) {
            const existingAssessmentReportBoilerplates = cache.readQuery<AssessmentReportBoilerplatesOutput, GetAssessmentReportBoilerplatesInput>({
              query: QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
              variables: {
                assessReportId: assessReportId,
              },
            });
            if (existingAssessmentReportBoilerplates) {
              const newAssessmentReportBoilerplates = [...existingAssessmentReportBoilerplates.assessmentReportBoilerplates, { ...data!.addAssessmentReportBoilerplate }];
              cache.writeQuery<AssessmentReportBoilerplatesOutput>({
                query: QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
                variables: {
                  assessReportId: assessReportId,
                },
                data: { assessmentReportBoilerplates: newAssessmentReportBoilerplates },
              });
            }
          }
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    console.log("addedAssessmentReportBoilerplate changed");
    NotificationUtils.notifyAdd(
      addedAssessmentReportBoilerplate?.addAssessmentReportBoilerplate.actionStatus!,
      addedAssessmentReportBoilerplate?.addAssessmentReportBoilerplate.actionStatusMessage!,
      () => onClose(true)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAssessmentReportBoilerplate]);

  useEffect(() => {
    console.log("updatedAssessmentReportBoilerplate changed");
    console.log(updatedAssessmentReportBoilerplate);
    NotificationUtils.notifyUpdate(
      updatedAssessmentReportBoilerplate?.updateAssessmentReportBoilerplate.actionStatus!,
      updatedAssessmentReportBoilerplate?.updateAssessmentReportBoilerplate.actionStatusMessage!,
      () => onClose(true)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAssessmentReportBoilerplate]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {editingAssessmentReportBoilerplate ? `Editing Assessment Boilerplate` : "Add Assessment Boilerplate"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Tooltip title="This is a readonly field" followCursor>
              <TextField id="boilerplateName-input" name="boilerplateName" label="Name" type="text" value={formValues.boilerplateName} onChange={handleInputChange} fullWidth required disabled />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <FormatEditor text={formValues.boilerplateText} editorRef={editorRef} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
