import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Assessment } from "../../models/Assessment";
import { useMutation } from "@apollo/client";
import { useContext, useEffect } from "react";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";
import { DeletedAssessmentOutput } from "../../graphQL/outputModels/DeletedAssessment.output";
import { DeleteAssessmentInput } from "../../graphQL/inputModels/deleteAssessment.input";
import { AssessmentsOutput } from "../../graphQL/outputModels/Assessments.output";
import { QUERIES } from "../../graphQL/queries";
import { GetAssessmentsInput } from "../../graphQL/inputModels/getAssessments.input";
import { UserContext } from "../../UserContextProvider";

interface DeleteAssessmentDialogProps {
  open: boolean;
  assessment: Assessment;
  onClose: (refetch: boolean) => void;
}

export default function DeleteAssessmentDialog({ open, onClose, assessment }: DeleteAssessmentDialogProps) {
  //GRAPHQL
  const [deleteAssessment, { loading: loadingDeleteAssessment, data: deletedAssessment }] = useMutation<DeletedAssessmentOutput, DeleteAssessmentInput>(MUTATIONS.DELETE_ASSESSMENT);
  const user = useContext(UserContext);

  // HANDLERS
  const handleDelete = () => {
    deleteAssessment({
      variables: {
        input: {
          assessmentId: assessment.assessmentId!,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteAssessment.actionStatus === ActionStatus.Success) {
          const existingAssessments = cache.readQuery<AssessmentsOutput, GetAssessmentsInput>({ query: QUERIES.GET_ASSESSMENTS });
          if (existingAssessments) {
            const newAssessments = existingAssessments.assessments.filter((p: Assessment) => p.assessmentId !== data?.deleteAssessment.assessmentId);
            cache.writeQuery<AssessmentsOutput, GetAssessmentsInput>({
              query: QUERIES.GET_ASSESSMENTS,
              variables: {
                memberId: user?.activeMember.companyId ?? 0
              },
              data: { assessments: newAssessments }
            });
          }
        }
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedAssessment?.deleteAssessment?.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(false);
      appNotification({ severity: "success", message: "Successfully deleted" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedAssessment]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Warning: Deleting '{assessment.assessmentName}'
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You are about to PERMANENTLY delete this assessment. If you instead want to keep this assessment, close out of this dialog and click the pencil icon to edit this assessment instead.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteAssessment && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteAssessment}>
          Delete Assessment
        </Button>
      </DialogActions>
    </Dialog>
  );
}
