import React, { useCallback, useEffect, useState } from "react"
import { DownloadUtils } from "../utils/download.utils";
import { FetchUtils } from "../utils/fetch.utils";

type ExportJob = { id: string, assessmentId: number, extension: "pdf" | "doc", processing: boolean, task: Task }
type vars = {
    assessmentId: number;
    isCreatePDF: boolean;
    assessmentName: string;
    tasks?: ExportJob[];
}
type Task = Promise<void> | void

export type ExportType = (params: vars) => void;

type ExportQueryRet = {
    execute: ExportType;
    processing: boolean;
    tasks: ExportJob[];
    loading: boolean;
}
let listeners: React.Dispatch<React.SetStateAction<any>>[] = [];
let state = { tasks: [] as ExportJob[] };

export default function useExportAssessmentReportQuery(): ExportQueryRet {
    const newListener = useState()[1];
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        listeners.push(newListener);

        return (() => {
            listeners = listeners.filter(listener => listener !== newListener);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTasks = (newTasks: ExportJob[]) => {
        state = { ...state, tasks: [...newTasks] };
        listeners.forEach((listener) => {
            listener(state);
        });
    };

    const execute = useCallback(({ assessmentId, isCreatePDF, assessmentName }: vars) => {

        async function run(id: string, assessmentId: number, isCreatePDF: boolean) {
            console.log(`EXECUTING export: ${assessmentId}`)

            // MOCK ASYNC CALL
            // await new Promise(resolve =>
            //     setTimeout(resolve, 10000)
            // );
            // return;

            try {
                setLoading(true)
                const response = await FetchUtils.EXPORT_ASSESSMENT_REPORT(
                    assessmentId,
                    isCreatePDF
                );
                if (response) {
                    DownloadUtils.downloadBlob(response, assessmentName, isCreatePDF ? "pdf" : "docx")
                }
            }
            catch (e: any) {
                console.log(e);
            }
            setLoading(false);
            console.log(`DONE with ${assessmentName}`);
        }


        if (assessmentId) {
            const id = crypto.randomUUID();
            const task1 = run(id, assessmentId, isCreatePDF).finally(() => {
                console.log("FINISHING");
                setTasks([...state.tasks.filter((t) => t.id !== id)])
            });

            console.log(state.tasks);
            let h = [...state.tasks];
            h.push({ id, task: Promise.resolve(task1), assessmentId: assessmentId, extension: isCreatePDF ? "pdf" : "doc", processing: true });
            setTasks([...h])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return {
        execute,
        tasks: state.tasks,
        processing: state.tasks.some(t => t.processing),
        loading: loading
    };
}