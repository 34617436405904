import gql from "graphql-tag";

const ADD_REPORT = gql`
  mutation AddReport($input: AddReportInput!) {
    addReport(input: $input) {
      reportId
      reportName
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      actionStatus
    }
  }
`;

const ADD_FOLDER = gql`
  mutation AddFolder($input: AddFolderInput!) {
    addFolder(input: $input) {
      folderId
      parentId
      folderName
      actionStatus
      actionStatusMessage
    }
  }
`

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folderId
      parentId
      folderName
      actionStatus
      actionStatusMessage
    }
  }
`

const DELETE_FOLDER = gql`
  mutation DeleteFolder($input: DeleteFolderInput!) {
    deleteFolder(input: $input) {
      folderName
      actionStatus
      actionStatusMessage
    }
  }
`

const ADD_LINK = gql`
  mutation AddLink($input: AddLinkInput!) {
    addLink(input: $input) {
      linkId
      displayName
      actionStatus
      actionStatusMessage
    }
  }
`

const UPDATE_LINK = gql`
  mutation UpdateLink($input: UpdateLinkInput!) {
    updateLink(input: $input) {
      linkId
      displayName
      actionStatus
      actionStatusMessage
    }
  }
`

const DELETE_LINK = gql`
  mutation DeleteLink($input: DeleteLinkInput!) {
    deleteLink(input: $input) {
      displayName
      actionStatus
      actionStatusMessage
    }
  }
`

const ADD_EVENT = gql`
  mutation AddEvent($input: AddEventInput!) {
    addEvent(input: $input) {
      eventId
      eventName
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      eventId
      eventName
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_EVENT = gql`
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      eventName
      actionStatus
      actionStatusMessage
    }
  }
`;


// *** NOT WORKING ***
// const ADD_FILE = gql`
//   mutation AddOtherFile($input: Upload!) {
//     addOtherFile(input: $input) {
//       fileId
//       folderId
//       fileName
//       fileSizeKb
//       actionStatus
//       actionStatusMessage
//     }
//   }
// `

const UPDATE_FILE = gql`
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      fileId
      fileName
      actionStatus
      actionStatusMessage
    }
  }
`

const DELETE_FILE = gql`
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      fileName
      fileExtension
      actionStatus
      actionStatusMessage
    }
  }
`

const ADD_PRESCRIPTIVE = gql`
  mutation AddPrescriptive($input: AddPrescriptiveInput!) {
    addPrescriptive(input: $input) {
      prescriptiveId
      prescriptiveNumber
      prescriptiveDescription
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_PRESCRIPTIVE = gql`
  mutation UpdatePrescriptive($input: UpdatePrescriptiveInput!) {
    updatePrescriptive(input: $input) {
      prescriptiveId
      prescriptiveNumber
      prescriptiveDescription
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_PRESCRIPTIVE = gql`
  mutation DeletePrescriptive($input: DeletePrescriptiveInput!) {
    deletePrescriptive(input: $input) {
      prescriptiveId
      prescriptiveNumber
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_QUESTION_RESPONSE = gql`
  mutation AddQuestionResponse($input: AddQuestionResponseInput!) {
    addQuestionResponse(input: $input) {
      responseId
      responseDescription
      responseAbbreviation
      ratingPoints
      ratingCompliant
      isDefault
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_QUESTION_RESPONSE = gql`
  mutation UpdateQuestionResponse($input: UpdateQuestionResponseInput!) {
    updateQuestionResponse(input: $input) {
      responseId
      responseDescription
      responseAbbreviation
      ratingPoints
      ratingCompliant
      isDefault
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_QUESTION_RESPONSE = gql`
  mutation DeleteQuestionResponse($input: DeleteQuestionResponseInput!) {
    deleteQuestionResponse(input: $input) {
      responseId
      responseDescription
      responseAbbreviation
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_ASSESSMENT_REPORT_BOILERPLATE = gql`
  mutation AddAssessmentReportBoilerplate($input: AddAssessmentReportBoilerplateInput!) {
    addAssessmentReportBoilerplate(input: $input) {
      boilerplateId
      assessmentReportSectionFK
      boilerplateName
      boilerplateText
      isHtmlText
      boilerplateSort
      isUserEditable
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_ASSESSMENT_REPORT_BOILERPLATE = gql`
  mutation UpdateAssessmentReportBoilerplate($input: UpdateAssessmentReportBoilerplateInput!) {
    updateAssessmentReportBoilerplate(input: $input) {
      boilerplateId
      assessmentReportSectionFK
      boilerplateName
      boilerplateText
      isHtmlText
      boilerplateSort
      isUserEditable
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_ASSESSMENT_REPORT_BOILERPLATE = gql`
  mutation DeleteAssessmentReportBoilerplate($input: DeleteAssessmentReportBoilerplateInput!) {
    deleteAssessmentReportBoilerplate(input: $input) {
      boilerplateId
      boilerplateName
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_MASTER_FILE = gql`
  mutation DeleteMasterFile($input: DeleteMasterFileInput!) {
    deleteMasterFile(input: $input) {
      fileId
      fileName
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_QUESTION = gql`
  mutation AddQuestion($input: AddQuestionInput!) {
    addQuestion(input: $input) {
      questionId
      questionDescription
      nonCompliantRecommendation
      isDefault
      isExtraCredit
      prescriptiveId
      questionNumber
      questionNumberSort
      responseIds
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      questionId
      questionDescription
      nonCompliantRecommendation
      isDefault
      isExtraCredit
      prescriptiveId
      questionNumber
      questionNumberSort
      responseIds
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($input: DeleteQuestionInput!) {
    deleteQuestion(input: $input) {
      questionId
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_SITE_VISIT_DOCUMENT = gql`
  mutation AddSiteVisitDocument($input: AddSiteVisitDocumentInput!) {
    addSiteVisitDocument(input: $input) {
      documentId
      documentDescription
      sortOrder
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_SITE_VISIT_DOCUMENT = gql`
  mutation UpdateSiteVisitDocument($input: UpdateSiteVisitDocumentInput!) {
    updateSiteVisitDocument(input: $input) {
      documentId
      documentDescription
      sortOrder
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_SITE_VISIT_DOCUMENT = gql`
  mutation DeleteSiteVisitDocument($input: DeleteSiteVisitDocumentInput!) {
    deleteSiteVisitDocument(input: $input) {
      documentId
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_ASSESSMENT = gql`
  mutation AddAssessment($input: AddAssessmentInput!) {
    addAssessment(input: $input) {
      assessmentId
      assessmentName
      assessmentDate
      assessmentTypeId
      memberId
      memberName
      memberPolicyNumber
      assessmentSiteVisitDocuments {
        assessmentDocumentId
        assessmentId
        sortOrder
        documentDescription 
        isReviewed
      }
      assessmentParticipants {
        assessmentParticipantId
        assessmentId
        participantName
        participantTitle
      }
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($input: DeleteAssessmentInput!) {
    deleteAssessment(input: $input) {
      assessmentId
      assessmentName
      actionStatus
      actionStatusMessage
    }
  }
`;

const ADD_ASSESSMENT_COMMENT = gql`
  mutation AddAssessmentComment($input: AddAssessmentCommentInput!) {
    addAssessmentComment(input: $input) {
      assessmentCommentId
      assessmentId
      assessmentQuestionId
      assessmentCommentDescription
      createdBy
      createdByFullName
      createdDate
      modifiedBy
      modifiedByFullName
      modifiedDate
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_ASSESSMENT_COMMENT = gql`
  mutation UpdateAssessmentComment($input: UpdateAssessmentCommentInput!) {
    updateAssessmentComment(input: $input) {
      assessmentCommentId
      assessmentId
      assessmentQuestionId
      assessmentCommentDescription
      createdBy
      createdByFullName
      createdDate
      modifiedBy
      modifiedByFullName
      modifiedDate
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_ASSESSMENT_COMMENT = gql`
  mutation DeleteAssessmentComment($input: DeleteAssessmentCommentInput!) {
    deleteAssessmentComment(input: $input) {
      assessmentCommentId
      assessmentId
      assessmentQuestionId
      actionStatus
      actionStatusMessage
    }
  }
`;

const DELETE_ASSESSMENT_ATTACHMENT = gql`
  mutation DeleteAssessmentAttachment($input: DeleteAssessmentAttachmentInput!) {
    deleteAssessmentAttachment(input: $input) {
      assessmentId
      assessmentAttachmentId
      assessmentQuestionId
      actionStatus
      actionStatusMessage
    }
  }
`;

const SET_SELECTED_QUESTION_RESPONSES = gql`
  mutation SetSelectedAssessmentQuestionResponses($input: SetSelectedAssessmentQuestionResponsesInput!) {
    setSelectedAssessmentQuestionResponses(input: $input) {
      assessmentId
      selectedAssessmentQuestionResponses {
        assessmentQuestionId
        assessmentId
        selectedAssessmentQuestionResponseId
      }
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_ASSESSMENT_RECOMMENDATION = gql`
  mutation UpdateAssessmentQuestionNonCompliantRecommendation($input: UpdateAssessmentQuestionNonCompliantRecommendationInput!) {
    updateAssessmentQuestionNonCompliantRecommendation(input: $input) {
      assessmentQuestionId
      assessmentNonCompliantRecommendation
      actionStatus
      actionStatusMessage
    }
  }
`;

const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      assessmentId
      assessmentName
      assessmentDate
      assessmentTypeId
      memberId
      memberName
      memberPolicyNumber
      performedBy
      assessmentSiteVisitDocuments {
        assessmentDocumentId
        sortOrder
        documentDescription
        isReviewed
      }
      assessmentParticipants {
        assessmentParticipantId
        assessmentId
        participantName
        participantTitle
      }
      
      actionStatus
      actionStatusMessage
    }
  }
`;


export const MUTATIONS = {
  ADD_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  ADD_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
  // ADD_FILE, // NOT WORKING
  UPDATE_FILE,
  DELETE_FILE,
  ADD_LINK,
  UPDATE_LINK,
  DELETE_LINK,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  ADD_PRESCRIPTIVE,
  UPDATE_PRESCRIPTIVE,
  DELETE_PRESCRIPTIVE,
  ADD_QUESTION_RESPONSE,
  UPDATE_QUESTION_RESPONSE,
  DELETE_QUESTION_RESPONSE,
  ADD_ASSESSMENT_REPORT_BOILERPLATE,
  UPDATE_ASSESSMENT_REPORT_BOILERPLATE,
  DELETE_ASSESSMENT_REPORT_BOILERPLATE,
  DELETE_MASTER_FILE,
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  ADD_SITE_VISIT_DOCUMENT,
  UPDATE_SITE_VISIT_DOCUMENT,
  DELETE_SITE_VISIT_DOCUMENT,
  ADD_ASSESSMENT,
  DELETE_ASSESSMENT,
  ADD_ASSESSMENT_COMMENT,
  UPDATE_ASSESSMENT_COMMENT,
  DELETE_ASSESSMENT_COMMENT,
  DELETE_ASSESSMENT_ATTACHMENT,
  SET_SELECTED_QUESTION_RESPONSES,
  UPDATE_ASSESSMENT_RECOMMENDATION,
  UPDATE_ASSESSMENT
}