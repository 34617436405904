import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { QUERIES } from '../../graphQL/queries';
import { LogOutput } from '../../graphQL/outputModels/Log.output';
import { logMapper } from './logMapper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import { DateUtils } from '../../utils/date.utils';
import { GetLogsInput } from '../../graphQL/inputModels/getLogs.input';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { LogCustom } from '../../models/Log';
import useLogExportQuery from '../../hooks/useLogExportQuery';

interface ColumnData {
  dataKey: keyof LogCustom;
  label: string;
  numeric?: boolean;
  width: number;
}

const loadingData: LogCustom[] = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

const columns: ColumnData[] = [
  {
    width: 200,
    label: 'Action',
    dataKey: 'action',
  },
  {
    width: 120,
    label: 'Metric',
    dataKey: 'metric',
  },
  {
    width: 320,
    label: 'Value',
    dataKey: 'value',
  },
  {
    width: 120,
    label: 'First Name',
    dataKey: 'firstName',
  },
  {
    width: 120,
    label: 'Last Name',
    dataKey: 'lastName',
  },
  {
    width: 120,
    label: 'User Email',
    dataKey: 'userEmail',
  },
  {
    width: 120,
    label: 'Date',
    dataKey: 'createdDateAsFormattedString',
  },
];

export default function LogReportPage() {
  // SETUP
  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate.valueOf());
  defaultStartDate.setDate(defaultStartDate.getDate() - 7); // 7 days ago
  defaultStartDate.setHours(0);
  defaultStartDate.setMinutes(0);
  defaultStartDate.setSeconds(0);
  defaultStartDate.setMilliseconds(0);

  const [formValues, setFormValues] = useState({
    startDate: defaultStartDate.toISOString(),
    startDateDate: defaultStartDate,
    startDateString: DateUtils.formatDateForTextfield(defaultStartDate),
    startTimeString: DateUtils.formatTimeForTextfield(defaultStartDate),
    endDate: defaultEndDate.toISOString(),
    endDateDate: defaultEndDate,
    endDateString: DateUtils.formatDateForTextfield(defaultEndDate),
    endTimeString: DateUtils.formatTimeForTextfield(defaultEndDate),
    timezone: DateUtils.getCurrTimezoneOffset(),
  });
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [currSort, setCurrSort] = useState<'action' | 'metric' | 'value' | 'firstName' | 'lastName' | 'userEmail' | 'date'>('date');

  // CUSTOM HOOKS
  const { execute: executeExportLogData, loading: callingExportLogData } = useLogExportQuery();

  // GRAPHQL
  const [getLogs, { loading, data: logData }] = useLazyQuery<LogOutput, GetLogsInput>(QUERIES.GET_LOGS, {
    fetchPolicy: 'cache-and-network',
  });

  // HANDLERS
  const handleDateChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    var year: number, month: number, day: number;
    var split = value.split('-');
    year = +split[0];
    month = +split[1];
    day = +split[2];
    var oldDate: Date;
    if (name === 'startDate') oldDate = formValues.startDateDate!;
    else oldDate = formValues.endDateDate!;
    const dateValue = new Date(year, month - 1, day);
    dateValue.setHours(oldDate.getHours(), oldDate.getMinutes());

    setFormValues({
      ...formValues,
      [name]: dateValue,
      [`${name}String`]: DateUtils.formatDateForTextfield(dateValue, formValues.timezone),
    });
  };

  /**
   * @param value time in format hh:ss
   */
  const handleTimeChange = (name: 'startTime' | 'endTime', value: string) => {
    const hrs = +value.substring(0, 2);
    const min = +value.substring(3, 5);

    if (name === 'startTime') {
      const newDate = new Date(formValues.startDate!);
      newDate.setHours(0, 0, 0);
      newDate.setHours(hrs, min);
      setFormValues({
        ...formValues,
        startDate: newDate.toISOString(),
        startDateDate: newDate,
        startTimeString: DateUtils.formatTimeForTextfield(newDate, formValues.timezone),
      });
    } else if (name === 'endTime') {
      const newDate = new Date(formValues.endDate!);
      newDate.setHours(0, 0, 0);
      newDate.setHours(hrs, min);
      setFormValues({
        ...formValues,
        endDate: newDate.toISOString(),
        endDateDate: newDate,
        endTimeString: DateUtils.formatTimeForTextfield(newDate, formValues.timezone),
      });
    }
  };

  const handleSortChanged = (nextSort: 'action' | 'metric' | 'value' | 'firstName' | 'lastName' | 'userEmail' | 'date') => {
    if (currSort === nextSort) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setCurrSort(nextSort);
      setSortDirection('desc');
    }
  };

  const handleDownloadCSV = () => {
    if (!callingExportLogData) executeExportLogData({ startRange: formValues.startDateString, endRange: formValues.endDateString });
  };

  // MEMO
  const mappedLogs = useMemo(() => {
    return logData?.logs?.map((e) => {
      return logMapper.mapToCustom(e);
    });
  }, [logData]);

  const sortedLogs = useMemo(() => {
    let sortedLogs = mappedLogs ?? [];
    if (currSort === 'action') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.action ?? '').localeCompare(b.action ?? ''));
      } else {
        sortedLogs.sort((a, b) => (b.action ?? '').localeCompare(a.action ?? ''));
      }
    } else if (currSort === 'metric') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.metric ?? '').localeCompare(b.metric ?? ''));
      } else {
        sortedLogs.sort((a, b) => (b.metric ?? '').localeCompare(a.metric ?? 'ZZ'));
      }
    } else if (currSort === 'value') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.value ?? '').localeCompare(b.value ?? ''));
      } else {
        sortedLogs.sort((a, b) => (b.value ?? '').localeCompare(a.value ?? ''));
      }
    } else if (currSort === 'firstName') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? ''));
      } else {
        sortedLogs.sort((a, b) => (b.firstName ?? '').localeCompare(a.firstName ?? ''));
      }
    } else if (currSort === 'lastName') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.lastName ?? '').localeCompare(b.lastName ?? 'ZZ'));
      } else {
        sortedLogs.sort((a, b) => (b.lastName ?? '').localeCompare(a.lastName ?? 'ZZ'));
      }
    } else if (currSort === 'userEmail') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => (a.userEmail ?? '').localeCompare(b.userEmail ?? ''));
      } else {
        sortedLogs.sort((a, b) => (b.userEmail ?? '').localeCompare(a.userEmail ?? ''));
      }
    } else if (currSort === 'date') {
      if (sortDirection === 'desc') {
        sortedLogs.sort((a, b) => {
          return b.createdDateDate!.diff(a.createdDateDate!);
        });
      } else {
        sortedLogs.sort((a, b) => {
          return a.createdDateDate!.diff(b.createdDateDate!);
        });
      }
    }

    return sortedLogs;
  }, [sortDirection, currSort, mappedLogs]);

  const VirtuosoTableComponents: TableComponents<LogCustom> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} sx={{ maxHeight: 'calc(100vh - 355px)', margin: 'auto', border: '1px grey solid', boxSizing: 'border-box' }} />
    )),
    Table: (props) => <Table {...props} stickyHeader sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        <TableCell align='center'>
          <TableSortLabel active={currSort === 'action'} direction={sortDirection} onClick={() => handleSortChanged('action')}>
            Action
          </TableSortLabel>
        </TableCell>
        <TableCell align='center'>
          <TableSortLabel active={currSort === 'metric'} direction={sortDirection} onClick={() => handleSortChanged('metric')}>
            Metric
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' sx={{ width: 200 }}>
          <TableSortLabel active={currSort === 'value'} direction={sortDirection} onClick={() => handleSortChanged('value')}>
            Value
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' sx={{ minWidth: 100 }}>
          <TableSortLabel active={currSort === 'firstName'} direction={sortDirection} onClick={() => handleSortChanged('firstName')}>
            First Name
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' sx={{ minWidth: 100 }}>
          <TableSortLabel active={currSort === 'lastName'} direction={sortDirection} onClick={() => handleSortChanged('lastName')}>
            Last Name
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' sx={{ minWidth: 100 }}>
          <TableSortLabel active={currSort === 'userEmail'} direction={sortDirection} onClick={() => handleSortChanged('userEmail')}>
            User Email
          </TableSortLabel>
        </TableCell>
        <TableCell align='center' style={{ width: 170 }}>
          <TableSortLabel active={currSort === 'date'} direction={sortDirection} onClick={() => handleSortChanged('date')}>
            Date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    );
  }

  function rowContent(_index: number, row: LogCustom) {
    // console.log(_index);
    // console.log(row.createdDateAsFormattedString);
    // console.log(columns);
    return (
      <React.Fragment>
        {columns.map((column, i) =>
          loading ? (
            <TableCell key={column.dataKey}>
              <Skeleton />
            </TableCell>
          ) : (
            <TableCell key={column.dataKey} align={i === 0 ? 'left' : 'center'}>
              {row[column.dataKey] as string}
            </TableCell>
          )
        )}
      </React.Fragment>
    );
  }

  // USEEFFECTS
  useEffect(() => {
    getLogs({
      variables: {
        startRange: formValues.startDate,
        endRange: formValues.endDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RENDER
  return (
    <Box sx={{ p: 5 }}>
      <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', mb: 5 }}>
          <Typography variant='h2' textAlign='start' mr='auto'>
            Log Report
          </Typography>
          <Tooltip placement='top' title='Download CSV'>
            <Button
              aria-label='refresh'
              color='orange'
              variant='contained'
              sx={{
                mr: 2,
                height: 40,
                width: 40,
                minWidth: 40,
                borderRadius: 20,
                p: 0,
              }}
              onClick={() => {
                handleDownloadCSV();
              }}
            >
              {callingExportLogData ? <CircularProgress sx={{ color: "white"}} size={20}/> : <DownloadIcon sx={{ fontSize: 30, color: 'white' }} />}
            </Button>
          </Tooltip>
        </Box>

        {/* Filter container */}
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.cyan.light,
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1,
            }}
          >
            <Typography sx={{ mr: 1, width: 50 }}>From:</Typography>
            <TextField type='date' label='Date*' name='startDate' value={formValues.startDateString} onChange={handleDateChange} InputLabelProps={{ shrink: true }} />
            <TextField
              type='time'
              label='Time*'
              name='startTime'
              value={formValues.startTimeString}
              onChange={(e) => {
                handleTimeChange('endTime', e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
            <Typography sx={{ ml: 1, mr: 1, width: 50 }}>To:</Typography>
            <TextField type='date' label='Date*' name='endDate' value={formValues.endDateString} onChange={handleDateChange} InputLabelProps={{ shrink: true }} />
            <TextField
              type='time'
              label='Time*'
              name='endTime'
              value={formValues.endTimeString}
              onChange={(e) => {
                handleTimeChange('endTime', e.target.value);
              }}
              sx={{ mr: 3 }}
              InputLabelProps={{ shrink: true }}
            />
            <Tooltip placement='top' title='Refresh'>
              <Button
                aria-label='refresh'
                color='orange'
                variant='contained'
                sx={{
                  height: 40,
                  width: 40,
                  minWidth: 40,
                  borderRadius: 20,
                  p: 0,
                  ml: 'auto',
                }}
                onClick={() => {
                  getLogs({
                    variables: {
                      startRange: formValues.startDate,
                      endRange: formValues.endDate,
                    },
                  });
                }}
              >
                <ArrowForwardIcon sx={{ fontSize: 30, color: 'white' }} />
              </Button>
            </Tooltip>
          </Box>
        </Paper>

        <Box style={{ height: 'calc(100vh - 355px)', minWidth: 810, width: '80vw', maxWidth: 1536 }}>
          <TableVirtuoso data={loading ? loadingData : sortedLogs} components={VirtuosoTableComponents} fixedHeaderContent={fixedHeaderContent} itemContent={rowContent} />
        </Box>
        {!loading && sortedLogs.length === 0 && <Typography sx={{ mt: 3 }}>There are no logs</Typography>}
      </Container>
    </Box>
  );
}
