// SET THE APPCONFIG
const appConfig = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    b2c: {
        tenant: process.env.REACT_APP_B2C_TENANT!,
        signInPolicy: process.env.REACT_APP_SIGNINPOLICY!,
        applicationID: process.env.REACT_APP_B2C_APPID!,
        reactRedirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        logoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
        scope: process.env.REACT_APP_B2C_SCOPE!
    },
    tinymce_apikey: process.env.REACT_APP_TINYMCE_APIKEY,
    environment: process.env.REACT_APP_ENVIRONMENT!
}

// EXPORT
export default appConfig;