/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Container, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { AddEventInput } from "../../graphQL/inputModels/addEvent.input";
import { GetPortalEventInput } from "../../graphQL/inputModels/getPortalEvent.input";
import { UpdateEventInput } from "../../graphQL/inputModels/updateEvent.input";
import { MUTATIONS } from "../../graphQL/mutations";
import { AddedEventOutput } from "../../graphQL/outputModels/AddedEvent.output";
import { AddedFilesToEventOutput } from "../../graphQL/outputModels/AddedFilesToEvent.output";
import { EventOutput } from "../../graphQL/outputModels/Event.output";
import { AddEventFormOutput } from "../../graphQL/outputModels/AddEventForm.output";
import { UpdatedEventOutput } from "../../graphQL/outputModels/UpdatedEvent.output";
import { QUERIES } from "../../graphQL/queries";
import useUploadBatchQuery from "../../hooks/useUploadBatchQuery";
import useUser from "../../hooks/useUser";
import { ActionStatus, ActionStatusNumber } from "../../models/ActionStatus";
import { EventCustom } from "../../models/Event";
import { AllRoutes } from "../../routing/Routes";
import { DateUtils } from "../../utils/date.utils";
import DeleteEventDialog from "./DeleteEventDIalog";
import EventDetailComponent from "./EventDetailComponent";
import { eventMapper } from "./eventMapper";

interface EventDetailPageProps {
  isAdding?: boolean;
  isEditing?: boolean;
}
export default function EventDetailPage({ isAdding, isEditing }: EventDetailPageProps) {
  // SETUP
  const { eventId: _eventId } = useParams();
  const eventId = +(_eventId ?? 0);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useUser();
  const [editMode, setEditMode] = useState((isAdding || isEditing) ?? false);
  const [deleteEventDialogOpen, setDeleteEventDialogOpen] = useState(false);
  // const [event, setEvent] = useState<PortalEvent>();

  // GRAPHQL
  const [getEvent, { loading: loadingEvent, data: eventData }] = useLazyQuery<EventOutput, GetPortalEventInput>(QUERIES.GET_EVENT, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingEventTypes, data: eventFormData } = useQuery<AddEventFormOutput>(QUERIES.GET_ADDEVENT_FORM, {});

  const [updateEvent, { loading: loadingUpdateEvent, data: updatedEvent }] = useMutation<UpdatedEventOutput, UpdateEventInput>(MUTATIONS.UPDATE_EVENT);

  const [addEvent, { loading: loadingAddEvent, data: addedEvent }] = useMutation<AddedEventOutput, AddEventInput>(MUTATIONS.ADD_EVENT);

  const [formValues, setFormValues] = useState<EventCustom>(
    isAdding
      ? {
          startDate: new Date().toISOString(),
          startDateDate: new Date(),
          startDateString: DateUtils.formatDateForTextfield(new Date()),
          startTimeString: DateUtils.formatTimeForTextfield(new Date()),
          endDate: new Date().toISOString(),
          endDateDate: new Date(),
          endDateString: DateUtils.formatDateForTextfield(new Date()),
          endTimeString: DateUtils.formatTimeForTextfield(new Date()),
        }
      : {
          startDateString: "",
          startTimeString: "",
          endDateString: "",
          endTimeString: "",
        }
  );

  // HOOKS
  const { callApi: uploadFiles, loading: loadingUpload, data: dataUpload } = useUploadBatchQuery<AddedFilesToEventOutput>();

  // HANDLERS
  const handlePublish = () => {
    if (isAdding) handleAddEvent(false);
    else handleUpdateEvent(false);
  };

  const handleSaveDraft = () => {
    if (isAdding) handleAddEvent(true);
    else handleUpdateEvent(true);
  };

  const handleGoBack = () => {
    const ii = location.pathname.lastIndexOf("/");
    navigate(location.pathname.substring(0, ii));
  };

  const handleAddEvent = (hide: boolean) => {
    addEvent({
      variables: {
        input: {
          eventId: formValues.eventId!,
          eventName: formValues.eventName!,
          eventTypeId: formValues.eventTypeId!,
          startDate: formValues.startDate!,
          endDate: formValues.endDate!,
          timezoneId: formValues.timezoneId!,
          allDay: formValues.allDay ?? false,
          location: formValues.location!,
          eventShortDescription: formValues.eventShortDescription!,
          eventFullDescription: formValues.eventFullDescription!,
          registrationLink: formValues.registrationLink!,
          hotelReservationLink: formValues.hotelReservationLink!,
          active: !hide,
        },
      },
    });
  };

  const handleUpdateEvent = (hide: boolean) => {
    updateEvent({
      variables: {
        input: {
          eventId: formValues.eventId!,
          eventName: formValues.eventName!,
          eventTypeId: formValues.eventTypeId!,
          startDate: formValues.startDate!,
          endDate: formValues.endDate!,
          timezoneId: formValues.timezoneId!,
          allDay: formValues.allDay ?? false,
          location: formValues.location!,
          eventShortDescription: formValues.eventShortDescription!,
          eventFullDescription: formValues.eventFullDescription!,
          registrationLink: formValues.registrationLink!,
          hotelReservationLink: formValues.hotelReservationLink!,
          active: !hide,
        },
      },
    });
  };

  var loadingMessage = "Loading event...";
  if (loadingAddEvent) loadingMessage = "Adding event...";
  if (loadingUpdateEvent) loadingMessage = "Updating event...";
  if (loadingUpload) loadingMessage = "Uploading files...";

  // USEEFFECTS
  useEffect(() => {
    if (!isAdding && (!_eventId || isNaN(+_eventId))) {
      navigate(AllRoutes.NotFound);
    } else if (!isAdding && _eventId && !isNaN(+_eventId)) {
      getEvent({
        variables: {
          eventId: eventId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (eventData) {
      setFormValues(eventMapper.mapToCustom(eventData.event));
    }
  }, [eventData]);

  useEffect(() => {
    if (isAdding && eventFormData?.timezones) {
      // console.log(DateUtils.getCurrTimezoneOffset());
      var g = eventFormData.timezones.find((t) => t.standardOffset === DateUtils.getCurrTimezoneOffset());
      setFormValues({
        ...formValues,
        timezoneId: g?.timezoneId,
        timezone: g,
      });
    }
  }, [eventFormData]);

  // Upload files after the event has been added/updated
  useEffect(() => {
    if (formValues.attachments || formValues.newThumbnail) {
      if (addedEvent?.addEvent.actionStatus === ActionStatus.Success) {
        uploadFiles({
          eventId: addedEvent.addEvent.eventId,
          thumbnail: formValues.newThumbnail,
          attachments: formValues.newAttachments,
        });
      } else if (updatedEvent?.updateEvent.actionStatus === ActionStatus.Success) {
        uploadFiles({
          eventId: updatedEvent.updateEvent.eventId,
          thumbnail: formValues.newThumbnail,
          attachments: formValues.newAttachments,
        });
      }
    }
    if (addedEvent?.addEvent.actionStatus === ActionStatus.Success) {
      appNotification({
        severity: "success",
        message: `Successfully added '${addedEvent?.addEvent.eventName}'`,
      });
      if (!formValues.attachments && !formValues.newThumbnail) handleGoBack();
    } else if (updatedEvent?.updateEvent.actionStatus === ActionStatus.Success) {
      appNotification({
        severity: "success",
        message: `Successfully updated '${updatedEvent?.updateEvent.eventName}'`,
      });
      if (!formValues.attachments && !formValues.newThumbnail) handleGoBack();
    }
  }, [addedEvent, updatedEvent]);

  // Check result of uploaded files
  useEffect(() => {
    if (!dataUpload) return;
    console.log("Data uploaded");
    console.log(dataUpload);
    if (formValues.newAttachments && dataUpload?.attachmentActionStatus !== ActionStatusNumber.Success) {
      console.error(dataUpload?.attachmentActionStatusMessage);
      appNotification({
        severity: "error",
        message: `Error: ${dataUpload.attachmentActionStatusMessage}`,
      });
    } else {
      setFormValues({ ...formValues, newAttachments: undefined });
      appNotification({
        severity: "error",
        message: `Successfully uploaded files`,
      });
    }

    if (formValues.newThumbnail && dataUpload?.thumbnailActionStatus !== ActionStatusNumber.Success) {
      console.error(dataUpload?.thumbnailActionStatusMessage);
      appNotification({
        severity: "error",
        message: `Error: ${dataUpload.thumbnailActionStatusMessage}`,
      });
    } else {
      setFormValues({ ...formValues, newThumbnail: undefined });
      appNotification({
        severity: "success",
        message: `Successfully uploaded files`,
      });
    }
  }, [dataUpload]);

  // RENDER
  const loading = loadingEvent || loadingEventTypes || loadingUpdateEvent || loadingAddEvent || loadingUpload;
  return (
    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", width: "100%" }}>
      <Container
        maxWidth="lg"
        sx={{
          p: 5,
          pt: 1,
          boxSizing: "border-box",
        }}
      >
        {deleteEventDialogOpen && eventData?.event && (
          <DeleteEventDialog
            open={deleteEventDialogOpen}
            onClose={(deleted: boolean) => {
              if (deleted) {
                handleGoBack();
              }
            }}
            event={eventData.event}
          />
        )}

        <Box sx={{ m: "auto", display: "flex", flexDirection: "column" }}>
          <Box display="flex" alignItems="center">
            <Button
              color="orange"
              variant="contained"
              onClick={handleGoBack}
              sx={{
                justifySelf: "flex-start",
                minWidth: 60,
                width: 60,
                height: 40,
                zIndex: 2,
                mb: 2,
              }}
            >
              Back
            </Button>
            {isAdmin && (
              <FormGroup sx={{ ml: "auto" }}>
                <FormControlLabel control={<Switch checked={editMode} onChange={(e) => setEditMode(e.target.checked)} />} label="Edit Mode" />
              </FormGroup>
            )}
          </Box>

          <EventDetailComponent
            formValues={formValues}
            setFormValues={setFormValues}
            eventTypes={eventFormData?.eventTypes}
            timezones={eventFormData?.timezones ?? []}
            editMode={editMode}
            loading={loading || (!isAdding && !formValues.eventId)}
            loadingMessage={loadingMessage}
          />
          {isAdmin && (
            <Box mt={5} position="relative">
              {eventData?.event && (
                <Button color="error" variant="contained" sx={{ position: { md: "absolute" }, left: 0, width: 100 }} onClick={() => setDeleteEventDialogOpen(true)}>
                  Delete
                </Button>
              )}
              <Button color="orange" variant="contained" sx={{ ml: 3, mr: 3, width: 180 }} onClick={handleSaveDraft}>
                Save and hide
              </Button>
              <Button color="orange" variant="contained" sx={{ width: 180 }} onClick={handlePublish}>
                Save and publish
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
