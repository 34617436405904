import { Box, Checkbox, Radio, Typography } from "@mui/material";
import { memo, useState } from "react";
import { Company } from "../../models/Company";

interface CustomRadioButtonProps {
  checked: boolean;
  company: Company;
  handleRadioChange: any;
  disabled?: boolean;
  useCheckbox?: boolean;
}

export function CustomRadioButton({ checked, company, handleRadioChange, disabled, useCheckbox }: CustomRadioButtonProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      borderBottom="1px solid #cdcdcd"
      sx={{ cursor: disabled ? "default" : "pointer", width: "50%" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (!disabled) handleRadioChange(company.companyId);
      }}
    >
      {useCheckbox ? (
        <Checkbox
          checked={checked}
          // onClick={() => handleRadioChange(company.companyId)}
          disabled={disabled}
        />
      ) : (
        <Radio
          checked={checked}
          // onClick={() => handleRadioChange(company.companyId)}
          disabled={disabled}
        />
      )}
      <Typography
        component="span"
        sx={{
          fontWeight: hovered && !disabled ? "bold" : "normal",
          color: (theme) => (disabled ? theme.palette.grey[500] : "black"),
          userSelect: "none",
        }}
      >
        {company.companyName}
      </Typography>
    </Box>
  );
}

function arePropsEqual(prevProps: CustomRadioButtonProps, nextProps: CustomRadioButtonProps) {
  return prevProps.checked === nextProps.checked && prevProps.disabled === nextProps.disabled;
}
export const MemoizedRadioButton = memo(CustomRadioButton, arePropsEqual);
