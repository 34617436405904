import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { QUERIES } from "../../../graphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { QuestionResponse } from "../../../models/QuestionResponse";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { QuestionResponsesOutput } from "../../../graphQL/outputModels/QuestionResponse.output";

interface QuestionResponseTableProps {
  onShowAddEditDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => void;
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
}

export default function QuestionResponseTable({ onShowAddEditDialog, onShowDeleteDialog }: QuestionResponseTableProps) {
  // GRAPHQL
  const [getQuestionResponses, { loading, data: questionResponsesData }] = useLazyQuery<QuestionResponsesOutput>(QUERIES.GET_QUESTION_RESPONSES, {
    fetchPolicy: "cache-and-network",
  });

  // HANDLERS
  const handleOpenAddDialog = (questionResponse?: QuestionResponse) => {
    onShowAddEditDialog("qresponses", questionResponse);
  };

  const handleDeleteDialog = (questionResponse: QuestionResponse) => {
    onShowDeleteDialog("qresponses", questionResponse);
  };

  // USEEFFECTS
  useEffect(() => {
    getQuestionResponses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 420px)"
        margin="auto"
        mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: 100 }}>
                Name
              </TableCell>
              <TableCell align="left">Abbr.</TableCell>
              <TableCell align="left">Points</TableCell>
              <TableCell align="left">Compliance</TableCell>
              <TableCell align="center" sx={{ width: 70 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              questionResponsesData?.questionResponses?.map((questionResponse, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {questionResponse.responseDescription}
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {questionResponse.responseAbbreviation}
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {questionResponse.ratingPoints ?? "N/A"}
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {questionResponse.ratingCompliant ? "Compliant" : questionResponse.ratingCompliant === false ? "Not Compliant" : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit question response">
                      <IconButton
                        onClick={() => {
                          handleOpenAddDialog(questionResponse);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove question response">
                      <IconButton
                        onClick={() => {
                          handleDeleteDialog(questionResponse);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (questionResponsesData?.questionResponses?.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no question responses</Typography>}
      <Button variant="contained" onClick={() => handleOpenAddDialog()} sx={{ mt: 1 }}>
        +
      </Button>
    </Box>
  );
}
