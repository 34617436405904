import { Navigate } from 'react-router-dom';
import PublicLandingPage from '../components/LandingPage/PublicLandingPage';
import MainContainer from './MainContainer';
import { AllRoutes } from './Routes';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import { StringUtils } from '../utils/string.utils';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../UserContextProvider';

interface PrivateRouteProps {
  children: any;
  navigateTo?: string;
  navigateToPublicPage?: boolean;
  admin?: boolean;
}
export default function PrivateRoute({ children, navigateToPublicPage, admin: pageIsAdmin }: PrivateRouteProps) {
  const user = useContext(UserContext);
  const isAuthenticated = useIsAuthenticated();

  if (user?.validUser && !StringUtils.isEmpty(user?.role) && !pageIsAdmin) return children;
  if (user?.validUser && !StringUtils.isEmpty(user?.role) && pageIsAdmin && user?.isAdmin) return children;
  if (user?.validUser && !StringUtils.isEmpty(user?.role) && pageIsAdmin && user?.isAdmin === false) return <Navigate to={AllRoutes.Unauthorized} replace />;

  if (isAuthenticated && !user) {
    return <LoadingPage/>
  }

  if (navigateToPublicPage) {
    return (
      <MainContainer>
        <PublicLandingPage />
      </MainContainer>
    );
  }

  if (user?.called)
  return <Navigate to={AllRoutes.Forbidden} replace />;
}
