import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { User, UserBasic } from "../../../models/User";
import { useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { AssessmentParticipant } from "../../../models/AssessmentParticipant";
import { useQuery } from "@apollo/client";
import { UsersOutput } from "../../../graphQL/outputModels/Users.output";
import { QUERIES } from "../../../graphQL/queries";

interface Step1ScorecardProps {
  assessmentDate: Dayjs;
  loading: boolean;
  performedByUser: User | null;
  participants: AssessmentParticipant[];
  onFormChanged: (e: any) => void;
  onPerformedByUserChanged: (u: UserBasic) => void;
}

export default function Step1Scorecard({ assessmentDate, loading, performedByUser, onFormChanged, onPerformedByUserChanged }: Step1ScorecardProps) {
  // HANDLERS
  const handleUserChanged = (e: any) => {
    const { value } = e.target;
    const selectedUser = sortedUsers.find((u) => u.userId === +value);
    onPerformedByUserChanged(selectedUser!);
  };

  // GRAPHQL
  const { loading: loadingUsers, data: usersData } = useQuery<UsersOutput>(QUERIES.GET_USERS);

  // USEMEMOS
  const sortedUsers = useMemo(() => {
    if (usersData) {
      let g = [...usersData.users].filter((u) => (u.fullName?.length ?? 0) > 1).sort((a, b) => a.fullName!.localeCompare(b.fullName!));
      return g;
    }
    return [];
  }, [usersData]);

  return (
    <Stack mt={3} spacing={4} alignItems="center" position="relative">
      {loadingUsers && (
        <Box sx={{ position: "absolute", top: 46, left: 90 }}>
          <CircularProgress size="1.5em" />
        </Box>
      )}
      <FormControl disabled={loading || loadingUsers} sx={{ width: "50%", mb: 1 }}>
        <InputLabel id="performedBy-label" required>
          Performed By
        </InputLabel>
        <Select labelId="performedBy-label" name="performedByUserId" label="Performed By" value={performedByUser?.userId ?? ""} onChange={handleUserChanged} required>
          {sortedUsers?.map((u) => {
            return (
              <MenuItem key={u.userId!} value={u.userId!}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                  {u.fullName}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>required</FormHelperText>
      </FormControl>

      {/* <TextField
          type="date"
          label="Assessment Date"
          name="assessmentDate"
          value={assessmentDateString}
          onChange={onDateChange}
          InputLabelProps={{ shrink: true }}
          required
          helperText="required"
          sx={{ width: 200 }}
        /> */}
      <DatePicker
        label={"Assessment Year *"}
        views={["year"]}
        sx={{ width: "50%" }}
        slotProps={{
          textField: {
            helperText: "required",
          },
        }}
        value={assessmentDate}
        onChange={(newValue) => onFormChanged({ target: { name: "assessmentDate", value: newValue } })}
      />
    </Stack>
  );
}
