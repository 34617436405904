import { Box, LinearProgress, Typography } from '@mui/material';
import appConfig from '../../appConfig';
import EnvironmentIndicator from '../EnvironmentIndicator/EnvironmentIndicator';
import { useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import { InteractionStatus } from '@azure/msal-browser';

export default function LoadingPage() {
  // SETUP
  const { inProgress } = useMsal();

  // USEMEMOS
  const loadingMessage = useMemo(() => {
    switch (inProgress) {
      case InteractionStatus.Login:
        return 'Logging in...';
      case InteractionStatus.Logout:
        return 'Logging out...';
      case InteractionStatus.Startup:
        return "";
      default:
        return "Loading your account...";
    }
  }, [inProgress]);

  // RENDER
  return (
    <Box
      id='App'
      sx={{
        // backgroundColor: (theme) => theme.palette.cyan.main,
        background: `radial-gradient(circle, #5dd1ca, #0a978c)`,
        color: 'white',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Typography sx={{ fontSize: '2em' }}>{loadingMessage}</Typography>
      <LinearProgress variant='indeterminate' color='secondary' sx={{ mt: 5, width: 250 }} />
      {appConfig.environment !== 'prod' && <EnvironmentIndicator />}
    </Box>
  );
}
