import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { Button, Typography } from "@mui/material";
import { appNotification } from "../../apollo/PortalApolloProvider";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (e) {
      console.error(e);
      appNotification({
        severity: "error",
        message: "There was a problem signing you in. Please try again later.",
      });
    }
  };

  return (
    <Button
      onClick={handleLogin}
      color="cyan"
      sx={{
        height: "100%",
      }}
    >
      <Typography color="text.primary">Login</Typography>
    </Button>
  );
};
