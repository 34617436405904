import { Box, Container, Typography } from '@mui/material';
import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AllRoutes } from '../../routing/Routes';

export default function PSOPage() {
  // SETUP
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin, portals } = useUser();
  const currPortal = portals?.find((p) => p.portalName?.toLocaleLowerCase() === 'pso');
    
  // USEEFFECTS
  useEffect(() => {
    if (portals?.length !== undefined && currPortal === undefined) {
      navigate(AllRoutes.NotFound);
    } else if (currPortal) {
      // TODO - fetch whatever we're fetching
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals, currPortal, location]);

  // RENDER
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={isAdmin ? 900 : 600} margin='auto'>
        <Typography variant='h2' textAlign='start'>
          PSO
        </Typography>
      </Box>
    </Container>
  );
}
