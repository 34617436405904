import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Report } from "../../models/Report";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { DeleteReportInput } from "../../graphQL/inputModels/deleteReport.input";
import { DeleteReportOutput } from "../../graphQL/outputModels/DeleteReport.output";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";

interface DeleteReportDialogProps {
  open: boolean;
  report: Report;
  onClose: (refetch: boolean) => void;
}

export default function DeleteReportDialog({ open, onClose, report }: DeleteReportDialogProps) {
  //GRAPHQL
  const [deleteReport, { loading: loadingDeleteReport, data: deletedReport }] = useMutation<DeleteReportOutput, DeleteReportInput>(MUTATIONS.DELETE_REPORT);

  // HANDLERS
  const handleDelete = () => {
    deleteReport({
      variables: {
        input: {
          reportId: report.reportId!,
        },
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedReport?.deleteReport?.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully deleted" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedReport]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Warning: Deleting '{report.reportName}'
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You are about to PERMANENTLY delete this report from all Portals. If you instead want to keep this report on other Portals, close out of this dialog and click the pencil icon to edit this
          report instead.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteReport && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteReport}>
          Delete Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}
