import { useLazyQuery } from "@apollo/client";
import { Box, Button, Container, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useState, useMemo, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PortalOutput } from "../../graphQL/outputModels/Portal.output";
import AddEditReportDialog from "./AddEditReportDialog";
import { Report } from "../../models/Report";
import DeleteReportDialog from "./DeleteReportDIalog";
import { AllRoutes } from "../../routing/Routes";
import { QUERIES } from "../../graphQL/queries";
import useUser from "../../hooks/useUser";
import { GetPortalInput } from "../../graphQL/inputModels/getPortal.input";
import { UserContext } from "../../UserContextProvider";

export default function ReportPage() {
  // SETUP
  const { portal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currReport, setCurrReport] = useState<Report>();
  // const [showDevDetails, setShowDevDetails] = useState(false);
  const { isAdmin, portals } = useUser();
  const user = useContext(UserContext);
  const currPortal = portals?.find((p) => p.portalName?.toLocaleLowerCase() === portal?.toLocaleLowerCase());
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [currSort, setCurrSort] = useState<"name" | "report">("name");

  // GRAPHQL
  const [getPortals, { loading, data: portalData, refetch: refetchGH }] = useLazyQuery<PortalOutput, GetPortalInput>(QUERIES.GET_PORTAL, {
    fetchPolicy: "cache-and-network",
    variables: {
      portalId: currPortal?.portalId ?? 0,
      memberId: user?.activeMember?.companyId ?? 0
    },
  });

  // HANDLERS
  const handleCloseDialog = (refetch: boolean) => {
    setCurrReport(undefined);
    setAddEditDialogOpen(false);
    setDeleteDialogOpen(false);
    if (refetch) {
      refetchGH();
    }
  };

  const handleSortChanged = (nextSort: "name" | "report") => {
    if (currSort === nextSort) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setCurrSort(nextSort);
      setSortDirection("desc");
    }
  };

  // USEMEMOS
  const sortedReports = useMemo(() => {
    var sortedData = [...(portalData?.portal.reports ?? [])];
    if (currSort === "name") {
      if (sortDirection === "desc") {
        sortedData.sort((a, b) => a.reportName!.localeCompare(b.reportName!));
      } else {
        sortedData.sort((a, b) => b.reportName!.localeCompare(a.reportName!));
      }
    }
    return sortedData;
  }, [sortDirection, currSort, portalData?.portal.reports]);

  // USEEFFECTS
  useEffect(() => {
    console.log('change')
    console.log(user?.activeMember)
    console.log(user?.activeMember.companyId)
    if (portals?.length !== undefined && currPortal === undefined) {
      navigate(AllRoutes.NotFound);
    } else if (currPortal && user?.activeMember.companyId)
      getPortals({
        variables: {
          portalId: currPortal?.portalId ?? 0,
          memberId: user.activeMember.companyId
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals, currPortal, location, user?.activeMember.companyId]);

  // RENDER
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={isAdmin ? 1000 : 600} margin="auto">
        <Typography variant="h2" textAlign="start">
          Reports
        </Typography>

        {addEditDialogOpen && <AddEditReportDialog open={addEditDialogOpen} onClose={handleCloseDialog} editingReport={currReport} />}
        {deleteDialogOpen && currReport && <DeleteReportDialog open={deleteDialogOpen} onClose={handleCloseDialog} report={currReport} />}

        {/* <Button onClick={() => setShowDevDetails(!showDevDetails)}>
        {showDevDetails ? "Hide" : "Show"} Dev Details
      </Button> */}

        {isAdmin && (
          <Button onClick={() => setAddEditDialogOpen(true)} variant="contained">
            Add Report
          </Button>
        )}

        <TableContainer component={Box} justifyContent="center" display="flex" overflow="auto" maxHeight="calc(100vh - 300px)" margin="auto" mt={5} border="1px grey solid" boxSizing="border-box">
          <Table aria-label="portal reports" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ minWidth: 200 }}>
                  <TableSortLabel active={currSort === "name"} direction={sortDirection} onClick={() => handleSortChanged("name")}>
                    Report Name
                  </TableSortLabel>
                </TableCell>
                {isAdmin && <TableCell align="center">PowerBI WorkspaceID</TableCell>}
                {isAdmin && <TableCell align="center">PowerBI ReportID</TableCell>}
                <TableCell align="center" sx={{ minWidth: isAdmin ? 100 : 50 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* show loading bars if loading */}
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  {isAdmin && (
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  )}
                  {isAdmin && (
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  )}

                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ) : (
                sortedReports.map((report, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                      {report.reportName}
                    </TableCell>
                    {isAdmin && <TableCell align="center">{report.powerBiWorkspaceId ?? "-"}</TableCell>}
                    {isAdmin && <TableCell align="center">{report.powerBiReportId ?? "-"}</TableCell>}
                    <TableCell align="center">
                      <Tooltip title="Run report">
                        <Link
                          to={report.reportId!.toString()}
                          style={{
                            padding: 0,
                            verticalAlign: "center",
                            height: 40,
                          }}
                        >
                          <IconButton sx={{ p: "4px" }}>
                            <PlayCircleOutlineIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      {isAdmin && (
                        <Tooltip title="Edit report">
                          <IconButton
                            onClick={() => {
                              setCurrReport(report);
                              setAddEditDialogOpen(true);
                            }}
                            color="default"
                            sx={{ p: "4px" }}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isAdmin && (
                        <Tooltip title="Remove report">
                          <IconButton
                            onClick={() => {
                              setCurrReport(report);
                              setDeleteDialogOpen(true);
                            }}
                            color="default"
                            sx={{ p: "4px" }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && sortedReports.length === 0 && <Typography sx={{ mt: 3 }}>There are no reports</Typography>}
      </Box>
    </Container>
  );
}
