import { AssessmentQuestion, AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";

export const questionsMapper = {
    mapToCustom
}

// const mockComments = [{
//     commentId: 1,
//     comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod sagittis sapien, sed venenatis dolor maximus a. Aliquam erat volutpat. Nunc bibendum felis ultricies, malesuada tellus quis, sodales lacus.",
//     createdBy: {
//         firstName: "Collin",
//         lastName: "Walther"
//     },
//     createdDate: new Date(2023, 2, 2, 9, 29),
// }, {
//     commentId: 2,
//     comment: "This is a comment",
//     createdBy: {
//         firstName: "Collin",
//         lastName: "Walther"
//     },
//     createdDate: new Date(2023, 2, 5, 14, 48),
//     modifiedBy: {
//         firstName: "Eric",
//         lastName: "Mclaughlin"
//     },
//     modifiedDate: new Date(2023, 2, 5, 15, 3)
// }]

function mapToCustom(list: AssessmentQuestion[], assessmentId: number): AssessmentQuestionCustom[] {
    var ret: AssessmentQuestionCustom[] = [];
    list.forEach((v, i) => {
        var mappedQ: AssessmentQuestionCustom = {
            ...v,
            index: i,
            origSelectedAssessmentQuestionResponseId: v.selectedAssessmentQuestionResponseId,
            assessmentComments: []
        };
        ret.push(mappedQ);
    })

    return ret;
}