import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "../../../graphQL/queries";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { SiteVisitDocument } from "../../../models/SiteVisitDocument";
import { SiteVisitDocumentsOutput } from "../../../graphQL/outputModels/SiteVisitDocuments.output";

interface SiteVisitDocumentsPageProps {
  onShowAddEditDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => void;
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
}

export default function SiteVisitDocumentsPage({ onShowAddEditDialog, onShowDeleteDialog }: SiteVisitDocumentsPageProps) {
  // GRAPHQL
  const [getSiteVisitDocuments, { loading, data: siteVisitDocumentsData }] = useLazyQuery<SiteVisitDocumentsOutput>(QUERIES.GET_SITE_VISIT_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  // HANDLERS
  const handleOpenAddDialog = (document?: SiteVisitDocument) => {
    onShowAddEditDialog("siteVisitDocument", document);
  };

  const handleDeleteDialog = (document: SiteVisitDocument) => {
    onShowDeleteDialog("siteVisitDocument", document);
  };

  // USEEFFECTS
  useEffect(() => {
    getSiteVisitDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RENDER
  return (
    <Box>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 420px)"
        margin="auto"
        mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: "100%" }}>
                Name
              </TableCell>
              <TableCell align="left" sx={{ width: 70 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              siteVisitDocumentsData?.siteVisitDocuments.map((document, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "td:not(:first-of-type) ": { p: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {document.documentDescription}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit document">
                      <IconButton
                        onClick={() => {
                          handleOpenAddDialog(document);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove document">
                      <IconButton
                        onClick={() => {
                          handleDeleteDialog(document);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (siteVisitDocumentsData?.siteVisitDocuments?.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no documents</Typography>}
      <Button variant="contained" onClick={() => handleOpenAddDialog()} sx={{ mt: 1 }}>
        +
      </Button>
    </Box>
  );
}
