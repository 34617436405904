import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CompanyOutput } from "../../graphQL/outputModels/Company.output";
import { QUERIES } from "../../graphQL/queries";
import { PortalFolder } from "../../models/PortalFolder";
import { AddedFolderOutput } from "../../graphQL/outputModels/AddedFolder.output";
import { AddFolderInput } from "../../graphQL/inputModels/addFolder.input";
import { MUTATIONS } from "../../graphQL/mutations";
import { UpdateFolderInput } from "../../graphQL/inputModels/updateFolder.input";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { UpdatedFolderOutput } from "../../graphQL/outputModels/UpdatedFolder.output";
import MemberPicker from "../MemberDialog/MemberPicker";

interface AddEditFolderDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  editingFolder?: PortalFolder;
  parentPortalId?: number;
  parentFolder?: PortalFolder;
  allowMemberSelection: boolean;
}

export default function AddEditFolderDialog({ open, onClose, editingFolder, parentPortalId, parentFolder, allowMemberSelection }: AddEditFolderDialogProps) {
  let defaultValues = {
    folderName: "",
    companyId: parentFolder?.companyId ?? 0,
    allCompanies: parentFolder?.allCompanies ?? false,
  };
  if (editingFolder) {
    defaultValues = {
      folderName: editingFolder.folderName!,
      companyId: editingFolder.companyId!,
      allCompanies: editingFolder.allCompanies ?? false,
    };
  }
  // SETUP
  const [_formValues, _setFormValues] = useState(defaultValues);
  const formValues = useRef(_formValues);
  function setFormValues(e: any) {
    formValues.current = e;
    _setFormValues(e);
  }

  const formValidated = formValues.current.folderName.length > 0 && (formValues.current.allCompanies === true ? true : formValues.current.companyId !== 0);

  // GRAPHQL
  const [getCompanies, { loading: loadingCompanies, data: companies }] = useLazyQuery<CompanyOutput>(QUERIES.GET_COMPANIES, {});
  const [addFolder, { loading: loadingAddFolder, data: addedFolder }] = useMutation<AddedFolderOutput, AddFolderInput>(MUTATIONS.ADD_FOLDER);
  const [updateFolder, { loading: loadingUpdateFolder, data: updatedFolder }] = useMutation<UpdatedFolderOutput, UpdateFolderInput>(MUTATIONS.UPDATE_FOLDER);

  // HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues.current,
      [name]: value,
    });
  };
  const handleCheckedChange = (e: any) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues.current,
      [name]: checked,
    });
  };
  const handleCompanyChange = (newId: number) => {
    setFormValues({ ...formValues.current, companyId: newId });
  };
  const handleSubmit = () => {
    if (!formValidated) return;
    if (!editingFolder) {
      console.log("Adding folder");
      addFolder({
        variables: {
          input: {
            folderName: formValues.current.folderName,
            parentId: parentFolder?.folderId!,
            companyId: formValues.current.companyId,
            portalId: parentPortalId!,
            allCompanies: formValues.current.allCompanies,
          },
        },
      });
    } else {
      console.log("Editing folder");
      updateFolder({
        variables: {
          input: {
            folderId: editingFolder.folderId!,
            folderName: formValues.current.folderName,
            parentId: editingFolder.parentId!,
            companyId: formValues.current.companyId,
            allCompanies: formValues.current.allCompanies,
          },
        },
      });
    }
  };

  // VARIABLES
  const loading = loadingCompanies || loadingAddFolder || loadingUpdateFolder;

  // USEEFFECTS
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("addedFolder changed");
    console.log(addedFolder);
    if (addedFolder?.addFolder.actionStatus === ActionStatus.Success) {
      console.log("add success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully added" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedFolder]);

  useEffect(() => {
    console.log("updatedFolder changed");
    console.log(updatedFolder);
    if (updatedFolder?.updateFolder.actionStatus === ActionStatus.Success) {
      console.log("update success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully updated" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFolder]);

  // USEMEMO
  const sortedCompanies = useMemo(() => {
    if (companies) {
      let g = [...companies.companies].sort((a, b) => a.companyName!.localeCompare(b.companyName!));
      return g;
    }
  }, [companies]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingFolder ? "Editing Folder" : "Add Folder"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="folderName-input"
              name="folderName"
              label="Folder Name"
              type="text"
              value={formValues.current.folderName}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
          </Grid>

          <Grid item xs={12}>
            <MemberPicker
              readonly={!allowMemberSelection}
              allCompanies={formValues.current.allCompanies}
              selectedCompanyIds={[formValues.current.companyId]}
              companies={sortedCompanies ?? []}
              onAllCompaniesChanged={handleCheckedChange}
              onCompanyChanged={handleCompanyChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
