import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { NotificationUtils } from "../../../utils/notification.utils";
import { SiteVisitDocument } from "../../../models/SiteVisitDocument";
import { AddedSiteVisitDocumentOutput } from "../../../graphQL/outputModels/AddedSiteVisitDocument.output";
import { AddSiteVisitDocumentInput } from "../../../graphQL/inputModels/addSiteVisitDocument.input";
import { UpdatedSiteVisitDocumentOutput } from "../../../graphQL/outputModels/UpdatedSiteVisitDocument.output";
import { UpdateSiteVisitDocumentInput } from "../../../graphQL/inputModels/updateSiteVisitDocument.input";
import { SiteVisitDocumentsOutput } from "../../../graphQL/outputModels/SiteVisitDocuments.output";
import { StringUtils } from "../../../utils/string.utils";
import { ActionStatus } from "../../../models/ActionStatus";

interface AddEditSiteVisitDocumentProps {
  open: boolean;
  editingDocument?: SiteVisitDocument;
  onClose: (refetch: boolean) => void;
}

export default function AddEditSiteVisitDocument({ open, onClose, editingDocument }: AddEditSiteVisitDocumentProps) {
  // SETUP
  let defaultValues: SiteVisitDocument = {
    documentId: 0,
    sortOrder: 0,
    documentDescription: "",
  };
  if (editingDocument) {
    defaultValues = {
      documentId: editingDocument.documentId,
      sortOrder: editingDocument.sortOrder,
      documentDescription: editingDocument.documentDescription,
    };
  }
  const [formValues, setFormValues] = useState(defaultValues);
  const formValidated = !StringUtils.isEmpty(formValues.documentDescription);

  // GRAPHQL
  const [addSiteVisitDocument, { loading: loadingAddSiteVisitDocument, data: addedSiteVisitDocument }] = useMutation<AddedSiteVisitDocumentOutput, AddSiteVisitDocumentInput>(
    MUTATIONS.ADD_SITE_VISIT_DOCUMENT
  );

  const [updateSiteVisitDocument, { loading: loadingUpdateSiteVisitDocument, data: updatedSiteVisitDocument }] = useMutation<UpdatedSiteVisitDocumentOutput, UpdateSiteVisitDocumentInput>(
    MUTATIONS.UPDATE_SITE_VISIT_DOCUMENT
  );

  // VARIABLES
  const loading = loadingAddSiteVisitDocument || loadingUpdateSiteVisitDocument;

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    console.log(formValues);
    if (!formValidated) return;
    if (editingDocument) {
      console.log("updating");
      updateSiteVisitDocument({
        variables: {
          input: {
            documentId: +formValues.documentId!,
            documentDescription: formValues.documentDescription!,
            sortOrder: +formValues.sortOrder!,
          },
        },
        update: (cache, { data }) => {
          if (data?.updateSiteVisitDocument.actionStatus === ActionStatus.Success) {
            const exisitingSiteVisitDocuments = cache.readQuery<SiteVisitDocumentsOutput>({
              query: QUERIES.GET_SITE_VISIT_DOCUMENTS,
            });
            if (exisitingSiteVisitDocuments) {
              const newSiteVisitDocuments = exisitingSiteVisitDocuments.siteVisitDocuments.map((d: SiteVisitDocument) => {
                if (d.documentId === formValues.documentId) {
                  return { ...d, ...data!.updateSiteVisitDocument };
                } else {
                  return d;
                }
              });
              cache.writeQuery<SiteVisitDocumentsOutput>({
                query: QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
                data: { siteVisitDocuments: newSiteVisitDocuments },
              });
            }
          }
        },
      });
    } else {
      console.log("adding");
      addSiteVisitDocument({
        variables: {
          input: {
            documentDescription: formValues.documentDescription!,
            sortOrder: +formValues.sortOrder!,
          },
        },
        update: (cache, { data }) => {
          if (data?.addSiteVisitDocument.actionStatus === ActionStatus.Success) {
            const exisitingSiteVisitDocuments = cache.readQuery<SiteVisitDocumentsOutput>({
              query: QUERIES.GET_SITE_VISIT_DOCUMENTS,
            });
            if (exisitingSiteVisitDocuments) {
              const newSiteVisitDocuments = [...exisitingSiteVisitDocuments.siteVisitDocuments, { ...data!.addSiteVisitDocument }];
              cache.writeQuery<SiteVisitDocumentsOutput>({
                query: QUERIES.GET_SITE_VISIT_DOCUMENTS,
                data: { siteVisitDocuments: newSiteVisitDocuments },
              });
            }
          }
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    console.log("addedSiteVisitDocument changed");
    NotificationUtils.notifyAdd(addedSiteVisitDocument?.addSiteVisitDocument.actionStatus!, addedSiteVisitDocument?.addSiteVisitDocument.actionStatusMessage!, () => onClose(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedSiteVisitDocument]);

  useEffect(() => {
    console.log("updatedSiteVisitDocument changed");
    console.log(updatedSiteVisitDocument);
    NotificationUtils.notifyUpdate(updatedSiteVisitDocument?.updateSiteVisitDocument.actionStatus!, updatedSiteVisitDocument?.updateSiteVisitDocument.actionStatusMessage!, () => onClose(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSiteVisitDocument]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingDocument ? `Editing Site Visit Document` : "Add Site Visit Document"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              id="documentDescription-input"
              name="documentDescription"
              label="Name"
              type="text"
              value={formValues.documentDescription}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
