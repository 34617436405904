import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { DeletedPrescriptiveOutput } from "../../../graphQL/outputModels/DeletedPrescriptive.output";
import { DeletePrescriptiveInput } from "../../../graphQL/inputModels/deletePrescriptive.input";
import { Prescriptive } from "../../../models/Prescriptive";
import { PrescriptivesOutput } from "../../../graphQL/outputModels/Prescriptives.output";
import { AssessmentDialogObjectType } from "../AssessmentAdministrationPage";
import { DeletedQuestionOutput } from "../../../graphQL/outputModels/DeletedQuestion.output";
import { DeleteQuestionInput } from "../../../graphQL/inputModels/deleteQuestion.input";
import { Question } from "../../../models/Question";
import { QuestionsOutput } from "../../../graphQL/outputModels/Questions.output";
import { SiteVisitDocument } from "../../../models/SiteVisitDocument";
import { DeletedSiteVisitDocumentOutput } from "../../../graphQL/outputModels/DeletedSiteVisitDocument.output";
import { DeleteSiteVisitDocumentInput } from "../../../graphQL/inputModels/deleteSiteVisitDocument.input";
import { SiteVisitDocumentsOutput } from "../../../graphQL/outputModels/SiteVisitDocuments.output";
import { QuestionResponse } from "../../../models/QuestionResponse";
import { DeletedQuestionResponseOutput } from "../../../graphQL/outputModels/DeletedQuestionResponse.output";
import { DeleteQuestionResponseInput } from "../../../graphQL/inputModels/deleteQuestionResponse.input";
import { QuestionResponsesOutput } from "../../../graphQL/outputModels/QuestionResponse.output";

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  dialogObject: AssessmentDialogObjectType;
  title: string;
  description?: string;
}

export default function DeleteDialog({ open, onClose, dialogObject, title, description }: DeleteDialogProps) {
  // SETUP
  const prescriptiveId: keyof Prescriptive = "prescriptiveId";
  const documentId: keyof SiteVisitDocument = "documentId";
  const questionId: keyof Question = "questionId";
  const questionResponseId: keyof QuestionResponse = "responseId";

  //GRAPHQL
  const [deletePrescriptive, { loading: loadingDeletePrescriptive, data: deletedPrescriptive }] = useMutation<DeletedPrescriptiveOutput, DeletePrescriptiveInput>(MUTATIONS.DELETE_PRESCRIPTIVE);
  const [deleteSiteVisitDocument, { loading: loadingSiteVisitDocument, data: deletedSiteVisitDocument }] = useMutation<DeletedSiteVisitDocumentOutput, DeleteSiteVisitDocumentInput>(
    MUTATIONS.DELETE_SITE_VISIT_DOCUMENT
  );
  const [deleteQuestion, { loading: loadingDeleteQuestion, data: deletedQuestion }] = useMutation<DeletedQuestionOutput, DeleteQuestionInput>(MUTATIONS.DELETE_QUESTION);
  const [deleteQuestionResponse, { loading: loadingDeleteQuestionResponse, data: deletedQuestionResponse }] = useMutation<DeletedQuestionResponseOutput, DeleteQuestionResponseInput>(
    MUTATIONS.DELETE_QUESTION_RESPONSE
  );
  const loading = loadingDeletePrescriptive || loadingSiteVisitDocument || loadingDeleteQuestion || loadingDeleteQuestionResponse;

  // HANDLERS
  const handleDelete = () => {
    console.log(dialogObject);
    if (questionId in dialogObject) {
      deleteQuestion({
        variables: {
          input: {
            questionId: dialogObject.questionId!,
          },
        },
        update: (cache, { data }) => {
          if (data?.deleteQuestion.actionStatus === ActionStatus.Success) {
            const existingQuestions = cache.readQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS });
            if (existingQuestions) {
              const newQuestions = existingQuestions.questions.filter((p: Question) => p.questionId !== data?.deleteQuestion.questionId);
              cache.writeQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS, data: { questions: newQuestions } });
            }
          }
        },
      });
    } else if (prescriptiveId in dialogObject) {
      deletePrescriptive({
        variables: {
          input: {
            prescriptiveId: dialogObject.prescriptiveId!,
          },
        },
        update: (cache, { data }) => {
          if (data?.deletePrescriptive.actionStatus === ActionStatus.Success) {
            const existingPrescriptives = cache.readQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES });
            if (existingPrescriptives) {
              const newPrescriptives = existingPrescriptives.prescriptives.filter((p: Prescriptive) => p.prescriptiveId !== data?.deletePrescriptive.prescriptiveId);
              cache.writeQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES, data: { prescriptives: newPrescriptives } });
            }
          }
        },
      });
    } else if (documentId in dialogObject) {
      deleteSiteVisitDocument({
        variables: {
          input: {
            documentId: dialogObject.documentId!,
          },
        },
        update: (cache, { data }) => {
          if (data?.deleteSiteVisitDocument.actionStatus === ActionStatus.Success) {
            const existingSiteVisitDocuments = cache.readQuery<SiteVisitDocumentsOutput>({ query: QUERIES.GET_SITE_VISIT_DOCUMENTS });
            if (existingSiteVisitDocuments) {
              const newSiteVisitDocuments = existingSiteVisitDocuments.siteVisitDocuments.filter((p: SiteVisitDocument) => p.documentId !== data?.deleteSiteVisitDocument.documentId);
              cache.writeQuery<SiteVisitDocumentsOutput>({ query: QUERIES.GET_SITE_VISIT_DOCUMENTS, data: { siteVisitDocuments: newSiteVisitDocuments } });
            }
          }
        },
      });
    } else if (questionResponseId in dialogObject) {
      deleteQuestionResponse({
        variables: {
          input: {
            responseId: dialogObject.responseId!,
          },
        },
        update: (cache, { data }) => {
          if (data?.deleteQuestionResponse.actionStatus === ActionStatus.Success) {
            const existingQuestionResponses = cache.readQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES });
            if (existingQuestionResponses) {
              const newQuestionResponses = existingQuestionResponses.questionResponses.filter((p: QuestionResponse) => p.responseId !== data?.deleteQuestionResponse.responseId);
              cache.writeQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES, data: { questionResponses: newQuestionResponses } });
            }
          }
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    if (
      deletedPrescriptive?.deletePrescriptive?.actionStatus === ActionStatus.Success ||
      deletedSiteVisitDocument?.deleteSiteVisitDocument?.actionStatus === ActionStatus.Success ||
      deletedQuestion?.deleteQuestion?.actionStatus === ActionStatus.Success ||
      deletedQuestionResponse?.deleteQuestionResponse?.actionStatus === ActionStatus.Success
    ) {
      console.log("deletion success!");
      onClose();
      appNotification({ severity: "success", message: "Successfully deleted" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedPrescriptive, deletedSiteVisitDocument, deletedQuestion, deletedQuestionResponse]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {`Warning: Deleting '${title}'`}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {description
            ? description
            : "You are about to PERMANENTLY delete this from the master data, although it WILL STILL exist on previously created assessments. If you instead want to keep this, close out of this dialog and click the pencil icon in the actions column to edit instead."}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
