import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { PortalFolder } from "../../models/PortalFolder";
import { PortalFile } from "../../models/PortalFile";
import { useEffect, useRef, useState } from "react";

import { DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";
import { MasterFile } from "../../models/MasterFile";
import { AssessmentAttachment } from "../../models/AssessmentAttachment";

interface IFolderButtonProps {
  folder?: PortalFolder;
  handleFolderClick?: (folder: PortalFolder) => void;
  handleRightClick?: (e: MouseEvent, folder?: PortalFolder, file?: PortalFile | MasterFile | AssessmentAttachment) => void;
}

interface IFileButtonProps {
  file?: PortalFile | MasterFile | AssessmentAttachment;
  currRenaming?: boolean;
  handleFileRename?: (file: PortalFile | MasterFile, newName: string) => void;
  handleFileClick?: (file: PortalFile | MasterFile) => void;
}

interface SimpleTextFieldProps {
  handleSubmit: (e: any) => void;
}
function SimpleTextField({ handleSubmit, ...rest }: SimpleTextFieldProps & TextFieldProps) {
  return (
    <TextField
      {...rest}
      size="small"
      variant="filled"
      multiline
      sx={{
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
        "& .MuiInputBase-root": {
          fontSize: "0.8em",
          p: 1,
        },
        "& .MuiInputLabel-root": { display: "none" },
      }}
      onKeyDown={handleSubmit}
    />
  );
}

export default function FolderFileButton({ folder, handleFolderClick, file, currRenaming, handleFileRename, handleFileClick, handleRightClick }: IFolderButtonProps & IFileButtonProps) {
  // SETUP
  const defaultValues = {
    fileName: file?.fileName ?? "",
    fileExt: file?.fileExtension ?? "",
  };
  const [formValues, setFormValues] = useState(defaultValues);
  const containerRef = useRef<HTMLAnchorElement>();
  const inputRef = useRef<HTMLInputElement>();

  // HANDLERS
  const handleClick = () => {
    if (handleFolderClick && folder) handleFolderClick(folder);
    else if (handleFileClick && file) handleFileClick(file);
  };

  const onContextMenu = (e: MouseEvent) => {
    if (handleRightClick) {
      e.preventDefault();
      handleRightClick(e, folder, file);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handelBlur = (e: any) => {
    console.log("blurring");
    console.log(e.currentTarget.value);
    if (currRenaming) {
      handleSubmitRenameFile(e);
    }
  };

  const handleSubmitRenameFile = (e: any) => {
    const { key, type } = e;
    console.log("try submit rename");
    console.log(key);
    console.log(type);
    // console.log(renameFocused);
    if (key === "Enter" || type === "blur") {
      console.log("next");
      if (formValues.fileName) {
        if (formValues.fileName.length > 0) {
          if (file && handleFileRename) {
            console.log(`File attempting to rename from ${file?.fileName} to ${formValues.fileName}`);
            handleFileRename(file, formValues.fileName);
          }
        }
      }
    }
  };

  // USEEFFECTS
  useEffect(() => {
    let c = containerRef.current;

    c?.addEventListener("contextmenu", onContextMenu);

    return () => {
      c?.removeEventListener("contextmenu", onContextMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (file && currRenaming) {
      if (inputRef.current) {
        inputRef.current?.focus();
        inputRef.current.selectionStart = 0;
        inputRef.current.selectionEnd = formValues.fileName.length;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, currRenaming]);

  const trimmedFE = file?.fileExtension?.replace(".", "").toLocaleLowerCase() ?? "";

  // RENDER
  return (
    <Box
      ref={containerRef}
      sx={{
        width: 80,
        maxHeight: 150,
        margin: "10px 10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        transition: "80ms transform ease-in-out",
        ":hover": {
          cursor: "pointer",
          transform: "scale(1.05)",
          backgroundColor: "initial",
        },
      }}
    >
      {folder ? (
        <FolderIcon onClick={handleClick} sx={{ width: 80, height: 80, color: "#FFE16A" }} />
      ) : (
        <Box sx={{ width: 56, height: 80 }} onClick={handleClick}>
          <FileIcon extension={trimmedFE} labelColor="#3f80c4" {...defaultStyles[trimmedFE as DefaultExtensionType]} />
        </Box>
      )}

      {currRenaming ? (
        <SimpleTextField
          id="fileName-input"
          name="fileName"
          label="File Name"
          placeholder="filename"
          type="text"
          value={formValues.fileName}
          onChange={handleInputChange}
          fullWidth
          // autoFocus
          inputRef={inputRef}
          // focused={renameFocused}
          onBlur={handelBlur}
          handleSubmit={handleSubmitRenameFile}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end" sx={{ fontSize: "0.5em" }}>
          //       {formValues.fileExt}
          //     </InputAdornment>
          //   ),
          // }}
        />
      ) : (
        <Typography
          onClick={handleClick}
          sx={{
            fontSize: "0.8em",
            //   letterSpacing: ".1rem",
            textAlign: "center",
            lineHeight: "0.9em",
            color: "black",
            textDecoration: "none",
            width: "100%",
            overflowWrap: "break-word",
            textTransform: "initial",
          }}
        >
          {folder?.folderName ?? formValues.fileName + "." + formValues.fileExt}
        </Typography>
      )}
    </Box>
  );
}
