import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useMemo, useRef } from "react";
import useUser from "../../hooks/useUser";
import VideoListComponent from "./VideoListComponent";
import SearchIcon from "@mui/icons-material/Search";

const mockVideos = [
  {
    id: 1,
    name: "Video 1",
  },
];

export default function VideoListPage() {
  // SETUP
  const [showAssessmentsFilter, setShowAssessmentsFilter] = useState(true);
  const [showScorecardsFilter, setShowScorecardsFilter] = useState(true);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [currSort, setCurrSort] = useState<"name" | "member" | "progress" | "date">("name");
  const { isAdmin } = useUser();
  const loading = false;

  // HANDLERS
  const handleSortChanged = (nextSort: "name" | "member" | "progress" | "date") => {
    if (currSort === nextSort) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setCurrSort(nextSort);
      setSortDirection("desc");
    }
  };

  const handleClearFilters = () => {
    setSearchFilter("");
    setShowAssessmentsFilter(true);
    setShowScorecardsFilter(true);
  };

  // RENDER
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={1000} margin="auto">
        <Stack direction="row">
          <Typography variant="h2" textAlign="start" fontFamily="">
            Video Training
          </Typography>
        </Stack>

        {/* Filter container */}
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.cyan.light,
            mt: 2,
            mb: 2,
          }}
        >
          <Stack direction="row" alignItems="center" p={1}>
            <TextField
              id="prescriptiveNumber-input"
              name="prescriptiveNumber"
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              type="text"
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
              fullWidth
            />

            <FormGroup sx={{ ml: 3 }}>
              <FormControlLabel control={<Checkbox checked={showAssessmentsFilter} onChange={(e, c) => setShowAssessmentsFilter(c)} />} label="Visits" />
            </FormGroup>
            <FormGroup sx={{ ml: 3 }}>
              <FormControlLabel control={<Checkbox checked={showScorecardsFilter} onChange={(e, c) => setShowScorecardsFilter(c)} />} label="Scorecards" />
            </FormGroup>

            <Button
              variant="contained"
              color="cyan"
              sx={{
                ml: 2,
                width: 250,
                height: 52,
                background: (theme) => theme.palette.cyan.lighter,
              }}
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Stack>
        </Paper>

        {/* Table */}
        <TableContainer component={Box} justifyContent="center" display="flex" overflow="auto" maxHeight="calc(100vh - 300px)" margin="auto" mt={0} border="1px grey solid" boxSizing="border-box">
          <Table aria-label="portal reports" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="left" sx={{ width: "auto" }}>
                  {/* <TableCell align="left" sx={{ width: "auto", "&:hover": { "span.MuiTableSortLabel": { display: "block" } } }}> */}
                  <TableSortLabel active={currSort === "name"} direction={sortDirection} onClick={() => handleSortChanged("name")}>
                    Assessment Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sortDirection="desc">
                  <TableSortLabel active={currSort === "member"} direction={sortDirection} onClick={() => handleSortChanged("member")}>
                    Member Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel active={currSort === "progress"} direction={sortDirection} onClick={() => handleSortChanged("progress")}>
                    Answered/Total
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel active={currSort === "date"} direction={sortDirection} onClick={() => handleSortChanged("date")}>
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sx={{ width: isAdmin ? 100 : 70 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* show loading bars if loading */}
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ) : (
                mockVideos.map((video, i) => <VideoListComponent key={video.id} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (mockVideos.length ?? 0) === 0 && <Typography sx={{ mt: 3 }}>There are no videos</Typography>}
      </Box>
    </Container>

    // <div>
    //   <iframe
    //     src="https://netrixmediastorage.blob.core.windows.net/videos/videoplayback.mp4?sp=r&st=2023-07-26T20:14:04Z&se=2023-07-27T04:14:04Z&sv=2022-11-02&sr=b&sig=L3Dsa1KNt%2B0XS4cxOcfCpd3rQLzB5aOG57Ip%2FaoJoqQ%3D"
    //     width="1280"
    //     height="720"
    //     frameBorder="0"
    //     scrolling="no"
    //     allowFullScreen
    //     title="Spinning Earth Demo.mp4"
    //   ></iframe>

    //   <link href="http://vjs.zencdn.net/c/video-js.css" rel="stylesheet" />
    //   <script src="http://vjs.zencdn.net/c/video.js"></script>
    //   <video className="video-js vjs-default-skin" controls preload="auto" width="640" height="480" data-setup="{}">
    //     <source
    //       src="https://netrixmediastorage.blob.core.windows.net/videos/videoplayback.mp4?sp=r&st=2023-07-26T20:14:04Z&se=2023-07-27T04:14:04Z&sv=2022-11-02&sr=b&sig=L3Dsa1KNt%2B0XS4cxOcfCpd3rQLzB5aOG57Ip%2FaoJoqQ%3D"
    //       type="video/mp4"
    //     />
    //   </video>
    // </div>
  );
}
