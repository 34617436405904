import { Box } from "@mui/material";
import Footer from "./Footer";

export default function MainContainer({ children }: any) {
  return (
    <Box
      id="main-container"
      sx={{
        // backgroundColor: (theme) => theme.palette.cyan.main,
        background: `radial-gradient(circle, #5dd1ca, #0a978c)`,
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        overflow: "auto",
        textAlign: "center",
      }}
    >
      {children}
      <Footer />
    </Box>
  );
}
