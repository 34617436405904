import { RouteObject } from "react-router-dom"

export const AllRoutes = {
    Home: "/",
    Account: "/account",
    Dashboard: "/dashboard",
    Claims: "/claims",
    Forbidden: "/forbidden",
    Unauthorized: "/unauthorized",
    NotFound: "/notfound",
    Logout: "/logout",
    LogReport: "/log-report",

    Portal: {
        main: "/portal",
        Documents: "documents",
        Links: "links",
        Events: "Events",
        Assessments: "Assessments",
        AssessmentAdministration: "Assessments/Administration",
        VideoTraining: "VideoTraining",
    },
}

export const PortalRoutes: RouteObject[] = [
    { path: `${AllRoutes.Portal.main}/:portal` },
    { path: `${AllRoutes.Portal.main}/:portal/:reportId` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Documents}` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Links}` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Events}` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Assessments}` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.AssessmentAdministration}` },
    { path: `${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.VideoTraining}` },
];