import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { useState, useMemo, useRef, useContext } from "react";
import { QUERIES } from "../../graphQL/queries";
import useUser from "../../hooks/useUser";
import SearchIcon from "@mui/icons-material/Search";
import AddEditAssessmentDialogPage from "./NewAssessmentDialog/AddEditAssessmentDialogPage";
import { AssessmentsOutput } from "../../graphQL/outputModels/Assessments.output";
import { assessmentsMapper } from "./mappers/assessmentsMapper";
import FilterDialog from "./AssessmentDetail/FilterDialog";
import BarGraph from "../Widget/BarGraph";
import PieChart from "../Widget/PieChart";
import AssessmentListComponent from "./AssessmentListComponent";
import useRawDataExportQuery from "../../hooks/useRawDataExportQuery";
import { GetMemberSummaryScoreDataInput } from "../../graphQL/inputModels/getMemberSummaryScoreDataInput.input";
import { MemberSummaryScoreDataOutput } from "../../graphQL/outputModels/MemberSummaryScoreData.output";
import { MemberSummaryScore, YESSummaryPrescriptiveScore } from "../../models/MemberSummaryScore";
import { GetAssessmentsInput } from "../../graphQL/inputModels/getAssessments.input";
import { UserContext } from "../../UserContextProvider";

const mockSummaryData: MemberSummaryScore = {
  memberId: 2891074,
  yESSummaryPrescriptiveScores: [2018, 2019, 2020, 2021]
    .map((y) => {
      return [1, 2, 3, 4, 5, 6, 7, 8].map((p): YESSummaryPrescriptiveScore => {
        return {
          assessmentDate: new Date(y, 1, 1),
          prescriptiveLabel: `Prescriptive ${p}`,
          prescriptiveScorePercent: Math.floor(Math.random() * (100 - 50 + 1)) + 50,
        };
      });
    })
    .flat(),
  assessmentId: 2,
  compliantCount: Math.floor(Math.random() * (900 - 600 + 1)) + 600,
  noncompliantCount: Math.floor(Math.random() * (100 - 50 + 1)) + 50,
  otherCount: Math.floor(Math.random() * (300 - 100 + 1)) + 100,
};

type memberFilter = {
  memberIds: number[];
  allMembers: boolean;
};

export default function AssessmentListPage() {
  // SETUP
  const [widgetExpanded, setWidgetExpanded] = useState(false);
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [showAssessmentsFilter, setShowAssessmentsFilter] = useState(true);
  const [showScorecardsFilter, setShowScorecardsFilter] = useState(true);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [_memberFilter, _setMemberFilter] = useState<memberFilter>({ memberIds: [], allMembers: true });
  const memberFilterRef = useRef(_memberFilter);
  function setMemberFilter(e: any) {
    memberFilterRef.current = e;
    _setMemberFilter(e);
  }
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [currSort, setCurrSort] = useState<"name" | "member" | "progress" | "date">("name");
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
  const { isAdmin } = useUser();
  const user = useContext(UserContext);

  // GRAPHQL
  const {
    loading,
    data: assessmentsData,
    refetch: getAssessments,
  } = useQuery<AssessmentsOutput, GetAssessmentsInput>(QUERIES.GET_ASSESSMENTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      memberId: user?.activeMember.companyId ?? 0
    }
  });
  const [getWidgetData, { loading: loadingWidgets, data: widgetData }] = useLazyQuery<MemberSummaryScoreDataOutput, GetMemberSummaryScoreDataInput>(QUERIES.GET_MEMBER_SUMMARY_SCORE_DATA, {
    fetchPolicy: "network-only",
  });

  // CUSTOM HOOKS
  const { execute: executeExportRawData, loading: callingExportRawData } = useRawDataExportQuery();

  // HANDLERS
  const handleCloseDialog = (refetch: boolean) => {
    setAddEditDialogOpen(false);
    setCompanyDialogOpen(false);
    if (refetch) {
      getAssessments();
    }
  };

  const handleAddAssessment = () => {
    setAddEditDialogOpen(true);
  };

  const handleDownloadRawData = () => {
    const assessmentIds = mappedAssessments.map((a) => a.assessmentId!);
    console.log(assessmentIds);
    if (!callingExportRawData) executeExportRawData({ assessmentIds: assessmentIds });
  };

  const handleClearFilters = () => {
    setSearchFilter("");
    setShowAssessmentsFilter(true);
    setShowScorecardsFilter(true);
    setMemberFilter({ memberIds: [], allMembers: true });
  };

  const handleAllCompaniesChanged = (e: any) => {
    const { checked } = e.target;
    setMemberFilter({
      ...memberFilterRef.current,
      allMembers: checked,
    });
  };

  const handleSelectedCompanyChanged = (newId: number) => {
    const memberIds = memberFilterRef.current.memberIds;
    var alreadyInList = memberIds.includes(newId);
    if (alreadyInList) {
      // remove from list
      var newList = memberIds.filter((c) => c !== newId);
      console.log([...newList]);
      setMemberFilter({
        ...memberFilterRef.current,
        memberIds: [...newList],
      });
    } else {
      // add to list
      setMemberFilter({
        ...memberFilterRef.current,
        memberIds: [...memberIds, newId],
      });
    }
  };

  const handleSortChanged = (nextSort: "name" | "member" | "progress" | "date") => {
    if (currSort === nextSort) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setCurrSort(nextSort);
      setSortDirection("desc");
    }
  };

  const handleWidgetAccordionChange = (isExpanding: boolean) => {
    if (isExpanding && mappedAssessments.length > 0) {
      getWidgetData({ variables: { memberId: mappedAssessments[0].memberId! } });
    }
    setWidgetExpanded(isExpanding);
  };

  // USEMEMOS
  const mappedAssessments = useMemo(() => {
    var mappedAssessments = assessmentsMapper.mapToCustom(assessmentsData?.assessments ?? []);
    var typeFilteredData = mappedAssessments.filter((d) => {
      var f = false;
      if (showAssessmentsFilter && d.assessmentTypeId) f = d.assessmentTypeId >= 1 && d.assessmentTypeId <= 4;
      if (showScorecardsFilter) f = f || d.assessmentTypeId === 5;
      return f;
    });
    var memberFilteredData = memberFilterRef.current.allMembers ? typeFilteredData : typeFilteredData.filter((d) => memberFilterRef.current.memberIds.includes(d.memberId!));
    var searchFilteredData =
      searchFilter === ""
        ? memberFilteredData
        : memberFilteredData.filter((d) => d.assessmentName?.toLowerCase()?.includes(searchFilter.toLowerCase()) || d.memberName?.toLowerCase()?.includes(searchFilter.toLowerCase()));
    return searchFilteredData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentsData, showAssessmentsFilter, showScorecardsFilter, memberFilterRef.current.allMembers, memberFilterRef.current.memberIds, searchFilter]);

  const sortedMappedAssessments = useMemo(() => {
    var sortedData = mappedAssessments;
    if (currSort === "date") {
      if (sortDirection === "desc") {
        sortedData.sort((a, b) => {
          return b.assessmentDateDate!.diff(a.assessmentDateDate!);
        });
      } else {
        sortedData.sort((a, b) => {
          return a.assessmentDateDate!.diff(b.assessmentDateDate!);
        });
      }
    } else if (currSort === "name") {
      if (sortDirection === "desc") {
        sortedData.sort((a, b) => a.assessmentName!.localeCompare(b.assessmentName!));
      } else {
        sortedData.sort((a, b) => b.assessmentName!.localeCompare(a.assessmentName!));
      }
    } else if (currSort === "member") {
      if (sortDirection === "desc") {
        sortedData.sort((a, b) => a.memberName!.localeCompare(b.memberName!));
      } else {
        sortedData.sort((a, b) => b.memberName!.localeCompare(a.memberName!));
      }
    } else if (currSort === "progress") {
      if (sortDirection === "desc") {
        sortedData.sort((a, b) => a.totalAnswers! / a.totalQuestions! - b.totalAnswers! / b.totalQuestions!);
      } else {
        sortedData.sort((a, b) => b.totalAnswers! / b.totalQuestions! - a.totalAnswers! / a.totalQuestions!);
      }
    }
    return sortedData;
  }, [sortDirection, currSort, mappedAssessments]);

  const showWidgets = useMemo(() => {
    if (mappedAssessments.length === 0) return false;

    let memberId = 0;
    for (let i = 0; i < mappedAssessments.length; i++) {
      let m = mappedAssessments[i];
      if (memberId === 0) memberId = m.memberId!;
      // return false as soon as you get 2 different memmber IDs
      else if (memberId !== m.memberId!) return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedAssessments]);

  // RENDER
  const memberFilterLabel = "SELECT MEMBER " + (memberFilterRef.current.allMembers ? "(ALL)" : `(${memberFilterRef.current.memberIds.length})`);
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={1000} margin='auto'>
        <Stack direction='row'>
          <Typography variant='h2' textAlign='start' fontFamily=''>
            Visits/Scorecards
          </Typography>

          {/* Dialogs */}
          {addEditDialogOpen && <AddEditAssessmentDialogPage open={true} onClose={handleCloseDialog} />}

          {companyDialogOpen && (
            <FilterDialog
              open={companyDialogOpen}
              onClose={() => handleCloseDialog(false)}
              allCompanies={memberFilterRef.current.allMembers}
              selectedCompanyIds={memberFilterRef.current.memberIds}
              onAllCompaniesChanged={handleAllCompaniesChanged}
              onCompanyChanged={handleSelectedCompanyChanged}
            />
          )}

          {/* Download Raw Data Button */}
          <Tooltip placement='bottom' title='Download raw data (of filtered list)'>
            <Fab aria-label='download raw data' color='primary' sx={{ ml: "auto" }} size='small' onClick={handleDownloadRawData}>
              {callingExportRawData ? <CircularProgress size='1.2em' sx={{ color: "white" }} /> : <DownloadIcon sx={{ fontSize: 30, color: "white" }} />}
            </Fab>
          </Tooltip>
          {/* Add Assessment Button */}
          {isAdmin && (
            <Tooltip placement='bottom' title='Add Assessment'>
              <Fab aria-label='add assessment' color='primary' sx={{ ml: 2 }} size='small' onClick={handleAddAssessment}>
                <AddOutlinedIcon sx={{ fontSize: 30, color: "white" }} />
              </Fab>
            </Tooltip>
          )}
        </Stack>

        {/* Widgets */}
        {showWidgets && (
          <Accordion expanded={widgetExpanded} onChange={(e, expanded) => handleWidgetAccordionChange(expanded)} sx={{ background: "none", mt: 2, "&.Mui-expanded": { mt: 1 } }}>
            <AccordionSummary
              sx={{ justifyContent: "center", background: (theme) => theme.palette.cyan.lighter, "&.Mui-expanded": { minHeight: 48 }, ".MuiAccordionSummary-content.Mui-expanded": { m: 1 } }}
            >
              <Typography ml='auto' mr='auto'>
                {widgetExpanded ? "Hide Data Visuals" : `Show Data Visuals`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, pt: 1, background: "white" }}>
              <Typography variant='h5' textAlign='center'>
                Scorecard Trends
              </Typography>

              <Box height={300} sx={{ fontFamily: "AvenirRoman" }}>
                {loadingWidgets ? (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    <Typography>Loading data...</Typography>
                    <LinearProgress sx={{ width: 200 }} />
                  </Stack>
                ) : (
                  <BarGraph data={widgetData?.memberSummaryScoreData.yESSummaryPrescriptiveScores ?? []} />
                  // <BarGraph data={mockSummaryData.yESSummaryPrescriptiveScores!} />
                )}
              </Box>

              <Divider sx={{ m: 1 }} />
              <Typography variant='h5' textAlign='center'>
                Current Compliance Performance
              </Typography>
              <Box height={320} sx={{ fontFamily: "AvenirRoman" }}>
                {loadingWidgets ? (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    <Typography>Loading data...</Typography>
                    <LinearProgress sx={{ width: 200 }} />
                  </Stack>
                ) : (
                  <PieChart data={widgetData?.memberSummaryScoreData ?? {}} />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {/* Filter container */}
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.cyan.light,
            mt: 2,
            mb: 2,
          }}
        >
          <Stack direction='row' alignItems='center' p={1}>
            <TextField
              id='prescriptiveNumber-input'
              name='prescriptiveNumber'
              label='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              type='text'
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
              fullWidth
            />

            <FormGroup sx={{ ml: 3 }}>
              <FormControlLabel control={<Checkbox checked={showAssessmentsFilter} onChange={(e, c) => setShowAssessmentsFilter(c)} />} label='Visits' />
            </FormGroup>
            <FormGroup sx={{ ml: 3 }}>
              <FormControlLabel control={<Checkbox checked={showScorecardsFilter} onChange={(e, c) => setShowScorecardsFilter(c)} />} label='Scorecards' />
            </FormGroup>
            {isAdmin && (
              <Button
                variant='contained'
                color='cyan'
                sx={{
                  ml: 2,
                  width: 280,
                  height: 52,
                  background: (theme) => theme.palette.cyan.lighter,
                }}
                onClick={() => {
                  setCompanyDialogOpen(true);
                }}
              >
                {memberFilterLabel}
              </Button>
            )}
            <Button
              variant='contained'
              color='cyan'
              sx={{
                ml: 2,
                width: 250,
                height: 52,
                background: (theme) => theme.palette.cyan.lighter,
              }}
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Stack>
        </Paper>

        <TableContainer component={Box} justifyContent='center' display='flex' overflow='auto' maxHeight='calc(100vh - 300px)' margin='auto' mt={0} border='1px grey solid' boxSizing='border-box'>
          <Table aria-label='portal reports' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='center'></TableCell>
                <TableCell align='left' sx={{ width: "auto" }}>
                  {/* <TableCell align="left" sx={{ width: "auto", "&:hover": { "span.MuiTableSortLabel": { display: "block" } } }}> */}
                  <TableSortLabel active={currSort === "name"} direction={sortDirection} onClick={() => handleSortChanged("name")}>
                    Assessment Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align='center' sortDirection='desc'>
                  <TableSortLabel active={currSort === "member"} direction={sortDirection} onClick={() => handleSortChanged("member")}>
                    Member Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align='center'>
                  <TableSortLabel active={currSort === "progress"} direction={sortDirection} onClick={() => handleSortChanged("progress")}>
                    Answered/Total
                  </TableSortLabel>
                </TableCell>
                <TableCell align='center'>
                  <TableSortLabel active={currSort === "date"} direction={sortDirection} onClick={() => handleSortChanged("date")}>
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align='center' sx={{ width: isAdmin ? 100 : 70 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* show loading bars if loading */}
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ) : (
                sortedMappedAssessments.map((assessment, i) => <AssessmentListComponent key={assessment.assessmentId} assessment={assessment} refetchList={() => getAssessments()} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (sortedMappedAssessments.length ?? 0) === 0 && <Typography sx={{ mt: 3 }}>There are no assessments</Typography>}
      </Box>
    </Container>
  );
}
