import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from, makeVar, defaultDataIdFromObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMsal } from '@azure/msal-react';

import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { loginRequest, loginSilentRequest, msalConfig } from '../auth/authConfig';
import appConfig from '../appConfig';
import { onError } from '@apollo/client/link/error';
import { AppNotification } from '../models/AppNotification';

interface Props {
  children: JSX.Element;
}

export const appNotification = makeVar<AppNotification | undefined>(undefined);

export default function PortalApolloProvider({ children }: Props) {
  const { instance, inProgress } = useMsal();

  const AsyncTokenLookup = async () => {
    const accounts = await instance.getAllAccounts();

    // const account = accounts.find((a) => a.environment === msalConfig.auth.knownAuthorities![0]);
    var authorityAccounts = accounts.filter((a) => a.environment === msalConfig.auth.knownAuthorities![0]);

    if (inProgress === InteractionStatus.None) {
      if (authorityAccounts.length > 0) {
        for( var account of accounts) {
          try {
            const result = await instance.acquireTokenSilent({
              ...loginSilentRequest,
              account,
            });
            return result.accessToken;
          } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
               return instance.acquireTokenRedirect(loginRequest);
            }
          }
        }

        //return instance.acquireTokenRedirect(loginRequest);
      } 
    }

    // if (account && inProgress === InteractionStatus.None) {
    //   try {
    //     debugger;
    //     const result = await instance.acquireTokenSilent({
    //       ...loginSilentRequest,
    //       account,
    //     });
    //     return result.accessToken;
    //   } catch (err) {
    //     debugger;
    //     if (err instanceof InteractionRequiredAuthError) {
    //       debugger;
    //       // fallback to interaction when silent call fails
    //       return instance.acquireTokenRedirect(loginRequest);
    //     }
    //   }
    // } else if (!account && inProgress === InteractionStatus.None) {
    //   debugger;
    //   return instance.acquireTokenRedirect(loginRequest);
    // }
    return null;
  };

  const withToken = setContext(async (_, { headers }) => {
    const token = await AsyncTokenLookup();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  // const startLink = new ApolloLink((operation, forward) => {
  // appNotification(undefined);
  //   return forward(operation);
  // });

  const errorLink = onError(({ networkError = {} as any, graphQLErrors, response, operation, forward }) => {
    // let n = networkError as ServerParseError;
    console.error(networkError);
    console.error(graphQLErrors);
    console.error(response);
    const status: number = networkError && networkError.statusCode ? networkError.statusCode : null;
    if (status === 503 || (!networkError && !graphQLErrors && !response)) {
      appNotification({ severity: 'error', message: 'the server is down', time: 10000 });
    } else if (graphQLErrors) {
      appNotification({
        severity: 'error',
        message: graphQLErrors[0].message,
      });
    } else
      appNotification({
        severity: 'error',
        message: networkError?.message ?? 'error',
      });
    // return forward(operation); // retry request
  });

  const httpLink = createHttpLink({
    uri: appConfig.apiBaseUrl + '/graphql',
  });

  const client = new ApolloClient({
    link: from([errorLink, withToken, httpLink]),
    connectToDevTools: appConfig.environment !== 'prod',
    cache: new InMemoryCache({
      dataIdFromObject(responseObject: any) {
        switch (responseObject.__typename) {
          case 'GQLPortal':
            return `GQLPortal:${responseObject.portalId}`;
          case 'GQLAssessment':
            return `GQLAssessment:${responseObject.assessmentId}`;
          case 'DtoAssessmentAttachment':
            return `DtoAssessmentAttachment:${responseObject.assessmentAttachmentId}`;
          case 'GQLAssessmentComment':
            return `GQLAssessmentComment:${responseObject.assessmentCommentId}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
