export enum ActionStatus {
    Success = "SUCCESS",
    Fail = "FAIL",
    Error = "ERROR"
}

export enum ActionStatusNumber {
    Success = 0,
    Fail = 1,
    Error = 2
}