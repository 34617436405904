import { Box, Typography } from "@mui/material";

export default function PrivateLandingPage() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography fontSize="4em" fontFamily="AvenirBlack" color="white">
        Welcome!
      </Typography>
      <Typography fontSize="1.5em">Click a Portal above to get started</Typography>
    </Box>
  );
}
