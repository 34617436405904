import { msalInstance } from "..";
import appConfig from "../appConfig";
import { loginRequest, msalConfig } from "../auth/authConfig";


export const FetchUtils = {
    //GET,
    //GETFile,
    //POST,
    UPLOAD_FILE,
    UPLOAD_FILES,
    UPLOAD_MASTER_FILE,
    UPLOAD_ASSESSMENT_ATTACHMENT,
    EXPORT_FILE,
    EXPORT_ASSESSMENT_REPORT,
    EXPORT_RAW_DATA,
    EXPORT_LOG_DATA
    //PUT,
    //DELETE
}


// function GET<T>(url: string): Promise<T> {
//     return acquireAccessToken().then((token) => {
//         return fetch(url, GETHeaders(token))
//             .then(handleResponse)
//             .then((data: T) => {
//                 return data;
//             })
//             .catch(handleError);
//     });
// }

//   function GETFile(url: string): Promise<any> {
//     return acquireAccessToken().then((token) => {
//       return fetch(url, GETHeaders(token))
//         .then(handleResponseFile)
//         .then((data: any | PromiseLike<any>) => {
//           return data;
//         })
//         .catch(handleError);
//     });
//   }

// function POST<T>(url: string, data?: any): Promise<T> {
//     return acquireAccessToken().then((token) => {
//         return fetch(url, POSTHeaders(token, data))
//             .then(handleResponse)
//             .then((data: T) => {
//                 return data;
//             })
//             .catch(handleError);
//     });
// }

async function UPLOAD_FILE<T>(file: File, parentFolderId: number): Promise<T> {

    let url = `${appConfig.apiBaseUrl}/api/report/uploadfile`;
    var token = await acquireAccessToken();
    var response = await fetch(url, UPLOAD_FILE_Headers(token, file, parentFolderId))
    if (response.ok) {
        var responseJson = await response.json();
        return responseJson as T;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function UPLOAD_FILES<T>(eventId: number, thumbnail?: File, attachments?: File[],): Promise<T> {

    let url = `${appConfig.apiBaseUrl}/api/report/uploadfilesToEvent`;
    var token = await acquireAccessToken();
    var response = await fetch(url, UPLOAD_FILES_Headers(token, eventId, thumbnail, attachments))
    if (response.ok) {
        var responseJson = await response.json();
        return responseJson as T;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function UPLOAD_MASTER_FILE<T>(file: File, isCoverImage: boolean = true, isDefault: boolean = true): Promise<T> {

    let url = `${appConfig.apiBaseUrl}/api/report/uploadMasterFile`;
    var token = await acquireAccessToken();
    var response = await fetch(url, UPLOAD_MASTER_FILE_Headers(token, file, isCoverImage, isDefault))
    if (response.ok) {
        var responseJson = await response.json();
        return responseJson as T;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function UPLOAD_ASSESSMENT_ATTACHMENT<T>(file: File, assessmentId: number, assessmentQuestionId?: number): Promise<T> {

    let url = `${appConfig.apiBaseUrl}/api/report/uploadAssessmentAttachment`;
    var token = await acquireAccessToken();
    var response = await fetch(url, UPLOAD_ASSESSMENT_ATTACHMENT_Headers(token, file, assessmentId, assessmentQuestionId))
    if (response.ok) {
        var responseJson = await response.json();
        return responseJson as T;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function EXPORT_FILE(workspaceId: string, reportId: string): Promise<Blob> {
    let url = `${appConfig.apiBaseUrl}/api/report/export?workspaceId=${workspaceId}&reportId=${reportId}`;
    var token = await acquireAccessToken();
    var response = await fetch(url, GETHeaders(token))
    if (response.ok) {
        var responseBlob = await response.blob();
        return responseBlob;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function EXPORT_ASSESSMENT_REPORT(assessmentId: number, isCreatePDF: boolean): Promise<Blob> {
    let url = `${appConfig.apiBaseUrl}/api/report/getAssessmentReport?assessmentId=${assessmentId}&isCreatePDF=${String(isCreatePDF)}`;
    var token = await acquireAccessToken();
    var response = await fetch(url, GETHeaders(token))
    if (response.ok) {
        var responseBlob = await response.blob();
        return responseBlob;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function EXPORT_RAW_DATA(assessmentIds: number[]): Promise<Blob> {
    var cs = assessmentIds.join(",");
    let url = `${appConfig.apiBaseUrl}/api/report/getAssessmentRawDataReport?assessmentIdCsv=${cs}`;
    var token = await acquireAccessToken();
    var response = await fetch(url, GETHeaders(token))
    if (response.ok) {
        var responseBlob = await response.blob();
        return responseBlob;
    }
    var r = await response.json();
    return Promise.reject(r);
}

async function EXPORT_LOG_DATA(startRange: string, endRange: string): Promise<Blob> {
    let url = `${appConfig.apiBaseUrl}/api/report/getLogDataReport?startRange=${startRange}&endRange=${endRange}`;
    var token = await acquireAccessToken();
    var response = await fetch(url, GETHeaders(token))
    if (response.ok) {
        var responseBlob = await response.blob();
        return responseBlob;
    }
    var r = await response.json();
    return Promise.reject(r);
}

// function PUT<T>(url: string, data?: any): Promise<T> {
//     return acquireAccessToken().then((token) => {
//         return fetch(url, PUTHeaders(token, data))
//             .then(handleResponse)
//             .then((data: T) => {
//                 return data;
//             })
//             .catch(handleError);
//     });
// }

// function DELETE<T>(url: string, data?: any): Promise<T> {
//     return acquireAccessToken().then((token) => {
//         return fetch(url, DELETEHeaders(token, data))
//             .then(handleResponse)
//             .then((data: T) => {
//                 return data;
//             })
//             .catch(handleError);
//     });
// }

function GETHeaders(token?: string) {
    return {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }),
    };
}

// function POSTHeaders(token?: string, data?: any) {
//     return {
//         method: 'POST',
//         headers: new Headers({
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//         }),
//         body: data ? JSON.stringify(data) : undefined
//     };
// }

function UPLOAD_FILE_Headers(token: string, file: File, parentFolderId: number) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('ParentFolderId', parentFolderId.toString());
    return {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
        }),
        body: formData
    };
}

function UPLOAD_FILES_Headers(token: string, eventId: number, thumbnail?: File, attachments?: File[],) {
    const formData = new FormData();

    if (thumbnail)
        formData.append('Thumbnail', thumbnail);
    if (attachments) {
        for (var attachment of attachments) {
            formData.append('Attachment', attachment);
        }
    }
    if (eventId)
        formData.append('eventId', eventId.toString());

    return {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
        }),
        body: formData
    };
}

function UPLOAD_MASTER_FILE_Headers(token: string, file: File, isCoverImage: boolean, isDefault: boolean) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('IsCoverImage', isCoverImage.toString());
    formData.append('IsDefault', isDefault.toString());
    return {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
        }),
        body: formData
    };
}

function UPLOAD_ASSESSMENT_ATTACHMENT_Headers(token: string, file: File, assessmentId: number, assessmentQuestionId?: number) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('AssessmentId', assessmentId.toString());

    if (assessmentQuestionId) {
        formData.append('AssessmentQuestionId', assessmentQuestionId.toString());
    }
    return {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
        }),
        body: formData
    };
}

// function PUTHeaders(token?: string, data?: any) {
//     return {
//         method: 'PUT',
//         headers: new Headers({
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//         }),
//         body: data ? JSON.stringify(data) : undefined
//     };
// }

// function DELETEHeaders(token?: string, data?: any) {
//     return {
//         method: 'DELETE',
//         headers: new Headers({
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//         }),
//         body: data ? JSON.stringify(data) : undefined
//     };
// }

// function handleResponse(response: Response) {
//     if (response.ok) {
//         return response.json();
//     } else {
//         try {
//             return response.json().then((r) => {
//                 // let f = r as ServerError;
//                 // return Promise.reject(f);
//                 return Promise.reject();
//             })
//         }
//         catch (e) {
//             //   return Promise.reject({ errorType: ServerErrorType.Error, displayMessage: "An unexpected server error occured. Please try again later." } as ServerError);
//             return Promise.reject();
//         }
//     }
// }

// function handleResponseFile(response: any) {
//   if (response.ok) {
//     response.json().then((data: any) => {
//       let formattedStartDate = DateUtils.formatDateTime(new Date(`${data.exportStartDate}Z`));

//       const filename = `${data.reportName}${data.reportSubtitle ? ` - ${data.reportSubtitle}` : ''}${data.exportStartDate ? ` ${formattedStartDate}` : ''}.${data.storageURL.split('.').pop()}`;
//       const bytes = _base64ToArrayBuffer(data.reportByteArray);
//       var blob = new Blob([bytes], { type: data.contentType });
//       let url = window.URL.createObjectURL(blob);
//       let a = document.createElement('a');
//       a.href = url;
//       a.download = filename ?? 'Download';
//       a.click();
//       return blob;
//     }

//     );

//   } else {
//     return Promise.reject(`Status Code: ${response.status}`);
//   }
// }

// function _base64ToArrayBuffer(base64: string) {
//   var binary_string = window.atob(base64);
//   var len = binary_string.length;
//   var bytes = new Uint8Array(len);
//   for (var i = 0; i < len; i++) {
//     bytes[i] = binary_string.charCodeAt(i);
//   }
//   return bytes.buffer;
// }

// function handleError(error: TypeError | ServerError): Promise<never> {
//   if (error instanceof TypeError) {
//     let se: ServerError = {
//       displayMessage: 'The server is down at the moment. Please try again later.',
//       errorType: ServerErrorType.Warning
//     }
//     return Promise.reject(se)
//   } else {
//     return Promise.reject(error);
//   }
// }

// function handleError(error: any) {
//     return Promise.reject();
// }




const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();


    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        return Promise.reject(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
    }

    const account = accounts.find(
        (a) => a.environment === msalConfig.auth.knownAuthorities![0]
    );
    const request = {
        scopes: loginRequest.scopes,
        account: activeAccount || account,
    };

    try {
        debugger;
        const authResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken;
    }
    catch (error) {
        debugger;
        msalInstance.acquireTokenRedirect(loginRequest).catch((e) => {
            console.error(e);
            throw error;
        })
    }
    return "";
};
