import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import appConfig from "../../appConfig";

export default function Logout() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${appConfig.b2c.logoutRedirectUri}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>logging out...</Box>;
}
