import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Tooltip, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { QUERIES } from "../../graphQL/queries";
import { EventCustom } from "../../models/Event";
import { PortalFile } from "../../models/PortalFile";
import { PortalFolder } from "../../models/PortalFolder";
import { DateUtils } from "../../utils/date.utils";
import DocumentContextMenu from "../FileStorage/DocumentContextMenu";
import FolderFileButton from "../FileStorage/FolderFileButton";
import PlaceIcon from "@mui/icons-material/Place";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { StringUtils } from "../../utils/string.utils";
import { ActionLink } from "./ActionLink";
import FileInput from "../FileStorage/FileInput";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { BlobOutput } from "../../graphQL/outputModels/Blob.output";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { UpdatedFileOutput } from "../../graphQL/outputModels/UpdatedFile.output";
import { UpdateFileInput } from "../../graphQL/inputModels/updateFile.input";
import { MUTATIONS } from "../../graphQL/mutations";
import imageCompression from "browser-image-compression";
import DeleteFileDialog from "../FileStorage/DeleteFileDIalog";
import { EventType } from "../../models/EventType";
import { Timezone } from "../../models/Timezone";
import { DownloadUtils } from "../../utils/download.utils";
import useUser from "../../hooks/useUser";

interface EventDetailComponentProps {
  formValues: EventCustom;
  setFormValues: React.Dispatch<React.SetStateAction<EventCustom>>;
  eventTypes?: EventType[];
  timezones: Timezone[];
  editMode: boolean;
  loading: boolean;
  loadingMessage: string;
}

export default function EventDetailComponent({ formValues, setFormValues, eventTypes, timezones, editMode, loading, loadingMessage }: EventDetailComponentProps) {
  // SETUP
  const [anchorElDocContext, setAnchorElDocContext] = useState<null | HTMLElement>(null);
  const docContextOpen = Boolean(anchorElDocContext);
  const [currFileContext, setCurrFileContext] = useState<PortalFile>();
  const [currRenamingFile, setCurrRenamingFile] = useState(false);
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);

  // HOOKS
  const { isAdmin } = useUser();

  // GRAPHQL
  const [getBlob, { data: blobData }] = useLazyQuery<BlobOutput>(QUERIES.GET_BLOB, {
    fetchPolicy: "cache-and-network",
  });

  const [updateFile] = useMutation<UpdatedFileOutput, UpdateFileInput>(MUTATIONS.UPDATE_FILE);

  // HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // console.log(value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    var year: number, month: number, day: number;
    var split = value.split("-");
    year = +split[0];
    month = +split[1];
    day = +split[2];
    var oldDate: Date;
    if (name === "startDate") oldDate = formValues.startDateDate!;
    else oldDate = formValues.endDateDate!;
    const dateValue = new Date(year, month - 1, day);
    dateValue.setHours(oldDate.getHours(), oldDate.getMinutes());

    setFormValues({
      ...formValues,
      [name]: dateValue,
      [`${name}String`]: DateUtils.formatDateForTextfield(dateValue, formValues.timezone?.standardOffset),
    });
  };

  /**
   * @param value time in format hh:ss
   */
  const handleTimeChange = (name: "startTime" | "endTime", value: string) => {
    const hrs = +value.substring(0, 2);
    const min = +value.substring(3, 5);

    if (name === "startTime") {
      const newDate = new Date(formValues.startDate!);
      newDate.setHours(0, 0, 0);
      newDate.setHours(hrs, min);
      setFormValues({
        ...formValues,
        startDate: newDate.toISOString(),
        startDateDate: newDate,
        startTimeString: DateUtils.formatTimeForTextfield(newDate, formValues.timezone?.standardOffset),
      });
    } else if (name === "endTime") {
      const newDate = new Date(formValues.endDate!);
      newDate.setHours(0, 0, 0);
      newDate.setHours(hrs, min);
      setFormValues({
        ...formValues,
        endDate: newDate.toISOString(),
        endDateDate: newDate,
        endTimeString: DateUtils.formatTimeForTextfield(newDate, formValues.timezone?.standardOffset),
      });
    }
  };

  const handleEventTypeChange = (chosenEventTypeId?: number) => {
    var chosenEventType = eventTypes?.find((et) => et.eventTypeId === chosenEventTypeId);
    setFormValues({
      ...formValues,
      eventTypeId: chosenEventTypeId,
      eventType: chosenEventType,
    });
  };

  const handleTimezoneChange = (chosenTimezoneId?: number) => {
    var chosenTimezone = timezones?.find((t) => t.timezoneId === chosenTimezoneId);
    if (chosenTimezone?.standardOffset === undefined) return;

    // Preserve the old hrs/min
    var oldTimeZone = formValues.timezone?.standardOffset;
    var oldHrs = +oldTimeZone!.substring(0, 3);
    var oldMin = +oldTimeZone!.substring(4, 6);
    var newHrs = +chosenTimezone.standardOffset.substring(0, 3);
    var newMin = +chosenTimezone.standardOffset.substring(4, 6);
    var hrDifferent = oldHrs - newHrs;
    var minDifferent = oldMin - newMin;

    const newStartDate = new Date(formValues.startDate!);
    newStartDate.setHours(formValues.startDateDate!.getHours() + hrDifferent, formValues.startDateDate!.getMinutes() + minDifferent);

    const newEndDate = new Date(formValues.endDate!);
    newEndDate.setHours(formValues.endDateDate!.getHours() + hrDifferent, formValues.endDateDate!.getMinutes() + minDifferent);

    setFormValues({
      ...formValues,
      timezoneId: chosenTimezoneId,
      timezone: chosenTimezone,
      startDate: newStartDate.toISOString(),
      startDateDate: newStartDate,
      startTimeString: DateUtils.formatTimeForTextfield(newStartDate, chosenTimezone.standardOffset),
      endDate: newEndDate.toISOString(),
      endDateDate: newEndDate,
      endTimeString: DateUtils.formatTimeForTextfield(newEndDate, chosenTimezone.standardOffset),
    });
  };

  const openDocContext = (e: MouseEvent, folder?: PortalFolder, file?: PortalFile) => {
    setAnchorElDocContext(e.currentTarget as HTMLElement);
    setCurrFileContext(file);
    // setCurrFolderContext(folder);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const f: File = e.target.files[0];
      const ind = f.name.lastIndexOf(".");
      const fileName = f.name.substring(0, ind);
      const ext = f.name.substring(ind + 1, f.name.length);
      setFormValues({
        ...formValues,
        attachments: [
          ...(formValues.attachments ?? []),
          {
            fileName: fileName,
            fileExtension: ext,
            fileId: -(formValues.attachments?.length ?? 0),
          },
        ],
        newAttachments: [...(formValues.newAttachments ?? []), f],
      });
    }
  };

  const handleThumbnailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const f: File = e.target.files[0];

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 700,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(f, options);
        setFormValues({
          ...formValues,
          pictureBlobUrl: URL.createObjectURL(compressedFile),
          newThumbnail: compressedFile,
        });
      } catch (error) {
        console.error(error);
        setFormValues({
          ...formValues,
          pictureBlobUrl: URL.createObjectURL(f),
          newThumbnail: f,
        });
      }
    }
  };

  const handleAllDayChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      allDay: e.target.checked,
    });
  };

  const handleFileClick = (file: PortalFile) => {
    console.log(`Downloading File: ${file.fileName}`);
    getBlob({
      variables: {
        fileId: file.fileId,
      },
    });
    appNotification({
      severity: "info",
      message: `Downloading ${file.fileName}`,
    });
  };

  const handleSubmitRenameFile = (file: PortalFile, newName: string) => {
    console.log("submitting rename to api");
    updateFile({
      variables: {
        input: {
          fileId: file.fileId!,
          newFileName: newName,
        },
      },
    });
    setFormValues({
      ...formValues,
      attachments: formValues.attachments?.map((a) => {
        return a.fileId === file.fileId ? { ...a, fileName: newName } : a;
      }),
    });
    setCurrRenamingFile(false);
    setCurrFileContext(undefined);
  };

  const handleDeleteFile = () => {
    var idToDelete = currFileContext?.fileId;
    console.log(currFileContext);
    var newAttachments = formValues.attachments!.filter((a) => a.fileId !== idToDelete);
    if (idToDelete) {
      setFormValues({
        ...formValues,
        attachments: [...newAttachments],
        deletedAttachmentIds: [...(formValues.deletedAttachmentIds ?? []), idToDelete],
      });
    }
    setDeleteFileDialogOpen(false);
    setCurrFileContext(undefined);
    setAnchorElDocContext(null);
  };

  // download the file
  useEffect(() => {
    if (blobData?.blob.url) {
      DownloadUtils.downloadURI(blobData.blob.url);
    }
  }, [blobData]);

  // RENDER
  return (
    <Paper
      elevation={12}
      sx={{
        borderRadius: 2,
        padding: 2,
        position: "relative",
      }}
    >
      {/* Dialogs */}
      {deleteFileDialogOpen && currFileContext && <DeleteFileDialog open={deleteFileDialogOpen} onClose={() => handleDeleteFile()} file={currFileContext} />}

      {/* Loading overlay */}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "white",
            opacity: 0.8,
            zIndex: 2,
          }}
        >
          <CircularProgress sx={{ color: (theme) => theme.palette.orange.main }} size="3em" />
          <Typography sx={{ mt: 2 }}>{loadingMessage}</Typography>
        </Box>
      )}

      {/* Main component */}
      <Grid container height="100%" sx={{ minHeight: 500 }}>
        <Grid item xs={12} md={7}>
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {/* Event Name */}
            {editMode ? (
              <TextField
                name="eventName"
                label="Name"
                inputProps={{
                  style: {
                    fontFamily: "AvenirBlack",
                    fontSize: "2em",
                    lineHeight: 1.5,
                    textAlign: "center",
                  },
                }}
                InputProps={{
                  sx: {
                    p: 0,
                  },
                }}
                type="text"
                multiline
                value={formValues.eventName}
                onChange={handleInputChange}
                required
                sx={{
                  ml: 1,
                  mr: 1,
                }}
              />
            ) : (
              <Typography
                sx={{
                  fontFamily: "AvenirBlack",
                  fontSize: "2em",
                  ml: "auto",
                  mr: "auto",
                }}
              >
                {formValues.eventName}
              </Typography>
            )}

            {/* Event Type */}
            {editMode ? (
              <Box>
                <FormControl disabled={loading} sx={{ width: 250, mt: 2, ml: "auto", mr: "auto" }}>
                  <InputLabel id="eventType-label">Event Type (optional)</InputLabel>
                  <Select labelId="eventType-label" name="eventTypeId" label="Event Type (Optional)" value={formValues.eventTypeId ?? ""} onChange={(e) => handleEventTypeChange(+e.target.value)}>
                    {eventTypes?.map((et) => {
                      return (
                        <MenuItem key={et.eventTypeId!} value={et.eventTypeId!}>
                          {et.eventTypeName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button sx={{ mt: 2.5, ml: 1 }} onClick={() => handleEventTypeChange(undefined)}>
                  Clear
                </Button>
              </Box>
            ) : (
              formValues.eventType && (
                <Typography component="span" sx={{ mb: 1, fontSize: "1.2em", fontStyle: "italic" }}>
                  {formValues.eventType.eventTypeName}
                </Typography>
              )
            )}

            {/* Event Location */}
            <Box mt={0}>
              {!loading && <PlaceIcon sx={{ fontSize: "1.4em", mr: 1, mt: editMode ? 2.5 : 0 }} color="cyan" />}
              {editMode ? (
                <TextField
                  name="location"
                  label="Location"
                  inputProps={{
                    sx: {
                      fontSize: "1.2em",
                      lineHeight: 1.5,
                      textAlign: "center",
                      // p: 0,
                      // width: "100%",
                    },
                  }}
                  InputProps={{
                    sx: { p: 1 },
                  }}
                  type="text"
                  multiline
                  value={formValues.location}
                  onChange={handleInputChange}
                  required
                  sx={{ mt: 1.5, width: "calc(100% - 2.5em)" }}
                />
              ) : (
                <Typography component="span" sx={{ mt: 1, fontSize: "1.2em" }}>
                  {formValues.location}
                </Typography>
              )}
            </Box>

            {/* Event Date/Time */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: editMode ? 1.5 : 0,
              }}
            >
              {!loading && <AccessTimeFilledIcon sx={{ fontSize: "1.2em", mr: 1 }} color="cyan" />}
              {editMode ? (
                <Box ml={3}>
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ mr: 2, width: 50 }}>Start:</Typography>
                    <TextField type="date" label="Date*" name="startDate" value={formValues.startDateString} onChange={handleDateChange} InputLabelProps={{ shrink: true }} />
                    <TextField
                      type="time"
                      label="Time*"
                      name="startTime"
                      value={formValues.allDay ? "00:00" : formValues.startTimeString}
                      disabled={formValues.allDay}
                      onChange={(e) => handleTimeChange("startTime", e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormGroup sx={{ ml: 1 }}>
                      <FormControlLabel control={<Switch checked={formValues.allDay ?? false} onChange={handleAllDayChanged} />} label="All Day" />
                    </FormGroup>
                  </Box>
                  <Box display="flex" alignItems="center" mt={2}>
                    <Typography sx={{ mr: 2, width: 50 }}>End:</Typography>
                    <TextField type="date" label="Date*" name="endDate" value={formValues.endDateString} onChange={handleDateChange} InputLabelProps={{ shrink: true }} />
                    <TextField
                      type="time"
                      label="Time*"
                      name="endTime"
                      value={formValues.allDay ? "00:00" : formValues.endTimeString}
                      disabled={formValues.allDay}
                      onChange={(e) => handleTimeChange("endTime", e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormControl disabled={loading} sx={{ ml: 1, mr: 1, width: 200 }}>
                      <InputLabel id="timezone-label">Timezone*</InputLabel>
                      <Select labelId="timezone-label" name="timezoneId" label="timezone*" value={formValues.timezoneId ?? ""} onChange={(e) => handleTimezoneChange(+e.target.value)}>
                        {timezones?.map((t) => {
                          return (
                            <MenuItem key={t.timezoneId!} value={t.timezoneId!}>
                              {t.displayName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              ) : (
                <Typography component="span" sx={{ mt: 1, fontSize: "1.2em" }}>
                  {DateUtils.getFriendlyRange(formValues.startDateDate!, formValues.endDateDate!, formValues.allDay ?? false, formValues.timezone)}
                </Typography>
              )}
            </Box>

            {/* Long Description */}
            {editMode ? (
              <TextField
                name="eventFullDescription"
                label="Description"
                inputProps={{
                  style: {
                    fontSize: "1.2em",
                    lineHeight: 1.5,
                    textAlign: "center",
                  },
                }}
                InputProps={{
                  sx: {
                    p: 0,
                    mr: 1,
                    minHeight: 100,
                    // maxHeight: "calc(100vh - 800px)",
                    overflow: "auto",
                  },
                }}
                type="text"
                multiline
                value={formValues.eventFullDescription}
                onChange={handleInputChange}
                required
                sx={{
                  mt: 2,
                }}
              />
            ) : (
              <Typography
                sx={{
                  mt: 5,
                  fontSize: "1.2em",
                  mr: 1,
                  maxHeight: "calc(100vh - 660px)",
                  overflow: "auto",
                }}
              >
                {formValues.eventFullDescription}
              </Typography>
            )}

            {/* Files */}
            <Box mt="auto" minHeight={100} overflow="auto">
              <Divider sx={{ mr: 2 }} />
              <Box display="flex" flexWrap="wrap" minHeight={100}>
                {formValues?.attachments?.map((f, i) => (
                  <FolderFileButton
                    key={`file-${f.fileId}`}
                    file={f}
                    handleFileClick={handleFileClick}
                    handleRightClick={openDocContext}
                    handleFileRename={handleSubmitRenameFile}
                    currRenaming={f.fileId === currFileContext?.fileId && currRenamingFile}
                  />
                ))}

                {/* Add File */}
                {editMode && (
                  <Box
                    sx={{
                      ml: 1,
                      mt: "auto",
                      mb: "auto",
                    }}
                  >
                    <Tooltip placement="top" title="Add File">
                      <Box>
                        <FileInput onFileChange={handleFileChange} type="file">
                          <Button
                            aria-label="add-file"
                            color="orange"
                            variant="contained"
                            component="span"
                            sx={{
                              height: 40,
                              width: 60,
                              minWidth: 60,
                              borderRadius: 20,
                              p: 0,
                            }}
                          >
                            <AttachFileOutlinedIcon sx={{ fontSize: 30, color: "white", mr: "-8px" }} />
                            <AddOutlinedIcon sx={{ fontSize: 22, color: "white" }} />
                          </Button>
                        </FileInput>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                {/* Right-click menu */}
                <DocumentContextMenu
                  open={docContextOpen}
                  onClose={() => setAnchorElDocContext(null)}
                  anchorEl={anchorElDocContext}
                  isAdmin={isAdmin}
                  fileContext={currFileContext}
                  handleFileChange={handleFileChange}
                  handleFileRename={() => setCurrRenamingFile(true)}
                  handleFileDownload={handleFileClick}
                  handleDeleteFile={() => setDeleteFileDialogOpen(true)}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Thumbnail Image */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                m: "auto",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: "auto",
                  maxHeight: 500,
                  width: "auto",
                  maxWidth: "100%",
                  m: "auto",
                }}
                alt=""
                src={loading && !formValues.pictureBlobUrl ? "" : formValues.pictureBlobUrl ?? "/images/cc-logo-cyan.png"}
              />
              {editMode && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ":hover #overlay": {
                      opacity: 0.5,
                    },
                  }}
                >
                  <FileInput onFileChange={handleThumbnailChange} type="image">
                    <Box
                      id="overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: -1,
                        bottom: 5,
                        backgroundColor: "rgb(100, 100, 100)",
                        opacity: 0,
                        transition: "opacity 300ms",
                        cursor: "pointer",
                      }}
                    />
                    <Button variant="contained" color="orange" component="span">
                      {StringUtils.isEmpty(formValues.pictureBlobUrl) ? "Add Image" : "Change Image"}
                    </Button>
                  </FileInput>
                </Box>
              )}
            </Box>

            {/* Registration/Hotel */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {editMode ? (
                <TextField label="Registration Link (optional)" name="registrationLink" value={formValues.registrationLink ?? ""} onChange={handleInputChange} />
              ) : (
                <ActionLink href={formValues.registrationLink} disabled={StringUtils.isEmpty(formValues.registrationLink)}>
                  Registration Details
                </ActionLink>
              )}
              {editMode ? (
                <TextField label="Hotel Reservation Link (optional)" name="hotelReservationLink" value={formValues.hotelReservationLink ?? ""} onChange={handleInputChange} sx={{ mt: 2 }} />
              ) : (
                !StringUtils.isEmpty(formValues.hotelReservationLink) && <ActionLink href={formValues.hotelReservationLink}>Reserve your Hotel Room</ActionLink>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
