import { Box, CircularProgress, Divider, FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { User, UserBasic } from "../../../models/User";
import { useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ParticipantTable from "../AssessmentDetail/ParticipantTable";
import { AssessmentParticipant } from "../../../models/AssessmentParticipant";
import { Dayjs } from "dayjs";
import { useQuery } from "@apollo/client";
import { UsersOutput } from "../../../graphQL/outputModels/Users.output";
import { QUERIES } from "../../../graphQL/queries";

interface Step1VisitProps {
  assessmentDate?: Dayjs;
  loading: boolean;
  performedByUser?: User | null;
  participants: AssessmentParticipant[];
  onFormChanged: (e: any) => void;
  onPerformedByUserChanged?: (u: UserBasic) => void;
}

export default function Step1Visit({ assessmentDate, loading, performedByUser, participants, onFormChanged, onPerformedByUserChanged }: Step1VisitProps) {
  // GRAPHQL
  const { loading: loadingUsers, data: usersData } = useQuery<UsersOutput>(QUERIES.GET_USERS);

  // HANDLERS
  const handleUserChanged = (e: any) => {
    const { value } = e.target;
    const selectedUser = sortedUsers.find((u) => u.userId === +value);
    if (onPerformedByUserChanged) onPerformedByUserChanged(selectedUser!);
  };

  const handleAddParticipant = (participant: AssessmentParticipant) => {
    onFormChanged({ target: { name: "participants", value: [...participants, { ...participant }] } });
  };

  const handleRemoveParticipant = (index: number) => {
    var newParticipants = [...participants];
    newParticipants.splice(index, 1);
    onFormChanged({ target: { name: "participants", value: newParticipants } });
  };

  const handleParticipantChanged = (e: any, index: number) => {
    const { name, value } = e.target;
    var newParticipants = participants.map((p, i): AssessmentParticipant => {
      if (i === index) return { ...p, [name]: value };
      else return p;
    });
    onFormChanged({ target: { name: "participants", value: [...newParticipants] } });
  };

  // USEMEMOS
  const sortedUsers = useMemo(() => {
    if (usersData) {
      let g = [...usersData.users].filter((u) => (u.fullName?.length ?? 0) > 1).sort((a, b) => a.fullName!.localeCompare(b.fullName!));
      return g;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  return (
    <Stack spacing={2}>
      {assessmentDate !== undefined && performedByUser !== undefined && (
        <>
          <Stack direction="row">
            <FormControl disabled={loading || loadingUsers} sx={{ width: "45%", mb: 1, position: "relative" }}>
              {loadingUsers && (
                <Box sx={{ position: "absolute", top: 14, right: 30, zIndex: 1000 }}>
                  <CircularProgress size="1.5em" />
                </Box>
              )}
              <InputLabel id="performedBy-label" required>
                Performed By
              </InputLabel>
              <Select labelId="performedBy-label" name="performedByUser" label="Performed By" value={performedByUser?.userId?.toString() ?? ""} onChange={handleUserChanged} required>
                {sortedUsers?.map((u) => {
                  return (
                    <MenuItem key={u.userId!} value={u.userId!}>
                      <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                        {u.fullName}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>required</FormHelperText>
            </FormControl>

            <Divider sx={{ flexBasis: 40, mb: "58px", mr: 1, ml: 1 }} />

            {/* <TextField
          type="date"
          label="Assessment Date"
          name="assessmentDate"
          value={assessmentDateString}
          onChange={onDateChange}
          InputLabelProps={{ shrink: true }}
          required
          helperText="required"
          sx={{ width: "45%" }}
              /> */}

            <DatePicker
              label={"Assessment Date *"}
              sx={{ width: "45%" }}
              slotProps={{
                textField: {
                  helperText: "required",
                },
              }}
              value={assessmentDate}
              onChange={(newValue) => onFormChanged({ target: { name: "assessmentDate", value: newValue } })}
            />
          </Stack>

          <Divider />
        </>
      )}

      <FormLabel required>Participants</FormLabel>
      <ParticipantTable participants={participants} onAddParticipant={handleAddParticipant} onRemoveParticipant={handleRemoveParticipant} onParticipantChanged={handleParticipantChanged} />

      {/* <TextField
          id="assessmentName-input"
          name="assessmentName"
          label="Visit Field"
          type="text"
          value={formValues.current.assessmentName}
          onChange={handleInputChange}
          fullWidth
          required
          helperText="required"
        /> */}
    </Stack>
  );
}
