import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";
import { DownloadUtils } from "../utils/download.utils";
import dayjs from "dayjs";
import { appNotification } from "../apollo/PortalApolloProvider";

type vars = {
    assessmentIds?: number[]
}
export type ExportType = (params: vars) => void;
type ExportQueryRet = {
    execute: ExportType;
    loading: boolean;
}
export default function useRawDataExportQuery(): ExportQueryRet {
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ assessmentIds }: vars) => {
        async function run({ assessmentIds }: vars) {
            if (assessmentIds && assessmentIds.length > 0) {
                console.log("executing export")
                setLoading(true);
                try {
                    const response = await FetchUtils.EXPORT_RAW_DATA(
                        assessmentIds
                    );
                    if (response) {
                        var currTime = dayjs().format("YYYYMMDD-HHmmss");
                        DownloadUtils.downloadBlob(response, "AssessmentRawDataReport_" + currTime, "xlsx");
                    }
                }
                catch (e: any) {
                    console.error(e);
                    appNotification({
                        severity: "error",
                        message: "There was an issue downloading the raw data.",
                    });
                }
                setLoading(false);
            }
        }

        run({ assessmentIds })
    }, []);



    return {
        execute,
        loading: loading,
    };
}