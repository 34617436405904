import { Event, EventCustom } from "../../models/Event";
import { DateUtils } from "../../utils/date.utils";

export const eventMapper = {
    mapToCustom
}


function mapToCustom(value: Event): EventCustom {
    var ret: EventCustom = { ...value };

    let d = value.startDate ? new Date(value.startDate) : new Date();
    ret.startDateDate = d;
    ret.startDateString = DateUtils.formatDateForTextfield(d, value.timezone?.standardOffset);
    ret.startTimeString = DateUtils.formatTimeForTextfield(d, value.timezone?.standardOffset);

    d = value.endDate ? new Date(value.endDate) : new Date();
    ret.endDateDate = d;
    ret.endDateString = DateUtils.formatDateForTextfield(d, value.timezone?.standardOffset);
    ret.endTimeString = DateUtils.formatTimeForTextfield(d, value.timezone?.standardOffset);

    return ret;
}