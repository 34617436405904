import { appNotification } from "../apollo/PortalApolloProvider";
import { ActionStatus, ActionStatusNumber } from "../models/ActionStatus";

export const NotificationUtils = {
    notifyAdd,
    notifyUpdate,
    notifyDelete
}

function notifyAdd(actionStatus: ActionStatus | ActionStatusNumber, actionStatusMessage: string, successCallback?: () => void) {
    if (actionStatus === ActionStatus.Success || actionStatus === ActionStatusNumber.Success) {
        console.log("add success!");
        if (successCallback)
            successCallback();
        appNotification({ severity: "success", message: "Successfully added" });
    } else if (actionStatus === ActionStatus.Fail || actionStatus === ActionStatusNumber.Fail) {
        console.error(actionStatusMessage);
        appNotification({ severity: "warning", message: `Error: ${actionStatusMessage}` });
    } else if (actionStatus === ActionStatus.Error || actionStatus === ActionStatusNumber.Error) {
        console.error(actionStatusMessage);
        appNotification({ severity: "error", message: `Error: ${actionStatusMessage}` });
    }
}

function notifyUpdate(actionStatus: ActionStatus | ActionStatusNumber, actionStatusMessage: string, successCallback?: () => void) {
    if (actionStatus === ActionStatus.Success || actionStatus === ActionStatusNumber.Success) {
        console.log("update success!");
        if (successCallback)
            successCallback();
        appNotification({ severity: "success", message: "Successfully updated" });
    } else if (actionStatus === ActionStatus.Fail || actionStatus === ActionStatusNumber.Fail) {
        console.error(actionStatusMessage);
        appNotification({ severity: "warning", message: `Error: ${actionStatusMessage}` });
    } else if (actionStatus === ActionStatus.Error || actionStatus === ActionStatusNumber.Error) {
        console.error(actionStatusMessage);
        appNotification({ severity: "error", message: `Error: ${actionStatusMessage}` });
    }
}

function notifyDelete(actionStatus: ActionStatus | ActionStatusNumber, actionStatusMessage: string, successCallback?: () => void) {
    if (actionStatus === ActionStatus.Success || actionStatus === ActionStatusNumber.Success) {
        console.log("delete success!");
        if (successCallback)
            successCallback();
        appNotification({ severity: "success", message: "Successfully deleted" });
    } else if (actionStatus === ActionStatus.Fail || actionStatus === ActionStatusNumber.Fail) {
        console.error(actionStatusMessage);
        appNotification({ severity: "warning", message: `Error: ${actionStatusMessage}` });
    } else if (actionStatus === ActionStatus.Error || actionStatus === ActionStatusNumber.Error) {
        console.error(actionStatusMessage);
        appNotification({ severity: "error", message: `Error: ${actionStatusMessage}` });
    }
}

