import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Assessment } from "../../models/Assessment";
import useExportAssessmentReportQuery from "../../hooks/useAssessmentReportQuery";

interface DownloadReportDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  assessment: Assessment;
  alreadyProcessingPDF: boolean;
  alreadyProcessingDoc: boolean;
  onFetchReport: (isPDF: boolean) => void;
}

export default function DownloadReportDialog({ open, onClose, assessment, alreadyProcessingPDF, alreadyProcessingDoc, onFetchReport }: DownloadReportDialogProps) {
  // CUSTOM HOOKS
  const { loading: callingExport } = useExportAssessmentReportQuery();

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box p={2}>
        <Typography variant="h2" fontWeight={500} fontSize="1.25em" lineHeight="1.6em" letterSpacing="initial">
          Which format?
        </Typography>
        <Typography variant="subtitle1" fontStyle="italic" lineHeight="1.2em" sx={{ color: (theme) => theme.palette.text.secondary }}>
          {assessment.assessmentName}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Stack sx={{ width: "100%", height: 40 }} direction="row" justifyContent="center" spacing={3} position="relative">
          <Button variant="outlined" color="cyan" sx={{ width: 100 }} onClick={() => onFetchReport(false)}>
            {alreadyProcessingDoc ? <CircularProgress size="2em" /> : "DOC"}
          </Button>
          <Button variant="outlined" color="orange" sx={{ width: 100 }} onClick={() => onFetchReport(true)} disabled={alreadyProcessingPDF}>
            {alreadyProcessingPDF ? <CircularProgress size="2em" /> : "PDF"}
          </Button>
        </Stack>
        {callingExport && (
          <Box sx={{ position: "absolute", right: 20, bottom: 20 }}>
            <CircularProgress size="2em" />
          </Box>
        )}
      </DialogContent>

      {/* <DialogActions>
        {loadingDeleteAssessment && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}
