import { Box, BoxProps } from "@mui/material";
import React, { useState } from "react";

interface IFileInputProps extends BoxProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>, parentFolderId?: number) => void;
  type: "image" | "file";
  children: any;
}

const FileInput = ({ onFileChange, type, children, ...rest }: IFileInputProps) => {
  const [_id] = useState(`upload-photo-${Math.floor(Math.random() * 999999)}`);

  return (
    <Box {...rest}>
      <label htmlFor={_id}>
        <input
          style={{ display: "none" }}
          id={_id}
          name="upload-photo"
          type="file"
          accept={type === "image" ? "image/*" : "*/*"}
          multiple={false}
          onChange={onFileChange}
          onClick={(e) => (e.currentTarget.value = "")} // trigger onChange again if same file is chosen.
        />

        {children}
      </label>
    </Box>
  );
};

export default FileInput;
