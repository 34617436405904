import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";

type vars = {
    file?: File
    isCoverImage?: boolean
    isDefault?: boolean
}

export default function useUploadMasterFileQuery<T>({ file, isCoverImage, isDefault }: vars = {}) {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ file, isCoverImage, isDefault }: vars) => {
        async function run(file: File) {
            console.log("executing upload")
            setLoading(true);
            const response = await FetchUtils.UPLOAD_MASTER_FILE<T>(
                file,
                isCoverImage,
                isDefault
            );
            console.log("done uploading")
            setLoading(false);
            setData(response);
            return response;
        }

        if (file) {
            run(file)
        }
    }, []);



    return {
        callApi: execute,
        loading: loading,
        data: data
    };
}