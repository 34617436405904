import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { AllRoutes } from "../../routing/Routes";
import FileExplorerComponent from "./FileExplorerComponent";

export default function FileStoragePage() {
  const { portal } = useParams();
  const navigate = useNavigate();
  const { portals } = useUser();
  const currPortal = portals?.find((p) => p.portalName?.toLocaleLowerCase() === portal?.toLocaleLowerCase());

  useEffect(() => {
    if (portals?.length !== undefined && currPortal === undefined) {
      navigate(AllRoutes.NotFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals, currPortal]);

  return (
    <Container maxWidth="md" sx={{ p: 5 }}>
      <Box>
        <Typography variant="h2" textAlign="start" margin="auto">
          Documents
        </Typography>
      </Box>
      <Box mt={5}>
        <FileExplorerComponent
          portalId={currPortal?.portalId}
          // loading={loading}
          // rootFolder={rootFolder?.folder}
          // // rootFolder={data}
          // refetch={getFolder}
        />
      </Box>
    </Container>
  );
}
