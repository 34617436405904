import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { DeletedAssessmentCommentOutput } from "../../../graphQL/outputModels/DeletedAssessmentComment.output";
import { DeleteAssessmentCommentInput } from "../../../graphQL/inputModels/deleteAssessmentComment.input";
import { AssessmentQuestionCommentsOutput } from "../../../graphQL/outputModels/AssessmentQuestionComment.output";
import { GetAssessmentQuestionCommentsInput } from "../../../graphQL/inputModels/getAssessmentQuestionComments.input";
import { AssessmentComment } from "../../../models/AssessmentComment";
import { AssessmentOutput } from "../../../graphQL/outputModels/Assessment.output";
import { GetAssessmentInput } from "../../../graphQL/inputModels/getAssessment.input";
import { AssessmentQuestion } from "../../../models/AssessmentQuestion";

interface DeleteCommentDialogProps {
  assessmentComment: AssessmentComment;
  onClose: () => void;
}

export default function DeleteCommentDialog({ assessmentComment, onClose }: DeleteCommentDialogProps) {
  //GRAPHQL
  const [deleteComment, { loading: loadingDeleteComment, data: deletedCommentData }] = useMutation<DeletedAssessmentCommentOutput, DeleteAssessmentCommentInput>(MUTATIONS.DELETE_ASSESSMENT_COMMENT);

  // HANDLERS
  const handleDelete = () => {
    deleteComment({
      variables: {
        input: {
          assessmentCommentId: assessmentComment.assessmentCommentId!,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteAssessmentComment.actionStatus === ActionStatus.Success) {
          const existingComments = cache.readQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
            query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
            variables: {
              assessmentQuestionId: assessmentComment.assessmentQuestionId!,
            },
          });
          if (existingComments) {
            const newComments = existingComments.assessmentCommentsByQuestionId.filter((p: AssessmentComment) => p.assessmentCommentId !== data?.deleteAssessmentComment.assessmentCommentId);
            cache.writeQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
              query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
              variables: {
                assessmentQuestionId: assessmentComment.assessmentQuestionId!,
              },
              data: {
                assessmentCommentsByQuestionId: newComments,
              },
            });
          }

          const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
            query: QUERIES.GET_ASSESSMENT,
            variables: {
              assessmentId: assessmentComment.assessmentId!,
            },
          });
          if (existingAssessment) {
            var newQuestions = existingAssessment.assessment.assessmentQuestions!.map((q): AssessmentQuestion => {
              if (q.assessmentQuestionId === data.deleteAssessmentComment.assessmentQuestionId) {
                return {
                  ...q,
                  assessmentCommentCount: q.assessmentCommentCount! - 1,
                };
              }
              return q;
            });
            cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
              query: QUERIES.GET_ASSESSMENT,
              variables: {
                assessmentId: assessmentComment.assessmentId!,
              },
              data: {
                assessment: {
                  ...existingAssessment.assessment,
                  assessmentQuestions: [...newQuestions],
                },
              },
            });
          }
        }
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedCommentData?.deleteAssessmentComment?.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose();
      appNotification({ severity: "success", message: "Successfully deleted" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedCommentData]);

  // RENDER
  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>
        {`Warning: Deleting Comment`}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure you want to delete this comment?</DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteComment && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteComment}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
