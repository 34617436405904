import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";
import { useEffect, useState, useRef, useMemo } from "react";
import { Editor as TinyMCEEditor } from "tinymce";
import FormatEditor from "../../FormatEditor/FormatEditor";
import { MUTATIONS } from "../../../graphQL/mutations";
import { UpdatedAssessmentRecommendationOutput } from "../../../graphQL/outputModels/UpdatedAssessmentRecommendation.output";
import { UpdateAssessmentRecommendationInput } from "../../../graphQL/inputModels/updateAssessmentRecommendation.input";
import { useMutation } from "@apollo/client";
import { ActionStatus } from "../../../models/ActionStatus";
import { AssessmentQuestion, AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";
import { AssessmentOutput } from "../../../graphQL/outputModels/Assessment.output";
import { QUERIES } from "../../../graphQL/queries";
import { Assessment } from "../../../models/Assessment";
import { GetAssessmentInput } from "../../../graphQL/inputModels/getAssessment.input";
import { NotificationUtils } from "../../../utils/notification.utils";
import useUser from "../../../hooks/useUser";
import { StringUtils } from "../../../utils/string.utils";

interface RecommendationDialogProps {
  onClose: () => void;
  q: AssessmentQuestionCustom;
}

export default function RecommendationDialog({ onClose, q }: RecommendationDialogProps) {
  // SETUP
  const [isEditing, setIsEditing] = useState(false);
  const descEditorRef = useRef<TinyMCEEditor>();
  const { isAdmin } = useUser();

  // GRAPHQL
  const [updateRecommendation, { loading: loadingUpdateRecommendation, data: updatedRecommendationData }] = useMutation<UpdatedAssessmentRecommendationOutput, UpdateAssessmentRecommendationInput>(
    MUTATIONS.UPDATE_ASSESSMENT_RECOMMENDATION
  );

  // HANDLERS
  const handleSubmit = () => {
    if (q.assessmentNonCompliantRecommendation === descEditorRef.current?.getContent()) return;
    updateRecommendation({
      variables: {
        input: {
          assessmentQuestionId: q.assessmentQuestionId!,
          assessmentNonCompliantRecommendation: descEditorRef.current!.getContent(),
        },
      },
      update: (cache, { data }) => {
        console.log(data);
        if (data?.updateAssessmentQuestionNonCompliantRecommendation.actionStatus === ActionStatus.Success) {
          const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
            query: QUERIES.GET_ASSESSMENT,
            variables: {
              assessmentId: q.assessmentId!,
            },
          });
          console.log(existingAssessment);
          if (existingAssessment) {
            console.log("fetching");
            var newAssessmentQuestions = existingAssessment.assessment.assessmentQuestions?.map((q): AssessmentQuestion => {
              if (q.assessmentQuestionId === data.updateAssessmentQuestionNonCompliantRecommendation.assessmentQuestionId) {
                return {
                  ...q,
                  assessmentNonCompliantRecommendation: data.updateAssessmentQuestionNonCompliantRecommendation.assessmentNonCompliantRecommendation,
                };
              }
              return q;
            });
            if (newAssessmentQuestions) {
              console.log("writing");
              const newAssessment: Assessment = { ...existingAssessment.assessment, assessmentQuestions: [...newAssessmentQuestions] };
              cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
                query: QUERIES.GET_ASSESSMENT,
                variables: {
                  assessmentId: q.assessmentId!,
                },
                data: { assessment: newAssessment },
              });
            }
          }
        }
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    NotificationUtils.notifyUpdate(
      updatedRecommendationData?.updateAssessmentQuestionNonCompliantRecommendation.actionStatus!,
      updatedRecommendationData?.updateAssessmentQuestionNonCompliantRecommendation.actionStatusMessage!,
      () => setIsEditing(false)
    );
  }, [updatedRecommendationData]);

  const chosenResponse = useMemo(() => {
    var r = q.assessmentQuestionResponses?.find((qr) => qr.assessmentResponseId === q.selectedAssessmentQuestionResponseId);
    return r;
  }, [q.assessmentQuestionResponses, q.selectedAssessmentQuestionResponseId]);

  // RENDER
  return (
    <Dialog open={true} fullWidth maxWidth={isEditing ? "md" : "sm"}>
      <DialogTitle pb={1}>
        Recommendation
        {/* <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setIsEditing(true);
            }}
            color="default"
            sx={{ p: "4px" }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0, pt: "0 !important" }}>
        <Box maxHeight="calc(100vh - 500px)" overflow="auto">
          {!isAdmin && chosenResponse?.ratingCompliant && (
            <Typography color="error" fontStyle="italic">
              (Admin - The below recommendation won't be seen by the user since the chosen response is compliant)
            </Typography>
          )}
          {isEditing ? (
            <FormatEditor text={q.assessmentNonCompliantRecommendation} editorRef={descEditorRef} height={200} />
          ) : isAdmin && chosenResponse?.ratingCompliant ? (
            <Typography>You have a good score! No recommendation needed.</Typography>
          ) : (
            <Typography component="div">{parse(StringUtils.isEmpty(q.assessmentNonCompliantRecommendation) ? "N/A" : q.assessmentNonCompliantRecommendation!)}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {isEditing ? (
          <>
            <Button variant="outlined" color="orange" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            {loadingUpdateRecommendation ? (
              <Box width={100} textAlign="center" ml={1}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Button variant="contained" color="orange" sx={{ width: 100 }} onClick={() => handleSubmit()}>
                Save
              </Button>
            )}
          </>
        ) : (
          <>
            {isAdmin && (
              <Button variant="outlined" color="orange" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}
            <Button variant="contained" color="orange" onClick={() => onClose()}>
              Ok
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
