import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";
import { DeletedLinkOutput } from "../../graphQL/outputModels/DeletedLink.output";
import { DeleteLinkInput } from "../../graphQL/inputModels/deleteLink.input";
import { PortalLink } from "../../models/PortalLink";

interface DeleteLinkDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  link: PortalLink;
}

export default function DeleteLinkDialog({ open, onClose, link }: DeleteLinkDialogProps) {
  //GRAPHQL
  const [deleteLink, { loading: loadingDeleteLink, data: deletedLink }] = useMutation<DeletedLinkOutput, DeleteLinkInput>(MUTATIONS.DELETE_LINK);

  // HANDLERS
  const handleDelete = () => {
    deleteLink({
      variables: {
        input: {
          linkId: link.linkId!,
        },
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedLink?.deleteLink?.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully deleted" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedLink]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Warning: Deleting '{link.displayName}'
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          You are about to PERMANENTLY delete this link from all Portals. If you instead want to keep this link on other Portals, close out of this dialog and click the pencil icon to edit this link
          instead.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteLink && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteLink}>
          Delete link
        </Button>
      </DialogActions>
    </Dialog>
  );
}
