import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { QUERIES } from "../../../graphQL/queries";
import { PrescriptivesOutput } from "../../../graphQL/outputModels/Prescriptives.output";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { Prescriptive } from "../../../models/Prescriptive";

interface PrescriptiveTableProps {
  onShowAddEditDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => void;
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
}

export default function PrescriptiveTable({ onShowAddEditDialog, onShowDeleteDialog }: PrescriptiveTableProps) {
  // GRAPHQL
  const [getPrescriptives, { loading, data: prescriptivesData }] = useLazyQuery<PrescriptivesOutput>(QUERIES.GET_PRESCRIPTIVES, {
    fetchPolicy: "cache-and-network",
  });

  // HANDLERS
  const handleOpenAddDialog = (prescriptive?: Prescriptive) => {
    onShowAddEditDialog("prescriptive", prescriptive);
  };

  const handleDeleteDialog = (prescriptive: Prescriptive) => {
    onShowDeleteDialog("prescriptive", prescriptive);
  };

  // USEEFFECTS
  useEffect(() => {
    getPrescriptives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 350px)"
        margin="auto"
        mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: 100 }}>
                Name
              </TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center" sx={{ width: 70 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              prescriptivesData?.prescriptives?.map((prescriptive, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {`Prescriptive ${prescriptive.prescriptiveNumber}`}
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {prescriptive.prescriptiveDescription}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit prescriptive">
                      <IconButton
                        onClick={() => {
                          handleOpenAddDialog(prescriptive);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove prescriptive">
                      <IconButton
                        onClick={() => {
                          handleDeleteDialog(prescriptive);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (prescriptivesData?.prescriptives?.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no prescriptives</Typography>}
      <Button variant="contained" onClick={() => handleOpenAddDialog()} sx={{ mt: 1 }}>
        +
      </Button>
    </Box>
  );
}
