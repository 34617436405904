import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { MUTATIONS } from "../../graphQL/mutations";
import { PortalFolder } from "../../models/PortalFolder";
import { DeletedFolderOutput } from "../../graphQL/outputModels/DeletedFolder.output";
import { DeleteFolderInput } from "../../graphQL/inputModels/deleteFolder.input";

interface DeleteFolderDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  folder: PortalFolder;
}

export default function DeleteFolderDialog({ open, onClose, folder }: DeleteFolderDialogProps) {
  //GRAPHQL
  const [deleteFolder, { loading: loadingDeleteFolder, data: deletedFolder }] = useMutation<DeletedFolderOutput, DeleteFolderInput>(MUTATIONS.DELETE_FOLDER);

  // HANDLERS
  const handleDelete = () => {
    deleteFolder({
      variables: {
        input: {
          folderId: folder.folderId!,
        },
      },
    });
  };

  // USEEFFECTS
  useEffect(() => {
    if (deletedFolder?.deleteFolder.actionStatus === ActionStatus.Success) {
      console.log("deletion success!");
      onClose(true);
      appNotification({
        severity: "success",
        message: `Successfully deleted '${deletedFolder.deleteFolder.folderName}'`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedFolder]);

  // RENDER
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth>
      <DialogTitle>
        Warning: Deleting '{folder.folderName}'
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>You are about to PERMANENTLY delete this folder and all of its contents. Are you sure you want to proceed? You cannot undo this action.</DialogContentText>
      </DialogContent>

      <DialogActions>
        {loadingDeleteFolder && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={loadingDeleteFolder}>
          Delete Folder
        </Button>
      </DialogActions>
    </Dialog>
  );
}
