import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed } from "powerbi-client";

interface EmbeddedReportComponentProps {
  embedUrl?: string;
  accessToken?: string;
  setReportCallback?: (pbiReport: Report) => void;
  setReportLoadedCallback: (loaded: boolean) => void;
}

export default function EmbeddedReportComponent({ embedUrl, accessToken, setReportCallback, setReportLoadedCallback }: EmbeddedReportComponentProps) {
  const reportSettings: models.ISettings = {
    bars: {
      actionBar: {
        visible: false,
      },
    },
    panes: {
      filters: {
        visible: false,
      },
      pageNavigation: {
        visible: true,
      },
    },
  };

  const reportConfig: models.IReportEmbedConfiguration = {
    type: "report",
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: accessToken,
    settings: reportSettings,
  };

  return (
    <PowerBIEmbed
      cssClassName="embedded-report-component"
      embedConfig={reportConfig}
      getEmbeddedComponent={(embedObject: Embed) => {
        const report = embedObject as Report;
        if (setReportCallback) setReportCallback(report);
        report.on("loaded", (e?: any, embeddedEntity?: Embed) => {
          console.log("report loaded");
          setReportLoadedCallback(true);
        });
      }}
    />
  );
}
