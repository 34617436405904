import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../auth/authConfig";
import { PortalToken } from '../models/PortalToken';

interface useMyPortalTokenContext {
    role?: string;
    company?: string;
    memberAccess?: string;
    portalMember?: string;
    firstName?: string;
    lastName?: string;
}

function useMyPortalToken(): useMyPortalTokenContext {
    const { accounts } = useMsal();
    const account = accounts.find(
        (a) => a.environment === msalConfig.auth.knownAuthorities![0]
    );
    let userClaims: PortalToken = account?.idTokenClaims as PortalToken;

    if (userClaims) {
        return {
            role: userClaims.extension_PortalRole,
            company: userClaims.extension_CompanyName,
            memberAccess: userClaims.extension_MemberAccess,
            portalMember: userClaims.extension_PortalMember,
            firstName: userClaims.extension_FirstName,
            lastName: userClaims.extension_LastName,
        }
    }
    return {

    } as useMyPortalTokenContext
}

export default useMyPortalToken;