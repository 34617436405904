import dayjs from "dayjs";
import { AssessmentComment, AssessmentCommentCustom } from "../../../models/AssessmentComment";

export const commentsMapper = {
    mapToCustom
}


function mapToCustom(list: AssessmentComment[]): AssessmentCommentCustom[] {
    var ret: AssessmentCommentCustom[] = [];
    list.forEach((v, i) => {
        var a = dayjs(v.createdDate);
        var b = dayjs(v.modifiedDate);
        var mappedC: AssessmentCommentCustom = {
            ...v,
            index: i,
            isModified: !a.isSame(b)
        };
        ret.push(mappedC);
    })

    console.log(ret)
    return ret;
}