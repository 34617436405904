import { Box, Button, Stack, Typography } from "@mui/material";
import FileInput from "../../FileStorage/FileInput";
import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MasterFilesOutput } from "../../../graphQL/outputModels/MasterFiles.output";
import { QUERIES } from "../../../graphQL/queries";
import useUploadMasterFileQuery from "../../../hooks/useUploadMasterFileQuery";
import { AddedMasterFileOutput } from "../../../graphQL/outputModels/AddedMasterFile.output";
import { ActionStatus, ActionStatusNumber } from "../../../models/ActionStatus";
import { NotificationUtils } from "../../../utils/notification.utils";
import { MasterFile } from "../../../models/MasterFile";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { DeletedMasterFileOutput } from "../../../graphQL/outputModels/DeletedMasterFile.output";
import { DeleteMasterFileInput } from "../../../graphQL/inputModels/deleteMasterFile.input";
import { MUTATIONS } from "../../../graphQL/mutations";

interface LogoBoxProps {
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
}

export default function LogoBox({ onShowDeleteDialog }: LogoBoxProps) {
  // SETUP
  // const [originalPictureBlobUrl, setOriginalPictureBlobUrl] = useState<string>();
  // const [pictureBlobUrl, setPictureBlobUrl] = useState<string>();
  const [masterLogo, setMasterLogo] = useState<MasterFile>();

  // HOOKS
  const { callApi: uploadMasterFile, data: dataUpload } = useUploadMasterFileQuery<AddedMasterFileOutput>();
  const [deleteMasterFile, { data: deletedMasterFile }] = useMutation<DeletedMasterFileOutput, DeleteMasterFileInput>(MUTATIONS.DELETE_MASTER_FILE);

  // GRAPHQL
  const [getMasterFiles, { loading, data: masterFilesData, refetch: refetchMasterFiles }] = useLazyQuery<MasterFilesOutput>(QUERIES.GET_MASTER_FILES, {
    fetchPolicy: "network-only",
  });

  // HANDLERS
  // const handleThumbnailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files) {
  //     const f: File = e.target.files[0];

  //     const options = {
  //       maxSizeMB: 0.5,
  //       maxWidthOrHeight: 700,
  //       useWebWorker: true,
  //     };

  //     try {
  //       const compressedFile = await imageCompression(f, options);
  //       // setPictureBlobUrl(URL.createObjectURL(compressedFile));
  //       setNewThumbnail(compressedFile);
  //     } catch (error) {
  //       console.error(error);
  //       // setPictureBlobUrl(URL.createObjectURL(f));
  //       setNewThumbnail(f);
  //     }
  //   }
  // };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const f: File = e.target.files[0];
      console.log("going to upload");
      // if (parentFolderId || currFolderContext?.folderId || currDir?.folderId) {
      // setMasterLoading(true);
      uploadMasterFile({
        file: f,
        isCoverImage: true,
        isDefault: true,
      });
      // }
    }
  };

  // USEEFFECTS
  useEffect(() => {
    getMasterFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("dataupload changed");
    if (dataUpload?.actionStatus === ActionStatusNumber.Success) {
      NotificationUtils.notifyAdd(dataUpload.actionStatus!, dataUpload.actionStatusMessage!);
      const f = masterFilesData?.masterFiles.find((f) => f.fileId === masterLogo?.fileId);
      if (f) {
        deleteMasterFile({
          variables: {
            input: {
              fileId: f?.fileId!,
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpload]);

  useEffect(() => {
    if (deletedMasterFile?.deleteMasterFile.actionStatus === ActionStatus.Success) {
      console.log("here");
      setMasterLogo(undefined);
      refetchMasterFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedMasterFile]);

  useEffect(() => {
    if (masterFilesData?.masterFiles && !masterLogo) {
      const defaultLogo = masterFilesData?.masterFiles.find((f) => f.isCoverImage && f.isDefault);
      setMasterLogo(defaultLogo);
      console.log(defaultLogo);
      // setPictureBlobUrl(defaultLogo?.blobUrl);
      // setOriginalPictureBlobUrl(defaultLogo?.blobUrl);
    }
    // else if (masterFilesData && originalPictureBlobUrl && dataUpload?.actionStatus === ActionStatusNumber.Success) {
    //   const gg = masterFilesData.masterFiles.find((f) => f.fileId === dataUpload.fileId);
    //   setPictureBlobUrl(gg?.blobUrl);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterFilesData, dataUpload]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Stack flexDirection="row" flexWrap="wrap">
        {masterFilesData?.masterFiles?.map((f) => (
          <Box
            key={`file-${f.fileId}`}
            sx={{
              boxShadow: f.blobUrl === masterLogo?.blobUrl ? "#f07428 0px 0px 7px 2px" : undefined,
            }}
          >
            <FolderFileButton
              file={f}
              handleFileClick={() => handleFileClick(f)}
              handleRightClick={openDocContext}
              // handleFileRename={handleSubmitRenameFile}
              // currRenaming={f.fileId === currFileContext?.fileId && currRenamingFile}
            />
          </Box>
        ))}
        <DocumentContextMenu
          open={docContextOpen}
          onClose={() => setAnchorElDocContext(null)}
          anchorEl={anchorElDocContext}
          isAdmin={isAdmin}
          fileContext={currFileContext}
          handleFileSetDefault={(file: MasterFile) => {}}
          handleFileSetPreview={(file: MasterFile) => {
            // setPictureBlobUrl(file.blobUrl);
          }}
          // handleFileChange={handleFileChange}
          // handleFileRename={() => setCurrRenamingFile(true)}
          // handleFileDownload={handleFileClick}
          // handleOpenFolder={handleFolderClick}
          // handleEditFolder={handleEditFolder}
          // handleDeleteFolder={handleDeleteFolder}
          handleDeleteFile={() => onShowDeleteDialog("logo", currFileContext!)}
        />
      </Stack> */}

      <Typography textAlign="start">Preview:</Typography>
      {!loading && masterLogo && (
        <Box
          sx={{
            position: "relative",
            m: "auto",
          }}
        >
          <Box
            component="img"
            sx={{
              position: "relative",
              height: "auto",
              maxHeight: 500,
              width: "auto",
              maxWidth: "100%",
              m: "auto",
              border: "1px solid #bbbbbb",
            }}
            alt={loading ? "loading" : "Preview"}
            src={loading ? "" : masterLogo.blobUrl}
          />
        </Box>
      )}

      <Stack direction="row" mt={2}>
        <FileInput onFileChange={handleFileChange} type="image" ml="auto">
          <Button variant="contained" color="orange" component="span">
            CHANGE IMAGE
          </Button>
        </FileInput>

        {/* <Button onClick={handleCancel} variant="outlined" color="orange" sx={{ ml: "auto", mr: 2 }}>
          Cancel
        </Button>

        <Button onClick={handleSubmit} disabled={originalPictureBlobUrl === pictureBlobUrl} variant="contained" color="orange">
          Set as default
        </Button> */}
      </Stack>
    </Box>
  );
}
