import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CompanyOutput } from "../../graphQL/outputModels/Company.output";
import { QUERIES } from "../../graphQL/queries";
import { PortalLink } from "../../models/PortalLink";
import { MemoizedRadioButton } from "../MemberDialog/CustomRadioButton";
import { PortalsOutput } from "../../graphQL/outputModels/Portals.output";
import { MUTATIONS } from "../../graphQL/mutations";
import { AddedLinkOutput } from "../../graphQL/outputModels/AddedLink.output";
import { AddLinkInput } from "../../graphQL/inputModels/addLink.input";
import { UpdateLinkInput } from "../../graphQL/inputModels/updateLink.input";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { UpdatedLinkOutput } from "../../graphQL/outputModels/UpdateLink.output";

interface AddEditLinkDialogProps {
  open: boolean;
  onClose: (refetch: boolean) => void;
  editingLink?: PortalLink;
}

export default function AddEditLinkDialog({ open, onClose, editingLink }: AddEditLinkDialogProps) {
  let defaultValues = {
    url: "https://",
    displayName: "",
    companyIds: [] as number[],
    allCompanies: false,
    portalIds: [] as number[],
    allPortals: false,
  };
  if (editingLink) {
    defaultValues = {
      url: editingLink.url ?? "",
      displayName: editingLink.displayName ?? "",
      companyIds: editingLink.companies?.map((c) => c.companyId!) ?? ([] as number[]),
      allCompanies: editingLink.allCompanies ?? false,
      portalIds: editingLink.portals?.map((p) => p.portalId!) ?? ([] as number[]),
      allPortals: editingLink.allPortals ?? false,
    };
  }

  // SETUP
  const [_formValues, _setFormValues] = useState(defaultValues);
  const formValues = useRef(_formValues);

  function setFormValues(e: any) {
    formValues.current = e;
    _setFormValues(e);
  }

  const formValidated =
    formValues.current.url.length > 4 &&
    formValues.current.displayName.length > 0 &&
    (formValues.current.allCompanies === true ? true : formValues.current.companyIds.length > 0) &&
    (formValues.current.allPortals === true ? true : formValues.current.portalIds.length > 0);

  // GRAPHQL
  const { loading: loadingPortals, data: portalData } = useQuery<PortalsOutput>(QUERIES.GET_PORTALS);
  const [getCompanies, { loading: loadingCompanies, data: companies }] = useLazyQuery<CompanyOutput>(QUERIES.GET_COMPANIES, {});
  const [addLink, { loading: loadingAddLink, data: addedLink }] = useMutation<AddedLinkOutput, AddLinkInput>(MUTATIONS.ADD_LINK);
  const [updateLink, { loading: loadingUpdateLink, data: updatedLink }] = useMutation<UpdatedLinkOutput, UpdateLinkInput>(MUTATIONS.UPDATE_LINK);

  // HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues.current,
      [name]: value,
    });
  };
  const handleCheckedChange = (e: any) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues.current,
      [name]: checked,
    });
  };
  const handleRadioChange = (newId: number) => {
    const alreadyIn = formValues.current.companyIds.some((c) => c === newId);
    let newList = [...formValues.current.companyIds];
    if (alreadyIn) newList = newList.filter((c) => c !== newId);
    else newList.push(newId);
    setFormValues({
      ...formValues.current,
      companyIds: [...newList],
    });
  };
  const handleSubmit = () => {
    if (!formValidated) return;
    console.log(formValues.current);
    if (editingLink) {
      console.log("updating link");
      updateLink({
        variables: {
          input: {
            linkId: editingLink.linkId!,
            url: formValues.current.url,
            displayName: formValues.current.displayName,
            companyIds: formValues.current.allCompanies ? [] : formValues.current.companyIds,
            portalIds: formValues.current.allPortals ? [] : formValues.current.portalIds,
            allCompanies: formValues.current.allCompanies,
            allPortals: formValues.current.allPortals,
          },
        },
      });
    } else {
      console.log("adding link");
      addLink({
        variables: {
          input: {
            url: formValues.current.url,
            displayName: formValues.current.displayName,
            companyIds: formValues.current.allCompanies ? [] : formValues.current.companyIds,
            portalIds: formValues.current.allPortals ? [] : formValues.current.portalIds,
            allCompanies: formValues.current.allCompanies,
            allPortals: formValues.current.allPortals,
          },
        },
      });
    }
  };

  // VARIABLES
  const portals = portalData?.portals ?? [];
  const loading = loadingPortals || loadingCompanies || loadingAddLink || loadingUpdateLink;

  // USEEFFECTS
  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const status = addedLink?.addLink.actionStatus;
    if (status) {
      if (status === ActionStatus.Success) {
        console.log("add link success");
        onClose(true);
        appNotification({ severity: "success", message: "Successfully added" });
      } else {
        console.log("add link fail");
        // onClose(true);
        appNotification({
          severity: "error",
          message: addedLink.addLink.actionStatusMessage ?? "There was an error",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedLink]);

  useEffect(() => {
    const status = updatedLink?.updateLink.actionStatus;
    if (status) {
      if (status === ActionStatus.Success) {
        console.log("updated link success");
        onClose(true);
        appNotification({
          severity: "success",
          message: "Successfully updated",
        });
      } else {
        console.log("update link fail");
        // onClose(true);
        appNotification({
          severity: "error",
          message: updatedLink.updateLink.actionStatusMessage ?? "There was an error",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedLink]);

  // USEMEMO
  const sortedCompanies = useMemo(() => {
    if (companies) {
      let g = [...companies.companies].sort((a, b) => a.companyName!.localeCompare(b.companyName!));
      return g;
    }
  }, [companies]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingLink ? "Editing Link" : "Add Link"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              id="displayName-input"
              name="displayName"
              label="Display Name"
              type="text"
              value={formValues.current.displayName}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField id="url-input" name="url" label="URL" type="text" value={formValues.current.url} onChange={handleInputChange} fullWidth required helperText="required" />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography id="addedit-link-member" component="span" sx={{ fontWeight: "bold" }}>
                Member *
              </Typography>
              <Checkbox
                name="allCompanies"
                checked={formValues.current.allCompanies}
                // value={formValues.current.allCompanies}
                onChange={handleCheckedChange}
                sx={{ ml: 3 }}
              />
              <Typography
                component="span"
                sx={{
                  fontStyle: "italic",
                  fontSize: "0.8em",
                  lineHeight: "0.8em",
                }}
              >
                Select all
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" maxHeight="30vh" aria-labelledby="addedit-link-company" overflow="auto">
              {sortedCompanies?.map((c) => (
                <MemoizedRadioButton
                  key={c.companyId}
                  checked={formValues.current.companyIds.some((x) => x === c.companyId)}
                  company={c}
                  handleRadioChange={handleRadioChange}
                  disabled={formValues.current.allCompanies}
                  useCheckbox
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth disabled={loadingPortals}>
              <InputLabel id="addEditLink-portals-label">{loadingPortals ? "Loading Portals..." : "Portals *"}</InputLabel>
              <Select
                labelId="addEditLink-portals-label"
                name="portalIds"
                label="Portals"
                multiple
                value={formValues.current.portalIds}
                onChange={handleInputChange}
                required
                renderValue={(selected) =>
                  selected
                    .map((s) => {
                      let selectedPortal = portals.find((p) => p.portalId! === s);
                      return selectedPortal?.portalName;
                    })
                    .join(", ")
                }
              >
                {portals.map((p, i) => (
                  <MenuItem key={i} value={p.portalId!}>
                    <Checkbox checked={formValues.current.portalIds.indexOf(p.portalId!) > -1} />
                    <ListItemText primary={p.portalName} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>required, multi-select</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
