import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { QUERIES } from "../../../graphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { AssessmentReportBoilerplatesOutput } from "../../../graphQL/outputModels/AssessmentReportBoilerplates.output";
import { AssessmentReportBoilerplate } from "../../../models/AssessmentReportBoilerplate";
import parse from "html-react-parser";
import { GetAssessmentReportBoilerplatesInput } from "../../../graphQL/inputModels/getAssessmentReportBoilerplates.input";

interface SiteAssessmentTableProps {
  onShowAddEditDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => void;
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
  assessReportType: AssessmentDropdownType;
}

export default function SiteAssessmentTable({ onShowAddEditDialog, onShowDeleteDialog, assessReportType }: SiteAssessmentTableProps) {
  // SETUP
  const assessReportId = assessReportType === "assessment" ? 1 : assessReportType === "scorecard" ? 2 : 0;

  // GRAPHQL
  const [getAssessmentReportBoilerplates, { loading, data: _assessmentReportBoilerplatesData }] = useLazyQuery<AssessmentReportBoilerplatesOutput, GetAssessmentReportBoilerplatesInput>(
    QUERIES.GET_ASSESSMENT_REPORT_BOILERPLATES,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        assessReportId: assessReportId,
      },
    }
  );

  // HANDLERS
  const handleOpenAddDialog = (boilerplate?: AssessmentReportBoilerplate) => {
    onShowAddEditDialog(assessReportType, boilerplate);
  };

  const handleDeleteDialog = (boilerplate: AssessmentReportBoilerplate) => {
    onShowDeleteDialog(assessReportType, boilerplate);
  };

  // USEMEMO
  const assessmentReportBoilerplatesData = useMemo(() => {
    return _assessmentReportBoilerplatesData?.assessmentReportBoilerplates
      .map((a) => {
        return { ...a, boilerplateName: a.boilerplateName?.substring(4) };
      })
      .sort((a) => a.boilerplateSort ?? 0);
  }, [_assessmentReportBoilerplatesData]);

  // USEEFFECTS
  useEffect(() => {
    getAssessmentReportBoilerplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 350px)"
        margin="auto"
        mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: 150 }}>
                Name
              </TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center" sx={{ width: 70 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              assessmentReportBoilerplatesData?.map((boilerplate, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word", verticalAlign: "top", paddingTop: "2em" }}>
                    <Box sx={{ fontWeight: "bold" }}>{boilerplate.boilerplateName}</Box>
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    <Box sx={{ maxHeight: 150, overflow: "auto" }}>{parse(boilerplate.boilerplateText ?? "")}</Box>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "top", paddingTop: "2em" }}>
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          handleOpenAddDialog(boilerplate);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                    {/* Hide 'remove' for now */}
                    {false && (
                      <Tooltip title="Remove">
                        <IconButton
                          onClick={() => {
                            handleDeleteDialog(boilerplate);
                          }}
                          color="default"
                          sx={{ p: "4px" }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (assessmentReportBoilerplatesData?.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no site assessment boilerplates</Typography>}

      {/* Hide 'add' for now */}
      {false && (
        <Button variant="contained" onClick={() => handleOpenAddDialog()} sx={{ mt: 1 }}>
          +
        </Button>
      )}
    </Box>
  );
}
