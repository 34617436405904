import { Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useMemo } from "react";

import appConfig from "../../appConfig";

interface FormatEditorProps {
  text?: string;
  placeholder?: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | undefined>;
  height?: number;
  toolbar?: "default" | "basic";
}

export default function FormatEditor({ text, placeholder, editorRef, height, toolbar = "default" }: FormatEditorProps) {
  // USEMEMO
  const getToolbar = useMemo(() => {
    if (toolbar === "basic") return "undo redo | bold italic | bullist numlist outdent indent | removeformat";
    return "undo redo | blocks | fontsize | bold italic forecolor | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | removeformat";
  }, [toolbar]);

  return (
    <Box width="100%">
      <Editor
        apiKey={appConfig.tinymce_apikey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={text}
        init={{
          height: height ?? 500,
          width: "100%",
          menubar: false,
          placeholder: placeholder,
          plugins: [
            // "advlist",
            // "autolink",
            "lists",
            // "link",
            // "image",
            // "charmap",
            // "preview",
            // "anchor",
            // "searchreplace",
            // "visualblocks",
            // "code",
            // "fullscreen",
            // "insertdatetime",
            // "media",
            // "table",
            // "code",
            "help",
            "wordcount",
          ],
          toolbar: getToolbar,
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </Box>
  );
}
