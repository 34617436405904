import { Typography } from "@mui/material";

export default function Footer() {
  return (
    <Typography sx={{ mt: "auto" }}>
      For Member portal access, content, and report issues or questions, please
      email us at portalhelp@caringcomm.org.
    </Typography>
  );
}
