import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { SignInButton } from "../Auth/SignInButton";
import Logo from "./Logo";

export default function PublicNav() {
  return (
    <AppBar className="topnav" position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{ height: "100%" }} disableGutters>
          <Logo />

          <Box display="flex" justifyContent="flex-end" sx={{ flexGrow: 1, height: "100%" }}>
            <SignInButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
