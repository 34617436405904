import { Box, CircularProgress, IconButton, LinearProgress, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { AssessmentCustom } from "../../models/Assessment";
import useUser from "../../hooks/useUser";
import { useState, useMemo } from "react";
import DeleteAssessmentDialog from "./DeleteAssessmentDialog";
import DownloadReportDialog from "./DownloadReportDialog";
import { useNavigate } from "react-router-dom";
import useExportAssessmentReportQuery from "../../hooks/useAssessmentReportQuery";

interface AssessmentListComponentProps {
  assessment: AssessmentCustom;
  refetchList: () => void;
}
export default function AssessmentListComponent({ assessment, refetchList }: AssessmentListComponentProps) {
  // SETUP
  const { isAdmin } = useUser();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [downloadReportDialogOpen, setDownloadReportDialogOpen] = useState(false);

  // CUSTOM HOOKS
  const { execute: executeExport, tasks: exportTasks, loading: callingExport } = useExportAssessmentReportQuery();

  // HANDLERS
  const onDownloadClicked = () => {
    if (isAdmin) {
      setDownloadReportDialogOpen(true);
    } else {
      onFetchReport(true);
    }
  };
  const onFetchReport = (isPDF: boolean) => {
    executeExport({
      assessmentId: assessment.assessmentId!,
      isCreatePDF: isPDF,
      assessmentName: assessment.assessmentName!,
    });
  };

  const onCloseDeleteDialog = (refetch: boolean) => {
    setDeleteDialogOpen(false);
    if (refetch) refetchList();
  };

  // USEMEMO
  const alreadyProcessingPDF = useMemo(() => {
    return exportTasks.some((t) => t.assessmentId === assessment.assessmentId && t.extension === "pdf");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportTasks, assessment.assessmentId, callingExport]);

  const alreadyProcessingDoc = useMemo(() => {
    return exportTasks.some((t) => t.assessmentId === assessment.assessmentId && t.extension === "doc");
  }, [exportTasks, assessment.assessmentId]);

  // RENDER
  return (
    <>
      {/* Dialogs */}
      {deleteDialogOpen && <DeleteAssessmentDialog open={true} onClose={onCloseDeleteDialog} assessment={assessment} />}
      {downloadReportDialogOpen && (
        <DownloadReportDialog
          open={true}
          onClose={() => setDownloadReportDialogOpen(false)}
          assessment={assessment!}
          alreadyProcessingPDF={alreadyProcessingPDF}
          alreadyProcessingDoc={alreadyProcessingDoc}
          onFetchReport={onFetchReport}
        />
      )}

      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="center">
          {assessment.assessmentTypeId === 5 ? (
            <Tooltip title="Scorecard">
              <ReceiptLongOutlinedIcon color="tertiary" />
            </Tooltip>
          ) : (
            <Tooltip title="Visit">
              <FactCheckOutlinedIcon color="cyan" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
          {assessment.assessmentName}
        </TableCell>
        <TableCell align="center">{assessment.memberName ?? "-"}</TableCell>
        <TableCell align="center">
          <Box>
            <Typography>{`${assessment.totalAnswers!} / ${assessment.totalQuestions!}`}</Typography>
            <LinearProgress variant="determinate" value={(100 * assessment.totalAnswers!) / assessment.totalQuestions!} sx={{ height: 6, borderRadius: 3 }} />
            {/* <Typography fontSize="small">{`${(100 * assessment.totalAnswers!) / assessment.totalQuestions!}%`}</Typography> */}
          </Box>
        </TableCell>
        <TableCell align="center">{assessment.assessmentTypeId === 5 ? assessment.assessmentDateDate?.year() : assessment.assessmentDateDate?.format("M/DD/YYYY") ?? "-"}</TableCell>
        <TableCell align="center">
          <Tooltip title={isAdmin ? "Download DOC or PDF" : "Download PDF"}>
            {!isAdmin && (alreadyProcessingPDF || callingExport) ? (
              <Box sx={{ p: "6px", display: "inline-flex" }}>
                <CircularProgress size="1em" />
              </Box>
            ) : (
              <IconButton sx={{ p: "4px" }} color="default" onClick={onDownloadClicked}>
                <DownloadIcon />
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => navigate(assessment.assessmentId!.toString())} sx={{ p: "4px" }} color="default">
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          {isAdmin && (
            <Tooltip title="Remove">
              <IconButton onClick={() => setDeleteDialogOpen(true)} sx={{ p: "4px" }} color="default">
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
