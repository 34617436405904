import { Accordion, AccordionSummary, AccordionDetails, Box, Button, CircularProgress, LinearProgress, Paper, Radio, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useEffect, useMemo, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as React from "react";
import useUser from "../../../hooks/useUser";
import { AssessmentQuestion, AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";
import { QUERIES } from "../../../graphQL/queries";
import parse from "html-react-parser";
import { AssessmentComment } from "../../../models/AssessmentComment";
import QuestionCommentComponent from "./QuestionCommentComponent";
import { AddedAssessmentCommentOutput } from "../../../graphQL/outputModels/AddedAssessmentComment.output";
import { AddAssessmentCommentInput } from "../../../graphQL/inputModels/addAssessmentComment.input";
import { MUTATIONS } from "../../../graphQL/mutations";
import { AssessmentQuestionCommentsOutput } from "../../../graphQL/outputModels/AssessmentQuestionComment.output";
import { GetAssessmentQuestionCommentsInput } from "../../../graphQL/inputModels/getAssessmentQuestionComments.input";
import { ActionStatus } from "../../../models/ActionStatus";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { AssessmentOutput } from "../../../graphQL/outputModels/Assessment.output";
import { GetAssessmentInput } from "../../../graphQL/inputModels/getAssessment.input";
import { commentsMapper } from "../mappers/commentsMapper";
import { Editor as TinyMCEEditor } from "tinymce";
import FormatEditor from "../../FormatEditor/FormatEditor";

type MemQType = {
  q: AssessmentQuestionCustom;
  i: number;
  handleResponseChange: (q: AssessmentQuestionCustom, responseId: number) => void;
  handleExpandFileAttachments: (q: AssessmentQuestionCustom) => void;
  handleToggleRecommendation: (q: AssessmentQuestionCustom) => void;
};

export const MemoQuestionComponent = React.memo(
  ({ q, i, handleResponseChange, handleExpandFileAttachments, handleToggleRecommendation }: MemQType) => {
    // SETUP
    const [expanded, setExpanded] = useState(false);
    const { isAdmin } = useUser();
    const commentEditorRef = useRef<TinyMCEEditor>();

    // GRAPHQL
    const [getComments, { loading: loadingGetComments, data: commentsData }] = useLazyQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>(
      QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
      {
        fetchPolicy: "cache-and-network",
        variables: {
          assessmentQuestionId: q.assessmentQuestionId!,
        },
      }
    );
    const [addComment, { loading: loadingAddComment, data: addedCommentData }] = useMutation<AddedAssessmentCommentOutput, AddAssessmentCommentInput>(MUTATIONS.ADD_ASSESSMENT_COMMENT);

    // HANDLERS
    const handleAddComment = (q: AssessmentQuestionCustom) => {
      if (!commentEditorRef.current?.getContent().length) return;
      addComment({
        variables: {
          input: {
            assessmentId: q.assessmentId!,
            assessmentQuestionId: q.assessmentQuestionId!,
            assessmentCommentDescription: commentEditorRef.current.getContent(),
          },
        },
        update: (cache, { data }) => {
          console.log(data);
          if (data?.addAssessmentComment.actionStatus === ActionStatus.Success) {
            const existingComments = cache.readQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
              query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
              variables: {
                assessmentQuestionId: q.assessmentQuestionId!,
              },
            });
            console.log(existingComments);
            console.log("writing");
            const newComments: AssessmentComment[] = [{ ...data.addAssessmentComment }, ...(existingComments?.assessmentCommentsByQuestionId ?? [])];
            cache.writeQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
              query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
              variables: {
                assessmentQuestionId: q.assessmentQuestionId!,
              },
              data: {
                assessmentCommentsByQuestionId: newComments,
              },
            });

            const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
              query: QUERIES.GET_ASSESSMENT,
              variables: {
                assessmentId: q.assessmentId!,
              },
            });
            if (existingAssessment) {
              var newQuestions = existingAssessment.assessment.assessmentQuestions!.map((q): AssessmentQuestion => {
                if (q.assessmentQuestionId === data.addAssessmentComment.assessmentQuestionId) {
                  return {
                    ...q,
                    assessmentCommentCount: q.assessmentCommentCount! + 1,
                  };
                }
                return q;
              });
              cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
                query: QUERIES.GET_ASSESSMENT,
                variables: {
                  assessmentId: q.assessmentId!,
                },
                data: {
                  assessment: {
                    ...existingAssessment.assessment,
                    assessmentQuestions: [...newQuestions],
                  },
                },
              });
            }
          }
        },
      });
    };

    // USEMEMOS
    const sortedComments = useMemo(() => {
      if (!commentsData?.assessmentCommentsByQuestionId) return [];
      var mappedComments = commentsMapper.mapToCustom(commentsData.assessmentCommentsByQuestionId);
      var _sortedComments = [...mappedComments];
      _sortedComments.sort((a, b) => {
        return new Date(b.createdDate!).getTime() - new Date(a.createdDate!).getTime();
      });
      return _sortedComments;
    }, [commentsData?.assessmentCommentsByQuestionId]);

    // USEEFFECTS
    useEffect(() => {
      if (expanded) {
        getComments();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    // USEEFFECTS
    useEffect(() => {
      if (addedCommentData?.addAssessmentComment?.actionStatus === ActionStatus.Success) {
        console.log("add success!");
        appNotification({ severity: "success", message: "Successfully added" });
        commentEditorRef.current?.setContent("");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedCommentData]);

    const chosenResponse = useMemo(() => {
      var r = q.assessmentQuestionResponses?.find((qr) => qr.assessmentResponseId === q.selectedAssessmentQuestionResponseId);
      return r;
    }, [q.assessmentQuestionResponses, q.selectedAssessmentQuestionResponseId]);

    // RENDER
    return (
      <Paper elevation={2} sx={{ minHeight: 100, background: "#EFEFEF", borderRadius: 1, p: 1, textAlign: "start" }}>
        <Box>
          <Typography component="span" fontWeight="bold">
            {q.assessmentQuestionNumber}
          </Typography>
          <Typography component="span" ml={1} sx={{ "> p": { display: "inline" } }}>
            {parse(q.assessmentQuestionDescription!)}
          </Typography>
        </Box>

        {/* Responses */}
        <Box mt={2} mb={2}>
          {q.assessmentQuestionResponses?.map((qr) => {
            return (
              <Box
                key={qr.assessmentResponseId}
                sx={{
                  width: "fit-content",
                  "&:hover": {
                    "*": {
                      fontWeight: isAdmin ? "bold" : "initial",
                    },
                  },
                }}
              >
                <Radio
                  inputProps={{
                    "aria-label": qr.assessmentResponseDescription,
                    "aria-checked": qr.assessmentResponseId === q.selectedAssessmentQuestionResponseId,
                  }}
                  sx={{ p: "2px" }}
                  color="info"
                  checked={qr.assessmentResponseId === q.selectedAssessmentQuestionResponseId}
                  onClick={() => handleResponseChange(q, qr.assessmentResponseId!)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleResponseChange(q, qr.assessmentResponseId!);
                  }}
                  disabled={!isAdmin}
                />
                <Button
                  sx={{
                    pl: 1,
                    pt: 0,
                    pb: 0,
                    textTransform: "initial",
                    minWidth: "initial",
                  }}
                  color="info"
                  onClick={() => handleResponseChange(q, qr.assessmentResponseId!)}
                  disabled={!isAdmin}
                >
                  <Typography color="black">{qr.assessmentResponseDescription}</Typography>
                </Button>
              </Box>
            );
          })}
        </Box>

        <Button variant="contained" onClick={() => handleExpandFileAttachments(q)}>
          {`File Attachments (${q.assessmentAttachments?.length ?? 0})`}
        </Button>
        <Button
          variant="contained"
          disabled={!((q.assessmentNonCompliantRecommendation?.length ?? 0) > 0) || chosenResponse?.ratingCompliant || (q.isExtraCredit && !((chosenResponse?.ratingPoints ?? 0) > 0))}
          onClick={() => handleToggleRecommendation(q)}
          sx={{ ml: 4 }}
        >
          View Recommendation
        </Button>

        {/* Comments */}
        <Accordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)} sx={{ background: "none", mt: 2, "&.Mui-expanded": { mt: 1 } }}>
          <AccordionSummary sx={{ justifyContent: "center", "&.Mui-expanded": { minHeight: 48 }, ".MuiAccordionSummary-content.Mui-expanded": { m: 1 } }}>
            <Typography ml="auto" mr="auto" sx={{ color: (theme) => theme.palette.info.main }}>
              {expanded ? "Hide Comments" : `Add or Show comments (${q.assessmentCommentCount!})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {loadingGetComments ? (
              <Stack alignItems="center" justifyContent="center" sx={{ height: 90, width: "100%" }}>
                <Typography>Loading comments...</Typography>
                <LinearProgress variant="indeterminate" color="secondary" sx={{ width: 200 }} />
              </Stack>
            ) : (
              <Box>
                <Paper sx={{ mt: 1, pl: 1, pt: 0.5, pb: 0.5, pr: 1, display: "flex", alignItems: "center" }}>
                  <FormatEditor editorRef={commentEditorRef} height={180} toolbar="basic" placeholder="Add a comment" />
                  <Stack direction="row" width={32} justifyContent="center">
                    {loadingAddComment ? (
                      <CircularProgress size="1em" />
                    ) : (
                      <Tooltip title="Save comment">
                        <IconButton onClick={() => handleAddComment(q)} color="default" sx={{ p: "4px" }}>
                          <SaveOutlinedIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Paper>
                {sortedComments?.map((c, i) => {
                  return <QuestionCommentComponent key={c.assessmentCommentId} q={q} c={c} />;
                })}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  },
  (prevProps, nextProps) => {
    var ret = true;
    ret = ret && prevProps.q.assessmentQuestionId === nextProps.q.assessmentQuestionId;
    ret = ret && prevProps.q.assessmentComments.length === nextProps.q.assessmentComments.length;
    ret = ret && prevProps.q.assessmentAttachments?.length === nextProps.q.assessmentAttachments?.length;
    // ret = ret && prevProps.q.areCommentsExpanded === nextProps.q.areCommentsExpanded;
    // ret = ret && prevProps.q.newComment === nextProps.q.newComment;
    // ret = ret && prevProps.q.newCommentLoading === nextProps.q.newCommentLoading;
    ret = ret && prevProps.q.assessmentCommentCount === nextProps.q.assessmentCommentCount;
    ret = ret && prevProps.q.selectedAssessmentQuestionResponseId === nextProps.q.selectedAssessmentQuestionResponseId;
    ret = ret && prevProps.q.assessmentNonCompliantRecommendation === nextProps.q.assessmentNonCompliantRecommendation;
    // if (prevProps.i === 0) {
    //   console.log(prevProps.q);
    //   console.log(nextProps.q);
    // }
    if (!ret) return ret;
    // for (var i = 0; i < prevProps.q.assessmentComments.length; i++) {
    //   var pp = prevProps.q.assessmentComments[i];
    //   var np = nextProps.q.assessmentComments[i];
    //   ret = ret && pp.assessmentCommentDescription === np.assessmentCommentDescription;
    //   ret = ret && pp.proposedComment === np.proposedComment;
    //   ret = ret && pp.isEditing === np.isEditing;
    //   if (!ret) return ret;
    // }

    // console.log(`${prevProps.i}: ${ret}`);
    return ret;
  }
);
