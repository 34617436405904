import { Divider, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { AssessmentParticipant } from "../../../models/AssessmentParticipant";
import { AssessmentSiteVisitDocumentSelection } from "../../../models/AssessmentSiteVisitDocumentSelection";

interface Step3Props {
  assessmentType: string;
  memberName: string;
  dateStr: string;
  performedByName: string;
  participants: AssessmentParticipant[];
  documents: AssessmentSiteVisitDocumentSelection[];
  isSuccessful: boolean;
}

export default function Step3({ assessmentType, memberName, dateStr, performedByName, participants, documents, isSuccessful }: Step3Props) {
  const calcAssessmentName = `${assessmentType} ${memberName} ${dateStr}`;

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={12}>
        <Divider sx={{ mb: 2 }} />

        {isSuccessful ? (
          <>
            <Typography variant="h6" textAlign="center" color="green">
              Success!
            </Typography>
            <Typography variant="body2" textAlign="center" fontStyle="italic">
              What would you like to do?
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" textAlign="center" fontWeight="bold">
              Confirm your information
            </Typography>
            <Typography variant="body2" textAlign="center" fontStyle="italic">
              You can update these later (except *)
            </Typography>
          </>
        )}

        <Divider sx={{ mt: 2, mb: 2 }} />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="end" fontWeight="bold">
          Type
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{assessmentType}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="end" fontWeight="bold">
          Date
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{dateStr}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="end" fontWeight="bold">
          Member
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{memberName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color={(theme) => theme.palette.text.secondary} textAlign="end" fontStyle="italic" fontWeight="bold">
          Calculated Assessment Name *
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color={(theme) => theme.palette.text.secondary} fontStyle="italic">
          {calcAssessmentName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="end" fontWeight="bold">
          Performed By
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{performedByName}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
        <Divider />
      </Grid>
      {assessmentType !== "Year-End Scorecard" && (
        <>
          <Grid item xs={6}>
            <Typography textAlign="end" fontWeight="bold">
              Participants
            </Typography>
          </Grid>
          {participants.length > 0 ? (
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  {participants.map((p, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell sx={{ width: "50%", textAlign: "end" }}>{p.participantName}</TableCell>
                        <TableCell sx={{ width: "50%" }}>{p.participantTitle}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography>N/A</Typography>
            </Grid>
          )}
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Typography textAlign="end" fontWeight="bold">
              Files
            </Typography>
          </Grid>
          {documents.length > 0 ? (
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  {documents.map((d, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "center" }}>{d.documentDescription}</TableCell>
                        <TableCell sx={{ width: 40 }}>{d.reviewSelection}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          ) : (
            <Grid item xs={6} sx={{ mt: 2 }}>
              <Typography>N/A</Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
