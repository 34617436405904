import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useMemo, useRef } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { useMutation, useQuery } from "@apollo/client";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { QuestionResponse } from "../../../models/QuestionResponse";
import { QuestionResponsesOutput } from "../../../graphQL/outputModels/QuestionResponse.output";
import { Question } from "../../../models/Question";
import { PrescriptivesOutput } from "../../../graphQL/outputModels/Prescriptives.output";
import FormatEditor from "../../FormatEditor/FormatEditor";
import { Editor as TinyMCEEditor } from "tinymce";
import { AddedQuestionOutput } from "../../../graphQL/outputModels/AddedQuestion.output";
import { AddQuestionInput } from "../../../graphQL/inputModels/addQuestion.input";
import { QuestionsOutput } from "../../../graphQL/outputModels/Questions.output";
import { NotificationUtils } from "../../../utils/notification.utils";
import { UpdatedQuestionOutput } from "../../../graphQL/outputModels/UpdatedQuestion.output";
import { UpdateQuestionInput } from "../../../graphQL/inputModels/updateQuestion.input";

interface AddEditQuestionRegistrationDialogProps {
  open: boolean;
  editingQuestion?: Question;
  onClose: (refetch: boolean) => void;
}

export default function AddEditQuestionRegistrationDialog({ open, onClose, editingQuestion }: AddEditQuestionRegistrationDialogProps) {
  // SETUP
  const descEditorRef = useRef<TinyMCEEditor>();
  const recEditorRef = useRef<TinyMCEEditor>();

  let defaultValues = {
    responseId: 0,
    questionDescription: "",
    questionNumber: "",
    prescriptiveId: null as number | null,
    ratingPoints: 0 as number | null,
    isExtraCredit: false as boolean | null,
    isDefault: false,
    responses: [] as QuestionResponse[],
    // potentialResponses: [] as QuestionResponse[],
    recommendation: "" as string | undefined,
  };
  if (editingQuestion) {
    // var rp = editingQuestion.questionResponses?.find((qr) => qr.assessmentResponseId === editingQuestion.selectedAssessmentQuestionResponseId);
    defaultValues = {
      responseId: editingQuestion?.questionId!,
      questionDescription: editingQuestion?.questionDescription!,
      questionNumber: editingQuestion?.questionNumber!.substring((editingQuestion.prescriptive?.prescriptiveNumber?.toLocaleString().length ?? 0) + 1),
      prescriptiveId: editingQuestion.prescriptiveId!,
      ratingPoints: 0,
      isExtraCredit: editingQuestion?.isExtraCredit ?? null,
      isDefault: editingQuestion?.isDefault!,
      responses: editingQuestion.questionResponses ?? [],
      // potentialResponses: editingQuestion.questionResponses ?? [],
      recommendation: editingQuestion.nonCompliantRecommendation,
    };
  }
  const [formValues, setFormValues] = useState(defaultValues);
  const formValidated = formValues.questionNumber.length > 0 && formValues.responses.length > 0;
  const [anchorElPortal, setAnchorElPortal] = useState<null | HTMLElement>(null);

  const [_potentialResponses, _setPotentialResponses] = useState<QuestionResponse[]>([...(editingQuestion?.questionResponses ?? [])]);
  const potentialResponsesRef = useRef(_potentialResponses);
  function setPotentialResponses(e: QuestionResponse[]) {
    potentialResponsesRef.current = e;
    _setPotentialResponses(e);
  }

  // GRAPHQL
  const [addQuestionResponse, { loading: loadingAddQuestion, data: addedQuestion }] = useMutation<AddedQuestionOutput, AddQuestionInput>(MUTATIONS.ADD_QUESTION);

  const [updateQuestion, { loading: loadingUpdateQuestion, data: updatedQuestion }] = useMutation<UpdatedQuestionOutput, UpdateQuestionInput>(MUTATIONS.UPDATE_QUESTION);

  const { loading: loadingPrescriptives, data: prescriptiveData } = useQuery<PrescriptivesOutput>(QUERIES.GET_PRESCRIPTIVES, {
    variables: {
      fetch: "network-only",
    },
  });
  const { loading: loadingQuestionResponses, data: questionResponsesData } = useQuery<QuestionResponsesOutput>(QUERIES.GET_QUESTION_RESPONSES, {
    variables: {
      fetch: "network-only",
    },
  });

  // VARIABLES
  const loading = loadingAddQuestion || loadingUpdateQuestion || loadingPrescriptives || loadingQuestionResponses;

  // HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleSubmit = (event: any) => {
    if (!formValidated) return;
    console.log(formValues);
    if (editingQuestion) {
      console.log("updating");
      updateQuestion({
        variables: {
          input: {
            questionId: editingQuestion.questionId!,
            questionDescription: descEditorRef.current?.getContent() ?? "",
            nonCompliantRecommendation: recEditorRef.current?.getContent() ?? "",
            isDefault: Boolean(formValues.isDefault),
            isExtraCredit: Boolean(formValues.isExtraCredit),
            prescriptiveId: +formValues.prescriptiveId!,
            questionNumber: `${formValues.prescriptiveId}.${formValues.questionNumber}`,
            responseIds: formValues.responses.map((r) => r.responseId!),
          },
        },
        update: (cache, { data }) => {
          if (data?.updateQuestion.actionStatus === ActionStatus.Success) {
            const existingQuestions = cache.readQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS });
            if (existingQuestions) {
              const newQuestions = existingQuestions.questions.map((q: Question) => {
                if (q.questionId === data?.updateQuestion.questionId) {
                  return {
                    ...q,
                    ...data!.updateQuestion,
                    assessmentQuestionResponses: [...questionResponsesData!.questionResponses.filter((qr) => data?.updateQuestion.responseIds?.includes(qr.responseId!))],
                    assessmentPrescriptive: { ...prescriptiveData?.prescriptives.find((p) => p.prescriptiveId === data?.updateQuestion.prescriptiveId) },
                  };
                } else {
                  return q;
                }
              });
              cache.writeQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS, data: { questions: newQuestions } });
            }
          }
        },
      });
    } else {
      console.log("adding");
      addQuestionResponse({
        variables: {
          input: {
            questionDescription: descEditorRef.current?.getContent() ?? "",
            nonCompliantRecommendation: recEditorRef.current?.getContent() ?? "",
            isDefault: Boolean(formValues.isDefault),
            isExtraCredit: Boolean(formValues.isExtraCredit),
            prescriptiveId: +formValues.prescriptiveId!,
            questionNumber: `${formValues.prescriptiveId}.${formValues.questionNumber}`,
            responseIds: formValues.responses.map((r) => r.responseId!),
          },
        },
        update: (cache, { data }) => {
          if (data?.addQuestion.actionStatus === ActionStatus.Success) {
            const existingQuestions = cache.readQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS });
            if (existingQuestions) {
              var newQuestion: Question = {
                ...data?.addQuestion,
                questionResponses: [...questionResponsesData!.questionResponses.filter((qr) => data?.addQuestion.responseIds?.includes(qr.responseId!))],
                prescriptive: { ...prescriptiveData?.prescriptives.find((p) => p.prescriptiveId === data?.addQuestion.prescriptiveId) },
              };
              const newQuestionResponses = [...existingQuestions.questions, { ...newQuestion }];
              cache.writeQuery<QuestionsOutput>({ query: QUERIES.GET_QUESTIONS, data: { questions: newQuestionResponses } });
            }
          }
        },
      });
    }
  };

  const handleQuestionResponseChange = (qr: QuestionResponse, checked: boolean) => {
    if (checked) {
      setPotentialResponses([...potentialResponsesRef.current, qr]);
    } else {
      var toRemove = potentialResponsesRef.current.findIndex((c) => c.responseId === qr.responseId);
      var newList = [...potentialResponsesRef.current];
      newList.splice(toRemove, 1);
      setPotentialResponses(newList);
    }
  };

  // USEMEMOS
  const memoChosenQuestionResponses = useMemo(() => {
    var l = [...formValues.responses];
    l.sort((a, b) => (b.ratingPoints ?? -1) - (a.ratingPoints ?? -1));
    var newList = l.map((qr) => {
      return { id: qr.responseId!, question: qr.responseDescription!, points: qr.ratingPoints ?? "N/A" };
    });
    return newList;
  }, [formValues.responses]);

  const calculatedQuestionNumber = useMemo(() => {
    var p = prescriptiveData?.prescriptives.find((p) => p.prescriptiveId === formValues.prescriptiveId);
    return `${p?.prescriptiveNumber ?? ""}.${formValues.questionNumber ?? ""}`;
  }, [prescriptiveData, formValues.prescriptiveId, formValues.questionNumber]);

  const memoProposedQuestionResponses = useMemo((): QuestionResponse[] => {
    if (!questionResponsesData?.questionResponses) return [];
    var l = [...questionResponsesData?.questionResponses];
    l.sort((a, b) => (b.ratingPoints ?? -1) - (a.ratingPoints ?? -1));
    return l;
  }, [questionResponsesData?.questionResponses]);

  // USEEFFECTS
  useEffect(() => {
    console.log("addedQuestion changed");
    console.log(addQuestionResponse);
    NotificationUtils.notifyAdd(addedQuestion?.addQuestion.actionStatus!, addedQuestion?.addQuestion.actionStatusMessage!, () => onClose(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedQuestion]);

  useEffect(() => {
    console.log("updatedQuestion changed");
    console.log(updatedQuestion);
    NotificationUtils.notifyAdd(updatedQuestion?.updateQuestion.actionStatus!, updatedQuestion?.updateQuestion.actionStatusMessage!, () => onClose(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedQuestion]);

  //

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ mt: 4, ".MuiPaper-root": { maxWidth: 845 } }}>
      <DialogTitle>
        {editingQuestion ? "Editing Question" : "Add Question"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ maxHeight: "65vh", overflow: "auto" }}>
        <Stack>
          <Stack direction="row" spacing={2}>
            <FormControl disabled={loading} sx={{ width: 440 }}>
              <InputLabel id="prescriptive-label" required>
                Prescriptive
              </InputLabel>
              <Select labelId="prescriptive-label" name="prescriptiveId" label="Prescriptive" value={formValues.prescriptiveId ?? ""} onChange={handleInputChange} required>
                {prescriptiveData?.prescriptives?.map((p) => {
                  return (
                    <MenuItem key={p.prescriptiveId!} value={p.prescriptiveId!}>
                      <Tooltip title={`${p.prescriptiveNumber} - ${p.prescriptiveDescription}`} placement="right">
                        <Typography width={400} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                          {p.prescriptiveNumber} - {p.prescriptiveDescription}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>required</FormHelperText>
            </FormControl>
            <Typography mt={"16px !important"}>+</Typography>
            <TextField
              id="questionNumber-input"
              name="questionNumber"
              label="Number"
              type="text"
              value={formValues.questionNumber}
              onChange={handleInputChange}
              required
              helperText="required"
              sx={{ width: 120 }}
            />

            <Typography mt={"16px !important"}>=</Typography>
            <TextField name="questionNumber" label="Display" type="text" value={calculatedQuestionNumber} disabled helperText="calculated" sx={{ width: 115 }} />
          </Stack>

          <FormGroup sx={{ mt: 2, width: "fit-content" }}>
            <FormControlLabel
              control={<Checkbox id="isExtraCredit-checkbox" name="isExtraCredit" checked={formValues.isExtraCredit ?? false} onChange={handleCheckBoxChange} />}
              label="Is Extra Credit"
            />
          </FormGroup>
          <Divider />

          <FormLabel sx={{ mt: 3, mb: 1 }} required>
            Question:
          </FormLabel>
          <FormatEditor text={formValues.questionDescription} editorRef={descEditorRef} height={200} />

          <Divider sx={{ mt: 3 }} />

          <FormLabel sx={{ mt: 3, mb: 1 }}>Non-compliant Recommendation:</FormLabel>
          <FormatEditor text={formValues.recommendation} editorRef={recEditorRef} height={200} />

          <Divider sx={{ mt: 3 }} />

          <InputLabel sx={{ mt: 3, mb: 1 }} required>
            Responses:
          </InputLabel>
          <Stack spacing={2} alignItems="center" justifyContent="center" pr={2} pl={2}>
            <Stack spacing={1} width={300}>
              {/* {memoQuestionResponses.length > 0 && <DraggableContainer list={memoQuestionResponses} handleDelete={handleDelete} onMoved={handleResponsesMoved} />} */}
              {/* <DraggableContainer list={memoQuestionResponses} handleDelete={handleDelete} onMoved={handleResponsesMoved} /> */}
              {memoChosenQuestionResponses.map((m) => {
                return (
                  <Paper key={m.id} sx={{ p: 1 }} elevation={5}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography>{m.question}</Typography>
                      <Typography fontWeight="bold">{m.points}</Typography>
                    </Stack>
                  </Paper>
                );
              })}
            </Stack>
            <Button variant="contained" sx={{ width: 200, fontSize: 12, height: 30 }} onClick={(e) => setAnchorElPortal(e.currentTarget)}>
              Update Responses
            </Button>

            <Box>
              <Menu
                // sx={{ mt: "45px" }}
                // className="topnav-menu"
                anchorEl={anchorElPortal}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(anchorElPortal)}
                MenuListProps={{
                  onMouseLeave: () => {
                    setAnchorElPortal(null);
                    setFormValues({ ...formValues, responses: [...potentialResponsesRef.current] });
                  },
                }}
              >
                <FormGroup sx={{ maxHeight: 400, overflow: "auto", flexWrap: "initial" }}>
                  {memoProposedQuestionResponses.map((q) => {
                    return (
                      <FormControlLabel
                        key={q.responseId!}
                        control={
                          <Checkbox
                            value={q.responseId}
                            checked={potentialResponsesRef.current.findIndex((r) => r.responseId === q.responseId) >= 0}
                            onChange={(e, c) => handleQuestionResponseChange(q, c)}
                          />
                        }
                        label={`${q.responseDescription!} [${q.ratingPoints ?? "N/A"}]`}
                        sx={{ m: 0, pl: 1, pr: 1, ":hover": { background: (theme) => theme.palette.orange.lighter } }}
                      />
                    );
                  })}
                </FormGroup>
              </Menu>
            </Box>
          </Stack>

          {/* <Stack direction="row" alignItems="center">
            <FormControl>
              <FormLabel id="rating-radio-buttons-group-label">Compliance</FormLabel>
              <RadioGroup aria-labelledby="rating-radio-buttons-group-label" defaultValue={formValues.isExtraCredit ?? ""} name="rating-radio-buttons-group" onChange={handleRadioChange}>
                <FormControlLabel value={true} control={<Radio id="isExtraCredit-radio" name="isExtraCredit" />} label="Compliant" />
                <FormControlLabel value={false} control={<Radio id="ratingNonCompliance-radio" name="isExtraCredit" />} label="Non-Compliant" />
                <FormControlLabel value={""} control={<Radio id="isExtraCreditNA-radio" name="isExtraCredit" />} label="N/A" />
              </RadioGroup>
            </FormControl>
          </Stack>

          <Divider />

          <Stack direction="row">
            <FormControlLabel
              control={<Checkbox disabled id="isDefault-checkbox" name="isDefault" checked={formValues.isDefault} onChange={handleRadioChange} />}
              label="Is Default"
            />
          </Stack>
          */}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
