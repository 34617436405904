import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { MasterFile } from "../../../models/MasterFile";
import { DownloadUtils } from "../../../utils/download.utils";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { PortalFolder } from "../../../models/PortalFolder";
import useUser from "../../../hooks/useUser";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { QUERIES } from "../../../graphQL/queries";
import FolderFileButton from "../../FileStorage/FolderFileButton";
import DocumentContextMenu from "../../FileStorage/DocumentContextMenu";
import FileInput from "../../FileStorage/FileInput";
import { AssessmentQuestion, AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";
import useUploadAssessmentAttachmentQuery from "../../../hooks/useUploadAssessmentAttachmentQuery";
import { AddedAssessmentAttachmentOutput } from "../../../graphQL/outputModels/AddedAssessmentAttachment.output";
import { AssessmentAttachmentsOutput } from "../../../graphQL/outputModels/AssessmentAttachments.output";
import { GetAssessmentInput } from "../../../graphQL/inputModels/getAssessment.input";
import { ActionStatus, ActionStatusNumber } from "../../../models/ActionStatus";
import { AssessmentAttachment } from "../../../models/AssessmentAttachment";
import { DeletedAssessmentAttachmentOutput } from "../../../graphQL/outputModels/DeletedAssessmentAttachment.output";
import { DeleteAssessmentAttachmentInput } from "../../../graphQL/inputModels/deleteAssessmentAttachment.input";
import { MUTATIONS } from "../../../graphQL/mutations";
import { AssessmentOutput } from "../../../graphQL/outputModels/Assessment.output";
import { Assessment } from "../../../models/Assessment";
import { GetAssessmentAttachmentsInput } from "../../../graphQL/inputModels/getAssessmentAttachments.input";

interface FileAttachmentsDialogProps {
  onClose: () => void;
  a?: Assessment;
  q?: AssessmentQuestionCustom;
}

export default function FileAttachmentsDialog({ onClose, a, q }: FileAttachmentsDialogProps) {
  // SETUP
  const [anchorElDocContext, setAnchorElDocContext] = useState<null | HTMLElement>(null);
  const docContextOpen = Boolean(anchorElDocContext);
  const [currFileContext, setCurrFileContext] = useState<AssessmentAttachment>();
  const apolloClient = useApolloClient();
  const { isAdmin, userId } = useUser();

  // HOOKS
  const { callApi: uploadAssessmentAttachment, loading: loadingUpload, data: dataUpload } = useUploadAssessmentAttachmentQuery<AddedAssessmentAttachmentOutput>();

  // GRAPHQL
  const {
    loading,
    data: attachmentsData,
    refetch,
  } = useQuery<AssessmentAttachmentsOutput, GetAssessmentAttachmentsInput>(QUERIES.GET_ASSESSMENT_ATTACHMENTS, {
    fetchPolicy: "network-only",
    variables: {
      assessmentId: q?.assessmentId ?? a?.assessmentId!,
      assessmentQuestionId: q?.assessmentQuestionId,
    },
  });
  const [deleteQuestionAttachment, { loading: loadingDeleteQuestionAttachment }] = useMutation<DeletedAssessmentAttachmentOutput, DeleteAssessmentAttachmentInput>(
    MUTATIONS.DELETE_ASSESSMENT_ATTACHMENT
  );

  //   HANDLERS
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const f: File = e.target.files[0];
      console.log("going to upload");
      uploadAssessmentAttachment({
        file: f,
        assessmentId: q?.assessmentId ?? a?.assessmentId!,
        assessmentQuestionId: q?.assessmentQuestionId,
      });
    }
  };

  const handleFileClick = (file: AssessmentAttachment) => {
    console.log(`Downloading File: ${file.fileName}`);
    DownloadUtils.downloadURI(file.blobUrl!);
    appNotification({
      severity: "info",
      message: `Downloading ${file.fileName}`,
      time: 3000,
    });
  };

  const handleDelete = (file: AssessmentAttachment) => {
    deleteQuestionAttachment({
      variables: {
        input: {
          assessmentAttachmentId: file.assessmentAttachmentId!,
        },
      },
      update: (cache, { data }) => {
        console.log(data);
        if (data?.deleteAssessmentAttachment.actionStatus === ActionStatus.Success) {
          const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
            query: QUERIES.GET_ASSESSMENT,
            variables: {
              assessmentId: q?.assessmentId ?? a?.assessmentId!,
            },
          });
          console.log(existingAssessment);
          if (existingAssessment) {
            console.log("fetching");
            if (a) {
              if (a.assessmentId === data.deleteAssessmentAttachment.assessmentId) {
                cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
                  query: QUERIES.GET_ASSESSMENT,
                  variables: {
                    assessmentId: a.assessmentId!,
                  },
                  data: {
                    assessment: { ...a, assessmentAttachments: [...a.assessmentAttachments!.filter((aa) => aa.assessmentAttachmentId !== data.deleteAssessmentAttachment.assessmentAttachmentId)] },
                  },
                });
              }
            } else if (q) {
              var newAssessmentQuestions = existingAssessment.assessment.assessmentQuestions?.map((q): AssessmentQuestion => {
                if (q.assessmentQuestionId === data.deleteAssessmentAttachment.assessmentQuestionId) {
                  return {
                    ...q,
                    assessmentAttachments: [...q.assessmentAttachments!.filter((aa) => aa.assessmentAttachmentId !== data.deleteAssessmentAttachment.assessmentAttachmentId)],
                  };
                }
                return q;
              });
              if (newAssessmentQuestions) {
                console.log("writing");
                const newAssessment: Assessment = { ...existingAssessment.assessment, assessmentQuestions: [...newAssessmentQuestions] };
                cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
                  query: QUERIES.GET_ASSESSMENT,
                  variables: {
                    assessmentId: q?.assessmentId!,
                  },
                  data: { assessment: newAssessment },
                });
              }
            }
            refetch();
          }
        }
      },
    });
  };

  const openDocContext = (e: MouseEvent, folder?: PortalFolder, file?: MasterFile) => {
    setAnchorElDocContext(e.currentTarget as HTMLElement);
    setCurrFileContext(file);
  };

  const getTitle = () => {
    if ((attachmentsData?.assessmentAttachmentsByAssessmentId?.length ?? 0) > 0) return "Click to Download an Attachment";
    return "Upload an Attachment";
  };

  const getLoadingDesc = () => {
    if (loading) return "Loading attachments...";
    if (loadingUpload) return "Uploading...";
    if (loadingDeleteQuestionAttachment) return "Deleting...";
  };

  const canDelete = (attachmentCreatedBy: number) => {
    return isAdmin || attachmentCreatedBy === userId;
  };

  // Callback for uploads
  useEffect(() => {
    if (dataUpload?.actionStatus === ActionStatusNumber.Success) {
      console.log("success");
      const cache = apolloClient.cache;
      console.log(dataUpload);
      const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
        query: QUERIES.GET_ASSESSMENT,
        variables: {
          assessmentId: q?.assessmentId ?? a?.assessmentId!,
        },
      });
      console.log(existingAssessment);
      if (existingAssessment) {
        console.log("fetching");
        if (a) {
          if (a.assessmentId === dataUpload.assessmentId) {
            cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
              query: QUERIES.GET_ASSESSMENT,
              variables: {
                assessmentId: a.assessmentId!,
              },
              data: { assessment: { ...a, assessmentAttachments: [...a.assessmentAttachments!, { ...dataUpload }] } },
            });
          }
        } else if (q) {
          var newAssessmentQuestions = existingAssessment.assessment.assessmentQuestions?.map((q): AssessmentQuestion => {
            if (q.assessmentQuestionId === dataUpload.assessmentQuestionId) {
              return {
                ...q,
                assessmentAttachments: [...q.assessmentAttachments!, { ...dataUpload }],
              };
            }
            return q;
          });
          if (newAssessmentQuestions) {
            console.log("writing");
            const newAssessment: Assessment = { ...existingAssessment.assessment, assessmentQuestions: [...newAssessmentQuestions] };
            cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
              query: QUERIES.GET_ASSESSMENT,
              variables: {
                assessmentId: q.assessmentId!,
              },
              data: { assessment: newAssessment },
            });
          }
        }
      }
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpload]);

  // RENDER
  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>
        {getTitle()}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Box maxHeight="calc(100vh - 500px)" overflow="auto" pt={2} pr={2} pl={2}>
          {loading || loadingUpload || loadingDeleteQuestionAttachment ? (
            <Stack height={150} display="flex" alignItems="center" justifyContent="center">
              <Typography>{getLoadingDesc()}</Typography>
              <LinearProgress variant="indeterminate" sx={{ mt: 2, mb: 4, width: 200 }} />
            </Stack>
          ) : (
            <Stack flexDirection="row" flexWrap="wrap" minHeight={150}>
              {attachmentsData?.assessmentAttachmentsByAssessmentId?.map((f) => (
                <Box key={`file-${f.assessmentAttachmentId}`}>
                  <FolderFileButton file={f} handleFileClick={() => handleFileClick(f)} handleRightClick={openDocContext} />
                </Box>
              ))}
              <DocumentContextMenu
                open={docContextOpen}
                onClose={() => setAnchorElDocContext(null)}
                anchorEl={anchorElDocContext}
                isAdmin={canDelete(currFileContext?.createdByUserId!)}
                fileContext={currFileContext}
                handleFileDownload={() => handleFileClick(currFileContext!)}
                handleDeleteFile={() => {
                  handleDelete(currFileContext!);
                }}
              />
            </Stack>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="orange" onClick={() => onClose()}>
          Close
        </Button>
        {loadingUpload ? (
          <Box ml="auto">
            <Button variant="contained" color="orange" component="span" disabled>
              Upload File
            </Button>
          </Box>
        ) : (
          <FileInput onFileChange={handleFileChange} type="file" ml="auto">
            <Button variant="contained" color="orange" component="span">
              Upload File
            </Button>
          </FileInput>
        )}
      </DialogActions>
    </Dialog>
  );
}
