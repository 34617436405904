import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Container, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useEffect, useMemo, useState, useRef } from "react";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { AssessmentQuestion, AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";
import { QUERIES } from "../../../graphQL/queries";
import { User } from "../../../models/User";
import { MemoQuestionComponent } from "./MemoQuestionComponent";
import FileAttachmentsDialog from "./FileAttachmentsDialog";
import RecommendationDialog from "./RecommendationDialog";
import { AssessmentOutput } from "../../../graphQL/outputModels/Assessment.output";
import { GetAssessmentInput } from "../../../graphQL/inputModels/getAssessment.input";
import { questionsMapper } from "../mappers/questionsMapper";
import { SetSelectedAssessmentQuestionResponsesOutput } from "../../../graphQL/outputModels/SetSelectedAssessmentQuestionResponses.output";
import { SetSelectedAssessmentQuestionResponsesInput } from "../../../graphQL/inputModels/setSelectedAssessmentQuestionResponses.input";
import { MUTATIONS } from "../../../graphQL/mutations";
import { SelectedAssessmentQuestionResponse } from "../../../models/SelectedAssessmentQuestionResponse";
import { Assessment } from "../../../models/Assessment";
import { ActionStatus } from "../../../models/ActionStatus";
import Step0, { AssessmentTypes } from "../NewAssessmentDialog/Step0";
import { Company } from "../../../models/Company";
import dayjs, { Dayjs } from "dayjs";
import { AssessmentParticipant, UpdateAssessmentParticipant } from "../../../models/AssessmentParticipant";
import { DocReviewEnum } from "../../../models/AssessmentSiteVisitDocument";
import { AssessmentSiteVisitDocumentSelection, UpdateAssessmentSiteVisitDocumentSelection } from "../../../models/AssessmentSiteVisitDocumentSelection";
import { CompanyOutput } from "../../../graphQL/outputModels/Company.output";
import Step1Visit from "../NewAssessmentDialog/Step1Visit";
import Step2Documents from "../NewAssessmentDialog/Step2Documents";
import { UpdateAssessmentInput } from "../../../graphQL/inputModels/updateAssessment.input";
import { UpdatedAssessmentOutput } from "../../../graphQL/outputModels/UpdatedAssessment.output";
import { StringUtils } from "../../../utils/string.utils";
import { NotificationUtils } from "../../../utils/notification.utils";

type NavItem = {
  assessmentEdit?: "Assessment Details" | "Participant List" | "Documents";
  prescriptive?: {
    prescriptiveNumber: number;
    totalAnswered: number;
    totalQuestions: number;
  };
};

type pageState = {
  assessmentId: number;
  assessmentName: string;
  assessmentTypeId: number | null;
  member: Company | null;
  assessmentDate: Dayjs;
  performedBy: number | null;
  participants: AssessmentParticipant[];
  documents: AssessmentSiteVisitDocumentSelection[];
};

export default function AssessmentDetailPage() {
  // SETUP
  const { assessmentId: _assessmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useUser();
  const [showFileAttachmentsForAssessment, setShowFileAttachmentsForAssessment] = useState<Assessment>();
  const [showFileAttachmentsForQuestion, setShowFileAttachmentsForQuestion] = useState<AssessmentQuestionCustom>();
  const [showRecommendation, setShowRecommendation] = useState<AssessmentQuestionCustom>();
  const [navPrescriptives, setNavPrescriptives] = useState<NavItem[]>();
  const [chosenNavIndex, setChosenNavIndex] = useState<number>();
  const questionListRef = useRef<HTMLDivElement>();
  const [_allMappedQuestions, _setAllMappedQuestions] = useState<AssessmentQuestionCustom[]>([]);
  const allMappedQuestionsRef = useRef(_allMappedQuestions);
  function setAllMappedQuestions(e: AssessmentQuestionCustom[]) {
    allMappedQuestionsRef.current = e;
    _setAllMappedQuestions(e);
  }
  const [_questionsData, _setQuestionsData] = useState<AssessmentQuestionCustom[]>([]);
  const questionsDataRef = useRef(_questionsData);
  function setQuestionsData(e: AssessmentQuestionCustom[]) {
    questionsDataRef.current = e;
    _setQuestionsData(e);
  }
  var defaultValues: pageState = {
    assessmentId: 0,
    assessmentName: "",
    assessmentTypeId: 0,
    member: null,
    assessmentDate: dayjs(),
    performedBy: null,
    participants: [],
    documents: [],
  };
  const [_formValues, _setFormValues] = useState(defaultValues);
  const formValues = useRef(_formValues);
  function setFormValues(e: pageState) {
    formValues.current = e;
    _setFormValues(e);
  }
  const isScorecard = formValues.current.assessmentTypeId === 5;

  // GRAPHQL
  const [getAssessment, { loading: loadingGetAssessment, data: assessmentData }] = useLazyQuery<AssessmentOutput, GetAssessmentInput>(QUERIES.GET_ASSESSMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      assessmentId: +(_assessmentId ?? 0),
    },
  });
  const [updateAssessment, { loading: loadingUpdateAssessment, data: updatedAssessmentData }] = useMutation<UpdatedAssessmentOutput, UpdateAssessmentInput>(MUTATIONS.UPDATE_ASSESSMENT);
  const [setSelectedQuestionResponses] = useMutation<SetSelectedAssessmentQuestionResponsesOutput, SetSelectedAssessmentQuestionResponsesInput>(MUTATIONS.SET_SELECTED_QUESTION_RESPONSES);
  const { loading: loadingCompanies, data: companiesData } = useQuery<CompanyOutput>(QUERIES.GET_COMPANIES);

  // HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setFormValues({
      ...formValues.current,
      [name]: value,
    });
  };

  const handleUserChanged = (u: User) => {
    setFormValues({
      ...formValues.current,
      performedBy: u.userId!,
    });
  };

  const handleResponseChange = (q: AssessmentQuestionCustom, responseId: number) => {
    console.log(responseId);
    var newMappedQuestions: AssessmentQuestionCustom[] = allMappedQuestionsRef.current.map((qd): AssessmentQuestionCustom => {
      if (qd.assessmentQuestionId === q.assessmentQuestionId) {
        return {
          ...qd,
          selectedAssessmentQuestionResponseId: qd.selectedAssessmentQuestionResponseId === responseId ? null : responseId,
        };
      }
      return qd;
    });

    // Update the UI firs to avoid loading times
    setAllMappedQuestions([...newMappedQuestions]);

    // Then call the API in the background
    var newResponses = newMappedQuestions.filter((q) => q.selectedAssessmentQuestionResponseId !== q.origSelectedAssessmentQuestionResponseId);
    if (newResponses.length > 0) {
      // Update selected responses only if there are new selections
      console.log("changed selected responses");
      handleUpdateResponses(newResponses);
    }
  };

  const handleDocumentsChanged = (documents: AssessmentSiteVisitDocumentSelection[]) => {
    setFormValues({
      ...formValues.current,
      documents: [...documents],
    });
  };

  const haveParticipantsChanged = () => {
    for (let index = 0; index < finalParticipants.length; index++) {
      const p = finalParticipants[index];
      if (!assessmentData?.assessment.assessmentParticipants?.find((ap) => ap.participantName === p.participantName && ap.participantTitle === p.participantTitle)) return true;
    }
    return false;
  };

  const haveSiteVisiteDocumentsChanged = () => {
    for (let index = 0; index < formValues.current.documents.length; index++) {
      const d = formValues.current.documents[index];
      var dd = assessmentData?.assessment.assessmentSiteVisitDocuments?.find((ad) => d.assessmentDocumentId === ad.assessmentDocumentId);
      if (d.reviewSelection !== dd?.isReviewed) return true;
    }
    return false;
  };

  const handleChangeNavIndex = (i: number) => {
    if (chosenNavIndex === i || chosenNavIndex === undefined) return;

    var wasEditingAssessment = navPrescriptives?.[chosenNavIndex].assessmentEdit;
    if (
      (wasEditingAssessment && formValues.current.assessmentTypeId !== assessmentData?.assessment.assessmentTypeId) ||
      !formValues.current.assessmentDate.isSame(dayjs(assessmentData?.assessment.assessmentDate)) ||
      formValues.current.performedBy !== assessmentData?.assessment.performedBy ||
      formValues.current.member?.companyId !== assessmentData?.assessment.memberId ||
      haveParticipantsChanged() ||
      haveSiteVisiteDocumentsChanged()
    ) {
      // Update assessment only if there were changes
      console.log("assessment data changed");
      console.log(formValues.current);
      console.log(assessmentData?.assessment);
      handleUpdateAssessment();
    }
    // else {
    //   var newResponses = allMappedQuestionsRef.current.filter((q) => q.selectedAssessmentQuestionResponseId !== q.origSelectedAssessmentQuestionResponseId);
    //   if (newResponses.length > 0) {
    //     // Update selected responses only if there are new selections
    //     console.log("changed selected responses");
    //     handleUpdateResponses(newResponses);
    //   }
    // }
    setChosenNavIndex(i);
  };

  const handleUpdateAssessment = () => {
    var assessmentType = AssessmentTypes.find((a) => a.assessmentTypeId === formValues.current.assessmentTypeId!)!.assessmentTypeName;
    var memberName = formValues.current.member?.companyName;
    const calcAssessmentName = `${assessmentType} ${memberName} ${finalDateString}`;
    updateAssessment({
      variables: {
        input: {
          assessmentId: assessmentData!.assessment.assessmentId!,
          assessmentName: calcAssessmentName,
          assessmentTypeId: formValues.current.assessmentTypeId!,
          assessmentDate: formValues.current.assessmentDate.toISOString(),
          memberId: formValues.current.member!.companyId!,
          memberName: formValues.current.member!.companyName!,
          memberPolicyNumber: formValues.current.member!.companyPolicyNo!,
          assessmentParticipants: finalParticipants.map((p): UpdateAssessmentParticipant => {
            return {
              assessmentId: assessmentData?.assessment.assessmentId!,
              assessmentParticipantId: p.assessmentParticipantId ?? 0,
              participantName: p.participantName!,
              participantTitle: p.participantTitle!,
            };
          }),
          assessmentSiteVisitDocumentSelections: formValues.current.documents.map((d): UpdateAssessmentSiteVisitDocumentSelection => {
            return {
              assessmentId: assessmentData?.assessment.assessmentId!,
              assessmentDocumentId: d.assessmentDocumentId,
              reviewSelection: d.reviewSelection,
            };
          }),
          performedBy: formValues.current.performedBy!,
        },
      },
      update: (cache, { data }) => {
        console.log(data);
        if (data?.updateAssessment.actionStatus === ActionStatus.Success) {
          const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
            query: QUERIES.GET_ASSESSMENT,
            variables: {
              assessmentId: assessmentData?.assessment.assessmentId!,
            },
          });
          console.log(existingAssessment);
          if (existingAssessment) {
            console.log("writing");
            const newAssessment: Assessment = {
              ...existingAssessment.assessment,
              assessmentName: data.updateAssessment.assessmentName,
              assessmentTypeId: data.updateAssessment.assessmentTypeId!,
              assessmentDate: data.updateAssessment.assessmentDate,
              memberId: data.updateAssessment.memberId,
              memberName: data.updateAssessment.memberName,
              memberPolicyNumber: data.updateAssessment.memberPolicyNumber,
              assessmentParticipants: data.updateAssessment.assessmentParticipants,
              assessmentSiteVisitDocuments: data.updateAssessment.assessmentSiteVisitDocuments,
              performedBy: data.updateAssessment.performedBy,
            };
            cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
              query: QUERIES.GET_ASSESSMENT,
              data: { assessment: newAssessment },
              variables: {
                assessmentId: assessmentData?.assessment.assessmentId!,
              },
            });
          }
        }
      },
    });
  };

  const handleUpdateResponses = (newResponses: AssessmentQuestionCustom[]) => {
    setSelectedQuestionResponses({
      variables: {
        input: {
          assessmentId: assessmentData!.assessment.assessmentId!,
          selectedAssessmentQuestionResponse: newResponses.map((nr): SelectedAssessmentQuestionResponse => {
            return {
              assessmentQuestionId: nr.assessmentQuestionId!,
              assessmentId: assessmentData!.assessment.assessmentId!,
              selectedAssessmentQuestionResponseId: nr.selectedAssessmentQuestionResponseId!,
            };
          }),
        },
      },
      update: (cache, { data }) => {
        console.log(data);
        if (data?.setSelectedAssessmentQuestionResponses.actionStatus === ActionStatus.Success) {
          const existingAssessment = cache.readQuery<AssessmentOutput, GetAssessmentInput>({
            query: QUERIES.GET_ASSESSMENT,
            variables: {
              assessmentId: assessmentData?.assessment.assessmentId!,
            },
          });
          console.log(existingAssessment);
          if (existingAssessment) {
            console.log("fetching responses");
            var newAssessmentQuestions = existingAssessment.assessment.assessmentQuestions?.map((q): AssessmentQuestion => {
              var f = data?.setSelectedAssessmentQuestionResponses.selectedAssessmentQuestionResponses?.find((s) => s.assessmentQuestionId === q.assessmentQuestionId);
              if (f) {
                return {
                  ...q,
                  selectedAssessmentQuestionResponseId: f.selectedAssessmentQuestionResponseId,
                };
              }
              return q;
            });
            if (newAssessmentQuestions) {
              console.log("writing");
              const newAssessment: Assessment = { ...existingAssessment.assessment, assessmentQuestions: [...newAssessmentQuestions] };
              cache.writeQuery<AssessmentOutput, GetAssessmentInput>({
                query: QUERIES.GET_ASSESSMENT,
                data: { assessment: newAssessment },
                variables: {
                  assessmentId: assessmentData?.assessment.assessmentId!,
                },
              });
            }
          }
        }
      },
    });
  };

  // USEMEMOS
  const finalParticipants = useMemo(() => {
    const participants = [...formValues.current.participants].filter((p) => !StringUtils.isEmpty(p.participantName) && !StringUtils.isEmpty(p.participantTitle));
    return participants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.current.participants]);

  const finalDateString = useMemo(() => {
    const fmt = isScorecard ? "YYYY" : "MMM D, YYYY";
    const d = dayjs(formValues.current.assessmentDate).format(fmt);
    return d;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScorecard, formValues.current.assessmentDate]);

  const progressTotalQuestions = useMemo(() => {
    return navPrescriptives?.filter((p) => p.prescriptive).reduce((a, curr) => a + curr.prescriptive!.totalQuestions, 0) ?? 10000;
  }, [navPrescriptives]);

  const progressTotalAnswered = useMemo(() => {
    return navPrescriptives?.filter((p) => p.prescriptive).reduce((a, curr) => a + curr.prescriptive!.totalAnswered, 0) ?? 0;
  }, [navPrescriptives]);

  // USEEFFECTS
  useEffect(() => {
    getAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assessmentData?.assessment.assessmentPrescriptives && allMappedQuestionsRef.current) {
      // populate nav
      console.log("updating nav");
      var prescriptives = assessmentData.assessment.assessmentPrescriptives.map((p): NavItem => {
        let pQuestions = allMappedQuestionsRef.current.filter((q) => q.assessmentPrescriptiveId === p.assessmentPrescriptiveId);
        let totQuestions = pQuestions?.length ?? 0;
        let totAnswered = pQuestions?.filter((q) => q.selectedAssessmentQuestionResponseId).length ?? 0;
        return { prescriptive: { prescriptiveNumber: p.assessmentPrescriptiveNumber!, totalQuestions: totQuestions, totalAnswered: totAnswered } };
      });
      if (isAdmin) {
        if (assessmentData.assessment.assessmentTypeId === 5) {
          setNavPrescriptives([{ assessmentEdit: "Assessment Details" }, ...prescriptives]);
        } else {
          setNavPrescriptives([{ assessmentEdit: "Assessment Details" }, { assessmentEdit: "Participant List" }, { assessmentEdit: "Documents" }, ...prescriptives]);
        }
      } else {
        setNavPrescriptives([...prescriptives]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentData?.assessment.assessmentPrescriptives, allMappedQuestionsRef.current]);

  // map questions to custom if assessment data changes
  useEffect(() => {
    console.log("assessment data changed");
    if (assessmentData?.assessment.assessmentQuestions) {
      var newMappedData = questionsMapper.mapToCustom([...(assessmentData.assessment.assessmentQuestions ?? [])], assessmentData.assessment.assessmentId!);
      setAllMappedQuestions(newMappedData);
      if (showFileAttachmentsForQuestion) {
        let newContext = newMappedData.find((d) => d.assessmentQuestionId === showFileAttachmentsForQuestion.assessmentQuestionId);
        if (newContext) {
          setShowFileAttachmentsForQuestion({ ...newContext });
        }
      }
      if (showRecommendation) {
        let newContext = newMappedData.find((d) => d.assessmentQuestionId === showRecommendation.assessmentQuestionId);
        if (newContext) {
          setShowRecommendation({ ...newContext });
        }
      }
    }
    if (assessmentData) {
      setFormValues({
        assessmentId: assessmentData.assessment.assessmentId!,
        assessmentName: assessmentData.assessment.assessmentName!,
        assessmentTypeId: assessmentData.assessment.assessmentTypeId!,
        member: { companyId: assessmentData.assessment.memberId, companyName: assessmentData.assessment.memberName, companyPolicyNo: assessmentData.assessment.memberPolicyNumber },
        assessmentDate: dayjs(assessmentData.assessment.assessmentDate!),
        performedBy: assessmentData.assessment.performedBy!,
        participants: [...(assessmentData.assessment.assessmentParticipants ?? [])],
        documents: [
          ...(assessmentData.assessment.assessmentSiteVisitDocuments?.map((d): AssessmentSiteVisitDocumentSelection => {
            return {
              assessmentId: assessmentData.assessment.assessmentId!,
              assessmentDocumentId: d.assessmentDocumentId!,
              documentDescription: d.documentDescription!,
              reviewSelection: d.isReviewed ?? DocReviewEnum.Unselected,
            };
          }) ?? []),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentData?.assessment]);

  useEffect(() => {
    if (allMappedQuestionsRef.current && navPrescriptives && chosenNavIndex !== undefined) {
      // only show the chosen prescriptive's mapped questions
      var filteredData = allMappedQuestionsRef.current.filter((q) => q.assessmentPrescriptive?.assessmentPrescriptiveNumber === navPrescriptives[chosenNavIndex].prescriptive?.prescriptiveNumber);
      setQuestionsData([...filteredData] ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMappedQuestionsRef.current, chosenNavIndex]);

  useEffect(() => {
    // scroll questions to top
    questionListRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [chosenNavIndex]);

  useEffect(() => {
    // Initialize chosenNavIndex to be the first prescriptive
    if (chosenNavIndex === undefined && navPrescriptives) {
      var firstPrescriptiveNav = navPrescriptives.findIndex((p) => p.prescriptive !== undefined);
      console.log(firstPrescriptiveNav);
      setChosenNavIndex(firstPrescriptiveNav);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navPrescriptives]);

  useEffect(() => {
    NotificationUtils.notifyUpdate(updatedAssessmentData?.updateAssessment.actionStatus!, updatedAssessmentData?.updateAssessment.actionStatusMessage!);
  }, [updatedAssessmentData]);

  // RENDER
  return (
    <Container maxWidth="xl">
      {/* Dialogs */}
      {(showFileAttachmentsForAssessment || showFileAttachmentsForQuestion) && (
        <FileAttachmentsDialog
          onClose={() => {
            setShowFileAttachmentsForAssessment(undefined);
            setShowFileAttachmentsForQuestion(undefined);
          }}
          a={showFileAttachmentsForAssessment}
          q={showFileAttachmentsForQuestion}
        />
      )}
      {showRecommendation && <RecommendationDialog onClose={() => setShowRecommendation(undefined)} q={showRecommendation} />}

      <Box margin="auto" sx={{ pt: 5, pr: "80px", pl: 4, pb: 2, position: "relative" }}>
        <Stack direction="row" pl={"200px"} pr={3}>
          <Typography variant="h2" textAlign="start">
            Assessment
          </Typography>
          <Button
            color="cyan"
            variant="contained"
            onClick={() => {
              const ii = location.pathname.lastIndexOf("/");
              navigate(location.pathname.substring(0, ii));
            }}
            sx={{
              minWidth: 60,
              height: 40,
              position: "absolute",
              left: 0,
            }}
            startIcon={<NavigateBeforeIcon />}
          >
            Back to List
          </Button>
          {!loadingGetAssessment && (
            <Button
              color="orange"
              variant="contained"
              onClick={() => setShowFileAttachmentsForAssessment(assessmentData?.assessment)}
              sx={{
                minWidth: 60,
                height: 40,
                ml: "auto",
              }}
            >
              {`Final Report & Related Resources (${assessmentData?.assessment.assessmentAttachments?.length ?? 0})`}
            </Button>
          )}
        </Stack>

        {loadingGetAssessment ? (
          <Stack justifyContent="center" alignItems="center" height="calc(100vh - 200px)" spacing={2}>
            <Typography color="white" variant="h5">
              Loading assessment...
            </Typography>
            <LinearProgress variant="indeterminate" color="secondary" sx={{ width: 200 }} />
          </Stack>
        ) : (
          <>
            {/* Navigation */}
            <Stack position="absolute" left={0} top={120} width={180} spacing={1}>
              {navPrescriptives?.map((p, i) => (
                <Button
                  key={i}
                  variant="contained"
                  color={p.assessmentEdit ? "light" : p.prescriptive?.totalAnswered === 0 ? "error" : p.prescriptive?.totalAnswered === p.prescriptive?.totalQuestions ? "success" : "warning"}
                  sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px 8px",
                    color: "black",
                    textTransform: "none",
                    cursor: "pointer",
                    transition: "150ms",
                    "&:hover": { transform: "scale(1.06)" },
                    transform: i === chosenNavIndex ? "scale(1.06)" : "",
                    boxShadow: i === chosenNavIndex ? "rgba(0, 0, 0, 0.75) 0px 0px 5px 2px !important" : "",
                  }}
                  onClick={() => handleChangeNavIndex(i)}
                >
                  {p.prescriptive ? (
                    <>
                      <Typography component="span">Prescriptive {p.prescriptive.prescriptiveNumber}</Typography>
                      <Typography component="span" fontWeight="bold">
                        {`${p.prescriptive.totalAnswered}/${p.prescriptive.totalQuestions}`}
                      </Typography>
                    </>
                  ) : (
                    <Typography component="span">{p.assessmentEdit}</Typography>
                  )}
                </Button>
              ))}
            </Stack>

            {/* Progress Bar */}
            <Box pl={"200px"} mt={2}>
              <Box pr={3}>
                <Typography>
                  {"You have answered "}
                  <Typography fontWeight="bold" component="span">
                    {progressTotalAnswered}
                  </Typography>
                  {" out of "}
                  <Typography fontWeight="bold" component="span">
                    {progressTotalQuestions === 10000 ? 0 : progressTotalQuestions}
                  </Typography>
                  {" questions in this assessment"}
                </Typography>
                <LinearProgress variant="determinate" color="info" value={(100 * progressTotalAnswered) / progressTotalQuestions} sx={{ height: 14, borderRadius: 1 }} />
              </Box>

              <Box ref={questionListRef} sx={{ overflow: "auto", mt: 4, height: "calc(100vh - 300px)", pr: 1, "> .MuiPaper-root:not(:first-of-type)": { mt: 2 } }}>
                {chosenNavIndex !== undefined && navPrescriptives?.[chosenNavIndex].assessmentEdit === "Assessment Details" && (
                  <Paper elevation={2} sx={{ minHeight: 100, background: "#EFEFEF", borderRadius: 1, p: 1, textAlign: "start", position: "relative" }}>
                    <Typography variant="h5" textAlign="center" mt={1} mb={3}>
                      Assessment Details
                    </Typography>
                    <Step0
                      assessmentTypeId={formValues.current.assessmentTypeId}
                      companies={companiesData?.companies ?? []}
                      selectedCompany={formValues.current.member}
                      assessmentDate={formValues.current.assessmentDate}
                      performedBy={formValues.current.performedBy}
                      loading={loadingCompanies}
                      onFormChanged={handleInputChange}
                      onPerformedByUserChanged={handleUserChanged}
                      isEditing={true}
                    />
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        handleUpdateAssessment();
                      }}
                      sx={{ ml: 2, position: "absolute", top: 10, right: 10 }}
                      endIcon={<SaveOutlinedIcon />}
                      disabled={loadingUpdateAssessment}
                    >
                      Save
                    </Button>
                  </Paper>
                )}

                {chosenNavIndex !== undefined && navPrescriptives?.[chosenNavIndex].assessmentEdit === "Participant List" && (
                  <Paper elevation={2} sx={{ minHeight: 100, background: "#EFEFEF", borderRadius: 1, p: 1, textAlign: "start", position: "relative" }}>
                    <Typography variant="h5" textAlign="center" mt={1} mb={3}>
                      Participants
                    </Typography>
                    <Step1Visit participants={formValues.current.participants} loading={false} onFormChanged={handleInputChange} />
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        handleUpdateAssessment();
                      }}
                      sx={{ ml: 2, position: "absolute", top: 10, right: 10 }}
                      endIcon={<SaveOutlinedIcon />}
                      disabled={loadingUpdateAssessment}
                    >
                      Save
                    </Button>
                  </Paper>
                )}

                {chosenNavIndex !== undefined && navPrescriptives?.[chosenNavIndex].assessmentEdit === "Documents" && (
                  <Paper elevation={2} sx={{ minHeight: 100, background: "#EFEFEF", borderRadius: 1, p: 1, pr: 3, textAlign: "start", position: "relative" }}>
                    <Typography variant="h5" textAlign="center" mt={1} mb={3}>
                      Documents
                    </Typography>
                    <Step2Documents documents={formValues.current.documents} loading={false} handleDocumentsChanged={handleDocumentsChanged} />
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => {
                        handleUpdateAssessment();
                      }}
                      sx={{ ml: 2, position: "absolute", top: 10, right: 10 }}
                      endIcon={<SaveOutlinedIcon />}
                      disabled={loadingUpdateAssessment}
                    >
                      Save
                    </Button>
                  </Paper>
                )}

                {/* Questions */}
                {questionsDataRef.current.map((q, i) => {
                  return (
                    <MemoQuestionComponent
                      key={q.assessmentQuestionId}
                      q={q}
                      i={i}
                      handleResponseChange={handleResponseChange}
                      handleToggleRecommendation={setShowRecommendation}
                      handleExpandFileAttachments={setShowFileAttachmentsForQuestion}
                    />
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}
