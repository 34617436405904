import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import EmbeddedReportComponent from "./EmbeddedReportComponent";
import "./EmbeddedReport.scss";
import { Box, Button, Typography } from "@mui/material";
import { ReportOutput } from "../../graphQL/outputModels/Report.output";
import { GetReportInput } from "../../graphQL/inputModels/getReport.input";
import { QUERIES } from "../../graphQL/queries";
import { useEffect, useMemo, useState } from "react";
import useExportQuery from "../../hooks/useExportQuery";
import { AllRoutes } from "../../routing/Routes";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export default function EmbeddedReportPage() {
  const { portal, reportId: _reportId } = useParams();
  const reportId = +(_reportId ?? 0);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [embedError, setEmbedError] = useState(false);
  const navigate = useNavigate();

  // GRAPHQL
  const { loading: loadingReport, data: reportData } = useQuery<ReportOutput, GetReportInput>(QUERIES.GET_REPORT, {
    fetchPolicy: "network-only",
    variables: {
      reportId: reportId,
    },
  });

  // CUSTOM HOOKS
  const { execute: executeExport, tasks: exportTasks } = useExportQuery();

  const reportName = reportData?.report?.reportName;
  const embedUrl = reportData?.report?.powerBiEmbed?.embedUrl;
  const embedToken = reportData?.report?.powerBiEmbed?.embedToken;
  const pbiWorkspaceId = reportData?.report?.powerBiWorkspaceId;
  const pbiReportId = reportData?.report?.powerBiReportId;

  // HANDLERS
  const handleExport = async () => {
    if (pbiWorkspaceId && pbiReportId && reportName) {
      console.log("starting export");
      // exportReport({
      //   workspaceId: pbiWorkspaceId,
      //   reportId: pbiReportId,
      //   reportName: reportName,
      // });
      executeExport({
        workspaceId: pbiWorkspaceId,
        reportId: pbiReportId,
        reportName: reportName,
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    if (!_reportId || isNaN(+_reportId)) {
      navigate(AllRoutes.NotFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportData) {
      if (reportData.report.powerBiEmbed?.embedToken === "") {
        console.log("unable to embed report");
        setEmbedError(true);
      }
    }
  }, [reportData]);

  // USEMEMO
  const alreadyProcessing = useMemo(() => {
    return exportTasks.some((t) => t.name === reportName);
  }, [exportTasks, reportName]);

  // RENDER
  return (
    <Box id="embedded-report-page" display="flex" alignItems="center">
      {/* <div>Viewing report {reportId}</div> */}
      <Box
        sx={{
          margin: "auto",
          p: 2,
          backgroundColor: "white",
          height: "80%",
          width: "80%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 1,
          boxShadow: "0 0 20px 5px rgb(0 0 0 / 20%)",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", mb: 2 }}>
          <Button
            color="orange"
            variant="contained"
            sx={{
              ml: "auto",
              // height: "40px",
              width: "100px",
            }}
            onClick={() => navigate(`${AllRoutes.Portal.main}/${portal}`)}
          >
            Back
          </Button>
          <Typography variant="h6" textAlign="center" flexGrow={1}>
            {reportName ?? "-"}
          </Typography>
          <Button
            color="orange"
            disabled={loadingReport || !reportLoaded || alreadyProcessing}
            variant="contained"
            sx={{
              ml: "auto",
              // height: "40px",
              width: "100px",
            }}
            onClick={handleExport}
          >
            Export
          </Button>
        </Box>

        {embedError ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineOutlinedIcon color="error" />
            <Typography sx={{ fontStyle: "italic", ml: 2 }}>There was an issue embedding this report</Typography>
          </Box>
        ) : (
          <EmbeddedReportComponent embedUrl={embedUrl} accessToken={embedToken} setReportLoadedCallback={setReportLoaded} />
        )}
      </Box>
    </Box>
  );
}
