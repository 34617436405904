export const DownloadUtils = {
    downloadURI,
    downloadBlob,
    downloadCSVFile
}

function downloadURI(uri: string, fileName?: string) {
    const link = document.createElement("a");
    link.href = uri;
    if (fileName) link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function downloadBlob(blob: Blob, fileName?: string, type: "pdf" | "docx" | "xlsx" = "pdf") {
    let tt = "application/pdf";
    if (type === "docx") tt = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    else if (type === "xlsx") tt = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const newBlob = new Blob([blob], { 'type': tt });
    const newUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.href = newUrl;
    link.setAttribute('download', `${fileName ?? "Download"}.${type}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(newUrl);
}

function downloadCSVFile(csv_data: string, title: string) {

    // Create CSV file object and feed our
    // csv_data into it
    // var CSVFile = new Blob([csv_data], { type: "text/csv" });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = `${title}.csv`;
    // var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv_data);

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
}