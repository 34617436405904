import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { PortalFile } from "../../models/PortalFile";
import { PortalFolder } from "../../models/PortalFolder";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileInput from "./FileInput";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { MasterFile } from "../../models/MasterFile";

interface DocumentContextMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  isAdmin: boolean;
  folderContext?: PortalFolder;
  fileContext?: PortalFile;
  handleFileChange?: (e: React.ChangeEvent<HTMLInputElement>, parentFolderId: number) => void;
  handleFileRename?: () => void;
  handleFileDownload?: (file: PortalFile) => void;
  handleOpenFolder?: (folder: PortalFolder) => void;
  handleEditFolder?: () => void;
  handleDeleteFolder?: () => void;
  handleDeleteFile?: () => void;
  handleFileSetDefault?: (file: MasterFile) => void;
  handleFileSetPreview?: (file: MasterFile) => void;
}

export default function DocumentContextMenu({
  open,
  onClose,
  anchorEl,
  isAdmin,
  folderContext,
  fileContext,
  handleFileChange,
  handleFileRename,
  handleFileDownload,
  handleOpenFolder,
  handleEditFolder,
  handleDeleteFolder,
  handleDeleteFile,
  handleFileSetDefault,
  handleFileSetPreview,
}: DocumentContextMenuProps) {
  // SETUP
  const editingFolder = Boolean(folderContext);
  const editingFile = Boolean(fileContext);

  // HANDLERS
  const handleDelete = () => {
    if (folderContext && handleDeleteFolder) {
      handleDeleteFolder();
    } else if (fileContext && handleDeleteFile) {
      handleDeleteFile();
    }
    onClose();
  };

  const editFolder = () => {
    if (handleEditFolder) {
      handleEditFolder();
      onClose();
    }
  };

  const setFileDefault = () => {
    if (fileContext && handleFileSetDefault) {
      handleFileSetDefault(fileContext);
      onClose();
    }
  };

  const setFilePreview = () => {
    if (fileContext && handleFileSetPreview) {
      handleFileSetPreview(fileContext);
      onClose();
    }
  };

  const renameFile = () => {
    if (handleFileRename) {
      handleFileRename();
      onClose();
    }
  };

  const downloadFile = () => {
    if (handleFileDownload) {
      handleFileDownload(fileContext!);
      onClose();
    }
  };

  const openFolder = () => {
    if (handleOpenFolder) {
      handleOpenFolder(folderContext!);
      onClose();
    }
  };

  // RENDER
  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
      {editingFolder && handleOpenFolder && (
        <MenuItem onClick={openFolder}>
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Open</ListItemText>
        </MenuItem>
      )}
      {editingFolder && isAdmin && handleFileChange && (
        <FileInput
          width="fit-content"
          type="file"
          onFileChange={(e) => {
            handleFileChange(e, folderContext!.folderId!);
          }}
        >
          <MenuItem onClick={onClose}>
            <ListItemIcon>
              <FileUploadOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Upload</ListItemText>
          </MenuItem>
        </FileInput>
      )}
      {editingFolder && isAdmin && handleEditFolder && (
        <MenuItem onClick={editFolder}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      )}
      {editingFile && isAdmin && handleFileSetDefault && (
        <MenuItem onClick={setFileDefault}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Set Default</ListItemText>
        </MenuItem>
      )}
      {editingFile && isAdmin && handleFileSetPreview && (
        <MenuItem onClick={setFilePreview}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Preview</ListItemText>
        </MenuItem>
      )}

      {editingFile && handleFileDownload && (
        <MenuItem onClick={downloadFile}>
          <ListItemIcon>
            <FileDownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
      )}

      {editingFile && isAdmin && handleFileRename && (
        <MenuItem onClick={renameFile}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
      )}

      {isAdmin && ((folderContext && handleDeleteFolder) || (fileContext && handleDeleteFile)) && (
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}
