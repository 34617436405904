import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { QUERIES } from "../../../graphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { AssessmentDialogObjectType, AssessmentDropdownType } from "../AssessmentAdministrationPage";
import { QuestionsOutput } from "../../../graphQL/outputModels/Questions.output";
import { Question } from "../../../models/Question";
import parse from "html-react-parser";

interface QuestionRegistrationTableProps {
  onShowAddEditDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => void;
  onShowDeleteDialog: (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => void;
}

export default function QuestionRegistrationTable({ onShowAddEditDialog, onShowDeleteDialog }: QuestionRegistrationTableProps) {
  // GRAPHQL
  const [getQuestions, { loading, data: _questionsData }] = useLazyQuery<QuestionsOutput>(QUERIES.GET_QUESTIONS, {
    fetchPolicy: "cache-and-network",
  });

  // HANDLERS
  const handleOpenAddDialog = (question?: Question) => {
    onShowAddEditDialog("question", question);
  };

  const handleDeleteDialog = (question: Question) => {
    onShowDeleteDialog("question", question);
  };

  // USEMEMOS
  const sortedQuestionsData = useMemo(() => {
    if (!_questionsData?.questions) return [];
    var sortedData = [..._questionsData.questions].sort((a, b) => (a.questionNumberSort ?? "").localeCompare(b.questionNumberSort ?? ""));
    return sortedData;
  }, [_questionsData]);

  // USEEFFECTS
  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TableContainer
        component={Box}
        justifyContent="center"
        display="flex"
        overflow="auto"
        maxHeight="calc(100vh - 350px)"
        margin="auto"
        mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Number</TableCell>
              <TableCell align="left">Question</TableCell>
              <TableCell align="left">Recommendation</TableCell>
              <TableCell align="center" sx={{ width: 70 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* show loading bars if loading */}
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ) : (
              sortedQuestionsData?.map((question, i) => (
                <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                    {question.questionNumber}
                  </TableCell>
                  <TableCell align="left" sx={{ overflowWrap: "break-word", p: 0 }}>
                    <Box sx={{ maxHeight: 150, overflow: "auto" }}>{parse(question.questionDescription ?? "")}</Box>
                  </TableCell>
                  <TableCell align={question.nonCompliantRecommendation ? "left" : "center"} sx={{ overflowWrap: "break-word" }}>
                    <Box sx={{ maxHeight: 150, overflow: "auto" }}>{parse(question.nonCompliantRecommendation ?? "-")}</Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit question">
                      <IconButton
                        onClick={() => {
                          handleOpenAddDialog(question);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove question">
                      <IconButton
                        onClick={() => {
                          handleDeleteDialog(question);
                        }}
                        color="default"
                        sx={{ p: "4px" }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (sortedQuestionsData?.length ?? 0) === 0 && <Typography sx={{ mt: 3, mb: 2 }}>There are no questions</Typography>}
      <Button variant="contained" onClick={() => handleOpenAddDialog()} sx={{ mt: 1 }}>
        +
      </Button>
    </Box>
  );
}
