import { useMutation } from "@apollo/client";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { Prescriptive } from "../../../models/Prescriptive";
import { AddedPrescriptiveOutput } from "../../../graphQL/outputModels/AddedPrescriptive.output";
import { AddPrescriptiveInput } from "../../../graphQL/inputModels/addPrescriptive.input";
import { UpdatedPrescriptiveOutput } from "../../../graphQL/outputModels/UpdatedPrescriptive.output";
import { UpdatePrescriptiveInput } from "../../../graphQL/inputModels/updatePrescriptive.input";
import { PrescriptivesOutput } from "../../../graphQL/outputModels/Prescriptives.output";

interface AddEditPrescriptiveDialogProps {
  open: boolean;
  editingPrescriptive?: Prescriptive;
  onClose: (refetch: boolean) => void;
}

export default function AddEditPrescriptiveDialog({ open, onClose, editingPrescriptive }: AddEditPrescriptiveDialogProps) {
  // SETUP
  let defaultValues = {
    prescriptiveId: 0,
    prescriptiveNumber: 0,
    prescriptiveDescription: "",
  };
  if (editingPrescriptive) {
    defaultValues = {
      prescriptiveId: editingPrescriptive?.prescriptiveId!,
      prescriptiveNumber: editingPrescriptive?.prescriptiveNumber!,
      prescriptiveDescription: editingPrescriptive?.prescriptiveDescription!,
    };
  }
  const [formValues, setFormValues] = useState(defaultValues);
  const formValidated = formValues.prescriptiveNumber > 0 && formValues.prescriptiveDescription.length > 0;

  // GRAPHQL
  const [addPrescriptive, { loading: loadingAddPrescriptive, data: addedPrescriptive }] = useMutation<AddedPrescriptiveOutput, AddPrescriptiveInput>(MUTATIONS.ADD_PRESCRIPTIVE);

  const [updatePrescriptive, { loading: loadingUpdatePrescriptive, data: updatedPrescriptive }] = useMutation<UpdatedPrescriptiveOutput, UpdatePrescriptiveInput>(MUTATIONS.UPDATE_PRESCRIPTIVE);

  // VARIABLES
  const loading = loadingAddPrescriptive || loadingUpdatePrescriptive;

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    if (!formValidated) return;
    console.log(formValues);
    if (editingPrescriptive) {
      console.log("updating");
      updatePrescriptive({
        variables: {
          input: {
            prescriptiveId: +formValues.prescriptiveId,
            prescriptiveNumber: +formValues.prescriptiveNumber,
            prescriptiveDescription: formValues.prescriptiveDescription,
          },
        },
        update: (cache, { data }) => {
          if (data?.updatePrescriptive.actionStatus === ActionStatus.Success) {
            const existingPrescriptives = cache.readQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES });
            if (existingPrescriptives) {
              const newPrescriptives = existingPrescriptives.prescriptives.map((p: Prescriptive) => {
                if (p.prescriptiveId === formValues.prescriptiveId) {
                  return { ...p, ...data!.updatePrescriptive };
                } else {
                  return p;
                }
              });
              cache.writeQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES, data: { prescriptives: newPrescriptives } });
            }
          }
        },
      });
    } else {
      console.log("adding");
      addPrescriptive({
        variables: {
          input: {
            prescriptiveNumber: +formValues.prescriptiveNumber,
            prescriptiveDescription: formValues.prescriptiveDescription,
          },
        },
        update: (cache, { data }) => {
          if (data?.addPrescriptive.actionStatus === ActionStatus.Success) {
            const existingPrescriptives = cache.readQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES });
            if (existingPrescriptives) {
              const newPrescriptives = [...existingPrescriptives.prescriptives, { ...data!.addPrescriptive }];
              cache.writeQuery<PrescriptivesOutput>({ query: QUERIES.GET_PRESCRIPTIVES, data: { prescriptives: newPrescriptives } });
            }
          }
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    console.log("addedPrescriptive changed");
    if (addedPrescriptive?.addPrescriptive.actionStatus === ActionStatus.Success) {
      console.log("add success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully added" });
    } else if (addedPrescriptive?.addPrescriptive.actionStatus === ActionStatus.Fail) {
      console.error(addedPrescriptive.addPrescriptive.actionStatusMessage);
      appNotification({ severity: "warning", message: `Error: ${addedPrescriptive.addPrescriptive.actionStatusMessage}` });
    } else if (addedPrescriptive?.addPrescriptive.actionStatus === ActionStatus.Error) {
      console.error(addedPrescriptive.addPrescriptive.actionStatusMessage);
      appNotification({ severity: "error", message: `Error: ${addedPrescriptive.addPrescriptive.actionStatusMessage}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedPrescriptive]);

  useEffect(() => {
    console.log("updatedPrescriptive changed");
    if (updatedPrescriptive?.updatePrescriptive.actionStatus === ActionStatus.Success) {
      console.log("update success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully updated" });
    } else if (addedPrescriptive?.addPrescriptive.actionStatus === ActionStatus.Fail) {
      console.error(addedPrescriptive.addPrescriptive.actionStatusMessage);
      appNotification({ severity: "warning", message: `Error: ${addedPrescriptive.addPrescriptive.actionStatusMessage}` });
    } else if (addedPrescriptive?.addPrescriptive.actionStatus === ActionStatus.Error) {
      console.error(addedPrescriptive.addPrescriptive.actionStatusMessage);
      appNotification({ severity: "error", message: `Error: ${addedPrescriptive.addPrescriptive.actionStatusMessage}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPrescriptive]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingPrescriptive ? "Editing Prescriptive" : "Add Prescriptive"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={2}>
            <TextField
              id="prescriptiveNumber-input"
              name="prescriptiveNumber"
              label="Number"
              type="number"
              value={formValues.prescriptiveNumber}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="prescriptiveDescription-input"
              name="prescriptiveDescription"
              label="Description"
              type="text"
              value={formValues.prescriptiveDescription}
              onChange={handleInputChange}
              multiline
              maxRows={10}
              fullWidth
              required
              helperText="required"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
