import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AssessmentParticipant } from "../../../models/AssessmentParticipant";

interface ParticipantTableProps {
  participants: AssessmentParticipant[];
  onAddParticipant: (participant: AssessmentParticipant) => void;
  onRemoveParticipant: (index: number) => void;
  onParticipantChanged: (e: any, index: number) => void;
}

export default function ParticipantTable({ participants, onAddParticipant, onRemoveParticipant, onParticipantChanged }: ParticipantTableProps) {
  // HANDLERS

  return (
    <Stack>
      <TableContainer
        component={Box}
        // justifyContent="center"
        // display="flex"
        // overflow="auto"
        // maxHeight="calc(100vh - 300px)"
        // margin="auto"
        // mt={0}
        // border="1px grey solid"
        border="none"
        boxShadow="none !important"
        boxSizing="border-box"
      >
        <Table aria-label="portal reports" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="center" sx={{ width: 50 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants?.map((participant, i) => (
              <TableRow key={participant.assessmentParticipantId ?? i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, td: { p: 1 } }}>
                <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                  {/* {participant.isEditing ? ( */}
                  <Box>
                    <TextField name="participantName" type="text" placeholder="enter name" value={participant.participantName} onChange={(e) => onParticipantChanged(e, i)} fullWidth size="small" />
                  </Box>
                  {/* ) : (
                    participant.name
                  )} */}
                </TableCell>
                <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                  {/* {participant.isEditing ? ( */}
                  <Box>
                    <TextField name="participantTitle" type="text" placeholder="enter title" value={participant.participantTitle} onChange={(e) => onParticipantChanged(e, i)} fullWidth size="small" />
                  </Box>
                  {/* ) : (
                    participant.title
                  )} */}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title="Remove">
                    <IconButton
                      onClick={() => {
                        onRemoveParticipant(i);
                      }}
                      color="default"
                      sx={{ p: "4px" }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" size="small" onClick={() => onAddParticipant({ participantName: "", participantTitle: "" })} sx={{ mt: 1, mr: "auto", ml: "auto" }}>
        + Add participant
      </Button>
    </Stack>
  );
}
