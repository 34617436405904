import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useRef, useState } from "react";
import { PowerBIWorkspaceOutput } from "../../graphQL/outputModels/PowerBIWorkspace.output";
import { Report } from "../../models/Report";
import { AddReportInput } from "../../graphQL/inputModels/addReportInput";
import { UpdateReportInput } from "../../graphQL/inputModels/updateReportInput";
import { UpdatedReportOutput } from "../../graphQL/outputModels/UpdatedReport.output";
import { AddedReportOutput } from "../../graphQL/outputModels/AddedReport.output";
import { ActionStatus } from "../../models/ActionStatus";
import { appNotification } from "../../apollo/PortalApolloProvider";
import { QUERIES } from "../../graphQL/queries";
import { MUTATIONS } from "../../graphQL/mutations";
import { PowBiReport } from "../../models/PowBiReport";
import { PortalsOutput } from "../../graphQL/outputModels/Portals.output";
import { CompanyOutput } from "../../graphQL/outputModels/Company.output";
import MemberPicker from "../MemberDialog/MemberPicker";

interface AddEditReportDialogProps {
  open: boolean;
  editingReport?: Report;
  onClose: (refetch: boolean) => void;
}

export default function AddEditReportDialog({ open, onClose, editingReport }: AddEditReportDialogProps) {
  // SETUP
  let defaultValues = {
    reportId: 0,
    reportName: "",
    powerBiWorkspaceId: "",
    powerBiReportId: "",
    portalIds: [] as number[],
    companyIds: [] as number[],
    allCompanies: false,
  };
  if (editingReport) {
    defaultValues = {
      reportId: editingReport?.reportId!,
      reportName: editingReport?.reportName!,
      powerBiWorkspaceId: editingReport?.powerBiWorkspaceId!,
      powerBiReportId: editingReport?.powerBiReportId!,
      portalIds: editingReport?.portals?.map((p) => p.portalId!) ?? [],
      companyIds: editingReport.companies?.map((c) => c.companyId!) ?? ([] as number[]),
      allCompanies: editingReport.allCompanies ?? false,
    };
  }
  const [_formValues, _setFormValues] = useState(defaultValues);
  const formValues = useRef(_formValues);

  function setFormValues(e: any) {
    formValues.current = e;
    _setFormValues(e);
  }
  const formValidated =
    formValues.current.reportName.length > 0 &&
    formValues.current.powerBiWorkspaceId.length > 1 &&
    formValues.current.powerBiReportId.length > 1 &&
    formValues.current.portalIds.length > 0 &&
    (formValues.current.allCompanies === true || formValues.current.companyIds.length > 0);

  // GRAPHQL
  const { loading: loadingPortals, data: portalData } = useQuery<PortalsOutput>(QUERIES.GET_PORTALS);
  const [addReport, { loading: loadingAddReport, data: addedReport }] = useMutation<AddedReportOutput, AddReportInput>(MUTATIONS.ADD_REPORT);
  const [updateReport, { loading: loadingUpdateReport, data: updatedReport }] = useMutation<UpdatedReportOutput, UpdateReportInput>(MUTATIONS.UPDATE_REPORT);
  const { loading: loadingCompanies, data: companies } = useQuery<CompanyOutput>(QUERIES.GET_COMPANIES, {});

  // -- GET POWERBI WORKSPACES/REPORTS
  const { loading: loadingWorkspaces, data: workspaces } = useQuery<PowerBIWorkspaceOutput>(QUERIES.GET_POWERBI_WORKSPACES, {
    fetchPolicy: "network-only",
  });

  // VARIABLES
  const loading = loadingAddReport || loadingUpdateReport || loadingWorkspaces || loadingPortals || loadingCompanies;
  const portals = portalData?.portals ?? [];
  const memoizedPowerBiReports = useMemo(() => {
    if (workspaces) {
      let gg = workspaces.powerBiWorkspaces.findIndex((w) => w.id === formValues.current.powerBiWorkspaceId);
      if (gg > -1) {
        return workspaces.powerBiWorkspaces[gg].powerBiReports;
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, formValues.current.powerBiWorkspaceId]);
  const reportDisabled = formValues.current.powerBiWorkspaceId === "";

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues.current,
      [name]: value,
    });
  };

  const handleMemberChanged = (id: number) => {
    const alreadyCheckedId = formValues.current.companyIds.find((cid) => cid === id);
    var newCompanyIds = [...formValues.current.companyIds];
    if (alreadyCheckedId) {
      newCompanyIds = newCompanyIds.filter((cid) => cid !== id);
    } else {
      newCompanyIds = [...newCompanyIds, id];
    }
    handleInputChange({ target: { name: "companyIds", value: newCompanyIds } });
  };

  const handleAllMembersChanged = () => {
    handleInputChange({ target: { name: "allCompanies", value: !formValues.current.allCompanies } });
  };

  const handleSubmit = (event: any) => {
    if (!formValidated) return;
    console.log(formValues.current);
    if (editingReport) {
      console.log("updating");
      updateReport({
        variables: {
          input: {
            reportId: formValues.current.reportId,
            reportName: formValues.current.reportName,
            powerBiReportId: formValues.current.powerBiReportId,
            powerBiWorkspaceId: formValues.current.powerBiWorkspaceId,
            portalIds: formValues.current.portalIds,
            allCompanies: formValues.current.allCompanies,
            companyIds: formValues.current.companyIds,
          },
        },
      });
    } else {
      console.log("adding");
      addReport({
        variables: {
          input: {
            reportName: formValues.current.reportName,
            powerBiReportId: formValues.current.powerBiReportId,
            powerBiWorkspaceId: formValues.current.powerBiWorkspaceId,
            portalIds: formValues.current.portalIds,
            allCompanies: formValues.current.allCompanies,
            companyIds: formValues.current.companyIds,
          },
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    console.log("addedReport changed");
    console.log(addedReport);
    if (addedReport?.addReport.actionStatus === ActionStatus.Success) {
      console.log("add success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully added" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedReport]);

  useEffect(() => {
    console.log("updatedReport changed");
    console.log(updatedReport);
    if (updatedReport?.updateReport.actionStatus === ActionStatus.Success) {
      console.log("update success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully updated" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedReport]);

  // USEMEMO
  const sortedCompanies = useMemo(() => {
    if (companies) {
      let g = [...companies.companies].sort((a, b) => a.companyName!.localeCompare(b.companyName!));
      return g;
    }
  }, [companies]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingReport ? "Editing Report" : "Add Report"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ maxHeight: "calc(100vh - 320px)" }}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <TextField
              id="reportName-input"
              name="reportName"
              label="Report Name"
              type="text"
              value={formValues.current.reportName}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth disabled={loading}>
              <InputLabel id="addreport-pbiWorkspace-label">{loadingWorkspaces ? "Loading Workspaces..." : "PowerBI Workspace *"}</InputLabel>
              <Select labelId="addreport-pbiWorkspace-label" name="powerBiWorkspaceId" label="PowerBI-Workspace" value={formValues.current.powerBiWorkspaceId} onChange={handleInputChange} required>
                {workspaces?.powerBiWorkspaces?.map((w, i) => {
                  return (
                    <MenuItem key={i} value={w.id}>
                      {w.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth disabled={loading}>
              <InputLabel id="addreport-pbiReport-label">{reportDisabled ? "" : "PowerBI Report *"}</InputLabel>
              <Select
                labelId="addreport-pbireport-label"
                name="powerBiReportId"
                label="PowerBI-Report"
                value={formValues.current.powerBiReportId}
                onChange={handleInputChange}
                required
                disabled={reportDisabled}
                displayEmpty
                renderValue={(selected) => {
                  if (reportDisabled) return "Choose a Workspace First";
                  if (selected) {
                    let selectedReport = memoizedPowerBiReports.find((r: PowBiReport) => r.id === selected);
                    return selectedReport?.name;
                  }
                  return undefined;
                }}
              >
                {memoizedPowerBiReports.map((r, i) => {
                  return (
                    <MenuItem key={i} value={r.id}>
                      {r.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <MemberPicker
              readonly={false}
              selectedCompanyIds={formValues.current.companyIds}
              companies={sortedCompanies ?? []}
              onCompanyChanged={handleMemberChanged}
              allCompanies={formValues.current.allCompanies}
              onAllCompaniesChanged={handleAllMembersChanged}
              maxHeight="22vh"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth disabled={loadingPortals}>
              <InputLabel id="addReport-portals-label">{loadingPortals ? "Loading Portals..." : "Portals *"}</InputLabel>
              <Select
                labelId="addReport-portals-label"
                name="portalIds"
                label="Portals"
                multiple
                value={formValues.current.portalIds}
                onChange={handleInputChange}
                required
                renderValue={(selected) =>
                  selected
                    .map((s) => {
                      let selectedPortal = portals.find((p) => p.portalId! === s);
                      return selectedPortal?.portalName;
                    })
                    .join(", ")
                }
              >
                {portals.map((p, i) => (
                  <MenuItem key={i} value={p.portalId!}>
                    <Checkbox checked={formValues.current.portalIds.indexOf(p.portalId!) > -1} />
                    <ListItemText primary={p.portalName} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>required, multi-select</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="contained" color="orange" onClick={handleSubmit} disabled={loading || !formValidated}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
