export interface AssessmentSiteVisitDocument {
    assessmentDocumentId?: number;
    sortOrder?: number;
    documentDescription?: string;
    isReviewed?: DocReviewEnum;
}

export enum DocReviewEnum {
    Unselected = "UNSELECTED",
    Yes = "YES",
    No = "NO",
    NA = "NA",
}

export enum DocReviewEnumNumber {
    Unselected = 0,
    Yes = 1,
    No = 2,
    NA = 3,
}

// export const DocReviewEnumToString = (d: DocReviewEnum): "Unselected" | "Yes" | "No" | "NA" => {
//     switch (d) {
//         case DocReviewEnum.Yes:
//             return "Yes"
//         case DocReviewEnum.No:
//             return "No"
//         case DocReviewEnum.NA:
//             return "NA"
//     }
//     return "Unselected";
// }