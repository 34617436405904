import { Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import PrescriptiveTable from "./AccordionContent/PrescriptiveTable";
import AddEditPrescriptiveDialog from "./Dialogs/AddEditPrescriptiveDialog";
import DeleteDialog from "./Dialogs/DeleteDialog";
import { AssessmentReportBoilerplate } from "../../models/AssessmentReportBoilerplate";
import AddEditQuestionResponseDialog from "./Dialogs/AddEditQuestionResponseDialog";
import AddEditSiteAssessmentBoilerplateDialog from "./Dialogs/AddEditSiteAssessmentBoilerplateDialog";
import QuestionResponseTable from "./AccordionContent/QuestionResponseTable";
import SiteAssessmentTable from "./AccordionContent/SiteAssessmentTable";
import LogoBox from "./AccordionContent/LogoBox";
import QuestionRegistrationTable from "./AccordionContent/QuestionRegistrationTable";
import { MasterFile } from "../../models/MasterFile";
import AddEditQuestionRegistrationDialog from "./Dialogs/AddEditQuestionRegistrationDialog";
import SiteVisitDocumentsPage from "./AccordionContent/SiteVisitDocumentsPage";
import { SiteVisitDocument } from "../../models/SiteVisitDocument";
import AddEditSiteVisitDocument from "./Dialogs/AddEditSiteVisitDocument";
import { Question } from "../../models/Question";
import { QuestionResponse } from "../../models/QuestionResponse";
import { Prescriptive } from "../../models/Prescriptive";

export type AssessmentDropdownType = "prescriptive" | "qresponses" | "question" | "assessment" | "scorecard" | "siteVisitDocument" | "logo";
export type AssessmentDialogObjectType = Prescriptive | QuestionResponse | Question | AssessmentReportBoilerplate | SiteVisitDocument;

export default function AssessmentAdministrationPage() {
  // SETUP
  const [dialogOpen, setDialogOpen] = useState<"addOrEdit" | "delete">();
  const [currDialog, setCurrDialog] = useState<AssessmentDropdownType>();
  const [currDialogObject, setCurrDialogObject] = useState<AssessmentDialogObjectType>();

  // HANDLERS
  const handleCloseDialog = () => {
    setDialogOpen(undefined);
    setCurrDialog(undefined);
    setCurrDialogObject(undefined);
  };

  const handleShowAddEditDialog = (currDialogArg: AssessmentDropdownType, dialogObjectType?: AssessmentDialogObjectType) => {
    setDialogOpen("addOrEdit");
    setCurrDialog(currDialogArg);
    setCurrDialogObject(dialogObjectType);
  };

  const handleShowDeleteDialog = (currDialogArg: AssessmentDropdownType, dialogObjectType: AssessmentDialogObjectType) => {
    setDialogOpen("delete");
    setCurrDialog(currDialogArg);
    setCurrDialogObject(dialogObjectType);
  };

  const getDeleteTitle = (): string => {
    switch (currDialog) {
      case "assessment":
      case "scorecard":
        return "" + (currDialogObject as AssessmentReportBoilerplate).boilerplateName;
      case "prescriptive":
        return `Precriptive ${(currDialogObject as Prescriptive).prescriptiveNumber}`;
      case "qresponses":
        return "" + (currDialogObject as QuestionResponse).responseDescription;
      case "question":
        return `Question ${(currDialogObject as Question).questionNumber}`;
      case "siteVisitDocument":
        return "" + (currDialogObject as SiteVisitDocument).documentDescription;
      case "logo":
        return `${(currDialogObject as MasterFile).fileName}.${(currDialogObject as MasterFile).fileExtension}`;
    }
    return "";
  };

  const getDeleteDescription = () => {
    return currDialog === "logo" ? "You are about to PERMANENTLY delete this from the master data, although it WILL STILL exist on previously created assessments. Are you sure?" : undefined;
  };

  // Styles
  const FlexBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    "button:first-of-type": {
      marginLeft: "auto",
    },
    "button:last-of-type": {
      marginLeft: 20,
      marginRight: 30,
    },
  });

  // RENDER
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={800} margin="auto">
        <Typography variant="h2" textAlign="start" sx={{ mb: 4 }}>
          Administration
        </Typography>

        {/* Dialogs */}
        {dialogOpen === "delete" && <DeleteDialog open={true} onClose={handleCloseDialog} dialogObject={currDialogObject!} title={getDeleteTitle()} description={getDeleteDescription()} />}

        {currDialog === "prescriptive" && dialogOpen === "addOrEdit" && (
          <AddEditPrescriptiveDialog open={true} onClose={handleCloseDialog} editingPrescriptive={currDialogObject ? (currDialogObject as Prescriptive) : undefined} />
        )}
        {currDialog === "qresponses" && dialogOpen === "addOrEdit" && (
          <AddEditQuestionResponseDialog open={true} onClose={handleCloseDialog} editingQuestionResponse={currDialogObject ? (currDialogObject as QuestionResponse) : undefined} />
        )}
        {currDialog === "question" && dialogOpen === "addOrEdit" && (
          <AddEditQuestionRegistrationDialog open={true} onClose={handleCloseDialog} editingQuestion={currDialogObject ? (currDialogObject as Question) : undefined} />
        )}
        {(currDialog === "assessment" || currDialog === "scorecard") && dialogOpen === "addOrEdit" && (
          <AddEditSiteAssessmentBoilerplateDialog
            open={true}
            onClose={handleCloseDialog}
            editingAssessmentReportBoilerplate={currDialogObject ? (currDialogObject as AssessmentReportBoilerplate) : undefined}
            assessReportId={currDialog === "assessment" ? 1 : 2}
          />
        )}
        {currDialog === "siteVisitDocument" && dialogOpen === "addOrEdit" && (
          <AddEditSiteVisitDocument open={true} onClose={handleCloseDialog} editingDocument={currDialogObject ? (currDialogObject as SiteVisitDocument) : undefined} />
        )}

        {/* Dropdowns */}
        <Accordion onChange={(e, exp) => {}} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <FlexBox>
              <Typography>Prescriptives</Typography>
              {/* {prescriptiveExpanded && <Button variant="outlined">Cancel</Button>} */}
              {/* {prescriptiveExpanded && <Button variant="contained">Save</Button>} */}
            </FlexBox>
          </AccordionSummary>
          <AccordionDetails>
            <PrescriptiveTable onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Question Responses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QuestionResponseTable onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Question Registration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QuestionRegistrationTable onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Site Visit Assessment Report Boilerplate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SiteAssessmentTable onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} assessReportType="assessment" />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Site Visit Documents</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SiteVisitDocumentsPage onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Year End Scorecard Report Boilerplate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SiteAssessmentTable onShowAddEditDialog={handleShowAddEditDialog} onShowDeleteDialog={handleShowDeleteDialog} assessReportType="scorecard" />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 1 }} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
            <Typography>Logo</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pl: 8, pr: 8, pt: 2, pb: 2 }}>
            <LogoBox onShowDeleteDialog={handleShowDeleteDialog} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
