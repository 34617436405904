import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";

type vars = {
    file?: File
    parentFolderId?: number
}

export default function useUploadQuery<T>({ file, parentFolderId }: vars = {}) {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ file, parentFolderId }: vars) => {
        async function run(file: File, parentFolderId: number) {
            console.log("executing upload")
            setLoading(true);
            const response = await FetchUtils.UPLOAD_FILE<T>(
                file,
                parentFolderId
            );
            console.log("done uploading")
            setLoading(false);
            setData(response);
            return response;
        }

        if (file && parentFolderId) {
            run(file, parentFolderId)
        }
    }, []);



    return {
        callApi: execute,
        loading: loading,
        data: data
    };
}