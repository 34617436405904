import { createContext, useEffect, useState } from 'react';
import { Portal } from './models/Portal';
import { Box } from '@mui/material';
import { MemberAssociation } from './models/MemberAssociation';
import useUser from './hooks/useUser';
import { makeVar, useReactiveVar } from '@apollo/client';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import TopNav from './components/Nav/TopNav';
import PublicNav from './components/Nav/PublicNav';
import LoadingPage from './components/LoadingPage/LoadingPage';

type UserContextType = {
  role: string;
  portals?: Portal[];
  isAdmin: boolean;
  validUser: boolean;
  userId: number;
  associations?: MemberAssociation[];
  activeMember: MemberAssociation;
  called: boolean;
};
export const UserContext = createContext<UserContextType | null>(null);
export const showNavVar = makeVar(true);

export function UserContextProvider({ children }: any) {
  // SETUP
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const showNav = useReactiveVar(showNavVar);
  const [user, setUser] = useState<UserContextType | null>(null);
  const { role, portals, isAdmin, validUser, userId, associations, loading: userLoading, called } = useUser();
  const authLoading = inProgress !== InteractionStatus.None;

  function setActiveMember(member: MemberAssociation) {
    setUser({ ...user!, activeMember: { ...member } });
  }

  useEffect(() => {
    if (role && portals && validUser && userId && associations) {
      setUser({ role, portals, isAdmin, validUser, userId, associations, activeMember: {} as MemberAssociation, called });
      if (!user?.activeMember) {
        setUser({ role, portals, isAdmin, validUser, userId, associations, activeMember: associations[0], called });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associations, isAdmin, portals, role, userId, validUser]);

  // NAV
  const Nav = function () {
    if (!showNav) return null;
    if (isAuthenticated && portals && validUser) return <TopNav portals={portals} admin={isAdmin} setActiveMember={setActiveMember} />;
    if (!authLoading && !userLoading && (!isAuthenticated || !validUser)) return <PublicNav />;
    return null;
  };

  function GetChildren() {
    if (userLoading || inProgress !== InteractionStatus.None) return <LoadingPage />;
    // if (validUser && role === '') return <LoadingPage />;
    return children;
  }

  // RENDER
  return (
    <UserContext.Provider value={user}>
      <Box display='flex' flexDirection='column' height='100vh'>
        <Nav />
        <GetChildren />
      </Box>
    </UserContext.Provider>
  );
}
