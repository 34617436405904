import { useLazyQuery } from "@apollo/client";
import { Box, Button, Container, IconButton, Link, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { useContext, useEffect, useState } from "react";
import { PortalOutput } from "../../graphQL/outputModels/Portal.output";
import { QUERIES } from "../../graphQL/queries";
import AddEditLinkDialog from "./AddEditLinkDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PortalLink } from "../../models/PortalLink";
import useUser from "../../hooks/useUser";
import DeleteLinkDialog from "./DeleteLinkDIalog";
import { AllRoutes } from "../../routing/Routes";
import { UserContext } from "../../UserContextProvider";
import { GetPortalLinksInput } from "../../graphQL/inputModels/getPortalLinks.input";

export default function LinksPage() {
  // SETUP
  const { portal } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currLink, setCurrLink] = useState<PortalLink>();
  const { isAdmin, portals } = useUser();
  const currPortal = portals?.find((p) => p.portalName?.toLocaleLowerCase() === portal?.toLocaleLowerCase());
  const user = useContext(UserContext);

  // GRAPHQL
  const [getLinks, { loading, data: portalData }] = useLazyQuery<PortalOutput, GetPortalLinksInput>(QUERIES.GET_PORTAL_LINKS, {
    fetchPolicy: "cache-and-network",
    variables: {
      portalId: currPortal?.portalId ?? 0,
      memberId: user?.activeMember.companyId ?? 0
    },
  });

  // HANDLERS
  const closeLinkDialog = (refetch: boolean) => {
    setAddEditDialogOpen(false);
    setDeleteDialogOpen(false);
    if (refetch) getLinks();
  };

  // USEEFFECTS
  useEffect(() => {
    if (portals?.length !== undefined && currPortal === undefined) {
      navigate(AllRoutes.NotFound);
    } else if (currPortal) {
      getLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals, currPortal, location]);

  // RENDER
  return (
    <Container maxWidth={false} sx={{ p: 5 }}>
      <Box maxWidth={isAdmin ? 900 : 600} margin="auto">
        <Typography variant="h2" textAlign="start">
          Links
        </Typography>

        {addEditDialogOpen && <AddEditLinkDialog open={addEditDialogOpen} onClose={closeLinkDialog} editingLink={currLink} />}

        {deleteDialogOpen && currLink && <DeleteLinkDialog open={deleteDialogOpen} onClose={closeLinkDialog} link={currLink} />}

        {isAdmin && (
          <Button
            variant="contained"
            color="orange"
            disabled={false}
            onClick={() => {
              setCurrLink(undefined);
              setAddEditDialogOpen(true);
            }}
          >
            Add link
          </Button>
        )}

        <TableContainer component={Box} justifyContent="center" display="flex" overflow="auto" maxHeight="calc(100vh - 300px)" margin="auto" mt={5} border="1px grey solid" boxSizing="border-box">
          <Table aria-label="portal reports" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Link Name</TableCell>
                <TableCell align="center">URL</TableCell>
                {/* <TableCell align="center"></TableCell> */}
                <TableCell align="center" sx={{ width: isAdmin ? 100 : 50 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* show loading bars if loading */}
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  {/* <TableCell>
                  <Skeleton />
                </TableCell> */}
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ) : (
                portalData?.portal?.links?.map((l, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left" sx={{ overflowWrap: "break-word" }}>
                      {l.displayName ?? "-"}
                    </TableCell>
                    <TableCell align="center" sx={{ overflowWrap: "anywhere" }}>
                      {l.url ?? "-"}
                    </TableCell>
                    {/* <TableCell align="center">
                    {report.powerBiReportId ?? "-"}
                  </TableCell> */}
                    <TableCell align="center">
                      <Tooltip title="Open in new tab">
                        <Link href={l.url!} target="_blank" rel="noopener noreferrer">
                          <IconButton sx={{ p: "4px" }}>
                            <OpenInNewOutlinedIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      {isAdmin && (
                        <Tooltip title="Edit link">
                          <IconButton
                            onClick={() => {
                              setCurrLink(l);
                              setAddEditDialogOpen(true);
                            }}
                            color="default"
                            sx={{ p: "4px" }}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isAdmin && (
                        <Tooltip title="Remove link">
                          <IconButton
                            onClick={() => {
                              setCurrLink(l);
                              setDeleteDialogOpen(true);
                            }}
                            color="default"
                            sx={{ p: "4px" }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (portalData?.portal.links?.length ?? 0) === 0 && <Typography sx={{ mt: 3 }}>There are no links</Typography>}
      </Box>
    </Container>
  );
}
