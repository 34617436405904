import gql from "graphql-tag";

const GET_POWERBI_WORKSPACES = gql`
  query GetPowerBIWorkspaces {
    powerBiWorkspaces {
      id
      name
      powerBiReports {
        id
        name
      }
    }
  }
`;

const GET_PORTAL = gql`
  query GetPortal($portalId: Int!, $memberId: Long!) {
    portal(portalId: $portalId, memberId: $memberId) {
      portalId
      portalName
      reports {
        reportId
        reportName
        powerBiWorkspaceId
        powerBiReportId
        portals {
          portalName
          portalId
        }
        companies {
          companyId
        }
        allCompanies
      }
    }
  }
`;

const GET_PORTALS = gql`
  query GetPortals {
    portals {
      portalId
      portalName
    }
  }
`;

const GET_PORTAL_FOLDER = gql`
  query GetPortalAndFolders($portalId: Int!, $memberId: Long!) {
    portal(portalId: $portalId, memberId: $memberId) {
      portalId
      portalName
      folder {
        folderId
        folderName
      }
    }
  }
`;

const GET_PORTAL_LINKS = gql`
  query GetPortal($portalId: Int!, $memberId: Long!) {
    portal(portalId: $portalId, memberId: $memberId) {
      portalId
      portalName
      links {
        linkId
        url
        displayName
        companies {
          companyId
        }
        allCompanies
        portals {
          portalId
        }
        allPortals
      }
    }
  }
`;

const GET_REPORT_EMBED = gql`
  query GetReportEmbedToken($reportId: String!) {
    embedReport(reportId: $reportId) {
      embedUrl
      embedToken
    }
  }
`;

const GET_REPORT = gql`
  query GetReport($reportId: Int!) {
    report(reportId: $reportId) {
      reportId
      reportName
      powerBiWorkspaceId
      powerBiReportId
      powerBiEmbed {
        embedUrl
        embedToken
      }
    }
  }
`;

const GET_ROLES = gql`
  query GetRole {
    role {
      roleName
      access
      portals {
        portalId
        portalName
      }
      userId
      memberAssociations {
        companyName
        companyId
      }
    }
  }
`;

const GET_EXPORT_JOB = gql`
  query GetExportJob($reportId: Int!) {
    exportJob(reportId: $reportId) {
      exportJobId
    }
  }
`;

const GET_FOLDER = gql`
  query GetFolder($folderId: Int!) {
    folder(folderId: $folderId) {
      folderId
      parentId
      companyId
      portalId
      folderName
      allCompanies
      folders {
        folderId
        parentId
        companyId
        portalId
        folderName
        allCompanies
      }
      files {
        fileId
        folderId
        fileName
        fileExtension
        fileSizeKb
      }
    }
  }
`;

const GET_BLOB = gql`
  query GetBlob($fileId: Int!) {
    blob(fileId: $fileId) {
      url
    }
  }
`;

const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      companyId
      companyName
      companyPolicyNo
    }
  }
`;

const GET_EVENTS = gql`
  query GetEvents {
    events {
      eventId
      eventName
      eventTypeId
      eventType {
        eventTypeId
        eventTypeName
      }
      startDate
      endDate
      timezoneId
      allDay
      location
      eventShortDescription
      eventFullDescription
      registrationLink
      pictureBlobUrl
      hotelReservationLink
      active
      timezone {
        abbrv
        standardOffset
      }
    }
    eventTypes {
      eventTypeId
      eventTypeName
    }
  }
`;

const GET_EVENT = gql`
  query GetEvent($eventId: Int!) {
    event(eventId: $eventId) {
      eventId
      eventName
      eventTypeId
      eventType {
        eventTypeId
        eventTypeName
      }
      startDate
      endDate
      timezoneId
      allDay
      location
      eventShortDescription
      eventFullDescription
      registrationLink
      pictureBlobUrl
      hotelReservationLink
      active
      attachments {
        fileId
        blobId
        fileName
        fileExtension
      }
      timezone {
        timezoneId
        abbrv
        standardOffset
      }
    }
    eventTypes {
      eventTypeId
      eventTypeName
    }
    timezones {
      timezoneId
      displayName
      abbrv
      standardOffset
    }
  }
`;

const GET_ADDEVENT_FORM = gql`
query GetAddEventForm {
  eventTypes {
    eventTypeId
    eventTypeName
  }
  timezones {
    timezoneId
    displayName
    abbrv
    standardOffset
  }
}
`;

const GET_LOGS = gql`
query GetLogs($startRange: DateTime, $endRange: DateTime) {
  logs(startRange: $startRange, endRange: $endRange) {
    portalName
    action
    value
    metric
    userEmail
    firstName
    lastName
    createdDate
  }
}
`;

const GET_PRESCRIPTIVES = gql`
  query GetPrescriptives {
    prescriptives {
      prescriptiveId
      prescriptiveNumber
      prescriptiveDescription
    }
  }
`;

const GET_QUESTION_RESPONSES = gql`
  query GetQuestionResponses {
    questionResponses {
      responseId
      responseDescription
      responseAbbreviation
      ratingPoints
      ratingCompliant
      isDefault
    }
  }
`;

// const GET_ASSESSMENT_REPORT_BOILERPLATE = gql`
//   query GetAssessmentReportBoilerplate {
//     assessmentReportBoilerplate {
//       boilerplateId
//       assessmentReportSectionFK
//       boilerplateName
//       boilerplateText
//       isHtmlText
//       boilerplateSort
//       isUserEditable
//     }
//   }
// `;

const GET_ASSESSMENT_REPORT_BOILERPLATES = gql`
  query GetAssessmentReportBoilerplates($assessReportId: Int) {
    assessmentReportBoilerplates(assessReportId: $assessReportId) {
      boilerplateId
      assessmentReportSectionFK
      boilerplateName
      boilerplateText
      isHtmlText
      boilerplateSort
      isUserEditable
    }
  }
`;

const GET_MASTER_FILES = gql`
  query GetMasterFiles {
    masterFiles {
      fileId
      blobId
      directoryName
      fileName
      fileExtension
      fileSizeKb
      isCoverImage
      isDefault
      blobUrl
    }
  }
`;

const GET_MASTER_FILE = gql`
  query GetMasterFile($fileId: Int!) {
    masterFile(fileId: $fileId) {
      blobUrl
    }
  }
`;

const GET_QUESTIONS = gql`
  query GetQuestions($prescriptiveId: Int) {
    questions(prescriptiveId: $prescriptiveId) {
      questionId
      questionDescription
      nonCompliantRecommendation
      isDefault
      isExtraCredit
      prescriptiveId
      prescriptive {
        prescriptiveId
        prescriptiveNumber
        prescriptiveDescription
      }
      questionNumber
      questionNumberSort
      questionResponses {
        responseId
        responseDescription
        responseAbbreviation
        ratingPoints
        ratingCompliant
        isDefault
      }
    }
  }
`;

const GET_SITE_VISIT_DOCUMENTS = gql`
  query GetSiteVisitDocuments {
    siteVisitDocuments {
      documentId
      documentDescription
      sortOrder
    }
  }
`;

const GET_ASSESSMENTS = gql`
  query GetAssessments($memberId: Long!) {
    assessments(memberId: $memberId) {
      assessmentId
      assessmentName
      assessmentDate
      assessmentTypeId
      memberId
      memberName
      memberPolicyNumber
      assessmentQuestions {
        assessmentQuestionId
        selectedAssessmentQuestionResponseId
      }
    }
  }
`;

const GET_ASSESSMENT = gql`
  query GetAssessment($assessmentId: Int!) {
    assessment(assessmentId: $assessmentId) {
      assessmentId
      assessmentName
      assessmentDate
      assessmentTypeId
      memberId
      memberName
      memberPolicyNumber
      performedBy
      assessmentPrescriptives {
        assessmentPrescriptiveId
        assessmentPrescriptiveNumber
        assessmentPrescriptiveDescription
      }
      assessmentSiteVisitDocuments {
        assessmentDocumentId
        sortOrder
        documentDescription
        isReviewed
      }
      assessmentParticipants {
        assessmentParticipantId
        assessmentId
        participantName
        participantTitle
      }
      assessmentQuestions {
        assessmentId
        assessmentQuestionId
        assessmentPrescriptiveId
        assessmentQuestionDescription
        assessmentNonCompliantRecommendation
        assessmentQuestionNumber
        questionNumberSort
        isDefault
        isExtraCredit
        selectedAssessmentQuestionResponseId
        assessmentCommentCount
        assessmentQuestionResponses {
          assessmentResponseId
          assessmentResponseDescription
          assessmentResponseAbbreviation
          ratingPoints
          ratingCompliant
          isDefault
          isSelectedResponse
        }
        assessmentPrescriptive {
          assessmentPrescriptiveId
          assessmentPrescriptiveNumber
          assessmentPrescriptiveDescription
        }
        assessmentAttachments {
          assessmentAttachmentId
        }
      }
      assessmentAttachments {
        assessmentAttachmentId
        blobId
        directoryName
        assessmentQuestionId
        fileName
        fileExtension
        fileSizeKb
      }
    }
  }
`;

const GET_ASSESSMENT_ATTACHMENTS = gql`
  query GetAssessmentAttachmentsByAssessmentId($assessmentId: Int!, $assessmentQuestionId: Int) {
    assessmentAttachmentsByAssessmentId(assessmentId: $assessmentId, assessmentQuestionId: $assessmentQuestionId) {
      assessmentId
      assessmentAttachmentId
      blobId
      directoryName
      assessmentQuestionId
      fileName
      fileExtension
      fileSizeKb
      blobUrl
      createdByUserId
    }
  }
`;

const GET_ASSESSMENT_COMMENTS_BY_QUESTION = gql`
  query GetAssessmentCommentsByQuestionId($assessmentQuestionId: Int!) {
    assessmentCommentsByQuestionId(assessmentQuestionId: $assessmentQuestionId) {
      assessmentCommentId
      assessmentId
      assessmentQuestionId
      assessmentCommentDescription
      createdBy
      createdByFullName
      createdDate
      modifiedBy
      modifiedByFullName
      modifiedDate
    }
  }
`;

const GET_MEMBER_SUMMARY_SCORE_DATA = gql`
  query GetMemberSummaryScoreData($memberId: Long!) {
    memberSummaryScoreData(memberId: $memberId) {
      memberId
      yESSummaryPrescriptiveScores {
        assessmentId
        assessmentDate
        prescriptiveLabel
        prescriptiveScorePercent
      }
      assessmentId
      compliantCount
      noncompliantCount
      otherCount
    }
  }
`

const GET_USERS = gql`
  query GetUsers {
    users {
      userId
      fullName
    }
  }
`


export const QUERIES = {
  GET_POWERBI_WORKSPACES,
  GET_REPORT,
  GET_PORTAL,
  GET_PORTALS,
  GET_PORTAL_FOLDER,
  GET_PORTAL_LINKS,
  GET_REPORT_EMBED,
  GET_ROLES,
  GET_EXPORT_JOB,
  GET_FOLDER,
  GET_BLOB,
  GET_COMPANIES,
  GET_EVENTS,
  GET_EVENT,
  GET_ADDEVENT_FORM,
  GET_LOGS,
  GET_PRESCRIPTIVES,
  GET_QUESTION_RESPONSES,
  GET_ASSESSMENT_REPORT_BOILERPLATES,
  GET_MASTER_FILES,
  GET_MASTER_FILE,
  GET_QUESTIONS,
  GET_ASSESSMENTS,
  GET_ASSESSMENT,
  GET_ASSESSMENT_ATTACHMENTS,
  GET_ASSESSMENT_COMMENTS_BY_QUESTION,
  GET_SITE_VISIT_DOCUMENTS,
  GET_MEMBER_SUMMARY_SCORE_DATA,
  GET_USERS
};
