import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { ActionStatus } from "../../../models/ActionStatus";
import { appNotification } from "../../../apollo/PortalApolloProvider";
import { QUERIES } from "../../../graphQL/queries";
import { MUTATIONS } from "../../../graphQL/mutations";
import { QuestionResponse } from "../../../models/QuestionResponse";
import { AddedQuestionResponseOutput } from "../../../graphQL/outputModels/AddedQuestionResponse.output";
import { AddQuestionResponseInput } from "../../../graphQL/inputModels/addQuestionResponse.input";
import { QuestionResponsesOutput } from "../../../graphQL/outputModels/QuestionResponse.output";
import { UpdateQuestionResponseInput } from "../../../graphQL/inputModels/updateQuestionResponse.input";
import { UpdatedQuestionResponseOutput } from "../../../graphQL/outputModels/UpdatedQuestionResponse.output";

interface AddEditQuestionResponseDialogProps {
  open: boolean;
  editingQuestionResponse?: QuestionResponse;
  onClose: (refetch: boolean) => void;
}

export default function AddEditQuestionResponseDialog({ open, onClose, editingQuestionResponse }: AddEditQuestionResponseDialogProps) {
  // SETUP
  let defaultValues = {
    responseId: 0,
    responseDescription: "",
    responseAbbreviation: "",
    ratingPoints: 0 as number | null,
    ratingCompliance: false as boolean | null,
    ratingIsDefault: false,
  };
  if (editingQuestionResponse) {
    defaultValues = {
      responseId: editingQuestionResponse?.responseId!,
      responseDescription: editingQuestionResponse?.responseDescription!,
      responseAbbreviation: editingQuestionResponse?.responseAbbreviation!,
      ratingPoints: editingQuestionResponse?.ratingPoints ?? null,
      ratingCompliance: editingQuestionResponse?.ratingCompliant ?? null,
      ratingIsDefault: editingQuestionResponse?.isDefault!,
    };
  }
  const [formValues, setFormValues] = useState(defaultValues);
  const formValidated = formValues.responseDescription.length > 0 && formValues.responseAbbreviation.length > 0;

  // GRAPHQL
  const [addQuestionResponse, { loading: loadingAddQuestionResponse, data: addedQuestionResponse }] = useMutation<AddedQuestionResponseOutput, AddQuestionResponseInput>(
    MUTATIONS.ADD_QUESTION_RESPONSE
  );

  const [updateQuestionResponse, { loading: loadingUpdateQuestionResponse, data: updatedQuestionResponse }] = useMutation<UpdatedQuestionResponseOutput, UpdateQuestionResponseInput>(
    MUTATIONS.UPDATE_QUESTION_RESPONSE
  );

  // VARIABLES
  const loading = loadingAddQuestionResponse || loadingUpdateQuestionResponse;

  // FORM HANDLERS
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    console.log(name);
    console.log(value);
    console.log(formValues.ratingCompliance);
    if (name === "ratingPoints" && value !== "" && formValues.ratingCompliance === null) {
      setFormValues({
        ...formValues,
        [name]: value,
        ratingCompliance: false,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleCheckBoxChange = (e: any) => {
    const { name, checked, id } = e.target;
    if (id === "ratingPointsNA-checkbox") {
      setFormValues({
        ...formValues,
        [name]: checked ? null : 0,
        ratingCompliance: checked ? null : false,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: checked,
      });
    }
  };

  const handleRadioChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "ratingCompliance") {
      setFormValues({
        ...formValues,
        [name]: value === "" ? null : value,
        ratingPoints: value === "" ? null : formValues.ratingCompliance === null ? 0 : formValues.ratingPoints,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event: any) => {
    if (!formValidated) return;
    console.log(formValues);
    if (editingQuestionResponse) {
      console.log("updating");
      updateQuestionResponse({
        variables: {
          input: {
            responseId: formValues.responseId,
            responseDescription: formValues.responseDescription,
            responseAbbreviation: formValues.responseAbbreviation,
            ratingPoints: formValues.ratingPoints !== null ? +formValues.ratingPoints : null,
            ratingCompliant: formValues.ratingCompliance !== null ? Boolean(formValues.ratingCompliance) : null,
            isDefault: formValues.ratingIsDefault,
          },
        },
        update: (cache, { data }) => {
          if (data?.updateQuestionResponse.actionStatus === ActionStatus.Success) {
            const existingQuestionResponses = cache.readQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES });
            if (existingQuestionResponses) {
              const newQuestionResponses = existingQuestionResponses.questionResponses.map((p: QuestionResponse) => {
                if (p.responseId === data?.updateQuestionResponse.responseId) {
                  return { ...p, ...data!.updateQuestionResponse };
                } else {
                  return p;
                }
              });
              cache.writeQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES, data: { questionResponses: newQuestionResponses } });
            }
          }
        },
      });
    } else {
      console.log("adding");
      addQuestionResponse({
        variables: {
          input: {
            responseDescription: formValues.responseDescription,
            responseAbbreviation: formValues.responseAbbreviation,
            ratingPoints: formValues.ratingPoints ? +formValues.ratingPoints : null,
            ratingCompliant: formValues.ratingCompliance !== null ? Boolean(formValues.ratingCompliance) : null,
            isDefault: formValues.ratingIsDefault,
          },
        },
        update: (cache, { data }) => {
          if (data?.addQuestionResponse.actionStatus === ActionStatus.Success) {
            const existingQuestionResponses = cache.readQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES });
            if (existingQuestionResponses) {
              const newQuestionResponses = [...existingQuestionResponses.questionResponses, { ...data!.addQuestionResponse }];
              cache.writeQuery<QuestionResponsesOutput>({ query: QUERIES.GET_QUESTION_RESPONSES, data: { questionResponses: newQuestionResponses } });
            }
          }
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    console.log("addedQuestionResponse changed");
    if (addedQuestionResponse?.addQuestionResponse.actionStatus === ActionStatus.Success) {
      console.log("add success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully added" });
    } else if (addedQuestionResponse?.addQuestionResponse.actionStatus === ActionStatus.Fail) {
      console.error(addedQuestionResponse.addQuestionResponse.actionStatusMessage);
      appNotification({ severity: "warning", message: `Error: ${addedQuestionResponse.addQuestionResponse.actionStatusMessage}` });
    } else if (addedQuestionResponse?.addQuestionResponse.actionStatus === ActionStatus.Error) {
      console.error(addedQuestionResponse.addQuestionResponse.actionStatusMessage);
      appNotification({ severity: "error", message: `Error: ${addedQuestionResponse.addQuestionResponse.actionStatusMessage}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedQuestionResponse]);

  useEffect(() => {
    console.log("updatedQuestionResponse changed");
    if (updatedQuestionResponse?.updateQuestionResponse.actionStatus === ActionStatus.Success) {
      console.log("update success!");
      onClose(true);
      appNotification({ severity: "success", message: "Successfully updated" });
    } else if (addedQuestionResponse?.addQuestionResponse.actionStatus === ActionStatus.Fail) {
      console.error(addedQuestionResponse.addQuestionResponse.actionStatusMessage);
      appNotification({ severity: "warning", message: `Error: ${addedQuestionResponse.addQuestionResponse.actionStatusMessage}` });
    } else if (addedQuestionResponse?.addQuestionResponse.actionStatus === ActionStatus.Error) {
      console.error(addedQuestionResponse.addQuestionResponse.actionStatusMessage);
      appNotification({ severity: "error", message: `Error: ${addedQuestionResponse.addQuestionResponse.actionStatusMessage}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedQuestionResponse]);

  // RENDER
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {editingQuestionResponse ? "Editing Question Response" : "Add Question Response"}
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          color="default"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <TextField
              id="responseDescription-input"
              name="responseDescription"
              label="Description"
              type="text"
              value={formValues.responseDescription}
              onChange={handleInputChange}
              fullWidth
              required
              helperText="required"
            />
            <TextField
              id="responseDescription-input"
              name="responseAbbreviation"
              label="Abbreviation"
              type="text"
              value={formValues.responseAbbreviation}
              onChange={handleInputChange}
              required
              helperText="required"
              sx={{ width: 175 }}
            />
          </Stack>

          <Divider />

          <Stack direction="row" alignItems="center" spacing={3}>
            <TextField id="ratingPoints-input" name="ratingPoints" label="Points" type="number" value={formValues.ratingPoints ?? ""} onChange={handleInputChange} sx={{ width: 100 }} />
            <FormGroup>
              <FormControlLabel control={<Checkbox id="ratingPointsNA-checkbox" name="ratingPoints" checked={formValues.ratingPoints === null} onChange={handleCheckBoxChange} />} label="N/A" />
            </FormGroup>
          </Stack>

          <Divider />

          <Stack direction="row" alignItems="center">
            {/* <FormControlLabel
              control={<Checkbox id="ratingCompliance-checkbox" name="ratingCompliance" checked={formValues.ratingCompliance || false} onChange={handleCheckBoxChange} />}
              label="Compliant"
            />
            <FormControlLabel
              control={<Checkbox id="ratingNonCompliance-checkbox" name="ratingCompliance" checked={!formValues.ratingCompliance || false} onChange={handleCheckBoxChange} />}
              label="Non Compliant"
            />
            <FormControlLabel
              control={<Checkbox id="ratingComplianceNA-checkbox" name="ratingCompliance" checked={formValues.ratingCompliance === null} onChange={handleCheckBoxChange} />}
              label="N/A"
            /> */}
            <FormControl>
              <FormLabel id="rating-radio-buttons-group-label">Compliance</FormLabel>
              <RadioGroup aria-labelledby="rating-radio-buttons-group-label" value={formValues.ratingCompliance ?? ""} name="rating-radio-buttons-group" onChange={handleRadioChange}>
                <FormControlLabel value={true} control={<Radio id="ratingCompliance-radio" name="ratingCompliance" />} label="Compliant" />
                <FormControlLabel value={false} control={<Radio id="ratingNonCompliance-radio" name="ratingCompliance" />} label="Non-Compliant" />
                <FormControlLabel value={""} control={<Radio id="ratingComplianceNA-radio" name="ratingCompliance" />} label="N/A" />
              </RadioGroup>
            </FormControl>
          </Stack>

          <Divider />

          <Stack direction="row">
            <FormControlLabel
              control={<Checkbox disabled id="ratingIsDefault-checkbox" name="ratingIsDefault" checked={formValues.ratingIsDefault} onChange={handleCheckBoxChange} />}
              label="Is Default"
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} size={30} />}
        <Button variant="outlined" color="orange" disabled={loading} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="orange" disabled={loading || !formValidated} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
