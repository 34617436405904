import { Route, Routes as RouteList } from "react-router-dom";
import Logout from "../components/Auth/Logout";
import PrivateLandingPage from "../components/LandingPage/PrivateLandingPage";
import PrivateRoute from "./PrivateRoute";
import { AllRoutes } from "./Routes";
import ReportPage from "../components/Report/ReportPage";
import StatusPage from "../components/StatusPage/StatusPage";
import EmbeddedReportPage from "../components/EmbeddedReport/EmbeddedReportPage";
import FileStoragePage from "../components/FileStorage/FileStoragePage";
import LinksPage from "../components/Links/LinkPage";
import EventListPage from "../components/Events/EventListPage";
import EventDetailPage from "../components/Events/EventDetailPage";
import MainContainer from "./MainContainer";
import LogReportPage from "../components/LogReport/LogReportPage";
import AssessmentListPage from "../components/Assessments/AssessmentListPage";
import AssessmentAdministrationPage from "../components/AssessmentAdministration/AssessmentAdministrationPage";
import AssessmentDetailPage from "../components/Assessments/AssessmentDetail/AssessmentDetailPage";
import VideoListPage from "../components/VideoTraining/VideoListPage";
import PSOPage from "../components/PSO/PSOPage";

export default function AppRouter() {
  return (
    <RouteList>
      <Route
        path={AllRoutes.Home}
        element={
          <PrivateRoute navigateToPublicPage={true}>
            <MainContainer>
              <PrivateLandingPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/pso`}
        element={
          <PrivateRoute>
            <MainContainer>
              <PSOPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal`}
        element={
          <PrivateRoute>
            <MainContainer>
              <ReportPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Documents}`}
        element={
          <PrivateRoute>
            <MainContainer>
              <FileStoragePage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Links}`}
        element={
          <PrivateRoute>
            <MainContainer>
              <LinksPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Assessments}/:assessmentId`}
        element={
          <PrivateRoute>
            <MainContainer>
              <AssessmentDetailPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Assessments}`}
        element={
          <PrivateRoute>
            <MainContainer>
              <AssessmentListPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.AssessmentAdministration}`}
        element={
          <PrivateRoute admin>
            <MainContainer>
              <AssessmentAdministrationPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Events}/add`}
        element={
          <PrivateRoute>
            <MainContainer>
              <EventDetailPage isAdding={true} />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Events}/:eventId`}
        element={
          <PrivateRoute>
            <MainContainer>
              <EventDetailPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.Events}`}
        element={
          <PrivateRoute>
            <MainContainer>
              <EventListPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/:reportId`}
        element={
          <PrivateRoute>
            <MainContainer>
              <EmbeddedReportPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.Portal.main}/:portal/${AllRoutes.Portal.VideoTraining}`}
        element={
          <PrivateRoute admin>
            <MainContainer>
              <VideoListPage />
            </MainContainer>
          </PrivateRoute>
        }
      />
      <Route
        path={`${AllRoutes.LogReport}`}
        element={
          <PrivateRoute admin>
            <MainContainer>
              <LogReportPage />
            </MainContainer>
          </PrivateRoute>
        }
      />

      <Route
        path={`${AllRoutes.Logout}`}
        element={
          <PrivateRoute>
            <MainContainer>
              <Logout />
            </MainContainer>
          </PrivateRoute>
        }
      />

      <Route
        path={AllRoutes.Unauthorized}
        element={
          <MainContainer>
            <StatusPage statusCode={401} />
          </MainContainer>
        }
      />
      <Route
        path={AllRoutes.Forbidden}
        element={
          <MainContainer>
            <StatusPage statusCode={403} />
          </MainContainer>
        }
      />
      <Route
        path={AllRoutes.NotFound}
        element={
          <MainContainer>
            <StatusPage statusCode={404} />
          </MainContainer>
        }
      />
      <Route
        path="*"
        element={
          <MainContainer>
            <StatusPage statusCode={404} />
          </MainContainer>
        }
      />
    </RouteList>
  );
}
