import { Box, CircularProgress, IconButton, LinearProgress, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

export default function VideoListComponent() {
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </>
  );
}
