import { useCallback, useState } from "react"
import { FetchUtils } from "../utils/fetch.utils";

type vars = {
    file?: File
    assessmentId?: number
    assessmentQuestionId?: number
}

export default function useUploadAssessmentAttachmentQuery<T>({ file, assessmentId, assessmentQuestionId }: vars = {}) {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(false);


    const execute = useCallback(({ file, assessmentId, assessmentQuestionId }: vars) => {
        async function run(file: File) {
            console.log("executing upload")
            setLoading(true);
            const response = await FetchUtils.UPLOAD_ASSESSMENT_ATTACHMENT<T>(
                file,
                assessmentId!,
                assessmentQuestionId
            );
            console.log("done uploading")
            setLoading(false);
            setData(response);
            return response;
        }

        if (file) {
            run(file)
        }
    }, []);



    return {
        callApi: execute,
        loading: loading,
        data: data
    };
}