import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Grid, Paper, IconButton, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { useEffect, useState, useRef } from "react";
import useUser from "../../../hooks/useUser";
import { AssessmentQuestionCustom } from "../../../models/AssessmentQuestion";
import { QUERIES } from "../../../graphQL/queries";
import { DateUtils } from "../../../utils/date.utils";
import { AssessmentComment, AssessmentCommentCustom } from "../../../models/AssessmentComment";
import { MUTATIONS } from "../../../graphQL/mutations";
import DeleteCommentDialog from "./DeleteCommentDialog";
import { UpdatedAssessmentCommentOutput } from "../../../graphQL/outputModels/UpdatedAssessmentComment.output";
import { UpdateAssessmentCommentInput } from "../../../graphQL/inputModels/updateAssessmentComment.input";
import { ActionStatus } from "../../../models/ActionStatus";
import { AssessmentQuestionCommentsOutput } from "../../../graphQL/outputModels/AssessmentQuestionComment.output";
import { GetAssessmentQuestionCommentsInput } from "../../../graphQL/inputModels/getAssessmentQuestionComments.input";
import { NotificationUtils } from "../../../utils/notification.utils";
import { Editor as TinyMCEEditor } from "tinymce";
import FormatEditor from "../../FormatEditor/FormatEditor";
import parse from "html-react-parser";

type MemQCType = {
  q: AssessmentQuestionCustom;
  c: AssessmentCommentCustom;
};

export default function QuestionCommentComponent({ q, c }: MemQCType) {
  // SETUP
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { isAdmin, userId } = useUser();
  const commentEditorRef = useRef<TinyMCEEditor>();

  // GRAPHQL
  const [updateComment, { loading: loadingUpdateComment, data: updatedCommentData }] = useMutation<UpdatedAssessmentCommentOutput, UpdateAssessmentCommentInput>(MUTATIONS.UPDATE_ASSESSMENT_COMMENT);

  // HANDLERS
  const handleEditComment = () => {
    if (!commentEditorRef.current?.getContent()) return;
    updateComment({
      variables: {
        input: {
          assessmentId: c.assessmentId!,
          assessmentQuestionId: c.assessmentQuestionId!,
          assessmentCommentId: c.assessmentCommentId!,
          assessmentCommentDescription: commentEditorRef.current.getContent(),
        },
      },
      update: (cache, { data }) => {
        console.log(data);
        if (data?.updateAssessmentComment.actionStatus === ActionStatus.Success) {
          const existingComments = cache.readQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
            query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
            variables: {
              assessmentQuestionId: q.assessmentQuestionId!,
            },
          });
          console.log(existingComments);
          console.log("writing");
          const newComments: AssessmentComment[] = existingComments!.assessmentCommentsByQuestionId.map((ec) => {
            if (ec.assessmentCommentId === data.updateAssessmentComment.assessmentCommentId) {
              return {
                ...ec,
                assessmentCommentDescription: data.updateAssessmentComment.assessmentCommentDescription,
                modifiedBy: data.updateAssessmentComment.modifiedBy,
                modifiedDate: data.updateAssessmentComment.modifiedDate,
              };
            }
            return ec;
          });
          cache.writeQuery<AssessmentQuestionCommentsOutput, GetAssessmentQuestionCommentsInput>({
            query: QUERIES.GET_ASSESSMENT_COMMENTS_BY_QUESTION,
            variables: {
              assessmentQuestionId: q.assessmentQuestionId!,
            },
            data: {
              assessmentCommentsByQuestionId: [...newComments],
            },
          });
        }
      },
    });
  };

  const handleDiscardComment = () => {
    commentEditorRef.current?.setContent(c.assessmentCommentDescription!);
    setIsEditing(false);
  };

  const canEdit = (c: AssessmentComment) => {
    return isAdmin || c.createdBy === userId;
  };

  // USEEFFECT
  useEffect(() => {
    NotificationUtils.notifyUpdate(updatedCommentData?.updateAssessmentComment.actionStatus!, updatedCommentData?.updateAssessmentComment.actionStatusMessage!, () => setIsEditing(false));
  }, [updatedCommentData]);

  // RENDER
  return (
    <>
      {/* Dialog */}
      {showDeleteDialog && <DeleteCommentDialog assessmentComment={c} onClose={() => setShowDeleteDialog(false)} />}

      <Paper key={c.assessmentCommentId} sx={{ mt: 1, pl: 1, pt: 0.5, pb: 0.5, pr: 1 }}>
        <Grid container sx={{ ".MuiTypography-root": { fontSize: 14 } }}>
          {/* Created By */}
          <Grid item xs={5}>
            <Typography component="span" fontWeight="bold">
              {c.createdByFullName}
            </Typography>
            <Typography component="span" ml={1}>
              {DateUtils.formatCustom("MMM dd, yyyy 'at' h:mm aa", c.createdDate)}
            </Typography>
          </Grid>

          {/* Modified By */}
          <Grid item xs>
            {c.isModified && (
              <>
                <Typography component="span" fontStyle="italic">
                  {"Last Modified By "}
                </Typography>
                <Typography component="span" fontWeight="bold">
                  {c.modifiedByFullName}
                </Typography>
                <Typography component="span">{DateUtils.formatCustom("MMM dd, yyyy 'at' h:mm aa", c.modifiedDate)}</Typography>
              </>
            )}
          </Grid>

          {/* Actions */}
          <Grid item xs={"auto"} minWidth={70} textAlign="end">
            {isEditing ? (
              <>
                <Tooltip title="Discard draft">
                  <IconButton onClick={handleDiscardComment} color="default" sx={{ p: "4px" }}>
                    <UndoOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                {loadingUpdateComment ? (
                  <Box display="inline-flex" p="4px">
                    <CircularProgress size="1em" />
                  </Box>
                ) : (
                  <Tooltip title="Save comment">
                    <IconButton onClick={handleEditComment} color="default" sx={{ p: "4px" }}>
                      <SaveOutlinedIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ) : (
              canEdit(c) && (
                <>
                  <Tooltip title="Edit comment">
                    <IconButton onClick={() => setIsEditing(true)} color="default" sx={{ p: "4px" }}>
                      <EditOutlinedIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove comment">
                    <IconButton onClick={() => setShowDeleteDialog(true)} color="default" sx={{ p: "4px" }}>
                      <DeleteOutlineIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </>
              )
            )}
          </Grid>

          {/* Comment */}
          <Grid item xs={12}>
            {isEditing ? (
              // <TextField
              //   fullWidth
              //   multiline
              //   rows={3}
              //   value={proposedComment}
              //   onChange={(e) => setProposedComment(e.target.value)}
              //   InputProps={{
              //     style: {
              //       paddingTop: 6,
              //       paddingBottom: 6,
              //       fontSize: 14,
              //     },
              //   }}
              // />
              <FormatEditor text={c.assessmentCommentDescription} editorRef={commentEditorRef} height={180} toolbar="basic" />
            ) : (
              <Typography sx={{ overflow: "auto", maxHeight: 150 }}>{parse(c.assessmentCommentDescription ?? "")}</Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
