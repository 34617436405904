import { Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { appNotification } from "../../apollo/PortalApolloProvider";
import FileInput from "./FileInput";
import AddEditFolderDialog from "./AddEditFolderDialog";
import FolderFileButton from "./FolderFileButton";
import { PortalFolder } from "../../models/PortalFolder";
import { PortalFile } from "../../models/PortalFile";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BlobOutput } from "../../graphQL/outputModels/Blob.output";
import { QUERIES } from "../../graphQL/queries";
import { FolderOutput } from "../../graphQL/outputModels/Folder.output";
import { PortalOutput } from "../../graphQL/outputModels/Portal.output";
import { AddedFileOutput } from "../../graphQL/outputModels/AddedFile.output";
import { MUTATIONS } from "../../graphQL/mutations";
import { GetFolderInput } from "../../graphQL/inputModels/getFolder.input";
import DocumentContextMenu from "./DocumentContextMenu";
import DeleteFolderDialog from "./DeleteFolderDIalog";
import { UpdatedFileOutput } from "../../graphQL/outputModels/UpdatedFile.output";
import { UpdateFileInput } from "../../graphQL/inputModels/updateFile.input";
import useUploadQuery from "../../hooks/useUploadQuery";
import useUser from "../../hooks/useUser";
import DeleteFileDialog from "./DeleteFileDIalog";
import { useLocation } from "react-router-dom";
import { DownloadUtils } from "../../utils/download.utils";
import { ActionStatusNumber } from "../../models/ActionStatus";
import { GetPortalFolderInput } from "../../graphQL/inputModels/getPortalFolder.input";
import { UserContext } from "../../UserContextProvider";

interface IFileExplorerComponentProps {
  portalId?: number;
}

export default function FileExplorerComponent({ portalId }: IFileExplorerComponentProps) {
  // SETUP
  const location = useLocation();
  const [currDir, setCurrDir] = useState<PortalFolder | undefined>();
  const [currFolderContext, setCurrFolderContext] = useState<PortalFolder>();
  const [breadcrumbs, setBreadcrumbs] = useState<PortalFolder[]>([]);
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [anchorElDocContext, setAnchorElDocContext] = useState<null | HTMLElement>(null);
  const docContextOpen = Boolean(anchorElDocContext);
  const [currFileContext, setCurrFileContext] = useState<PortalFile>();
  const [currRenamingFile, setCurrRenamingFile] = useState(false);
  const [masterLoading, setMasterLoading] = useState(false);
  const user = useContext(UserContext);

  // HOOKS
  const { callApi: uploadFile, loading: loadingUpload, data: dataUpload } = useUploadQuery<AddedFileOutput>();
  const { isAdmin } = useUser();

  // GRAPHQL
  const [getPortal, { loading: loadingPortal, data: portalData }] = useLazyQuery<PortalOutput, GetPortalFolderInput>(QUERIES.GET_PORTAL_FOLDER, {
    fetchPolicy: "network-only",
    variables: {
      portalId: portalId ?? 0,
      memberId: user?.activeMember.companyId ?? 0
    },
  });

  const [getFolder, { loading: loadingFolder, data: rootFolderData }] = useLazyQuery<FolderOutput, GetFolderInput>(QUERIES.GET_FOLDER, {
    fetchPolicy: "network-only",
  });

  const [getBlob, { data: blobData }] = useLazyQuery<BlobOutput>(QUERIES.GET_BLOB, {
    fetchPolicy: "cache-and-network",
  });

  const [updateFile] = useMutation<UpdatedFileOutput, UpdateFileInput>(MUTATIONS.UPDATE_FILE);

  const loading = masterLoading || loadingPortal || loadingFolder || loadingUpload;

  // HANDLERS
  const openDocContext = (e: MouseEvent, folder?: PortalFolder, file?: PortalFile) => {
    setAnchorElDocContext(e.currentTarget as HTMLElement);
    setCurrFileContext(file);
    setCurrFolderContext(folder);
  };

  const closeContext = () => {
    setAnchorElDocContext(null);
  };

  const handleFolderClick = (clickedFolder: PortalFolder, moveBreadcrumb: number = 1) => {
    setCurrDir(clickedFolder);
    getFolder({
      variables: {
        folderId: clickedFolder.folderId!,
      },
    });
    if (moveBreadcrumb >= 1) setBreadcrumbs([...breadcrumbs, clickedFolder]);
    else {
      setBreadcrumbs([...breadcrumbs.splice(0, -moveBreadcrumb)]);
    }
    setCurrRenamingFile(false);
    setCurrFolderContext(undefined);
    setCurrFileContext(undefined);
  };

  const handleFileClick = (file: PortalFile) => {
    console.log(`Downloading File: ${file.fileName}`);
    getBlob({
      variables: {
        fileId: file.fileId,
      },
    });
    appNotification({
      severity: "info",
      message: `Downloading ${file.fileName}`,
      time: 3000,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, parentFolderId?: number) => {
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        let f: File = e.target.files[i];
        if (parentFolderId || currFolderContext?.folderId || currDir?.folderId) {
          setMasterLoading(true);
          uploadFile({
            file: f,
            parentFolderId: parentFolderId ?? currFolderContext?.folderId ?? currDir?.folderId,
          });
        }
      }
    }
  };

  const handleNewFolderClick = () => {
    setAddEditDialogOpen(true);
    setCurrFolderContext(undefined);
  };

  const handleCloseDialog = (reload: boolean) => {
    setAddEditDialogOpen(false);
    setDeleteFolderDialogOpen(false);
    setDeleteFileDialogOpen(false);
    setCurrFolderContext(undefined);
    setCurrFileContext(undefined);
    if (reload) {
      getFolder({
        variables: {
          folderId: currDir!.folderId!,
        },
      });
    }
  };

  const handleEditFolder = () => {
    setAddEditDialogOpen(true);
  };

  const handleDeleteFolder = () => {
    setDeleteFolderDialogOpen(true);
  };

  const handleDeleteFile = () => {
    setDeleteFileDialogOpen(true);
  };

  const handleSubmitRenameFile = (file: PortalFile, newName: string) => {
    console.log("submitting rename to api");
    updateFile({
      variables: {
        input: {
          fileId: file.fileId!,
          newFileName: newName,
        },
      },
    });
    setCurrRenamingFile(false);
    setCurrFileContext(undefined);
  };

  // USEEFFECTS
  useEffect(() => {
    if (portalId) {
      setCurrDir(undefined);
      setBreadcrumbs([]);
      getPortal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalId, location]);

  useEffect(() => {
    setCurrDir(rootFolderData?.folder);
    setMasterLoading(false);
  }, [rootFolderData]);

  useEffect(() => {
    let portalRootFolder = portalData?.portal.folder;
    if (portalRootFolder) {
      console.log("changing");
      if (
        // prevent any accidental folder clicks... like when re-rendering during development
        breadcrumbs.length === 0 ||
        !breadcrumbs.some((c) => c.companyId === portalRootFolder?.companyId)
      )
        handleFolderClick(portalRootFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalData]);

  // download the file
  useEffect(() => {
    if (blobData?.blob.url) {
      DownloadUtils.downloadURI(blobData.blob.url);
    }
  }, [blobData]);

  useEffect(() => {
    if (dataUpload?.actionStatus === ActionStatusNumber.Success) {
      appNotification({
        severity: "success",
        message: `Uploaded ${dataUpload.fileName}`,
      });
      getFolder({
        variables: {
          folderId: currDir!.folderId!,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpload]);

  // RENDER
  return (
    <Paper
      elevation={12}
      sx={{
        borderRadius: 2,
        padding: 2,
        minWidth: 400,
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        {/* dialogs */}
        {addEditDialogOpen && (
          <AddEditFolderDialog
            open={addEditDialogOpen}
            onClose={handleCloseDialog}
            editingFolder={currFolderContext}
            parentFolder={currDir}
            allowMemberSelection={currFolderContext?.parentId === portalData?.portal.folder?.folderId || (!currFolderContext && currDir?.folderId === portalData?.portal.folder?.folderId)}
          />
        )}
        {deleteFolderDialogOpen && currFolderContext && <DeleteFolderDialog open={deleteFolderDialogOpen} onClose={handleCloseDialog} folder={currFolderContext} />}
        {deleteFileDialogOpen && currFileContext && <DeleteFileDialog open={deleteFileDialogOpen} onClose={handleCloseDialog} file={currFileContext} />}

        <Grid container height="calc(100vh - 400px)" minHeight={200}>
          {/* breadcrumbs */}
          <Grid item xs={12} height={60} overflow="hidden" textAlign="start">
            {breadcrumbs.map((bc, i) => (
              <Button key={i} onClick={() => handleFolderClick(bc, -1 - i)} sx={{ fontFamily: "AvenirBlack" }}>
                {`> ${bc.folderName}`}
              </Button>
            ))}
          </Grid>

          {/* search bar */}
          {/* <Grid item xs={5} md={4} height={60}>
            <Box sx={{ display: "flex", backgroundColor: "white", ml: "auto" }}>
              <InputBase
                sx={{ flexGrow: 1, pl: 1 }}
                placeholder="Search..."
                inputProps={{ "aria-label": "search..." }}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid> */}

          {/* current company label */}
          {/* <Grid item xs={5} md={4} height={60}>
            <Box sx={{ ml: "auto", textAlign: "end" }}>
              {`Current Company: ${
                currDir?.allCompanies === true
                  ? "ALL"
                  : currDir?.companyId ?? "-"
              }`}
            </Box>
          </Grid> */}

          {/* folders/files */}
          <Grid item xs={12} height="calc(100vh - 500px)" minHeight={100} overflow="auto">
            {loading ? (
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box display="flex" flexWrap="wrap">
                {currDir?.folders?.map((d) => (
                  <FolderFileButton key={`folder-${d.folderId}`} folder={d} handleFolderClick={handleFolderClick} handleRightClick={openDocContext} />
                ))}
                {currDir?.files?.map((f) => (
                  <FolderFileButton
                    key={`file-${f.fileId}`}
                    file={f}
                    handleFileClick={handleFileClick}
                    handleRightClick={openDocContext}
                    handleFileRename={handleSubmitRenameFile}
                    currRenaming={f.fileId === currFileContext?.fileId && currRenamingFile}
                  />
                ))}
                <DocumentContextMenu
                  open={docContextOpen}
                  onClose={closeContext}
                  anchorEl={anchorElDocContext}
                  isAdmin={isAdmin}
                  folderContext={currFolderContext}
                  fileContext={currFileContext}
                  handleFileChange={handleFileChange}
                  handleFileRename={() => setCurrRenamingFile(true)}
                  handleFileDownload={handleFileClick}
                  handleOpenFolder={handleFolderClick}
                  handleEditFolder={handleEditFolder}
                  handleDeleteFolder={handleDeleteFolder}
                  handleDeleteFile={handleDeleteFile}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        {isAdmin && (
          <Box mt={3} display="flex" width="100%">
            <Button
              variant="contained"
              color="orange"
              disabled={loading}
              onClick={() => {
                setCurrFolderContext(currDir);
                setCurrFileContext(undefined);
                handleEditFolder();
              }}
            >
              Edit Folder
            </Button>
            <Button variant="contained" color="orange" disabled={loading} sx={{ ml: "auto", mr: 2 }} onClick={handleNewFolderClick}>
              New Folder
            </Button>
            <FileInput width="fit-content" onFileChange={handleFileChange} type="file">
              <Button variant="contained" color="orange" component="span" disabled={loading}>
                Upload File
              </Button>
            </FileInput>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
