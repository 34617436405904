import React, { useCallback, useEffect, useState } from "react"
import { DownloadUtils } from "../utils/download.utils";
import { FetchUtils } from "../utils/fetch.utils";

type ExportJob = { id: string, name: string, processing: boolean, task: Task }
type vars = {
    workspaceId: string;
    reportId: string
    reportName: string;
    tasks?: ExportJob[];
}
type Task = Promise<void> | void

export type ExportType = (params: vars) => void;

type ExportQueryRet = {
    execute: ExportType;
    processing: boolean;
    tasks: ExportJob[];
}
let listeners: React.Dispatch<React.SetStateAction<any>>[] = [];
let state = { tasks: [] as ExportJob[] };

export default function useExportQuery(): ExportQueryRet {
    const newListener = useState()[1];


    useEffect(() => {
        listeners.push(newListener);

        return (() => {
            listeners = listeners.filter(listener => listener !== newListener);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTasks = (newTasks: ExportJob[]) => {
        state = { ...state, tasks: [...newTasks] };
        listeners.forEach((listener) => {
            listener(state);
        });
    };

    const execute = useCallback(({ workspaceId, reportId, reportName }: vars) => {

        async function run(id: string, workspaceId: string, reportId: string) {
            console.log(`EXECUTING export: ${reportName}`)

            // MOCK ASYNC CALL
            // await new Promise(resolve =>
            //     setTimeout(resolve, 10000)
            // );
            // return;

            try {
                const response = await FetchUtils.EXPORT_FILE(
                    workspaceId,
                    reportId
                );
                if (response) {
                    DownloadUtils.downloadBlob(response, reportName)
                }
            }
            catch (e: any) {
                console.log(e);
            }

            console.log(`DONE with ${reportName}`);
        }


        if (workspaceId && reportId) {
            const id = crypto.randomUUID();
            const task1 = run(id, workspaceId, reportId).finally(() => {
                console.log("FINISHING");
                setTasks([...state.tasks.filter((t) => t.id !== id)])
            });

            console.log(state.tasks);
            let h = [...state.tasks];
            h.push({ id, task: Promise.resolve(task1), name: reportName, processing: true });
            setTasks([...h])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return {
        execute,
        tasks: state.tasks,
        processing: state.tasks.some(t => t.processing)
    };
}